import React, { useEffect, useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import styled from 'styled-components';

const ChipInputContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
`;

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ChipsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
`;

const Chip = styled.li`
  background-color: #e0e0e0;
  color: #333;
  border-radius: 20px;
  padding: 6px 12px;
  margin: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  transition: all 0.2s ease;

  &:hover {
    background-color: #d0d0d0;
  }
`;

const ChipText = styled.span`
  margin-right: 6px;
`;

const DeleteIcon = styled(IoCloseCircleOutline)`
  cursor: pointer;
  font-size: 18px;
  color: #666;
  transition: color 0.2s ease;

  &:hover {
    color: #f44336;
  }
`;

const Input = styled.input`
  flex-grow: 1;
  border: none;
  background-color: transparent;
  font-size: 16px;
  padding: 8px;
  margin: 4px;
  outline: none;

  &::placeholder {
    color: #999;
  }
`;

const ErrorMessage = styled.p`
  color: #f44336;
  font-size: 14px;
  margin-top: 8px;
  text-align: left;
`;

function ChipInput({setRequirement,requirement}) {
  const [text, setText] = useState("");
  const [chips, setChips] = useState([requirement]);
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    if (requirement) {
      const initialChips = Array.isArray(requirement) ? requirement : [requirement];
      setChips(initialChips);
    }
  }, [requirement]);


  function removeChip(chipToRemove) {
    const updatedChips = chips.filter((chip) => chip !== chipToRemove);
    setChips(updatedChips);
    setRequirement(updatedChips);
  }

  function handlePressEnter(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!text.trim()) return;
      if (chips.includes(text.trim())) {
        return setValidationError("This requirement already exists.");
      }
      const newChips = [...chips, text.trim()];
      setChips(newChips);
      setRequirement(newChips);
      setText("");
      setValidationError("");
    }
  }

  return (
    <ChipInputContainer>
      <InputContainer>
        <ChipsList>
          {chips.map((chip, index) => (
            <Chip key={index}>
              <ChipText>{chip}</ChipText>
              <DeleteIcon onClick={() => removeChip(chip)} tabIndex="0" />
            </Chip>
          ))}
        </ChipsList>
        <Input
          type="text"
          placeholder="Type and press Enter to add requirements"
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handlePressEnter}
        />
      </InputContainer>
      {validationError && <ErrorMessage>{validationError}</ErrorMessage>}
    </ChipInputContainer>
  );
}

export default ChipInput;