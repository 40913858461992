import React, { useState, useRef } from "react";
import "./login.css";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Button, Stack, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

function ForgotPassword() {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const toast = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  // Extract uidb64 and token from URL parameters
  const searchParams = new URLSearchParams(location.search);
  const uide = searchParams.get("uide");
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiUrlsoket = process.env.REACT_APP_API_URL_SOKET;
  const resetPassword = async (e) => {
    e.preventDefault();

    if (password !== password2) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Passwords do not match",
        life: 3000,
      });
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}recruiters/api/reset-password/`,
        {
          uide,
          new_password: password,
          token: searchParams.get("token"),
        }
      );

      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Password reset successful",
          life: 3000,
        });
        // Redirect to login page after successful reset
        setTimeout(() => navigate("/login"), 3000);
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.error || "An error occurred",
        life: 3000,
      });
    }
  };

  return (
    <div className="Auth-form-container">
      <Toast ref={toast} />
      <form className="Auth-form" onSubmit={resetPassword}>
        <div className="Auth-form-content">
          <center>
          <img
            src="https://res.cloudinary.com/dwckvxlca/image/upload/v1726419523/sds674s2tg5so9oxnbw3.png"
            alt="."
            // style={{ width: "90px", marginRight: "16px" }}
          />          </center>
          <div className="form-group mt-3">
            <Stack spacing={3} margin={0}>
              <TextField
                type="password"
                label="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <TextField
                type="password"
                label="Confirm New Password"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                required
              />
            </Stack>
          </div>
          <div className="d-grid gap-2 mt-3">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              sx={{
                bgcolor: "#9cd6d1",
                ":hover": {
                  bgcolor: "#009688",
                },
              }}
            >
              Reset Password
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
