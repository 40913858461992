import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import AdminLayout from "layouts/Admin.js";
import Login from "views/Auth/Login";
import AdminA from "./views/admin/AdminA";
import "./views/index.css";
import Home from "views/client/Home";
import PostDetails from "views/Posts/PostDetails";
import InternDetails from "views/interns/InternshipDetails";
import ForgotPassword from "views/Auth/ForgotPassword";
import store from "./Redux/store";
import { Provider } from "react-redux";
import ApplicationForm from "views/client/ApplicationForm ";
import Profile from "views/client/profile";
import SavedPosts from "views/Posts/SavedPosts";
import PostsApplied from "views/Posts/PostsApplied";
import AddPost from "views/client/AddPost";
import ViewJob from "views/client/viewjob";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'; 
import CreateRecruiter from "views/admin/createRecruiter";
import ListRecruiter from "views/admin/ListRecruiter";
const root = ReactDOM.createRoot(document.getElementById("root"));

// root.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <Routes>
//         <Route path="/admin/*" element={<AdminLayout />} />
//         <Route path="/" element={<Navigate to="/home" replace />} />
//         <Route path="/login" element={<Login />} />
//         <Route path="/home" element={<Home />} />
//         <Route path="/application" element={<ApplicationForm />} />
//         <Route path="/profile" element={<Profile />} />
//         <Route path="/saved_posts" element={<SavedPosts />} />
//         <Route path="/post_applied" element={<PostsApplied />} />
//         <Route path="/addpost" element={<AddPost />} />
//         <Route path="/viewjob" element={<ViewJob />} />
//         <Route path="/forgote_password" element={<ForgotPassword />} />
//         <Route
//           path="/post/Postdetails/:postid/:imageSrc/:title/:description/:requirement?/:deadline/:company"
//           element={<PostDetails />}
//         />
//         <Route
//           path="/intern/Postdetails/:internid/:imageSrc/:title/:description/:requirement?/:deadline/:company"
//           element={<InternDetails />}
//         />

//         {/* Admin routes */}
//         <Route path="/AdminA/*" element={<AdminA />}>
//           <Route path="createRecruiter" element={<CreateRecruiter />} />
//           <Route path="ListRecruiter" element={<ListRecruiter />} />
//         </Route>
//         {/* Catch-all route */}
//         <Route path="*" element={<Navigate to="/home" replace />} />
//       </Routes>
//     </BrowserRouter>
//   </Provider>
// );
// serviceWorkerRegistration.register();

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/application" element={<ApplicationForm />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/saved_posts" element={<SavedPosts />} />
        <Route path="/post_applied" element={<PostsApplied />} />
        <Route path="/addpost" element={<AddPost />} />
        <Route path="/viewjob" element={<ViewJob />} />
        <Route path="/forgote_password" element={<ForgotPassword />} />
        <Route
          path="/post/Postdetails/:postid/:imageSrc/:title/:description/:requirement?/:deadline/:company"
          element={<PostDetails />}
        />
        <Route
          path="/intern/Postdetails/:internid/:imageSrc/:title/:description/:requirement?/:deadline/:company"
          element={<InternDetails />}
        />
        {/* Admin routes */}
        <Route path="/AdminA/*" element={<AdminA />}>
          <Route path="createRecruiter" element={<CreateRecruiter />} />
          <Route path="ListRecruiter" element={<ListRecruiter />} />
        </Route>
        {/* Catch-all route */}
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);

// Modified service worker registration
// serviceWorkerRegistration.unregister(); // Change this to unregister for now