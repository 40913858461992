import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import PerfectScrollbar from "perfect-scrollbar";
import { COLORS } from "colors/color";
import useSignalingStore from "../../views/signalingStore";

const SidebarContainer = styled.div`
  width: 280px;
  background: ${COLORS.signIn};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-right: 1px solid rgba(255, 255, 255, 0.18);
  color: ${COLORS.signIn};
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
`;

const Logo = styled.div`
  padding: 30px 20px;
  text-align: center;
  img {
    max-width: 140px;
    transition: all 0.3s ease;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
    &:hover {
      transform: scale(1.05) rotate(2deg);
    }
  }
`;

const NavContainer = styled.nav`
  padding: 20px 0;
  flex-grow: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
  }
`;

const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 15px 25px;
  color: ${COLORS.white};
  text-decoration: none;
  transition: all 0.3s ease;
  margin: 8px 15px;
  border-radius: 16px 0 0 16px;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, ${COLORS.white}, ${COLORS.signIn});
    opacity: 0;
    transition: opacity 0.3s ease, width 0.3s ease;
    z-index: -1;
  }

  &:hover {
    color: ${COLORS.white};
    transform: translateX(10px);
    box-shadow: -4px 0 15px rgba(0, 0, 0, 0.1);
    &:before {
      opacity: 0.8;
      width: calc(100% + 10px);
    }
  }

  &.active {
    background: ${COLORS.bgColor};
    box-shadow: -4px 0 32px 0 ${COLORS.white};
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-left: 4px solid ${COLORS.Button_OnHold};
    color: ${COLORS.signIn};
    padding-left: 21px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -20px;
    width: 20px;
    height: 100%;
    background: ${COLORS.bgColor};
    border-radius: 0 10px 10px 0;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: scaleY(0);
    transform-origin: center right;
  }

  &:hover:after,
  &.active:after {
    opacity: 1;
    transform: scaleY(1);
  }

  i {
    margin-right: 15px;
    font-size: 20px;
    transition: transform 0.3s ease;
  }

  &:hover i {
    transform: scale(1.2);
  }

  p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
  }
`;
function Sidebar({ routes }) {
  const location = useLocation();
  const sidebar = useRef();
  let ps;
  const [totalNotifications, setTotalNotifications] = useState(0);
  const {
    notificationCounts,
    getTotalNotifications,
    setInitialNotificationCounts,
    updateNotificationCount,
    addMessageNotification,
    signalingServer2,
    setSignalingServer2,
  } = useSignalingStore();
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiUrlsoket = process.env.REACT_APP_API_URL_SOKET;
  useEffect(() => {
    setTotalNotifications(getTotalNotifications());
  }, [notificationCounts, getTotalNotifications]);
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    // Fetch unread message counts and connect to WebSocket when component mounts
    fetchUnreadMessageCounts();
    const cleanupSocket = connectToSocket();

    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      if (cleanupSocket) cleanupSocket();
    };
  }, []);

  useEffect(() => {
    setTotalNotifications(getTotalNotifications());
  }, [notificationCounts, getTotalNotifications]);

  const fetchUnreadMessageCounts = async () => {
    try {
      const response = await fetch(
        `${apiUrl}messaging/api/unread-message-counts/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch unread message counts");
      }
      const data = await response.json();
      setInitialNotificationCounts(data);
    } catch (error) {
      console.error("Error fetching unread message counts:", error);
    }
  };

  const connectToSocket = () => {
    const senderId = getUserIdFromAccessToken();
    if (senderId) {
      const signalingServerUrl = `${apiUrlsoket}ws/chat/${senderId}/0/`;
      const socket = new WebSocket(signalingServerUrl);

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "chat_message") {
          console.log("testoo:", data);
          updateNotificationCount(data.sender_id);
          addMessageNotification({
            id: Date.now(),
            message: data.message,
          });
        }
      };

      return () => {
        socket.close();
      };
    }
  };

  const getUserIdFromAccessToken = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      return decodedToken.user_id;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  };

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  return (
    <SidebarContainer>
      <Logo>
      <img
        src="https://res.cloudinary.com/dwckvxlca/image/upload/v1726419523/sds674s2tg5so9oxnbw3.png"
        alt="."
        // style={{ width: "90px", marginRight: "16px" }}
      />
      </Logo>
      <NavContainer ref={sidebar}>
        {routes.map((route, index) => (
          <NavItem
            key={index}
            to={route.layout + route.path}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <i className={route.icon} />
            <p>{route.name}</p>
            {route.name === "Chat" && totalNotifications > 0 && (
              <NotificationBadge>{totalNotifications}</NotificationBadge>
            )}
          </NavItem>
        ))}
      </NavContainer>
    </SidebarContainer>
  );
}

const NotificationBadge = styled.span`
  background-color: gray;
  color: ${COLORS.white};
  margin-right: 15px;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

export default Sidebar;
