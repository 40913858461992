import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import DataInternsTable from "./internDetails";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import "./intern.css";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogTitle from "@mui/material/DialogTitle";
import Lottie from "react-lottie";
import { Alert, AlertTitle, Autocomplete } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Button,
  IconButton,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
  useTheme,
  Box,
  Snackbar,
  Tooltip,
  Chip,
  Divider,
  Paper,
} from "@mui/material";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { InputTextarea } from "primereact/inputtextarea";
import { connect } from "react-redux"; // Import connect from react-redux
import { setUserId } from "../../Redux/actions"; // Import your action
import { styled } from "@mui/material/styles";
import successAnimation from "../client/Animation - 1716461046736.json";
// import InputMask from "react-input-mask";
import { COLORS } from "colors/color";
import SendIcon from "@mui/icons-material/Send";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import ImageUploadButton from "views/ImageUploadButton";
import dayjs from "dayjs";
import ChipInput from "components/Chips/chip";
// import ChipInput from "components/Chips/chipsWithInput";

// const useStyles = makeStyles((theme) => ({
//   dialogPaper: {
//     width: "50vw",
//     borderRadius: "15px", // Adjust the radius as needed
//     position: "fixed",
//     top: "35%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//   },
// }));
const HoverIconButton = styled(IconButton)(({ theme }) => ({
  color: "#87ceeb",
  "&:hover": {
    color: "#1e90ff", // Change this to the color you want on hover
  },
}));

const InternshippsDemo = () => {
  const theme = useTheme();
  const [internship, setInternship] = useState([]);
  const [deleteInternshipDialog, setDeleteInternshipDialog] = useState(false);
  const [deleteInternshippsDialog, setDeleteInternshippsDialog] =
    useState(false);
  const [selectedInternshipps, setSelectedInternshipps] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const [open, openchange] = useState(false);
  const [open2, openchange2] = useState(false);
  const [visible] = React.useState(true);
  const [state, statechange] = useState(false);
  const [finishing, setfinishing] = useState(false);
  const [internshipSubject, setInternshipSubject] = useState("");
  const [email, setEmail] = useState("");
  const [internshipDeadline, setInternshipDeadline] = useState("");
  const [internshipLocation, setInternshipLocation] = useState("");
  const [internDetails, setInternDetails] = useState(false);
  const [isScrappingLaunched, setIsScrappingLaunched] = useState(false);
  const [code, setCode] = useState("");
  const [rowEmail, setRowEmail] = useState("");
  const [emailForSending, setEmailForSending] = useState("");
  const [copied, setCopied] = useState(false);
  const [postDescription, setPostDescription] = useState("");
  const [postImage, setPostImage] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [requirement, setRequirement] = useState("");
  // const switcher = useContext(SwitcherContext);
  const [switcherValue, setSwitcherValue] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const userId = getUserIdFromAccessToken();
  const isEmpty = false;
  const functionopenpopup = () => {
    console.log("functionopenpopup");
    openchange(true);
  };
  const closepopup = () => {
    openchange(!visible);
  };

  const openpopup2 = () => {
    openchange2(true);
  };
  const closepopup2 = () => {
    openchange2(false);
  };
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    Load();
    console.log("user id is : ", userId);
  }, []);

  const handleClose = () => {
    hideDeleteInternshippsDialog();
  };

  const hideDeleteInternshippsDialog = () => {
    setDeleteInternshippsDialog(false);
  };
  const hidde_finishing = () => {
    setfinishing(false);
    setInternDetails(false);
    statechange(false);
    setIsScrappingLaunched(false);
    Load();
  };

  const confirmDeleteInternshipp = (internship) => {
    // setInternship(internship);
    // setDeleteInternshippDialog(true);
    setDeleteInternshippsDialog(true);
    deleteInternshipById(internship.id);
    // deleteInternshipp()
  };

  //----------------------------------------------------------------------------------

  const StyledPaper = styled(Paper)(({ theme }) => ({
    position: "absolute",
    top: "-20%",
    left: "50%",
    transform: "translateX(-50%)",
    width: "90%",
    padding: theme.spacing(3),
    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
    backdropFilter: "blur(4px)",
    borderRadius: "10px",
    border: "1px solid rgba(255, 255, 255, 0.18)",
    zIndex: 1300,
  }));

  const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
      borderRadius: "15px",
      boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
      backdropFilter: "blur(4px)",
      border: "1px solid rgba(255, 255, 255, 0.18)",
    },
  }));

  const ActionButton = styled(Button)({
    color: COLORS.signIn,
    border: "1px solid",
    borderColor: COLORS.grey,
    backgroundColor: COLORS.white,
    "&:hover": {
      backgroundColor: COLORS.Button_OnHold,
      borderColor: COLORS.signIn,
      color: COLORS.signIn,
    },
  });
  const [showMessagePaper, setShowMessagePaper] = useState(false);

  const deleteInternshipp = () => {
    deleteInternshipById(MyRowData);

    setDeleteInternshipDialog(false);
    console.log("test");
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Internship Deleted",
      life: 3000,
    });
  };

  const confirmDeleteSelected = () => {
    setDeleteInternshippsDialog(true);
  };

  const deleteSelectedInternshipps = () => {
    console.log("selected items : ", selectedInternshipps);
    selectedInternshipps.map((internship) => {
      confirmDeleteInternshipp(internship);
      console.log("internship number ", internship.id, "hase been deleted");
      setDeleteInternshippsDialog(false);
      setDeleteInternshipDialog(false);
    });

    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "internships Deleted",
      life: 3000,
    });
  };
  const [MyRowData, setMyRowData] = useState("");
  const deleteInternship = (rowData) => {
    setMyRowData(rowData.id);
    setDeleteInternshipDialog(true);
  };

  const [interns, setInterns] = useState([]);

  // async function LoadIntern(id) {
  //   try {
  //     const response = await fetch(${apiUrl}ai/all_Internship_Interns/${id}/);
  //     // if (!response.ok) {
  //     //   throw new Error("Failed to fetch data");
  //     // }
  //     const json = await response.json();
  //     // if (!Array.isArray(json)) {
  //     //   throw new Error("Expected an array");
  //     // }
  //     setInterns(json);
  //     console.log("the interns are: ", json);
  //   } catch (error) {
  //     console.error("Error fetching interns:", error.message);
  //     // Handle error state, e.g., set an error flag or show a message to the user
  //   }
  // }

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  useEffect(() => {
    console.log("verif Code is ", code);
  }, [code]);
  const LoadIntern = async (id) => {
    try {
      console.log("id is : ", id);
      const response = await fetch(
        `${apiUrl}ai/all_Internship_Interns/${id}/`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"), // Add a space after 'Bearer'
          },
        }
      );
      console.log("response : ", response);
      if (response.status === 401) {
        console.log("Unauthorized. Redirecting to login page...");
        navigate("/login");
        // Stop execution of the function after redirecting
        return; // or throw new Error('Unauthorized'); depending on your requirement
      }
      const result = await response.json();
      console.log("Data is : ", result);
      setInterns(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //   const handlePost = async () => {
  //     try {
  //         const response = await axios.post('http://your-backend-url.com/create-linkedin-ugc-post/', {
  //             text: text,
  //             media_url: mediaUrl
  //         });

  //         if (response.status === 201) {
  //             setResponseMessage('UGC post created successfully');
  //         } else {
  //             setResponseMessage('Failed to create UGC post');
  //         }
  //     } catch (error) {
  //         console.error('Error creating UGC post:', error);
  //         setResponseMessage('Error creating UGC post');
  //     }
  // };
  // const handleCreatePost = async (postText) => {
  //   try {
  //       const response = await axios.post(
  //           `${apiUrl}ai/create-linkedin-ugc-post/`,
  //           {
  //               text: postText
  //           },
  //           {
  //               headers: {
  //                   "Content-Type": "application/json",
  //                   "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
  //               }
  //           }
  //       );
  //       console.log(response.data);
  //       // Handle success
  //   } catch (error) {
  //       console.error('Error creating LinkedIn UGC post:', error);
  //       // Handle error
  //   }
  // };

  useEffect(() => {
    console.log("req is : ", requirement);
  }, [open, requirement]);

  // const handleCreatePost = async () => {
  //   console.log(
  //     "props",
  //     internshipSubject,
  //     email,
  //     internshipDeadline,
  //     internshipLocation,
  //     postImage,
  //     postDescription,
  //     requirement
  //   );
  //   try {
  //     const linkedInAccessToken =
  //       "xx";

  //     const response = await axios.post(
  //       `${apiUrl}ai/api/linkedin/post/`,
  //       {
  //         subject: internshipSubject,
  //         access_token: linkedInAccessToken,
  //         email: email,
  //         internshipDeadline: internshipDeadline,
  //         internshipLocation: internshipLocation,
  //         postImage: postImage,
  //         postDescription: postDescription,
  //         requirement: requirement,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         },
  //       }
  //     );

  //     console.log(response.data);
  //   } catch (error) {
  //     if (error.response) {
  //       // The request was made, and the server responded with a status code
  //       // that falls out of the range of 2xx
  //       console.error("Error response data:", error.response.data);
  //       console.error("Error response status:", error.response.status);
  //       console.error("Error response headers:", error.response.headers);
  //     } else if (error.request) {
  //       // The request was made but no response was received
  //       console.error("Error request data:", error.request);
  //     } else {
  //       // Something happened in setting up the request that triggered an Error
  //       console.error("Error message:", error.message);
  //     }
  //     console.error("Error config:", error.config);
  //     // Handle error (e.g., show error message to the user)
  //   }
  // };
  const client_id = "86dpwd87p8nanb";
  useEffect(() => {
    console.log("clientid is : ", client_id);
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    console.log("test2");
    if (code) {
      console.log("The URL contains the code:", code);
      fetchCallbackData(code);
    } else {
      console.log("Code not found in the URL.");
    }
  }, []);

  const fetchCallbackData = async (code) => {
    console.log("Code:", code);
    try {
      const response = await axios.get(
        `${apiUrl}authentication/linkedin_callback_post_internship/`,
        {
          params: { code: code },
        }
      );
      console.log("LinkedIn token response:", response.data);
      localStorage.setItem(
        "accessToken_LinkedIn",
        response.data.linkedIn_access_token
      );
      localStorage.setItem(
        "linkedin_person_id",
        response.data.linkedin_person_id
      );
      handleCreatePost(
        response.data.linkedIn_access_token,
        response.data.linkedin_person_id
      );
      // Handle the response, store the access token, etc.
    } catch (error) {
      console.error("Error fetching data from callback:", error);
    }
  };
  const handleCreatePost = async (token, personnalId) => {
    console.log("token,personnalId", token, personnalId);
    const formData = JSON.parse(localStorage.getItem("postFormData"));
    if (!formData) {
      console.error("Form data not found in localStorage");
    }
    console.log(
      "==>",
      internshipSubject,
      email,
      internshipDeadline,
      internshipLocation,
      postImage,
      postDescription,
      requirement
    );
    try {
      const formData = JSON.parse(localStorage.getItem("postFormData"));
    if (!formData) {
      console.error("Form data not found in localStorage");
    }
    const {
      internshipSubject,
      email,
      internshipDeadline,
      internshipLocation,
      postImage,
      postDescription,
      requirement
    } = formData;
      const response = await axios.post(
        `${apiUrl}authentication/linkedin/post-create-internship/`,
        {
          subject: internshipSubject,
          access_token: token,
          personal_id: personnalId,
          email:email,
          application_link: "https://smart-hire.ma/home",
          internship_deadline: internshipDeadline,
          internship_location: internshipLocation,
          post_image: postImage,
          post_description: postDescription,
          requirements: requirement,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      console.log(response.data);
      localStorage.removeItem("linkedin_person_id");
      localStorage.removeItem("accessToken_LinkedIn");
      localStorage.removeItem("postFormData");
    } catch (error) {
      // Gérer l'erreur (similaire à votre code original)
      console.error("Erreur lors de la création du post :", error);
      // Afficher un message d'erreur à l'utilisateur
    }
  };
  async function save(event) {
    const formData = {
      internshipSubject,
      email,
      internshipDeadline,
      internshipLocation,
      postImage,
      postDescription,
      requirement,
    };
    localStorage.setItem("postFormData", JSON.stringify(formData));

    if (internshipDeadline.trim() === "") {
      isEmpty = true;
    }
    try {
      await axios.post(`${apiUrl}ai/internship/create/`, {
        subject: internshipSubject,
        email: email,
        location: internshipLocation,
        deadline: internshipDeadline,
        recruiter: userId,
      });
      try {
        console.log("window.location.origin", window.location.origin);
        // window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${window.location.origin}/admin/intern/InternDetails/&scope=openid%20email%20profile%20w_member_social`;
        let fileURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${window.location.origin}/admin/intern/InternDetails/&scope=openid%20email%20profile%20w_member_social`;
        window.open(fileURL, "_blank");      
      } catch (error) {
        console.error("Error redirecting to LinkedIn authentication:", error);
      }

      // handleCreatePost();
      // alert("Post Registation Successfully");
      setInternshipSubject("");
      setEmail("");
      setInternshipLocation("");
      setInternshipDeadline("");
      closepopup();
      Load();
      
      // handleCreatePost();
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "internship created",
        life: 3000,
      });
    } catch (err) {
      alert("post Registation Failed");
    }
  }
  const Scrapping = async () => {
    try {
      const response = await fetch(`${apiUrl}ai/start_scraping/${id}/`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"), // Add a space after 'Bearer'
        },
      });
      console.log("response : ", response);
      if (response.status === 401) {
        console.log("Unauthorized. Redirecting to login page...");
        navigate("/login");
        // Stop execution of the function after redirecting
        return; // or throw new Error('Unauthorized'); depending on your requirement
      }
      const result = await response.json();
      console.log("after Scrapping : ", result);
      setIsScrappingLaunched(false);
      statechange(false);
      setfinishing(true);
      LoadIntern(id);
      // setInterns(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const code_outlook = async () => {
    try {
      const response = await fetch(`${apiUrl}ai/code_outlook/${id}/`);
      const result = await response.json();
      console.log("code : ", result.user_code);
      setCode(result.user_code);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(code)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        })
        .catch(() => {
          setSnackbarOpen(true);
        });
    } else {
      setSnackbarOpen(true);
    }
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  function openAuthPopup() {
    const authUrl =
      "https://login.microsoftonline.com/common/oauth2/deviceauth";
    const width = window.screen.width / 3;
    const height = window.screen.height;
    const left = window.screen.width - width;
    const top = 0;

    window.open(
      authUrl,
      "authPopup",
      `width=${width},height=${height},top=${top},left=${left}`
    );
  }

  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    setPostDescription(value);
    if (value.length < 30) {
      setDescriptionError("Description must be at least 30 characters long");
    } else {
      setDescriptionError("");
    }
  };
  const leftToolbarTemplate = () => {
    return (
      <Box display="flex" alignItems="center">
        <Button
          variant="contained"
          type="submit"
          sx={{
            height: "100%",
            color: COLORS.white,
            border: "1px solid",
            borderColor: COLORS.grey,
            bgcolor: COLORS.signIn,
            ":hover": {
              bgcolor: COLORS.Button_OnHold,
              borderColor: COLORS.signIn,
              color: COLORS.signIn,
            },
          }}
          onClick={functionopenpopup}
        >
          New
        </Button>
        <Box mx={1} />
        <Button
          variant="contained"
          type="submit"
          sx={{
            height: "100%",
            color: COLORS.signIn,
            border: "1px solid",
            borderColor: COLORS.grey,
            bgcolor: COLORS.white,
            ":hover": {
              bgcolor: COLORS.Button_OnHold,
              borderColor: COLORS.signIn,
              color: COLORS.signIn,
            },
          }}
          onClick={confirmDeleteSelected}
          disabled={!selectedInternshipps || !selectedInternshipps.length}
        >
          Delete
        </Button>
      </Box>
    );
  };
  // const [internData, setInternData] = useState([]);
  // const handleClick = (rowData) => {
  //   setInternData(rowData);
  //   setInternDetails(true);
  // };
  const [id, setId] = useState();
  const [subject1, setSubject1] = useState();

  const handleClick = (rowData) => {
    setId(rowData.id);
    setSubject1(rowData.subject);
    LoadIntern(rowData.id);
    // Append the selected row to the existing internData array
    // setInternData((prevData) => [...prevData, rowData]);
    setInternDetails(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <IconButton
          sx={{
            color: COLORS.grey,
          }}
          onClick={() => {
            handleClick(rowData);
          }}
          color="inherit"
          aria-label="delete"
        >
          <RemoveRedEyeIcon />
        </IconButton>
        <IconButton
          sx={{
            color: COLORS.pulse_color,
          }}
          onClick={() => {
            deleteInternship(rowData);
          }}
          color="inherit"
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">Manage Internships</h4>
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </IconField>
    </div>
  );
  const navigate = useNavigate();
  async function Load() {
    try {
      const response = await fetch(
        `${apiUrl}ai/internships_by_recruiter/${userId}/`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      console.log("response : ", response);
      if (response.status === 401) {
        console.log("Unauthorized. Redirecting to login page...");
        navigate("/login");
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch data. Status: " + response.status);
      }

      const json = await response.json();
      setInternship(json);
      console.log(json);
      return json;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const deleteInternshipById = async (internshipId) => {
    try {
      console.log(internshipId);
      console.log(`${apiUrl}ai/internship/${internshipId}/delete/`);

      // Send a DELETE request to the API endpoint with the post's ID
      // await axios.delete(`${apiUrl}ai/internship/${internshipId}/delete/`);
      await axios.delete(
        `${apiUrl}ai/internship/delete/${internshipId}/`
      );

      // If the request is successful, the post has been deleted
      // console.log(Internship with ID ${internshipId} has been deleted.);
      Load();
    } catch (error) {
      // Handle any errors that occur during the deletion process
      console.error(
        Error`deleting internship with ID ${internshipId}: ${error.message}`
      );
    }
  };
  const [visible1, setVisible1] = useState(false);
  const [subject, setSubject] = useState("");

  //   function MyDialog() {

  //     const footerContent = (
  //         <div>
  //             <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
  //             <Button label="Yes" icon="pi pi-check" onClick={() => setVisible(false)} autoFocus />
  //         </div>
  //     );

  //     return (
  //         <div className="card">
  //             <div className="flex flex-wrap justify-content-center gap-2 mb-2">
  //                 <Button label="Left" icon="pi pi-arrow-right" onClick={() => show('left')} className="p-button-help" style={{ minWidth: '10rem' }} />
  //                 <Button label="Right" icon="pi pi-arrow-left" onClick={() => show('right')} className="p-button-help" style={{ minWidth: '10rem' }} />
  //             </div>
  //             <div className="flex flex-wrap justify-content-center gap-2 mb-2">
  //                 <Button label="TopLeft" icon="pi pi-arrow-down-right" onClick={() => show('top-left')} className="p-button-warning" style={{ minWidth: '10rem' }} />
  //                 <Button label="Top" icon="pi pi-arrow-down" onClick={() => show('top')} className="p-button-warning" style={{ minWidth: '10rem' }} />
  //                 <Button label="TopRight" icon="pi pi-arrow-down-left" onClick={() => show('top-right')} className="p-button-warning" style={{ minWidth: '10rem' }} />
  //             </div>
  //             <div className="flex flex-wrap justify-content-center gap-2">
  //                 <Button label="BottomLeft" icon="pi pi-arrow-up-right" onClick={() => show('bottom-left')} className="p-button-success" style={{ minWidth: '10rem' }} />
  //                 <Button label="Bottom" icon="pi pi-arrow-up" onClick={() => show('bottom')} className="p-button-success" style={{ minWidth: '10rem' }} />
  //                 <Button label="BottomRight" icon="pi pi-arrow-up-left" onClick={() => show('bottom-right')} className="p-button-success" style={{ minWidth: '10rem' }} />
  //             </div>

  //             <Dialog header="Header" visible={visible} position={position} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent} draggable={false} resizable={false}>
  //                 <p className="m-0">
  //                     Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
  //                     Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
  //                     consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
  //                     Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
  //                 </p>
  //             </Dialog>
  //         </div>
  //     )
  // }

  const [showSuccess, setShowSuccess] = useState(false);
  const successOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const sendMessage = async () => {
    console.log("rowEmail:", rowEmail, "subject:", subject);
    try {
      const response = await fetch(`${apiUrl}ai/send-email/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subject: subject,
          message: emailForSending,
          recipient: rowEmail,

          // myMail,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        console.log("Email sent successfully");
        setShowSuccess(true);
        // Close the dialog after showing success message for a while
        setTimeout(() => {
          setVisible1(false);
          setShowSuccess(false);
        }, 3500);
      } else {
        console.log(`Error: ${data.error}`);
      }
    } catch (error) {
      console.log(`Error: ${error.toString()}`);
    }
  };
  // const [rowEmail, setRowEmail]=useState('');
  const openDialog = (row_email) => {
    console.log("open Dialog ==> ", row_email);

    setRowEmail(row_email.email);
    setInternDetails(false);
    setEmailForSending(`
    Subject: Congratulations! You've Been Accepted for an Internship Opportunity
    
    Dear ${row_email.name},
    
    I hope this email finds you well.
    
    We are delighted to inform you that after careful consideration of your application, you have been selected to join our team as an intern. Congratulations on being accepted for the internship opportunity .
    
    Your enthusiasm, qualifications, and passion for ${row_email.subject} stood out among the many applicants we received. We believe that your skills and experiences will greatly contribute to our team and that you will benefit immensely from the learning opportunities provided during your internship.
    
    Here are a few details about your internship:
    - Internship Position: [Position Title]
    - Start Date: [Start Date]
    - Duration: [Duration of Internship]
    - Location: [Office Location or Remote]
    
    Before your start date, we will send you more information about your role, responsibilities, and the onboarding process. In the meantime, please feel free to reach out if you have any questions or require further clarification.
    
    We are excited to welcome you to our team and look forward to working together. Congratulations once again, and we wish you a fulfilling and enriching experience as an intern .
    
    Best regards,
    `);
    // <<<<<<< HEAD
    // handleClose()
    setSubject(row_email.subject);
    // sendMessage(row_email.subject, row_email.email);
    // =======

    //     setSubject(`${row_email.subject}`);
    //     sendMessage(row_email.email);
    setVisible1(true);
  };

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  }));

  const StyledChip = styled(Chip)(({ theme }) => ({
    backgroundColor: COLORS.signIn,
    color: COLORS.white,
    fontWeight: "bold",
  }));
  const cities = [
    "Casablanca",
    "Rabat",
    "Marrakesh",
    "Fes",
    "Tangier",
    "Agadir",
    "Meknes",
    "Oujda",
    "Kenitra",
    "Tetouan",
    "Safi",
    "El Jadida",
    "Nador",
    "Khouribga",
    "Beni Mellal",
    "Mohammedia",
    "Laayoune",
    "Khemisset",
    "Settat",
    "Sidi Kacem",
    "Taza",
    "Larache",
    "Berkane",
    "Errachidia",
    "Taroudant",
    "Guelmim",
    "Ouarzazate",
    "Essaouira",
    "Dakhla",
    "Azrou",
    "Oued Zem",
    "Fqih Ben Salah",
  ];

  const handleImageUpload = (postImage) => {
    setPostImage(postImage);
  };

  return (
    <div className="content" style={{ padding: "1%" }}>
      <Toast ref={toast} />
      <div className="intern">
        <Toolbar
          className="mb-4"
          left={leftToolbarTemplate}
          // right={rightToolbarTemplate}
        ></Toolbar>

        <DataTable
          value={internship}
          selection={selectedInternshipps}
          onSelectionChange={(e) => setSelectedInternshipps(e.value)}
          dataKey="id"
          paginator
          rows={10}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} internships"
          globalFilter={globalFilter}
          header={header}
          className="p-datatable-sm p-datatable-gridlines shadow-2 border-round-xl"
          responsiveLayout="scroll"
          stripedRows
          showGridlines
          size="small"
          emptyMessage="No internships found"
          paginatorClassName="p-paginator-bottom"
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            field="id"
            header="Id"
            sortable
            style={{ minWidth: "12rem" }}
          ></Column>
          <Column
            field="subject"
            header="Subject"
            sortable
            body={internship.subject}
            style={{ minWidth: "16rem" }}
          ></Column>
          <Column
            field="email"
            header="Email"
            sortable
            style={{ minWidth: "8rem" }}
          ></Column>
          <Column
            field="location"
            header="Location"
            sortable
            style={{ minWidth: "10rem" }}
          ></Column>
          <Column
            body={actionBodyTemplate}
            exportable={false}
            style={{ minWidth: "12rem" }}
          ></Column>
        </DataTable>
      </div>
      <Dialog open={open} onClose={closepopup} fullWidth maxWidth="sm">
        {" "}
        <DialogContent>
          <Stack spacing={2.5} margin={2}>
            <Typography
              fontSize={25}
              fontFamily={"sans-serif"}
              // color={theme.palette.mode === "dark" ? "#009688" : "#9cd6d1"}
            >
              Add Internship
            </Typography>
            <TextField
              variant="outlined"
              label="Internship Subject"
              id="internshipSubject"
              value={internshipSubject}
              onChange={(event) => {
                setInternshipSubject(event.target.value);
              }}
            ></TextField>
            <TextField
              variant="outlined"
              label="Email"
              id="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            ></TextField>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateField"]}>
                <DateField
                  sx={{ width: "100%" }}
                  label="Deadline"
                  value={internshipDeadline ? dayjs(internshipDeadline) : null}
                  onChange={(newValue) => {
                    setInternshipDeadline(
                      newValue ? newValue.format("YYYY-MM-DD") : ""
                    );
                  }}
                  format="YYYY-MM-DD"
                />
              </DemoContainer>
            </LocalizationProvider>

            <Autocomplete
              options={cities}
              value={internshipLocation}
              onChange={(event, newValue) => setInternshipLocation(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "100%", marginTop: "3%", marginBottom: "3%" }}
                  variant="outlined"
                  label="Location"
                />
              )}
            />
            {/* <ImageUploadButton onImageUpload={handleImageUpload} /> */}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}></Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            type="submit"
            sx={{
              height: "100%",
              color: COLORS.white,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.signIn,
              ":hover": {
                bgcolor: COLORS.white,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
            onClick={() => {
              console.log(
                internshipSubject,
                email,
                internshipDeadline,
                internshipLocation
              );
              if (internshipSubject === "" || email === "") {
                // <Message severity="error" text="Error Message" />
                window.alert("Veillez remplir les champs obligatoires!");
              } else {
                closepopup();
                openpopup2();
                // save();
              }
            }}
          >
            NEXT
          </Button>{" "}
        </DialogActions>
      </Dialog>

      <Dialog open={open2} onClose={closepopup2} fullWidth maxWidth="sm">
        {" "}
        <DialogContent>
          <Stack spacing={2.5} margin={2}>
            <Typography>Description</Typography>

            <InputTextarea
              rows={5}
              cols={61}
              value={postDescription}
              onChange={handleDescriptionChange}
              className={descriptionError ? "p-invalid" : ""}
            />
            <ChipInput
              requirement={requirement}
              setRequirement={setRequirement}
            />
            <ImageUploadButton onImageUpload={handleImageUpload} />
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}></Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              closepopup2();
              openchange(true);
            }}
            variant="contained"
            type="submit"
            sx={{
              height: "100%",
              color: COLORS.signIn,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.white,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            PREVIOUS
          </Button>
          <Button
            variant="contained"
            type="submit"
            sx={{
              height: "100%",
              color: COLORS.white,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.signIn,
              ":hover": {
                bgcolor: COLORS.white,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
            onClick={() => {
              console.log(
                internshipSubject,
                email,
                internshipDeadline,
                internshipLocation
              );
              if (internshipSubject === "" || email === "") {
                // <Message severity="error" text="Error Message" />
                window.alert("Veillez remplir les champs obligatoires!");
              } else {
                console.log("requirement", requirement);
                try {
                  save();
                  // Wait for 3 seconds before closing the dialog
                  setTimeout(() => {
                    closepopup2();
                  }, 1000);
                } catch (error) {
                  console.error("Error saving internship:", error);
                  // Handle error (e.g., show an error message)
                }
              }
            }}
          >
            ADD INTERNSHIP
          </Button>{" "}
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteInternshippsDialog}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", alignItems: "center" }}>
            {internship && (
              <span>
                Are you sure you want to delete the selected internship?
              </span>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            type="submit"
            sx={{
              height: "100%",
              color: COLORS.signIn,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.white,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={deleteSelectedInternshipps}
            variant="contained"
            type="submit"
            sx={{
              height: "100%",
              color: COLORS.white,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.signIn,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteInternshipDialog}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", alignItems: "center" }}>
            {internship && (
              <span>
                Are you sure you want to delete the selected internship?
              </span>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button
            onClick={() => {
              setDeleteInternshipDialog(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={deleteInternshipp} color="primary">
            Delete
          </Button> */}
          <Button
            onClick={() => {
              setDeleteInternshipDialog(false);
            }}
            variant="contained"
            type="submit"
            sx={{
              height: "100%",
              color: COLORS.signIn,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.white,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={deleteInternshipp}
            variant="contained"
            type="submit"
            sx={{
              height: "100%",
              color: COLORS.white,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.signIn,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={internDetails}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <div class="row d-flex align-items-center">
          <div className="col-md-4" style={{ marginTop: "10px" }}>
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "16px", // Add some padding around the title
              }}
            >
              <Typography
                variant="h5"
                component="div"
                sx={{
                  display: "flex",
                  flexDirection: "column", // Stack content vertically
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#f5f5f5",
                  padding: "16px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  color: "#333",
                  fontSize: "1.2rem", // Slightly smaller font size
                  textAlign: "center",
                  width: "100%", // Full width
                  maxWidth: "90%", // Limit maximum width
                  wordWrap: "break-word", // Allow word wrapping
                }}
              >
                {/* <span style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                Detail:
              </span> */}
                <span style={{ color: "#ff0404" }}>{subject1}</span>
              </Typography>
            </DialogTitle>
          </div>
          <div class="col-md-4"></div>
          <div class="col-md-4 text-right mr-2">
            {/* {state ? (
              <div
                className="d-flex align-items-center justify-content-end"
                style={{ marginRight: "30px" }}
              >
                <strong>Scraping...</strong>
                <div
                  className="spinner-border text-success"
                  role="status"
                  style={{ marginLeft: "10px", marginRight: "20px" }}
                >
                  <span className="sr-only"></span>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  onClick={hidde_finishing}
                ></button>
              </div>
            ) : finishing ? (
              <div style={{ marginTop: "10px" }}>
                <Alert severity="success">
                  <AlertTitle>Scraping Success</AlertTitle>
                </Alert>
              </div>
            ) : finishing ? (
              <div
                class="alert alert-success alert-dismissible fade show"
                role="alert"
                style={{ marginTop: "20px" }}
              >
                <strong>Scraping is finished!</strong>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={hidde_finishing}
                ></button>
              </div>
            ) : (
              <div
                class="alert  alert-dismissible fade show"
                role="alert"
                style={{ marginTop: "10px" }}
              >
                <strong>Scraping is finished!</strong>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={hidde_finishing}
                ></button>
              </div>
            )} */}
            {state ? (
                <div
                  className="d-flex align-items-center justify-content-end"
                  style={{ marginRight: "30px" }}
                >
                  <strong>Scraping...</strong>
                  <div
                    className="spinner-border text-success"
                    role="status"
                    style={{ marginLeft: "10px", marginRight: "20px" }}
                  >
                    <span className="sr-only"></span>
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={hidde_finishing}
                  ></button>
                </div>
              ) : finishing ? (
                <div style={{ marginTop: "10px" }}>
                  <Alert severity="success">
                    <AlertTitle>Scraping Success</AlertTitle>
                  </Alert>
                </div>
              ) : (
                // <div
                //   className="alert alert-warning alert-dismissible fade show"
                //   role="alert"
                //   style={{ marginTop: "10px" }}
                // >
                //   <strong>No scraping in progress!</strong>
                //   {/* <button
                //     type="button"
                //     className="btn-close"
                //     data-bs-dismiss="alert"
                //     aria-label="Close"
                //     onClick={hidde_finishing}
                //   ></button> */}
                // </div>
                <></>
              )}

          </div>
        </div>

        <DialogContent>
          <Box display="flex" alignItems="center" mb={3}>
            <Tooltip title="Refresh data">
              <StyledIconButton
                onClick={() => {
                  statechange(true);
                  Scrapping();
                  console.log(interns);
                }}
                onMouseUp={() => {
                  setTimeout(() => {
                    code_outlook();
                    setIsScrappingLaunched(true);
                  }, 3000);
                  setTimeout(() => {
                    openAuthPopup();
                  }, 5000);
                }}
                aria-label="refresh"
              >
                <RefreshIcon />
              </StyledIconButton>
            </Tooltip>
            <Box ml={2}>
              {isScrappingLaunched && (
                <Box
                  display="flex"
                  alignItems="center"
                  bgcolor="#f0f0f0"
                  borderRadius={2}
                  p={2}
                  boxShadow={1}
                >
                  <Typography variant="h6" fontWeight="bold" mr={2}>
                    Verification Code:
                  </Typography>
                  <Typography
                    variant="h6"
                    fontFamily="monospace"
                    letterSpacing={1}
                    bgcolor="white"
                    p={1}
                    borderRadius={1}
                  >
                    {code}
                  </Typography>
                  <Tooltip title={copied ? "Copied!" : "Copy code"}>
                    <IconButton
                      onClick={handleCopy}
                      size="small"
                      sx={{ ml: 2 }}
                    >
                      {copied ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <ContentCopyIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackbarOpen}
                    autoHideDuration={2000}
                    onClose={handleSnackbarClose}
                  >
                    <Box
                      sx={{
                        backgroundColor: "error.main",
                        color: "error.contrastText",
                        padding: 2,
                        borderRadius: 1,
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        textAlign: "center",
                        minWidth: "300px",
                      }}
                    >
                      Please copy the code manually
                    </Box>
                  </Snackbar>
                </Box>
              )}
            </Box>
          </Box>

          <Divider sx={{ mb: 3 }} />

          <Box 
          className="content"
          
        >
           {interns && interns.length > 0 ? (
              <DataInternsTable
                intern={interns}
                openDialog={openDialog}
                rowEmail={rowEmail}
              />
            ) : (
              <Typography variant="h6" color="textSecondary">
                No data available. The list is empty.
              </Typography>
            )}
        </Box>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              hidde_finishing();
            }}
            variant="contained"
            type="submit"
            sx={{
              height: "100%",
              color: COLORS.signIn,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.white,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            Exit
          </Button>
          {/* <Button
            variant="contained"
            onClick={() => {
              setInternDetails(false);
            }}
            sx={{
              bgcolor: theme.palette.mode === "dark" ? "#009688" : "#9cd6d1",
              ":hover": {
                bgcolor: theme.palette.mode === "dark" ? "#9cd6d1" : "#009688",
              },
            }}
          >
            hallo
          </Button> */}
          {/* <Button onClick={deleteInternshipp} color="primary">
            Delete
          </Button> */}
        </DialogActions>
      </Dialog>
      <Dialog open={visible1} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Send Message</DialogTitle>
        {!showSuccess ? (
          <DialogContent>
            <Typography
              fontSize={14}
              fontFamily={"sans-serif"}
              sx={{
                paddingBottom: "10px",
              }}
              // color={theme.palette.mode === "dark" ? "#009688" : "#9cd6d1"}
            >
              Email : {rowEmail}
            </Typography>
            <TextField
              variant="outlined"
              label="Subject"
              id="subject"
              value={subject}
              onChange={(event) => {
                setSubject(event.target.value);
              }}
            ></TextField>
            {/* <Box flexGrow={9} borderRadius={8}></Box> */}
            <InputTextarea
              value={emailForSending}
              onChange={(e) => setEmailForSending(e.target.value)}
              rows={7}
              cols={80}
              style={{ resize: "none" }}
            />
          </DialogContent>
        ) : null}

        {!showSuccess ? (
          <DialogActions>
            {/* <Button onClick={sendMessage} color="primary">
              send
            </Button> */}

            <Button
              onClick={() => {
                setVisible1(false);
                setInternDetails(true);
              }}
              variant="contained"
              type="submit"
              sx={{
                height: "100%",
                color: COLORS.signIn,
                border: "1px solid",
                borderColor: COLORS.grey,
                bgcolor: COLORS.white,
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={sendMessage}
              sx={{
                height: "100%",
                color: COLORS.white,
                border: "1px solid",
                borderColor: COLORS.grey,
                bgcolor: COLORS.signIn,
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
            >
              <SendIcon />
            </Button>
          </DialogActions>
        ) : null}
        {showSuccess ? (
          <Lottie options={successOptions} height={300} width={200} />
        ) : null}
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(InternshippsDemo);
