import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Typography, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import styled from 'styled-components';
import { Card, CardHeader, CardBody, CardFooter } from "reactstrap";

const StyledCard = styled(Card)`
  margin-bottom: 30px;
`;

const CardHeaderWrapper = styled(CardHeader)`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const CardBodyWrapper = styled(CardBody)`
  padding: 15px;
  height: 285px; // Increased from 266px to 400px
`;

const CardFooterWrapper = styled(CardFooter)`
  padding: 15px;
`;

const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const LegendIcon = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
`;

const PostScoreStatistics = ({ userId, apiUrl }) => {
  const [postsData, setPostsData] = useState({});
  const [selectedPost, setSelectedPost] = useState('');
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}authentication/scores-for-post/${userId}/`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setPostsData(data);
        if (Object.keys(data).length > 0) {
          setSelectedPost(Object.keys(data)[0]);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userId, apiUrl]);
  
  const colors = ['#dc3545', '#ffc107', '#28a745'];

  useEffect(() => {
    if (selectedPost && postsData[selectedPost]) {
      const postData = postsData[selectedPost];
      const totalScores = postData.bad + postData.moyen + postData.excellent;
      const labels = ['Bad ', 'Moyen', 'Excellent'];
      const data = [postData.bad, postData.moyen, postData.excellent];
      const percentages = data.map(value => ((value / totalScores) * 100).toFixed(1));

      setChartData({
        labels,
        datasets: [{
          data,
          backgroundColor: colors,
          hoverBackgroundColor: colors,
        }],
        percentages,
      });
    }
  }, [selectedPost, postsData]);

  const handlePostChange = (event) => {
    setSelectedPost(event.target.value);
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.parsed || 0;
            const percentage = chartData.percentages[context.dataIndex];
            return `${label}: ${value} (${percentage}%)`;
          }
        }
      }
    },
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography style={{ color: 'red' }}>Error: {error}</Typography>;

  return (
    <StyledCard>
      <CardHeaderWrapper style={{ marginTop: '-15px' }}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>Post Score Statistics</Typography>
        <FormControl fullWidth style={{ marginTop: '0px', width:'100px' }}>
          {/* <InputLabel id="post-select-label"><h1>Select a Post</h2></InputLabel> */}
          <Select
            labelId="post-select-label"
            value={selectedPost}
            onChange={handlePostChange}
            label="Select a Post"
          >
            {Object.keys(postsData).map((postId) => (
              <MenuItem key={postId} value={postId}>Post {postId}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardHeaderWrapper>
      <CardBodyWrapper>
        <Pie data={chartData} options={chartOptions} />
      </CardBodyWrapper>
      <CardFooterWrapper>
        <Legend>
          {chartData.labels.map((label, index) => (
            <LegendItem key={label}>
              <LegendIcon style={{ backgroundColor: colors[index] }} />
              {label} ({chartData.percentages[index]}%)
            </LegendItem>
          ))}
        </Legend>
        <hr />
      </CardFooterWrapper>
    </StyledCard>
  );
};

export default PostScoreStatistics;