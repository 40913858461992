// import React, { useState, useEffect } from "react";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import { Button, CardActionArea, Modal, Box } from "@mui/material";
// import Lottie from 'react-lottie';
// import successAnimation from './Animation - 1716461046736.json'; // Update the path to your success animation JSON file
// import "./home.css";
// import pulseLogo from 'assets/img/PULSE-digital-logo.png'; // Adjust the path according to your project structure
// import DevOpsLoop from 'assets/img/DevOpsLoop.png'; // Adjust the path according to your project structure
// import ApplicationForm from "./ApplicationForm ";

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

// const successOptions = {
//   loop: false,
//   autoplay: true,
//   animationData: successAnimation,
//   rendererSettings: {
//     preserveAspectRatio: 'xMidYMid slice'
//   }
// };

// export default function MyCard(props) {
//   const [isFixed, setIsFixed] = useState(false);
//   const [showForm, setShowForm] = useState(false);
//   const [Hiddforum, setHiddforum] = useState(false);
//   const [showSuccess, setShowSuccess] = useState(false);

//   const handleApplyClick = () => {
//     setShowForm(true);
//   };

//   const handleClose = () => {
//     setShowForm(false);
//     setHiddforum(false); // Reset Hiddforum state when modal is manually closed
//     setShowSuccess(false); // Reset success animation state
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 240) {
//         setIsFixed(true);
//       } else {
//         setIsFixed(false);
//       }
//     };
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     if (Hiddforum) {
//       setShowSuccess(true);
//       setTimeout(() => {
//         handleClose();
//       }, 3000); // Display success animation for 3 seconds before closing modal
//     }
//   }, [Hiddforum]);

//   return (
//     <div className={`fixed-component ${isFixed ? "fixed" : "fixed2"}`}>

//       <Card>
//         <CardActionArea>
//           <CardMedia
//             component="img"
//             height="200"
//             width="250"
//             image={props.image ? props.image : DevOpsLoop}
//             alt="Job Post Image"
//           />
//           <CardContent>
//             <Typography gutterBottom variant="h5" component="div">
//               {props.title}
//             </Typography>
//             <Typography variant="body2" color="text.secondary">
//               {props.description}
//             </Typography>
//             <Typography variant="body2" color="text.secondary">
//               {props.requirements}
//             </Typography>
//             <Typography variant="body2" color="text.secondary">
//               {props.localisation}
//             </Typography>
//             <Typography variant="body2" color="text.secondary">
//               {props.mode}
//             </Typography>
//             <Typography variant="body2" color="text.secondary">
//               {props.deadline}
//             </Typography>
//             {/* <Typography variant="body2" color="text.secondary">
//               {props.id}
//             </Typography> */}
//           </CardContent>
//           <div style={{ display: 'flex', justifyContent: 'center' }}>

//             <button type="button"  class="btn btn-outline-primary btn-lg" onClickCapture={handleApplyClick}>
//               Apply
//             </button>
//         </div>
//         </CardActionArea>

//       </Card>
//       <Modal
//         open={showForm}
//         onClose={handleClose}
//         aria-labelledby="application-form-modal-title"
//         aria-describedby="application-form-modal-description"
//       >
//         <Box sx={style}>
//           {showSuccess ? (
//             <Lottie options={successOptions} height={200} width={200} />
//           ) : (
//             <ApplicationForm setHiddforum={setHiddforum} post_id={props.id} post_title={props.title} />
//           )}
//         </Box>
//       </Modal>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Modal, Box, ButtonBase, Button, Dialog, DialogContent } from "@mui/material";
import Lottie from "react-lottie";
import successAnimation from "./Animation - 1716461046736.json"; // Update the path to your success animation JSON file
import "./home.css";
import pulseLogo from "assets/img/PULSE-digital-logo.png"; // Adjust the path according to your project structure
import DevOpsLoop from "assets/img/DevOpsLoop.png"; // Adjust the path according to your project structure
import ApplicationForm from "./ApplicationForm ";
import { COLORS } from "colors/color";
import LoginForDialog from "views/Auth/LoginForDialog";
import axios from "axios";

const style = {
  // <<<<<<< HEAD
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // width: 400,
  // bgcolor: "background.paper",
  // borderRadius: 2,
  // =======
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%", // You can adjust this value
  maxWidth: "700px", // Set a maximum width if desired
  bgcolor: "background.paper",
  // >>>>>>> d38f552fb8c65c9629d1ed5d94496992a304116b
  boxShadow: 24,
  p: 4,
  borderRadius: "24px", // Add this line for rounded corners
  maxHeight: "90vh", // Set a maximum height
  overflow: "auto", // Allow scrolling if content exceeds maxHeight
};

const successOptions = {
  loop: false,
  autoplay: true,
  animationData: successAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function MyCard(props) {
  const [isFixed, setIsFixed] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [Hiddforum, setHiddforum] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const [userRole, setUserRole] = useState(getUserRoleFromAccessToken());
  const [hasApplied,setHasApplied]=useState(false);

  function getUserRoleFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token : ", decodedToken);
      const userRole = decodedToken.role;
      return userRole;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  const [loginDialog, setLoginDialog]=useState(false);

  const handleApplyClick = () => {
    localStorage.getItem('accessToken') ? (
    setShowForm(true)):(      
      // setLoginDialog(true)
      window.location.href = "../login"

    )
  };

  const handleClose = () => {
    setShowForm(false);
    setHiddforum(false); // Reset Hiddforum state when modal is manually closed
    setShowSuccess(false); // Reset success animation state
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 240) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (Hiddforum) {
      setShowSuccess(true);
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [Hiddforum]);
  const [applicationList, setApplicationList] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
}

const addPostApplied = (post) => {
  setApplicationList((prev) => [...prev, post]);
};


const postApplied = async () => {
  const userId = getUserIdFromAccessToken();
  try {
    const response = await fetch(`${apiUrl}candidates/list_applied_posts/${userId}/`);

    if (!response.ok) {
      if (response.status === 404) {
        console.error("Candidate not found");
        setApplicationList([]);
        return { error: "Candidate not found" };
      } else {
        throw new Error(`An error occurred: ${response.statusText}`);
      }
    }

    const json = await response.json();
    
    console.log('lists:', json);
    setApplicationList(json); 
    console.log('application list is :',json); 
    setIsClicked(false)
    return json;
  } catch (error) {
    console.error("Error loading data", error);
    return { error: error.message };
  }
};

useEffect(() => {
  postApplied();
}, [isClicked]);

useEffect(() => {
  hasAlreadyApplied()
}, []);

const hasAlreadyApplied=async ()=>{
  const userId = getUserIdFromAccessToken();
  try {
    const response = await axios.get(
      `${apiUrl}candidates/check-applied/${userId}/`,
     
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      } 
    );
    console.log("has applied ? :", response.data.has_applied);
    setHasApplied(response.data.has_applied)
  } catch (error) {
    console.error("Error :", error.response.data);
  }
}

  return (
    <>
      {/* <<<<<<< HEAD */}
      <Card className="my-card">
        <CardActionArea>
          <CardMedia
            component="img"
            height="200"
            width="250"
            image={props.image ? props.image : DevOpsLoop}
            alt="Job Post Image"
          />
          <CardContent
            className={`card-content ${
              props.isFooterVisible ? "card-content-small" : ""
            }`}
          >
            <div className="card-section">
              <Typography
                variant="h6"
                component="h1"
                className="section-title"
              ></Typography>
              <Typography gutterBottom variant="h2" component="div">
                {props.title}
              </Typography>
            </div>
            <div>
              <Typography variant="h6" component="h1">
                Location
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {props.localisation}
              </Typography>
            </div>
            <div>
              <Typography variant="h6" component="h1">
                Post Description
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                dangerouslySetInnerHTML={{
                  __html: props.description.replace(/\. /g, ".<br /><br />"),
                }}
              />
            </div>
            <div>
              <Typography variant="h6" component="h1">
                Requirements
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {props.requirements}
              </Typography>
            </div>
            <div>
              <Typography variant="h6" component="h1">
                Mode
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {props.mode}
              </Typography>
            </div>
            <div>
              <Typography variant="h6" component="h1">
                Deadline
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {props.deadline}
              </Typography>
            </div>
          </CardContent>
        </CardActionArea>
        <div style={{ display: "flex", justifyContent: "center" }}>
        {applicationList && applicationList.some(application => application.post.id === props.id) ? (
            <Button
              onClickCapture={handleApplyClick}
              disabled="true"
              variant="contained"
              sx={{
                margin: "3%",
                width: "50%",
                height: "100%",
                color: COLORS.white,
                border: "1px solid",
                borderColor: COLORS.grey,
                bgcolor: COLORS.signIn,
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
            >
              Already applied to this post
            </Button>
          ) : (
            
            <Button
              onClickCapture={handleApplyClick}
              disabled={userRole === "r"}
              variant="contained"
              sx={{
                margin: "3%",
                width: "30%",
                height: "100%",
                color: COLORS.white,
                border: "1px solid",
                borderColor: COLORS.grey,
                bgcolor: COLORS.signIn,
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
            >
              Apply
            </Button>
          )}
        </div>
      </Card>
      <Modal
        open={showForm}
        onClose={handleClose}
        aria-labelledby="application-form-modal-title"
        aria-describedby="application-form-modal-description"
      >
        {/* =======
    <Card className="my-card">
      <CardActionArea>
        <CardMedia
          component="img"
          height="200"
          width="250"
          image={props.image ? props.image : DevOpsLoop}
          alt="Job Post Image" />
        <CardContent className={`card-content ${props.isFooterVisible ? 'card-content-small' : ''}`}>
          <div className="card-section">
            <Typography variant="h6" component="h1" className="section-title"></Typography>
            <Typography gutterBottom variant="h2" component="div">
              {props.title}
            </Typography>
          </div>
          <div>
            <Typography variant="h6" component="h1">Location</Typography>
            <Typography variant="body2" color="text.secondary">
              {props.localisation}
            </Typography>
          </div>
          <div>
            <Typography variant="h6" component="h1">Post Description</Typography>
            <Typography variant="body2" color="text.secondary" dangerouslySetInnerHTML={{ __html: props.description.replace(/\. /g, '.<br /><br />') }} />
          </div>
          <div>
            <Typography variant="h6" component="h1">Requirements</Typography>
            <Typography variant="body2" color="text.secondary">
              {props.requirements}
            </Typography>
          </div>
          <div>
            <Typography variant="h6" component="h1">Mode</Typography>
            <Typography variant="body2" color="text.secondary">
              {props.mode}
            </Typography>
          </div>
          <div>
            <Typography variant="h6" component="h1">Deadline</Typography>
            <Typography variant="body2" color="text.secondary">
              {props.deadline}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button type="button"  class="btn btn-outline-primary btn-lg" onClickCapture={handleApplyClick}>
          Apply
        </button>
      </div>
    </Card>
    <Modal
      open={showForm}
      onClose={handleClose}
      aria-labelledby="application-form-modal-title"
      aria-describedby="application-form-modal-description"
    >
>>>>>>> d38f552fb8c65c9629d1ed5d94496992a304116b */}
        <Box sx={style}>
          {showSuccess ? (
            <Lottie options={successOptions} />
          ) : (
            <ApplicationForm
              setHiddforum={setHiddforum}
              post_id={props.id}
              post_title={props.title}
              postApplied={postApplied}
              setIsClicked={setIsClicked}
              hasApplied={hasApplied}
              setHasApplied={setHasApplied}
              addPostApplied={addPostApplied}
            />
          )}
        </Box>
      </Modal>
      <Dialog
      open={loginDialog}
      >
        <DialogContent>
        <LoginForDialog/>
        </DialogContent>
      </Dialog>
    </>
  );
}
