// import React from 'react'
// import {
//     CCardBody,
//     CCardTitle,
//     CCardSubtitle,
//     CCardText,
//     CCardLink,
//     CCard,
//   } from "@coreui/react";
// function MyCard(props,title, subtitle,text) {
//   return (
//     <div>
//         <CCard style={{ width: "18rem" }}>
//         <CCardBody>
//           <CCardTitle>{props.title}</CCardTitle>
//           <CCardSubtitle className="mb-2 text-body-secondary">
//             {props.subtitle}
//           </CCardSubtitle>
//           <CCardText>
//             {props.text}
//           </CCardText>
//           {/* <CCardLink href="#">Card link</CCardLink> */}
//           <CCardLink href="#">Read more</CCardLink>
//         </CCardBody>
//       </CCard>
//     </div>
//   )
// }

//-==============================================================================

import * as React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux"; // Import connect from react-redux
import { setUserId } from "../../Redux/actions"; // Import your action
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Avatar, SvgIcon } from "@mui/material";
import { Box, Chip, IconButton } from "@mui/material";
import pulseLogo from "assets/img/PULSE-digital-logo.png"; // Adjust the path according to your project structure
import DevOpsLoop from "assets/img/DevOpsLoop.png"; // Adjust the path according to your project structure
import "./home.css";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { styled } from "@mui/system";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { COLORS } from "colors/color";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 500,
  height: 400,
  marginBottom: theme.spacing(3),
  borderRadius: "12px",
  boxShadow: "0 8px 24px rgba(0,0,0,0.12)",
  position: "relative",
  overflow: "hidden",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 16px 32px rgba(0,0,0,0.16)",
  },
}));

const CardContentStyled = styled(CardContent)({
  padding: "4%",
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

const RecruiterAvatar = styled(Avatar)({
  width: 60,
  height: 60,
  border: "4px solid #fff",
  boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
  marginBottom: "16px",
});

const SaveButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: 8,
  right: 8,
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
}));
function MyCard({
  title,
  description,
  deadline,
  image,
  post_id,
  savedBlack,
  refresh,
  recruiter
}) {
  const [isSaved, setIsSaved] = useState(savedBlack);

  const handleSaveIconClick = async () => {
    console.log("Saved icon clicked!");
    const userId = getUserIdFromAccessToken();

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(
        `${apiUrl}candidates/save_post/${userId}/${post_id}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        console.log(data.message);
        setIsSaved(!isSaved);
        refresh(); //refreshing the saving posts page
        // Toggle the saved state
      } else {
        console.error(data.error);
        // Handle the error accordingly
      }
    } catch (error) {
      console.error("Error saving the post:", error);
    }
  };

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  return (
    <StyledCard>
      <CardMedia
        component="img"
        height="200"
        image={image || DevOpsLoop}
        alt="Job Post Image"
      />
      <SaveButton onClick={handleSaveIconClick} size="small">
        {isSaved ? (
          <BookmarkIcon color="primary" />
        ) : (
          <BookmarkBorderOutlinedIcon />
        )}
      </SaveButton>
      <CardContentStyled>
  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
    <RecruiterAvatar
      src={recruiter.image ? recruiter.image : "com.png"}
      alt="Recruiter"
      sx={{ 
        width: 40, 
        height: 40, 
        marginRight: 2,
        border: '2px solid', 
        borderColor: 'primary.main' 
      }}
    />
    <Typography
      variant="h6"
      component="div"
      sx={{ 
        // fontWeight: "bold",
        fontSize: '1rem',
        color: COLORS.grey,
      }}
    >
      {`${recruiter.last_name} ${recruiter.first_name}`}
    </Typography>
  </Box>

  <Typography
    variant="h6"
    component="div"
    sx={{ 
      fontWeight: "bold", 
      marginBottom: 2,
      fontSize: '1.2rem',
      color: 'text.primary'
    }}
  >
    {title}
  </Typography>

  <Typography
    variant="body2"
    color="text.secondary"
    sx={{ flexGrow: 1, marginBottom: 2 }}
  >
    {description.length > 100
      ? `${description.slice(0, 100)}...`
      : description}
  </Typography>

  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }}
  >
    <Chip
      icon={<AccessTimeIcon />}
      label={`Deadline: ${deadline}`}
      size="small"
      color="primary"
      variant="outlined"
    />
  </Box>
</CardContentStyled>
    </StyledCard>
  );
}

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCard);
