import React, { useEffect, useRef, useState } from "react";
import { Steps } from "primereact/steps";
import { Toast } from "primereact/toast";
import ButtonAppBar from "components/Navbars/navBar";
import EastIcon from "@mui/icons-material/East";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Container, Row } from "reactstrap";
import "./home.css";
import MyArrayInput from "../MyArrayInput";
import { COLORS } from "../../colors/color";
import axios from "axios";
import { PersonAdd } from "@mui/icons-material";

import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IMaskInput } from "react-imask";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { InputTextarea } from "primereact/inputtextarea";
import ImageUploadButton from "views/ImageUploadButton";
import { connect } from "react-redux"; // Import connect from react-redux
import { setUserId } from "../../Redux/actions"; // Import your action
import { Navigate, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { DatePicker } from "@mui/x-date-pickers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(00[*])0 00-00-00-00"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const MaskedTextField = (props) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: TextMaskCustom,
      }}
    />
  );
};

const PersonalInfo = ({
  goToNextStep,
  CompanyName,
  Phone,
  Industry,
  Description,
  industries,
}) => {
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [industry, setIndustry] = useState("");
  const [description, setDescription] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");

  const handleCompanyNameChange = (event) => {
    const value = event.target.value;
    setCompanyName(value);
    if (value.length < 2) {
      setCompanyNameError("Company's Name must be at least 2 characters long");
    } else {
      setCompanyNameError("");
    }
  };

  const handleContinue = () => {
    if (companyName.length >= 2) {
      CompanyName(companyName);
      Phone(phone);
      Industry(industry);
      Description(description);

      goToNextStep();
    } else {
      setCompanyNameError("Company's Name must be at least 2 characters long");
    }
  };

  return (
    <Container
      style={{ border: "1px solid #efd", width: "70%", borderRadius: "10px" }}
    >
      <div className="content">
        <TextField
          sx={{ width: "100%", marginTop: "2%" }}
          variant="outlined"
          label="Company's Name"
          id="Company"
          value={companyName}
          onChange={handleCompanyNameChange}
          error={!!companyNameError}
          helperText={companyNameError}
          required={true}
        />
        <Box>
          <FormControl
            variant="standard"
            sx={{ width: "100%", marginTop: "2%" }}
          >
            <MaskedTextField
              mask="(99#)9 99-99-99-99"
              definitions={{
                "#": /[1-9]/,
              }}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              name="phone"
              id="phone"
              label="Phone Number"
              variant="outlined"
              required={true}
              fullWidth
            />
          </FormControl>
        </Box>
        <FormControl sx={{ width: "100%", marginTop: "3%" }}>
          <InputLabel id="industry-label">Company's Industry</InputLabel>
          <Select
            labelId="industry-label"
            id="industry"
            value={industry}
            onChange={(event) => setIndustry(event.target.value)}
            label="Company's Industry"
            required={true}
          >
            {industries.map((industry, index) => (
              <MenuItem key={index} value={industry}>
                {industry}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          sx={{ width: "100%", marginTop: "3%" }}
          variant="outlined"
          label="Company's Description"
          id="Description"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
        <Row>
          <div
            style={{ textAlign: "right", marginTop: "4%", marginBottom: "4%" }}
          >
            <Button
              variant="contained"
              onClick={handleContinue}
              sx={{
                height: "130%",
                color: COLORS.white,
                border: "1px solid",
                borderColor: COLORS.signIn,
                bgcolor: COLORS.signIn,
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
            >
              Continue <EastIcon />
            </Button>
          </div>
        </Row>
      </div>
    </Container>
  );
};

const PostInfo = ({
  goToNextStep,
  goBackStep,
  cities,
  JobTitle,
  NumPeople,
  Location,
  Salary,
  Deadline,
}) => {
  const [jobTitle, setJobTitle] = useState("");
    const [numPeople, setNumPeople] = useState("");
  const [location, setLocation] = useState("");
  const [salary, setSalary] = useState("");
  const [deadline, setDeadline] = useState(null);
  const [jobTitleError, setJobTitleError] = useState("");

  const handleJobTitleChange = (event) => {
    const value = event.target.value;
    setJobTitle(value);
    if (value.length < 10) {
      setJobTitleError("Job Title must be at least 10 characters long");
    } else {
      setJobTitleError("");
    }
  };

  const handleContinue = () => {
    if (jobTitle.length >= 10) {
      JobTitle(jobTitle);
      NumPeople(numPeople);
      Location(location);
      Salary(salary);
      Deadline(deadline);

      goToNextStep();
    } else {
      setJobTitleError("Job Title must be at least 10 characters long");
    }
  };

  return (
    <Container
      style={{
        border: "1px solid #efd",
        width: "70%",
        borderRadius: "10px",
        paddingBottom: "2%",
        marginBottom: "4%",
      }}
    >
      <div className="content">
        <TextField
          required
          sx={{ width: "100%", marginTop: "2%" }}
          variant="outlined"
          label="Job Title"
          id="title"
          value={jobTitle}
          onChange={handleJobTitleChange}
          error={!!jobTitleError}
          helperText={jobTitleError}
        />
        <FormControl fullWidth sx={{ width: "100%", marginTop: "2%" }}>
          <InputLabel id="demo-simple-select-label">
            Number of people to hire in this job
          </InputLabel>
          <Select
            labelId="number"
            id="number"
            value={numPeople}
            onChange={(event) => setNumPeople(event.target.value)}
            label="Number of people to hire in this job"
          >
            {[...Array(10).keys()].map((num) => (
              <MenuItem key={num} value={num + 1}>
                {num + 1}
              </MenuItem>
            ))}
            <MenuItem value={10}>10+</MenuItem>
          </Select>
        </FormControl>

        <Autocomplete
          options={cities}
          value={location}
          onChange={(event, newValue) => setLocation(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: "100%", marginTop: "3%", marginBottom: "3%" }}
              variant="outlined"
              label="Location"
            />
          )}
        />
        <FormControl fullWidth sx={{}}>
          <InputLabel htmlFor="outlined-adornment-amount">Salary</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            value={salary}
            onChange={(event) => setSalary(event.target.value)}
            startAdornment={
              <InputAdornment position="start">MAD</InputAdornment>
            }
            label="Salary"
          />
        </FormControl>
        <Box sx={{ width: "100%", marginTop: "3%" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateField"]}>
              <DateField
                sx={{ width: "100%" }}
                label="Deadline"
                value={deadline}
                onChange={(newValue) => setDeadline(newValue)}
                format="MM-DD-YYYY"
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
        <Row>
          <div className="col-6" style={{ textAlign: "left", marginTop: "4%" }}>
            <Button
              variant="contained"
              sx={{
                height: "130%",
                color: COLORS.grey,
                border: "1px solid", // Ensure border is visible
                borderColor: "#8B8B8B",
                bgcolor: "transparent",
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  color: COLORS.signIn,
                  borderColor: COLORS.signIn,
                },
              }}
              onClick={goBackStep}
            >
              <KeyboardBackspaceIcon /> Back
            </Button>
          </div>
          <div
            className="col-6"
            style={{ textAlign: "right", marginTop: "4%" }}
          >
            <Button
              variant="contained"
              onClick={handleContinue}
              sx={{
                height: "130%",
                color: COLORS.white,
                border: "1px solid",
                borderColor: COLORS.signIn,
                bgcolor: COLORS.signIn,
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
            >
              Continue <EastIcon />
            </Button>
          </div>
        </Row>
      </div>
    </Container>
  );
};

const JobDescription = ({
  goToNextStep,
  goBackStep,
  ArrayOfStrings,
  PostDescription,
  PostImage,
}) => {
  const [arrayOfStrings, setArrayOfStrings] = useState([]);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [requirementsError, setRequirementsError] = useState("");

  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    setDescription(value);
    if (value.length < 30) {
      setDescriptionError("Description must be at least 30 characters long");
    } else {
      setDescriptionError("");
    }
  };

  const handleNextStep = () => {
    if (description.length < 30) {
      setDescriptionError("Description must be at least 30 characters long");
    } else if (arrayOfStrings.length < 1) {
      setRequirementsError("Requirements cannot be empty");
    } else {
      setRequirementsError(""); // Reset requirements error if requirements are met
      ArrayOfStrings(arrayOfStrings);
      PostDescription(description);
      goToNextStep();
    }
  };
  const handleImageUpload = (postImage) => {
    PostImage(postImage);
    console.log(postImage);
  };
  return (
    <Container
      style={{
        border: "1px solid #efd",
        width: "70%",
        borderRadius: "10px",
        paddingBottom: "2%",
        marginBottom: "4%",
      }}
    >
      <div className="content">
        <Box sx={{ width: "100%", marginTop: "3%" }}>
          <Typography fontSize={16} color="#000">
            Description
          </Typography>
          <InputTextarea
            rows={5}
            cols={94}
            value={description}
            onChange={handleDescriptionChange}
            className={descriptionError ? "p-invalid" : ""}
          />
          {descriptionError && (
            <Typography color="error" variant="body2">
              {descriptionError}
            </Typography>
          )}
        </Box>
        <Box sx={{ width: "100%", marginTop: "3%" }}>
          <Typography fontSize={16} color="#000">
            Requirements
          </Typography>
          <MyArrayInput value={arrayOfStrings} onChange={setArrayOfStrings} />
          {requirementsError && (
            <Typography color="error" variant="body2">
              {requirementsError}
            </Typography>
          )}
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%", marginTop: "3%", height: "20%" }}
          >
            <ImageUploadButton onImageUpload={handleImageUpload} />
          </Stack>
        </Box>
        <Row>
          <div className="col-6" style={{ textAlign: "left", marginTop: "4%" }}>
            <Button
              variant="contained"
              sx={{
                height: "130%",
                color: COLORS.grey,
                border: "1px solid", // Ensure border is visible
                borderColor: "#8B8B8B",
                bgcolor: "transparent",
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  color: COLORS.signIn,
                  borderColor: COLORS.signIn,
                },
              }}
              onClick={goBackStep}
            >
              <KeyboardBackspaceIcon /> Back
            </Button>
          </div>
          <div
            className="col-6"
            style={{ textAlign: "right", marginTop: "4%" }}
          >
            <Button
              variant="contained"
              onClick={handleNextStep}
              sx={{
                height: "130%",
                color: COLORS.white,
                border: "1px solid",
                borderColor: COLORS.signIn,
                bgcolor: COLORS.signIn,
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
            >
              Continue <EastIcon />
            </Button>
          </div>
        </Row>
      </div>
    </Container>
  );
};
const Confirmation = ({
  userId,
  createRecruiter,
  addPost,
  industries,
  goToNextStep,
  goBackStep,
  cities,
  companyName,
  setCompanyName,
  phone,
  setPhone,
  industry,
  setIndustry,
  description,
  setDescription,
  companyNameError,
  setCompanyNameError,
  jobTitle,
  setJobTitle,
  numPeople,
  setNumPeople,
  location,
  setLocation,
  salary,
  setSalary,
  deadline,
  setDeadline,
  arrayOfStrings,
  setArrayOfStrings,
  postDescription,
  setPostDescription,
  postImage,
  setPostImage,
  accessToken,
  setAccessToken,
}) => {
  // const [arrayOfStrings, setArrayOfStrings] = useState([]);
  // const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [requirementsError, setRequirementsError] = useState("");
  const [openConfirm, setOpenConfirm] = useState("");
  const [confirm, setConfirm] = useState("");

  // const [salary, setSalary] = useState("");
  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    setPostDescription(value);
    if (value.length < 30) {
      setDescriptionError("Description must be at least 30 characters long");
    } else {
      setDescriptionError("");
    }
  };
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    setIsClicked(true);
    console.log('isClicked')
    handleNextStep();
  }, [confirm]);
  const handleNextStep = () => {
    if (postDescription.length < 30) {
      setDescriptionError("Description must be at least 30 characters long");
    } else if (arrayOfStrings.length < 1) {
      setRequirementsError("Requirements cannot be empty");
    } else {
      console.log("confirm!");
      setRequirementsError("");
      // goToNextStep();
      setOpenConfirm(true);
      if (isClicked) {
        console.log('isClicked2')

        if (confirm) {
          console.log('is confirmed')

          createRecruiter()
            .then((newAccessToken) => {
              if (newAccessToken) {
                navigate("/admin/dashboard/", { state: { newAccessToken } });
              } else {
                console.error("createRecruiter did not return expected data");
                // Handle the case when resolved value is undefined or has an unexpected structure
              }
            })
            .catch((error) => {
              console.error("Error creating recruiter:", error);
              // Handle error
            });
          // navigate('/admin/post/PostDetails/*'})
        } else {
          return;
        }
      }
    }
  };
  // const handleImageUpload = (postImage) => {
  //   console.log('Clicked');
  //   setPostImage(postImage);
  //   console.log(postImage);
  // };
  const handleImageUpload = (imageUrl) => {
    setPostImage(imageUrl);
  };

  const fileInputRef = React.createRef();
  return (
    <Container
      style={{
        border: "1px solid #efd",
        width: "70%",
        borderRadius: "10px",
        paddingBottom: "2%",
        marginBottom: "4%",
      }}
    >
      <div
        className="col-4"
        style={{ fontSize: "15px", fontFamily: "bold", marginBottom: "5%" }}
      >
        <Typography>
          <strong>Personal Information</strong>
        </Typography>
      </div>

      <div className="row">
        <div className="row" style={{ marginTop: "3%" }}>
          <div
            className="col-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography>Company's name</Typography>
          </div>
          <div className="col-8">
            <TextField
              required
              sx={{ width: "100%", marginTop: "2%" }}
              variant="outlined"
              // label="Job Title"
              id="title"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="row" style={{ marginTop: "3%" }}>
          <div
            className="col-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography>Phone number</Typography>
          </div>
          <div className="col-8">
            {/* <FormControl variant="outlined" fullWidth sx={{ marginTop: "2%" }}>
          <PhoneInput
            country={"us"}
            value={phone}
            onChange={(value) => setPhone(value)}
            inputComponent={(props) => (
              <TextField
                {...props}
                variant="outlined"
                fullWidth
                placeholder="Enter phone number"
              />
            )}
          />
        </FormControl> */}
            <FormControl
              variant="standard"
              sx={{ width: "100%", marginTop: "2%" }}
            >
              <MaskedTextField
                mask="(99#)9 99-99-99-99"
                definitions={{
                  "#": /[1-9]/,
                }}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                name="phone"
                id="phone"
                //   label="Phone Number"
                variant="outlined"
                required={true}
                fullWidth
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="row" style={{ marginTop: "3%" }}>
          <div
            className="col-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography>Industry</Typography>
          </div>
          <div className="col-8">
            <FormControl sx={{ width: "100%", marginTop: "3%" }}>
              <InputLabel id="industry-label">Company's Industry</InputLabel>
              <Select
                labelId="industry-label"
                id="industry"
                value={industry}
                onChange={(event) => setIndustry(event.target.value)}
                label="Company's Industry"
                required={true}
              >
                {industries.map((industry, index) => (
                  <MenuItem key={index} value={industry}>
                    {industry}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="row" style={{ marginTop: "3%" }}>
          <div
            className="col-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography>company's description</Typography>
          </div>
          <div className="col-8">
            <TextField
              sx={{ width: "100%", marginTop: "3%" }}
              variant="outlined"
              //   label="Company's Description"
              id="Description"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
        </div>
      </div>
      <hr style={{ width: "100%", margin: "20px 0" }} />
      <div
        className="col-4"
        style={{ fontSize: "15px", fontFamily: "bold", marginBottom: "5%" }}
      >
        <Typography>
          <strong>Post Information</strong>
        </Typography>
      </div>
      <div className="row">
        <div className="row" style={{ marginTop: "3%" }}>
          <div
            className="col-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography>Job title</Typography>
          </div>
          <div className="col-8">
            <TextField
              required
              sx={{ width: "100%", marginTop: "2%" }}
              variant="outlined"
              value={jobTitle}
              //   label="Job Title"
              id="title"
              onChange={(e) => setJobTitle(e.target.value)}
              // onChange={setJobTitle}
            />
          </div>
        </div>
      </div>
      <div className="row" style={{ marginTop: "3%" }}>
        <div className="row">
          <div
            className="col-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography>Number of peaple to hire</Typography>
          </div>
          <div className="col-8">
            <FormControl fullWidth sx={{ width: "100%", marginTop: "2%" }}>
              <Select
                labelId="number"
                id="number"
                value={numPeople}
                onChange={(event) => setNumPeople(event.target.value)}
                // label="Number of people to hire in this job"
              >
                {[...Array(10).keys()].map((num) => (
                  <MenuItem key={num} value={num + 1}>
                    {num + 1}
                  </MenuItem>
                ))}
                <MenuItem value={10}>10+</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="row" style={{ marginTop: "3%" }}>
          <div
            className="col-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography>Location</Typography>
          </div>
          <div className="col-8">
            <Autocomplete
              options={cities}
              value={location}
              onChange={(event, newValue) => setLocation(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: "100%", marginTop: "3%", marginBottom: "3%" }}
                  variant="outlined"
                  // label="Location"
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="row" style={{ marginTop: "3%" }}>
          <div
            className="col-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography>Salary</Typography>
          </div>
          <div className="col-8">
            <FormControl fullWidth sx={{}}>
              {/* <InputLabel htmlFor="outlined-adornment-amount">Salary</InputLabel> */}
              <OutlinedInput
                id="outlined-adornment-amount"
                value={salary}
                onChange={(event) => setSalary(event.target.value)}
                startAdornment={
                  <InputAdornment position="start">MAD</InputAdornment>
                }
                //   label="Salary"
              />
            </FormControl>
          </div>
        </div>
        <div className="row" style={{ marginTop: "3%" }}>
          <div
            className="col-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography>Deadline</Typography>
          </div>
          <div className="col-8">
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateField"]}>
                <DateField
                  sx={{ width: "100%" }}
                  // label="Deadline"
                  value={deadline}
                  onChange={(newValue) => setDeadline(newValue)}
                  format="MM-DD-YYYY"
                />
              </DemoContainer>
            </LocalizationProvider> */}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  value={deadline}
                  onChange={(newValue) => setDeadline(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
      </div>

      <hr style={{ width: "100%", margin: "10px 0" }} />
      <div className="row">
        <div className="row" style={{ marginTop: "3%" }}>
          <Typography>
            <strong>Description</strong>
          </Typography>

          <div
            className="col-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography>Description</Typography>
          </div>
          <div className="col-8">
            <InputTextarea
              rows={5}
              cols={61}
              value={postDescription}
              onChange={handleDescriptionChange}
              className={descriptionError ? "p-invalid" : ""}
            />
            {descriptionError && (
              <Typography color="error" variant="body2">
                {descriptionError}
              </Typography>
            )}
          </div>
        </div>
        <div className="row" style={{ marginTop: "3%" }}>
          <div
            className="col-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography>Requirements</Typography>
          </div>
          <div className="col-8">
            <MyArrayInput value={arrayOfStrings} onChange={setArrayOfStrings} />
            {requirementsError && (
              <Typography color="error" variant="body2">
                {requirementsError}
              </Typography>
            )}
          </div>
        </div>
        <div className="row" style={{ marginTop: "4%" }}>
          <div
            className="col-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography>Image</Typography>
          </div>
          <div className="col-8">
            {/* <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ width: "100%", marginTop: "3%", height: "20%" }}
            >
              <ImageUploadButton onImageUpload={handleImageUpload} />
            </Stack> */}
            <img
              src={postImage}
              alt="Selected"
              style={{
                maxWidth: "60%",
                margin: "0 auto",
                border: "3px solid #E5E4E2",
                borderRadius: "10px",
              }}
              onClick={() => fileInputRef.current.click()}
            />
          </div>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(e) => {
              const file = e.target.files[0];
              const formData = new FormData();
              formData.append("file", file);
              formData.append("upload_preset", "tpjsjr7k");
              axios
                .post(
                  `https://api.cloudinary.com/v1_1/dwckvxlca/image/upload`,
                  formData
                )
                .then((res) => {
                  const imageUrl = res.data.secure_url;
                  setPostImage(imageUrl);
                  console.log(postImage);
                })
                .catch((err) => console.log(err));
            }}
          />
          {/* <ImageUploadButton onImageUpload={handleImageUpload} />{" "} */}
        </div>
      </div>
      <hr style={{ width: "100%", margin: "10px 0" }} />
      <div className="row">
        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            marginTop: "5%",
          }}
        >
          <Typography
            style={{
              textAlign: "justify",
              width: "90%",
            }}
          >
            By selecting <strong>Confirm</strong>, you agree that this job post
            reflects your requirements, and agree it will be posted and
            applications will be processed following JobApply's{" "}
            <Link href="https://pulse.digital/a-propos/" underline="hover">
              Terms
            </Link>
            ,{" "}
            <Link href="https://pulse.digital/a-propos/" underline="hover">
              Cookie
            </Link>
            , and{" "}
            <Link href="https://pulse.digital/a-propos/" underline="hover">
              Privacy Policies
            </Link>
            .
          </Typography>
        </div>
        <Row>
          <div
            className="col-6"
            style={{ textAlign: "left", marginTop: "6%", marginBottom: "4%" }}
          >
            <Button
              variant="contained"
              sx={{
                height: "130%",
                color: COLORS.grey,
                border: "1px solid", // Ensure border is visible
                borderColor: "#8B8B8B",
                bgcolor: "transparent",
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  color: COLORS.signIn,
                  borderColor: COLORS.signIn,
                },
              }}
              onClick={goBackStep}
            >
              <KeyboardBackspaceIcon /> Back
            </Button>
          </div>
          <div
            className="col-6"
            style={{ textAlign: "right", marginTop: "6%", marginBottom: "4%" }}
          >
            <Button
              variant="contained"
              onClick={handleNextStep}
              sx={{
                height: "130%",
                color: COLORS.white,
                border: "1px solid",
                borderColor: COLORS.signIn,
                bgcolor: COLORS.signIn,
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
            >
              Confirm
            </Button>
          </div>
        </Row>
      </div>
      <Dialog open={openConfirm} fullWidth maxWidth="sm">
        <DialogContent sx={{ padding: "24px", backgroundColor: "#f5f5f5" }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar
              sx={{
                bgcolor: COLORS.signIn,
                width: 60,
                height: 60,
                marginBottom: 2,
              }}
            >
              <PersonAdd fontSize="large" />
            </Avatar>
            <DialogTitle>
              <Typography
                variant="h4"
                fontWeight="bold"
                color={COLORS.signIn}
                textAlign="center"
              >
                Become a Recruiter
              </Typography>
            </DialogTitle>
            <Divider sx={{ width: "100%", margin: "16px 0" }} />
            <Typography
              variant="body1"
              color={COLORS.grey}
              textAlign="center"
              sx={{ marginBottom: 2 }}
            >
              Are you sure you want to convert your candidate account to a
              recruiter account?
            </Typography>
            <Typography
              variant="body2"
              color={COLORS.grey}
              textAlign="center"
              sx={{
                backgroundColor: "#e0e0e0",
                padding: "12px",
                borderRadius: "8px",
                marginBottom: 2,
              }}
            >
              This action will delete your current candidate profile and create
              a new recruiter account with your existing information.
            </Typography>
            <Typography
              variant="body2"
              color={COLORS.signIn}
              fontWeight="bold"
              textAlign="center"
            >
              This action cannot be undone.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            type="submit"
            sx={{
              height: "100%",
              color: COLORS.signIn,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.white,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
            onClick={() => {
              setOpenConfirm(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            sx={{
              height: "100%",
              color: COLORS.white,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.signIn,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
            onClick={() => {
              setConfirm(true);
              return;
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

function AddPost() {
  //---------Personal Info Variables -----------------
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [industry, setIndustry] = useState("");
  const [description, setDescription] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");

  //---------Post Info Variables-------------------
  const [jobTitle, setJobTitle] = useState("");
  const [numPeople, setNumPeople] = useState("");
  const [location, setLocation] = useState("");
  const [salary, setSalary] = useState("");
  const [deadline, setDeadline] = useState(null);

  //----------Job Desc Variables -----------
  const [arrayOfStrings, setArrayOfStrings] = useState([]);
  const [postDescription, setPostDescription] = useState("");
  const [postImage, setPostImage] = useState("");

  const [activeIndex, setActiveIndex] = useState(0);
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken") || ""
  );
  const toast = useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  // Helper function to parse JWT
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  // Function to get user ID from access token
  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      return decodedToken.user_id;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  const userId = getUserIdFromAccessToken();

  useEffect(() => {
    console.log("addpost userId: ", userId);
  }, [userId]);

  async function createRecruiter() {
    console.log("test");

    try {
      const response = await axios.post(
        `${apiUrl}authentication/change-role-to-recruiter/${userId}/`,
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("test");
      const data = response.data;
      console.log("response:", data);

      if (data.recruiter_id) {
        const newAccessToken = data.access_token;
        setAccessToken(newAccessToken);
        localStorage.setItem("accessToken", newAccessToken);
        localStorage.setItem("refreshToken", data.refresh_token);

        const postData = {
          title: jobTitle,
          numPeople: numPeople,
          location: location,
          salary: salary,
          // deadline: deadline,
          deadline: "2024-12-12",
          requirements: arrayOfStrings,
          description: description,
          image: postImage,
          recruiter: data.recruiter_id,
        };
        console.log("postData", postData);

        await addPost(postData, newAccessToken);
        return newAccessToken;
      } else {
        console.error("Error changing role to recruiter", data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function addPost(postData, newAccessToken) {
    console.log("postData", postData);
    try {
      const response = await fetch(`${apiUrl}posts/create/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${newAccessToken}`,
        },
        body: JSON.stringify(postData),
      });

      const data = await response.json();

      if (response.ok) {
        console.log("Post created successfully:", data);
      } else {
        console.error("Error creating post:", data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const industries = [
    "Technology",
    "Finance",
    "Healthcare",
    "Education",
    "Manufacturing",
    "Retail",
    "Hospitality",
    "Transportation",
    "Real Estate",
    "Construction",
    "Energy",
    "Entertainment",
    "Telecommunications",
    "Agriculture",
    "Food and Beverage",
    "Aerospace",
    "Automotive",
    "Pharmaceutical",
    "Consulting",
    "Legal Services",
  ];

  const cities = [
    "Casablanca",
    "Rabat",
    "Marrakesh",
    "Fes",
    "Tangier",
    "Agadir",
    "Meknes",
    "Oujda",
    "Kenitra",
    "Tetouan",
    "Safi",
    "El Jadida",
    "Nador",
    "Khouribga",
    "Beni Mellal",
    "Mohammedia",
    "Laayoune",
    "Khemisset",
    "Settat",
    "Sidi Kacem",
    "Taza",
    "Larache",
    "Berkane",
    "Errachidia",
    "Taroudant",
    "Guelmim",
    "Ouarzazate",
    "Essaouira",
    "Dakhla",
    "Azrou",
    "Oued Zem",
    "Fqih Ben Salah",
  ];

  const items = [
    {
      label: "Personal Info",
      command: (event) =>
        toast.current.show({
          severity: "info",
          summary: "Personal Info",
          detail: event.item.label,
        }),
    },
    {
      label: "Post Info",
      command: (event) =>
        toast.current.show({
          severity: "info",
          summary: "Post Info",
          detail: event.item.label,
        }),
    },
    {
      label: "Description",
      command: (event) =>
        toast.current.show({
          severity: "desc",
          summary: "Description",
          detail: event.item.label,
        }),
    },
    {
      label: "Confirmation",
      command: (event) =>
        toast.current.show({
          severity: "info",
          summary: "Confirmation",
          detail: event.item.label,
        }),
    },
  ];

  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="center-content container">
            <PersonalInfo
              goToNextStep={goToNextStep}
              CompanyName={setCompanyName}
              Phone={setPhone}
              Industry={setIndustry}
              Description={setDescription}
              setCompanyNameError={setCompanyNameError}
              industries={industries}
            />
          </div>
        );
      case 1:
        return (
          <div className="center-content container">
            <PostInfo
              JobTitle={setJobTitle}
              NumPeople={setNumPeople}
              Location={setLocation}
              Salary={setSalary}
              Deadline={setDeadline}
              goToNextStep={goToNextStep}
              goBackStep={goBackStep}
              cities={cities}
            />
          </div>
        );
      case 2:
        return (
          <div className="center-content container">
            <JobDescription
              PostImage={setPostImage}
              ArrayOfStrings={setArrayOfStrings}
              PostDescription={setPostDescription}
              goToNextStep={goToNextStep}
              goBackStep={goBackStep}
            />
          </div>
        );
      case 3:
        return (
          <div className="center-content container">
            <Confirmation
              companyName={companyName}
              setCompanyName={setCompanyName}
              phone={phone}
              setPhone={setPhone}
              industry={industry}
              setIndustry={setIndustry}
              description={description}
              setDescription={setDescription}
              jobTitle={jobTitle}
              setJobTitle={setJobTitle}
              numPeople={numPeople}
              setNumPeople={setNumPeople}
              location={location}
              setLocation={setLocation}
              salary={salary}
              setSalary={setSalary}
              deadline={deadline}
              setDeadline={setDeadline}
              arrayOfStrings={arrayOfStrings}
              setArrayOfStrings={setArrayOfStrings}
              postDescription={postDescription}
              setPostDescription={setPostDescription}
              goToNextStep={goToNextStep}
              goBackStep={goBackStep}
              cities={cities}
              industries={industries}
              postImage={postImage}
              setPostImage={setPostImage}
              createRecruiter={createRecruiter}
              addPost={addPost}
              userId={userId}
              setAccessToken={setAccessToken}
              accessToken={accessToken}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const goToNextStep = () => {
    if (activeIndex < items.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  };

  const goBackStep = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };

  return (
    <>
      <ButtonAppBar isAuth={true} addPost={true} />
      <div className="container" style={{ marginTop: "3%" }}>
        <Toast ref={toast}></Toast>
        <Steps
          model={items}
          activeIndex={activeIndex}
          onSelect={(e) => setActiveIndex(e.index)}
          readOnly={false}
        />
        <div
          className="center-content container"
          style={{ width: "70%", marginTop: "5%", borderRadius: "20px" }}
        >
          {activeIndex === 0 && (
            <img alt="..." src={require("assets/img/emp2.png")} />
          )}
          {activeIndex === 1 && (
            <img alt="..." src={require("assets/img/details.png")} />
          )}
          {activeIndex === 2 && (
            <img alt="..." src={require("assets/img/describe.png")} />
          )}
          {activeIndex === 3 && (
            <img alt="..." src={require("assets/img/conf.png")} />
          )}
        </div>
        <div style={{ marginTop: "3%" }}>{renderStepContent(activeIndex)}</div>
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPost);
