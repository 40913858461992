// // reducers.js
// import { SET_USER_ID } from './actions';

// const initialState = {
//   userId: null,
// };

// const userIdReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case SET_USER_ID:
//       return {
//         ...state,
//         userId: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// export default userIdReducer;
import { combineReducers } from 'redux';
import { SET_USER_ID } from './actions';

const initialState = {
  userId: null,
};

const userIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user: userIdReducer,
  // other reducers can be added here
});

export default rootReducer;
