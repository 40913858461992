import { Navbar, Container, Nav } from "react-bootstrap";
import SearchBar from "./SearchBar";
import MyCard from "./Card";
import "./home.css";
import {Box} from "@mui/material";
import MyCardDetails from "./CardDetails";
import DevOpsLoop from "assets/img/DevOpsLoop.png"; // Adjust the path according to your project structure
import ButtonAppBar from "../../components/Navbars/navBar";
import LoginForDialog from "views/Auth/LoginForDialog";
import SignInForDialog from "views/Auth/SignInForDialog"; // Make sure the path is correct
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation, useNavigate } from "react-router-dom";
import App from "views/Chat/Chat";
import useSignalingStore from "../signalingStore";

function Home() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiUrlsoket = process.env.REACT_APP_API_URL_SOKET;

  const [ChatComponent, setChatComponent] = useState(false);
  const [allJobs, setAllJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [lastPost, setLastPost] = useState(null);
  const [count, setCount] = useState(0);

  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const location = useLocation();

  const closeLoginPopUp = () => {
    setOpenLoginDialog(false);
  };

  const closeSignInPopUp = () => {
    setOpenSignInDialog(false);
  };

  const Load = async (page = 1) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      // Simulate a delay of 2 seconds
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Fetch the data from the API
      const response = await fetch(`${apiUrl}posts/list/?page=${page}`);

      // Check if the response is ok
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Parse the JSON response
      const json = await response.json();
      if (json.results.length === 0) {
        setHasMore(false);
      } else {
        // Update the allJobs state with the new results
        setAllJobs((prevJobs) => [...prevJobs, ...json.results]);
        if (count === 0) {
          setLastPost(json.results[0]);
          setCount(1);
        }

        // Log the lastPost for debugging
        //console.log("lastPost is:", json.results[0]);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      // Set the isLoading state to false after the process is completed
      setIsLoading(false);
    }
  };

  useEffect(() => {
    //console.log("location", location);
    Load(1);
  }, []);

  useEffect(() => {
    if (page > 1) {
      Load(page);
    }
  }, [page]);

  useEffect(() => {
    if (ChatComponent === false) {
      //console.log("ChatComponent", ChatComponent);
      const handleScroll = () => {
        if (
          window.innerHeight + window.scrollY >=
            document.body.offsetHeight - 2 &&
          hasMore &&
          !isLoading
        ) {
          setPage((prevPage) => prevPage + 1);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [hasMore, isLoading]);

  const handleSearchResults = (results, message) => {
    setSearchResults(results);
    setNoResultsMessage(message);
  };

  const jobsToDisplay =
    searchResults.length > 0
      ? searchResults.map((result) => result.post)
      : allJobs;

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  useEffect(() => {
    //console.log("is Auth ?: ", isAuth);
  }, [isAuth]);

  const handleCardClick = (job) => {
    if (isSmallScreen) {
      navigate("/viewjob", { state: { job } });
    } else {
      setSelectedJob(job);
    }
  };

  const handleLoginClick = () => {
    setOpenLoginDialog(true);
  };

  const handleSignInClick = () => {
    setOpenSignInDialog(true);
  };

  useEffect(() => {
    if (ChatComponent == true) {
      const handleScroll = () => {
        //console.log("scrolling");
        const footer = document.querySelector("footer");
        const rect = footer.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        setIsFooterVisible(isVisible);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const senderId = getUserIdFromAccessToken();
  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token : ", decodedToken);
      const senderId = decodedToken.user_id;
      return senderId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function getUserRoleFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      //console.log("Access token : ", decodedToken);
      const userRole = decodedToken.role;
      return userRole;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    //console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  const connectToSocket = (userId) => {
    const signalingServerUrl = `${apiUrlsoket}ws/notif/${userId}/`;
    const newSignalingServer = new WebSocket(signalingServerUrl);
    // Save the signalingServer to the global state
    useSignalingStore.getState().setSignalingServer(newSignalingServer);

    return newSignalingServer;
  };

  const { signalingServer } = useSignalingStore();

  useEffect(() => {
    if (!signalingServer) {
      // If no signaling server, connect
      const userId = getUserIdFromAccessToken(); // Implement this function
      if (userId) {
        connectToSocket(userId);
      }
    }
  }, [signalingServer]);

  return (
    <>
      {ChatComponent ? (
        <>
          <ButtonAppBar
            isAuth={isAuth}
            onLoginClick={handleLoginClick}
            onSignInClick={handleSignInClick}
            setChatComponent={setChatComponent}
          />
          <App candidate_id={senderId} />
        </>
      ) : (
        <>
          <ButtonAppBar
            isAuth={isAuth}
            onLoginClick={handleLoginClick}
            onSignInClick={handleSignInClick}
            setChatComponent={setChatComponent}
          />
          <SearchBar props={handleSearchResults} />
          <div className="container">
            <div className="row justify-content-center home">
              <div className="col-lg-5 col-md-6 col-12 myCard">
                <div className="mycard-scroll">
                  {noResultsMessage ? (
                    <div className="no-results-message">
                      <p className="no-results-title">
                        {noResultsMessage.split(".")[0]}
                      </p>
                      <p className="no-results-subtitle">
                        {noResultsMessage.split(".")[1]}
                      </p>
                    </div>
                  ) : (
                    jobsToDisplay.map((job, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          handleCardClick(job);
                          //console.log('posts are : ',job)
                        }}
                      >
                        <MyCard
                          title={job.title}
                          deadline={job.deadline}
                          description={job.description}
                          image={job.image}
                          post_id={job.id}
                          recruiter={job.recruiter}
                          // userId={userId}
                        />
                      </div>
                    ))
                  )}
                  {isLoading && (
                    <div className="loading-container">
                      <p>Loading more posts...</p>
                    </div>
                  )}
                </div>
              </div>
              {!isSmallScreen && (
                <div className="col-lg-5 col-md-6 col-12 myCard card-gap">
                  <div className="mycard-scroll fixed-component">
                    {selectedJob ? (
                      <MyCardDetails
                        title={selectedJob.title}
                        description={selectedJob.description}
                        requirements={
                          <span style={{ color: "black", fontSize: "12px" }}>
                            {selectedJob.requirements.join(", ")}
                          </span>
                        }
                        localisation={selectedJob.localisation}
                        mode={selectedJob.mode}
                        deadline={selectedJob.deadline}
                        image={selectedJob.image}
                        id={selectedJob.id}
                        isFooterVisible={isFooterVisible}
                        has_applied={selectedJob.has_applied}
                      />
                    ) : (
                      <Box
                      component="img"
                      src="https://res.cloudinary.com/dwckvxlca/image/upload/v1726682352/fpqoe6vvi41nbwge5bm4.png"
                      alt=""
                      sx={{
                        width: "100%",
                        height: "80%",
                        objectFit: "contain",
                        maxHeight: "100%",
                      }}
                    />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer />
          
            <Dialog
              open={openLoginDialog}
              onClose={closeLoginPopUp}
              // fullWidth
              // sx={{width:'1000%',
              //   height:'1000%'
              // }}
            >
              <DialogContent>
                <LoginForDialog />
              </DialogContent>
              <DialogActions />
            </Dialog>
          

          <Dialog
            open={openSignInDialog}
            onClose={closeSignInPopUp}
            // maxWidth="sm"
          >
            <DialogContent>
              <SignInForDialog />
            </DialogContent>
            <DialogActions />
          </Dialog>
        </>
      )}
    </>
  );
}
export default Home;
