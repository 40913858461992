import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Stack,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
  Menu,
} from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import { COLORS } from "colors/color";
import { styled } from "@mui/material/styles";
import { List, ListItem } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";

function formatDate(dateString) {
  const options = { year: "numeric", month: "short", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

const Calendar = () => {
  const [eventGuid, setEventGuid] = useState(0);
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [candidate, setCandidate] = useState("");
  const [data, setData] = useState([]);
  const [openDelete, setOpenDelete] = useState("");
  const [formData, setFormData] = useState({
    candidate: "",
    event_title: "",
    event_start_date: "",
    event_end_date: "",
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = getUserIdFromAccessToken();

  const navigate = useNavigate();
  const fetchEventsByRecruiter = useCallback(async () => {
    if (!userId) return;
    try {
      const response = await axios.get(`${apiUrl}authentication/events/${userId}/`);
      const events = response.data
        .map((event) => ({
          id: event.id,
          title: event.event_title,
          start: event.event_start_date,
          end: event.event_end_date,
          extendedProps: {
            candidate: {
              full_name: `${event.candidate.first_name} ${event.candidate.last_name}`,
              candidate_id: event.candidate.id,
            },
          },
        }))
        .sort((a, b) => new Date(a.start) - new Date(b.start)); // Sort events by start date
  
      if (JSON.stringify(events) !== JSON.stringify(currentEvents)) {
        setCurrentEvents(events);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  }, [userId, apiUrl, currentEvents]);
  

  useEffect(() => {
    fetchEventsByRecruiter();
  }, [fetchEventsByRecruiter]);

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      return decodedToken.user_id;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  const createEventId = useCallback(() => {
    const newId = eventGuid + 1;
    setEventGuid(newId);
    return String(newId);
  }, [eventGuid]);

  const [selectInfo, setSelectInfo] = useState(null);

  const handleDateSelect = (selectInfo) => {
    setDialogOpen(true);
    setSelectInfo(selectInfo);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSubmit = async () => {
    console.log("candidate id is :", candidate);
    setFormData({
      candidate: candidate,
      event_title: title,
      event_start_date: selectInfo.startStr,
      event_end_date: selectInfo.endStr,
    });

    try {
      const response = await fetch(`${apiUrl}authentication/events/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Data posted successfully!");
        const calendarApi = selectInfo.view.calendar;
        calendarApi.unselect();
        calendarApi.addEvent({
          id: createEventId(),
          title: title,
          candidate: candidate,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay,
          extendedProps: {
            candidate: {
              full_name: candidate,
            },
          },
        });
        setDialogOpen(false);
        setTitle("");
        setCandidate("");
        fetchEventsByRecruiter(); // Refresh events after adding new one
      } else {
        console.error("Failed to post data:", response.statusText);
      }
    } catch (error) {
      console.error("Failed to post data:", error.message);
    }
  };

  const deleteInfo = async (eventId) => {
    try {
      await axios.delete(`${apiUrl}authentication/events/delete/${eventId}/`);
      console.log(`Event with ID ${eventId} deleted successfully`);
    } catch (error) {
      console.error(
        `Error deleting event with ID ${eventId}: ${error.message}`
      );
    }
  };

  const [info, setInfo] = useState("");
  const handleEventClick = (clickInfo) => {
    setInfo(clickInfo);
    console.log("clickInfo", clickInfo);
    // setOpenDelete(true);
  };

  const handleSelectChangeCandidate = (event) => {
    setCandidate(event.target.value);
  };

  const ScrollableEventList = styled(List)(({ theme }) => ({
    maxHeight: 'calc(100vh - 200px)', 
    overflowY: 'auto',

    padding: 0,
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }));

  const EventItem = styled("li")(({ theme, eventColor }) => ({
    marginBottom: "20px",
    background: `linear-gradient(135deg, ${eventColor} 0%, ${eventColor}88 100%)`,
    borderRadius: "12px",
    padding: "16px",
    position: "relative",
    overflow: "hidden",
    boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 6px 20px rgba(0,0,0,0.15)",
    },
  }));

  const renderSidebarEvent = (event) => {
    console.log("Sidebar Event:", event);


    const handleSendMessage = () => {
      console.log("Send message for event:", event);
      navigate(`/admin/Chat/`, {
        state: { candidate: event.extendedProps.candidate },
      });
    };

    const getRandomColor = () => {
      const colors = ["#FF6B6B", "#4ECDC4", "#45B7D1", "#FFA07A", "#98D8C8"];
      return colors[Math.floor(Math.random() * colors.length)];
    };


    const eventColor = getRandomColor();

    return (
      <EventItem
        key={event.id}
        eventColor={eventColor}
        onClick={handleSendMessage}
        style={{ cursor: "pointer" }}
      >
        <Typography
          variant="h4"
          style={{ fontWeight: "bold", color: "#ffffff", marginBottom: "8px" }}
        >
          {formatDate(event.start).split(" ")[0]}
        </Typography>
        <Typography
          variant="body2"
          style={{ color: "#ffffff", opacity: 0.8, marginBottom: "12px" }}
        >
          {formatDate(event.start)}
        </Typography>
        <Typography
          variant="h6"
          style={{ fontWeight: "bold", color: "#ffffff", marginBottom: "12px" }}
        >
{/* >>>>>>> e4877010eb46bbe01c4241442c26797f3bb25fad */}
          {event.title}
        </Typography>
        <div style={{
          fontSize: "14px",
          color: "#ffffff",
          display: "flex",
          alignItems: "center",
          background: "rgba(255,255,255,0.2)",
          padding: "6px 10px",
          borderRadius: "20px",
          width: "fit-content",
        }}>
          <svg
            viewBox="0 0 24 24"
            width="16"
            height="16"
            stroke="#ffffff"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ marginRight: "8px" }}
          >
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
          {event.extendedProps && event.extendedProps.candidate
            ? event.extendedProps.candidate.full_name || "No name available"
            : "No candidate information"}
        </div>
      </EventItem>
    );
  };

  const renderEventContent = (eventInfo) => (
    <div
      style={{
        backgroundColor: COLORS.signIn,
        color: "#ffffff", // White text for contrast
        padding: "4px 8px",
        borderRadius: "4px",
        fontSize: "0.85em",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        boxShadow: "0 1px 2px rgba(0,0,0,1)",
        border: "1px solid rgba(0,0,0,1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        minHeight: "24px",
      }}
    >
      <span>{eventInfo.event.title}</span>
      {eventInfo.event.extendedProps &&
        eventInfo.event.extendedProps.candidate && (
          <span
            style={{
              fontSize: "0.8em",
              opacity: 0.8,
              marginLeft: "4px",
              maxWidth: "40%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {eventInfo.event.extendedProps.candidate.full_name || ""}
          </span>
        )}
    </div>
  );
  const ModernPaper = styled(Paper)(({ theme }) => ({
    width: "300px",
    overflowY: "auto",
    padding: "24px",
    backgroundColor: "#f8f9fa",
    boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
    borderRadius: "16px",
  }));

  const EventList = styled(List)({
    padding: 0,
  });

  const CalendarContainer = styled("div")({
    flexGrow: 1,
    height: "100%",
    "& .fc": {
      fontFamily: "Arial, sans-serif",
      "& .fc-toolbar-title": {
        fontSize: "1.5rem",
        fontWeight: "bold",
      },
      "& .fc-button": {
        backgroundColor: COLORS.signIn,
        borderColor: COLORS.signIn,
        "&:hover": {
          backgroundColor: COLORS.white,
          borderColor: COLORS.signIn,
          color: COLORS.signIn,
        },
      },
      "& .fc-day-today": {
        backgroundColor: "rgba(74, 144, 226, 0.1) !important",
      },
      "& .fc-event": {
        backgroundColor: "transparent",
        borderColor: "transparent",
        borderRadius: "4px",
      },
    },
  });
 
  return (
    <div className="content">
      <Stack
        direction="row"
        spacing={3}
        sx={{ height: "calc(100vh - 20px)", width: "100%", p: 3 }}
      >
        <ModernPaper elevation={0}>
          <Typography variant="h5" sx={{ textAlign: "center", mb: 3 }}>
            All Events ({currentEvents.length})
          </Typography>
          <EventList>
            {currentEvents.map((event) => renderSidebarEvent(event))}
          </EventList>
        </ModernPaper>
        <div style={{ flexGrow: 1, height: "100%" }}>
          <CalendarContainer>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              initialView="dayGridMonth"
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              weekends={weekendsVisible}
              eventContent={renderEventContent}
              eventClick={handleEventClick}
              events={currentEvents}
              height="100%"
              aspectRatio={1.35}
            />
          </CalendarContainer>
        </div>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Add New Event</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Event Title"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="candidate-label">Candidate's Name</InputLabel>
              <Select
                labelId="candidate-label"
                id="candidate"
                value={candidate}
                onChange={handleSelectChangeCandidate}
              >
                {data.map((candidate) => (
                  <MenuItem key={candidate.id} value={candidate.id}>
                    {`${candidate.first_name} ${candidate.last_name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleDialogSubmit} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>

      <Dialog
        open={openDelete}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: "8px",
            padding: "24px",
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <DialogContent>
          <Typography
            fontSize={28}
            fontWeight="bold"
            fontFamily="sans-serif"
            color={COLORS.signIn}
            gutterBottom
          >
            Confirm Deletion
          </Typography>
          <Typography
            fontSize={18}
            fontFamily="sans-serif"
            color={COLORS.grey}
            paragraph
          >
            Are you sure you want to delete this event?
          </Typography>
          <Typography
            fontSize={14}
            fontFamily="sans-serif"
            color={COLORS.signIn}
            paragraph
          >
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            onClick={() => setOpenDelete(false)}
            sx={{
              height: "100%",
              color: COLORS.signIn,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.white,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              deleteInfo(info.event.id);
              info.event.remove();
              fetchEventsByRecruiter();
              setOpenDelete(false);
            }}
            sx={{
              height: "100%",
              color: COLORS.white,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.signIn,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                borderColor: COLORS.signIn,
                color: COLORS.signIn,
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Calendar;