import React, { useEffect, useState } from "react";
import { Line, Pie, Bar } from "react-chartjs-2";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BiWindowOpen,
  BiTask,
  BiVideo,
  BiSolidMessageAltDetail,
} from "react-icons/bi";
import { IconContext } from "react-icons";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Chart } from "primereact/chart";
import RefreshIcon from "@mui/icons-material/Refresh";
import { styled } from "@mui/system";

// core components
import {
  dashboard24HoursPerformanceChart,
  dashboardEmailStatisticsChart,
} from "variables/charts.js";

// import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { setUserId } from "../Redux/actions";
import { Box, Button, CardContent, Paper, Typography } from "@mui/material";
import { COLORS } from "colors/color";
import InternshipStatistics from "./InternshipStatistics";
import PostScoreStatistics from './PostScoreStatistics';
function Dashboard() {
  const currentDate = new Date();
  const lastFiveMonthsLabels = [];
  for (let i = 6; i >= 0; i--) {
    const date = new Date(currentDate);
    date.setMonth(date.getMonth() - i);
    const monthName = date.toLocaleDateString("en-US", { month: "long" });
    lastFiveMonthsLabels.push(monthName);
  }
  // //console.log(lastFiveMonthsLabels);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [posts, setPosts] = useState(0);
  const [applicants, setApplicants] = useState(0);
  const [genderData, setGenderData] = useState([]);
  const [userRole, setUserRole] = useState(getUserRoleFromAccessToken());
  const [roleLoaded, setRoleLoaded] = useState(false);
  const [error, setError] = useState(null);

  const [genderChartData, setGenderChartData] = useState(null);
  const [GenderChartOptions, setGenderChartOptions] = useState({
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Number of Candidates",
        },
      },
      x: {
        title: {
          display: true,
          text: "Month",
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Candidates by Gender per Month",
      },
    },
  });
  const userId = getUserIdFromAccessToken();


  const [postsData, setPostsData] = useState([]);
  // const [userId, setUserId] = useState(null);
  const location = useLocation();
  //console.log("location is : ", location);
  useEffect(() => {
    const { state } = location;
    if (state && state.newAccessToken) {
      const { newAccessToken } = state;
      localStorage.setItem("accessToken", newAccessToken);
      setUserId(getUserIdFromAccessToken());
      setUserRole("r");
      LoadApplications();
      LoadGenderChart();
      LoadPostsChart();
      // }
      setRoleLoaded(true);
    } else {
      console.error("No access token found in location");
    }
  }, []);

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: lastFiveMonthsLabels,
      datasets: [
        {
          label: "My First dataset",
          backgroundColor: documentStyle.getPropertyValue("--blue-500"),
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          data: [65, 59, 80, 81, 56, 55, 40],
        },
        {
          label: "My Second dataset",
          backgroundColor: documentStyle.getPropertyValue("--pink-500"),
          borderColor: documentStyle.getPropertyValue("--pink-500"),
          data: [28, 48, 40, 19, 86, 27, 90],
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            fontColor: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };
  }, []);

  const transformData = (data) => {
    const transformedData = [];
    for (const month in data) {
      const monthData = data[month];
      let maleCount = 0;
      let femaleCount = 0;
      for (const postId in monthData) {
        const post = monthData[postId];
        maleCount += post["male"] || 0;
        femaleCount += post["female"] || 0;
      }
      transformedData.push({ month, male: maleCount, female: femaleCount });
    }
    return transformedData;
  };

  const transformedGenderData = transformData(genderData);
  const navigate = useNavigate();
  useEffect(() => {
    const { state } = location;
    if (!state) {
      if (userRole !== "r") {
        //console.log("User is not a recruiter. Navigating to home.");
        navigate("/home");
      } else {
        LoadApplications();
        LoadGenderChart();
        LoadPostsChart();
      }
    }
  }, [userRole]);
  const LoadApplications = async () => {
    //console.log("user id is : ", userId);
    const response = await fetch(
      `${apiUrl}recruiters/${userId}/total_application_and_post/`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }
    );
    if (response.status === 401) {
      //console.log("Unauthorized. Redirecting to login page...");
      navigate("/login");
      return;
    }
    const json = await response.json();
    setPosts(json.total_posts);
    setApplicants(json.total_applications);
    return json;
  };


  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      //console.log("Access token : ", decodedToken);
      const userId = decodedToken.user_id;
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function getUserRoleFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      //console.log("Access token : ", decodedToken);
      const userRole = decodedToken.role;
      return userRole;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    //console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }
  const LoadGenderChart = async () => {
    try {
      const response = await fetch(
        `${apiUrl}recruiters/${userId}/candidates_by_gender_per_recruiter/`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );
      if (response.status === 401) {
        //console.log("Unauthorized. Redirecting to login page...");
        navigate("/login");
        return;
      }
      const json = await response.json();
      //console.log("Raw API response:", json);
      setGenderData(json);

      // Process the data
      const processedData = {};
      for (const [month, posts] of Object.entries(json)) {
        processedData[month] = { male: 0, female: 0 };
        for (const postData of Object.values(posts)) {
          processedData[month].male += postData.male || 0;
          processedData[month].female += postData.female || 0;
        }
      }
      //console.log("Processed data:", processedData);

      // Convert month numbers to names
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const chartData = {
        labels: lastFiveMonthsLabels,
        datasets: [
          {
            label: "Male",
            data: lastFiveMonthsLabels.map((monthName) => {
              const monthNumber = String(
                monthNames.indexOf(monthName) + 1
              ).padStart(2, "0");
              return processedData[monthNumber]?.male || 0;
            }),
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
            pointRadius: 5,
            pointBackgroundColor: "rgb(75, 192, 192)",
          },
          {
            label: "Female",
            data: lastFiveMonthsLabels.map((monthName) => {
              const monthNumber = String(
                monthNames.indexOf(monthName) + 1
              ).padStart(2, "0");
              return processedData[monthNumber]?.female || 0;
            }),
            fill: false,
            borderColor: "rgb(175, 152, 185)",
            tension: 0.1,
            pointRadius: 5,
            pointBackgroundColor: "rgb(175, 152, 185)",
          },
        ],
      };
      //console.log("Chart data:", chartData);
      setGenderChartData(chartData);
    } catch (error) {
      console.error("Error fetching gender data:", error);
      setError("Failed to load chart data. Please try again.");
    }
  };
  const LoadPostsChart = async () => {
    try {
      const response = await fetch(
        `${apiUrl}recruiters/${userId}/candidates_applied_to_RHposts/`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        }
      );

      if (response.status === 401) {
        //console.log("Unauthorized. Redirecting to login page...");
        navigate("/login");
        return;
      }

      const json = await response.json();
      //console.log("API Response:", json);

      if (Object.keys(json).length === 0) {
        //console.log("No data received from API");
        setPostsData({ labels: [], datasets: [] });
        return;
      }

      // Process the data
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const postIds = new Set();
      const monthlyData = {};

      Object.entries(json).forEach(([month, posts]) => {
        const monthIndex = parseInt(month) - 1;
        const monthName = monthNames[monthIndex];

        Object.entries(posts).forEach(([postId, data]) => {
          postIds.add(postId);
          if (!monthlyData[postId]) {
            monthlyData[postId] = Array(12).fill(0);
          }
          monthlyData[postId][monthIndex] = data.total_candidates;
        });
      });

      const chartData = {
        labels: monthNames,
        datasets: Array.from(postIds).map((postId, index) => ({
          label: `Post ${postId}`,
          data: monthlyData[postId],
          borderColor: getColor(index),
          backgroundColor: getColor(index, 0.5),
          borderWidth: 2,
          fill: false,
        })),
      };

      //console.log("Chart Data:", chartData);
      setPostsData(chartData);
    } catch (error) {
      console.error("Error fetching posts data:", error);
      setPostsData({ labels: [], datasets: [] });
    }
  };

  const getColor = (index, opacity = 1) => {
    const color = modernColors[index % modernColors.length];
    return opacity === 1
      ? color
      : `${color}${Math.floor(opacity * 255)
          .toString(16)
          .padStart(2, "0")}`;
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Applications per Post by Month",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
        },
      },
      y: {
        title: {
          display: true,
          text: "Number of Applications",
        },
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          max: 20
        }
      },
    },
  };
  //----------------------------------------------------------------------------------------------

  const modernColors = [
    "#3498db",
    "#2ecc71",
    "#e74c3c",
    "#f39c12",
    "#9b59b6",
    "#1abc9c",
    "#d35400",
    "#34495e",
    "#16a085",
    "#c0392b",
  ];
  const StyledCard = styled(Paper)(({ theme }) => ({
    marginBottom: "2%",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: "0 8px 30px rgba(0,0,0,0.15)",
    },
  }));

  const CardHeaderWrapper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  }));

  const CardBodyWrapper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    height: "350px",
  }));
  const CardBodyWrapper2 = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    height: "500px",
  }));

  const CardFooterWrapper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  }));

  //--------------------------------------------------------------------------------------
  const data = {
    labels: lastFiveMonthsLabels,
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "rgba(255,99,132,0.2)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        // stack: 3,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [65, 59, 80, 81, 56, 55, 40],
      },
      {
        label: "My second dataset",
        backgroundColor: "rgba(155,231,91,0.2)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        // stack: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [45, 79, 10, 41, 16, 85, 20],
      },
      {
        label: "My second dataset",
        backgroundColor: "rgba(155,231,91,0.2)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        // stack: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [45, 79, 10, 41, 16, 85, 20],
      },
    ],
  };
  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    type: "bar",
  };

  const transformData2 = (data) => {
    const transformedData = [];
    for (const month in data) {
      // //console.log("this is the month : ", month);
      const monthData = data[month];
      // //console.log("this is the monthData : ", monthData);

      for (const postId in monthData) {
        // //console.log("this is the postId ", postId);
        const post = monthData[postId];
        // //console.log("this is the post ", post);
        const postIndex = transformedData.findIndex(
          (item) => item.id === postId
        );
        // //console.log("this is the postIndex ", postIndex);
        if (postIndex !== -1) {
          // If the post already exists in transformedData, update its count for the current month
          transformedData[postIndex][month] = post["total_candidates"] || 0;
        } else {
          // If the post doesn't exist in transformedData, create a new entry
          const newPost = {
            id: postId,
            [month]: post["total_candidates"] || 0,
          };
          transformedData.push(newPost);
        }
      }
    }
    return transformedData;
  };

  const transformedPostsData = transformData2(postsData);
  // //console.log("transformedPostsData : ", transformedPostsData);

  const chartData2 = {
    labels: lastFiveMonthsLabels,
    datasets: transformedPostsData.map((post) => ({
      label: `Post ${post.id}`,
      data: Object.values(post).slice(1),
      borderWidth: 1,
    })),
  };
  const chartOptions2 = {
    maintainAspectRatio: true, // Allow chart to resize
    labels: lastFiveMonthsLabels,
  };

  useEffect(() => {
    LoadPostsChart().then(transformData2);
  }, []);

  return (
    <>
      <div
        className="content dashboard-container"
        style={{ marginLeft: "20px", marginRight: "20px", marginTop: "70px" }}
      >
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <IconContext.Provider
                        value={{ color: "FF9800", size: "50px" }}
                      >
                        <i className="c">
                          <BiWindowOpen />
                        </i>
                      </IconContext.Provider>
                      {/* <i className="bi bi-calendar-date"/> */}
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Posts</p>
                      <CardTitle tag="p">{posts}</CardTitle>
                      {/* <i className="bi bi-calendar-date" /> */}
                      {/* <i className="bi bi-calendar-date-fill"/> */}
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                {/* <div className="stats">
                  <i className="fas fa-sync-alt" /> Update Now
                </div> */}
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <IconContext.Provider
                        value={{ color: "219C90", size: "50px" }}
                      >
                        <BiTask />
                      </IconContext.Provider>
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Applicant</p>
                      <CardTitle tag="p">{applicants}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                {/* <div className="stats">
                  <i className="far fa-calendar" /> Last day
                </div> */}
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <IconContext.Provider
                        value={{ color: "FF6868", size: "50px" }}
                      >
                        <BiVideo />
                      </IconContext.Provider>
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Interview</p>
                      <CardTitle tag="p">5</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <IconContext.Provider
                        value={{ color: "50C4ED", size: "50px" }}
                      >
                        <BiSolidMessageAltDetail />
                      </IconContext.Provider>
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Messages</p>
                        <CardTitle tag="p">10</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <StyledCard elevation={0}>
              <CardHeaderWrapper>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                  <Typography variant="h5" fontWeight="bold">
                  Application's Candidates
                </Typography>
                <Button
                  variant="contained"
                  startIcon={<RefreshIcon />}
                  sx={{
                    height: '100%',
                    color: COLORS.white,
                    border: '1px solid',
                    borderColor: COLORS.grey,
                    bgcolor: COLORS.signIn,
                    ':hover': {
                      bgcolor: COLORS.white,
                      borderColor: COLORS.signIn,
                      color: COLORS.signIn,
                    },
                  }}
                  onClick={LoadGenderChart}
                >
                  Refresh
                </Button>
              </Box>
    
              </CardHeaderWrapper>
              <CardBodyWrapper>
                <Chart
                  type="bar"
                  data={postsData}
                  options={{
                    ...chartOptions,
                    maintainAspectRatio: false, // This allows the chart to fill its container
                    responsive: true,
                  }}
                  style={{ height: "100%", width: "100%" }} // This ensures the chart takes up all available space
                />
              </CardBodyWrapper>
            </StyledCard>
          </Col>
        </Row>
          <Row> <Col md="6"><InternshipStatistics userId={userId} apiUrl={apiUrl} /></Col> 
          <Col md="6"><PostScoreStatistics userId={userId} apiUrl={apiUrl} /></Col></Row>
          {/* <Col md="6">
            <StyledCard elevation={0}>
              <CardBodyWrapper2>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Box>
                    <Typography variant="h5">
                      Application Rate (Male/Female)
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      Line Chart with Points
                    </Typography>
                  </Box>
             
                </Box>
                <Box height={400}>
                  {error ? (
                    <Typography color="error">{error}</Typography>
                  ) : genderChartData ? (
                    <Line
                      data={genderChartData}
                      options={{
                        ...options,
                        responsive: true,
                        maintainAspectRatio: false,
                      }}
                    />
                  ) : (
                    <Typography>Loading chart data...</Typography>
                  )}
                </Box>
              </CardBodyWrapper2>
            </StyledCard>
          </Col>
        </Row> */}
        
        {/* <Row>
          <Col md="12">
            <StyledCard elevation={0} sx={{ marginTop: "2%" }}>
              <CardHeaderWrapper>
                <CardTitle tag="h5">Application's candidates</CardTitle>
                
              </CardHeaderWrapper>
              <CardBodyWrapper>
                <Line
                  data={dashboard24HoursPerformanceChart.data}
                  options={dashboard24HoursPerformanceChart.options}
                  width={400}
                  height={100}
                />
              </CardBodyWrapper>
              <CardFooterWrapper>
                <hr />
                <div className="stats">
                  <i className="fa fa-history" /> Updated 3 minutes ago
                </div>
              </CardFooterWrapper>
            </StyledCard>
          </Col>
        </Row> */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);