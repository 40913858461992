import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { styled } from "@mui/system";
import { CreateOutlined, DeleteOutline } from "@mui/icons-material";
import { COLORS } from "colors/color";

const StyledCard = styled(Card)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  borderRadius: "12px",
  background: `linear-gradient(135deg, ${COLORS.primary}, ${COLORS.secondary})`,
  overflow: "hidden",
  boxShadow: "0 6px 20px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 12px 25px rgba(0, 0, 0, 0.2)",
  },
}));

const StyledCardMedia = styled(CardMedia)({
  height: 200,
  objectFit: "cover",
});

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: "10px",
  borderRadius: "8px",
  textTransform: "none",
  boxShadow: "none",
  "&:hover": {
    boxShadow: "0 4px 10px rgba(0,0,0,0.6)",
  },
}));

function RecruiterCard({
  recruiterId,
  imageSrc,
  firstName,
  lastName,
  email,
  company,
  address,
  city,
  country,
  postal_code, // Changed from postalCode to postal_code
  comp_industry, // Changed from compIndustry to comp_industry
  comp_description,
  onDeleteRecruiter,
  updateRecruiter,
  onSelectRecruiter,
}) {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [recruiterFirstName, setRecruiterFirstName] = useState(firstName);
  const [recruiterLastName, setRecruiterLastName] = useState(lastName);
  const [recruiterEmail, setRecruiterEmail] = useState(email);
  const [recruiterCompany, setRecruiterCompany] = useState(company);

  const [recruiterData, setRecruiterData] = useState({
    firstName,
    lastName,
    email,
    company,
    address,
    city,
    country,
    postal_code, // Changed from postalCode to postal_code
    comp_industry, // Changed from compIndustry to comp_industry
    comp_description,
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRecruiterData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleUpdateRecruiter = () => {
    setOpen(false);
    const updatedRecruiterData = {
      recruiterId,
      ...recruiterData,
    };
    updateRecruiter(updatedRecruiterData);
  };

  return (
    <StyledCard>
      <StyledCardMedia
        component="img"
        image={imageSrc}
        alt={`${firstName} ${lastName}`}
      />
      <StyledCardContent>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ color: COLORS.signIn, fontWeight: "bold" }}
        >
          {`${firstName} ${lastName}`}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          <span style={{ color: COLORS.grey, fontWeight: "bold" }}>
            Email:{" "}
          </span>
          {email}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          <span style={{ color: COLORS.grey, fontWeight: "bold" }}>
            Company:{" "}
          </span>
          {company}
        </Typography>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <StyledButton
            onClick={() => onSelectRecruiter(recruiterId)}
            variant="contained"
            sx={{
              color: COLORS.signIn,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.white,
              ":hover": {
                bgcolor: COLORS.signIn,
                borderColor: COLORS.signIn,
                color: COLORS.white,
              },
            }}
          >
            More details
          </StyledButton>
          <Stack direction="row" spacing={2} alignItems="center">
            <IconButton
              onClick={() => setOpen(true)}
              size="small"
              sx={{ color: "#9f9f9f" }}
            >
              <CreateOutlined />
            </IconButton>
            <IconButton
              onClick={() => setOpenDelete(true)}
              size="small"
              sx={{ color: "#9f9f9f" }}
            >
              <DeleteOutline />
            </IconButton>
          </Stack>
        </Stack>
      </StyledCardContent>
      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Typography fontSize={18}>
            Are you sure you want to delete this recruiter?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpenDelete(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onDeleteRecruiter(recruiterId);
              setOpenDelete(false);
            }}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Update Dialog */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Stack spacing={2.5} margin={1}>
            <Typography
              fontSize={25}
              fontFamily={"sans-serif"}
              color={COLORS.pulse_color}
            >
              Update Recruiter
            </Typography>
            <TextField
              variant="outlined"
              label="First Name"
              name="firstName"
              value={recruiterData.firstName}
              onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              label="Last Name"
              name="lastName"
              value={recruiterData.lastName}
              onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              label="Email"
              name="email"
              value={recruiterData.email}
              onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              label="Company"
              name="company"
              value={recruiterData.company}
              onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              label="Address"
              name="address"
              value={recruiterData.address}
              onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              label="City"
              name="city"
              value={recruiterData.city}
              onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              label="Country"
              name="country"
              value={recruiterData.country}
              onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              label="Postal Code"
              name="postal_code" // Changed from postalCode to postal_code
              value={recruiterData.postal_code} // Changed from postalCode to postal_code
              onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              label="Company Industry"
              name="comp_industry" // Changed from compIndustry to comp_industry
              value={recruiterData.comp_industry} // Changed from compIndustry to comp_industry
              onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              label="Company Description"
              name="comp_description" // Changed from compDescription to comp_description
              value={recruiterData.comp_description} // Changed from compDescription to comp_description
              onChange={handleInputChange}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            sx={{
              color: COLORS.signIn,
              bgcolor: COLORS.white,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                color: COLORS.signIn,
              },
            }}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateRecruiter}
            sx={{
              color: COLORS.white,
              bgcolor: COLORS.signIn,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                color: COLORS.signIn,
              },
            }}
          >
            UPDATE
          </Button>
        </DialogActions>
      </Dialog>
    </StyledCard>
  );
}

RecruiterCard.propTypes = {
  recruiterId: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  address: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  postal_code: PropTypes.string, // Changed from postalCode to postal_code
  comp_industry: PropTypes.string, // Changed from compIndustry to comp_industry
  comp_description: PropTypes.string,
  onDeleteRecruiter: PropTypes.func.isRequired,
  updateRecruiter: PropTypes.func.isRequired,
  onSelectRecruiter: PropTypes.func.isRequired,
};

export default RecruiterCard;
