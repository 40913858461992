import React from "react";
import Chat from "../Chat/Chat";
import CreateRecruiter from "../../views/admin/createRecruiter";
import ListRecruiter from "../../views/admin/ListRecruiter";
const adminRoutes = [
  {
    path: "chat",
    name: "Chat",
    icon: "fas fa-comment",
    component: <Chat />,
  },
  {
    path: "/ListRecruiter",
    name: "Our Recruiters",
    component: <ListRecruiter />,
  },
];

export default adminRoutes;
