import React, { useState, useRef } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import SvgCreatRecruiter from "./SvgCreatRecruiter.jsx";

import {
  TextField,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Container,
  Typography,
  Dialog,
  DialogContent,
  Box,
  IconButton,
  Snackbar,
  Grid,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { LinearProgress } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
const generateRandomPassword = () => {
  const length = 12;
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
  let password = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    password += charset.charAt(Math.floor(Math.random() * n));
  }
  return password;
};

function getUserIdFromAccessToken() {
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    console.error("No access token found in local storage");
    return null;
  }

  try {
    const decodedToken = parseJwt(accessToken);
    console.log("Access token : ", decodedToken);
    const senderId = decodedToken.user_id;
    return senderId;
  } catch (error) {
    console.error("Failed to decode access token", error);
    return null;
  }
}

function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  console.log("payload : ", jsonPayload);

  return JSON.parse(jsonPayload);
}

const cities = [
  "Casablanca",
  "Rabat",
  "Marrakesh",
  "Fes",
  "Tangier",
  "Agadir",
  "Meknes",
  "Oujda",
  "Kenitra",
  "Tetouan",
  "Safi",
  "El Jadida",
  "Nador",
  "Khouribga",
  "Beni Mellal",
  "Mohammedia",
  "Laayoune",
  "Khemisset",
  "Settat",
  "Sidi Kacem",
  "Taza",
  "Larache",
  "Berkane",
  "Errachidia",
  "Taroudant",
  "Guelmim",
  "Ouarzazate",
  "Essaouira",
  "Dakhla",
  "Azrou",
  "Oued Zem",
  "Fqih Ben Salah",
];
const apiUrl = process.env.REACT_APP_API_URL;
const apiUrlsoket = process.env.REACT_APP_API_URL_SOKET;
const CreateRecruiter = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    //  password: "",
    gender: "",
    company: "",
    address: "",
    city: "",
    country: "",
    postal_code: "",
    comp_industry: "",
    comp_description: "",
    image: null,
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const toast = useRef(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        image: file,
      }));
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleRemoveImage = () => {
    setFormData((prevState) => ({
      ...prevState,
      image: null,
    }));
    setPreviewImage(null);
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const generatedPassword = generateRandomPassword();
    const adminId = getUserIdFromAccessToken();


    try {
      const response = await fetch(
        `${apiUrl}recruiters/create_recruiter_admin/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            password: generatedPassword,
            adminId: adminId,
          }),
        }
      );

      if (response.ok) {
        // Show success dialog immediately
        setOpenDialog(true);

        // Reset form
        setFormData({
          username: "",
          first_name: "",
          last_name: "",
          email: "",
          gender: "",
          company: "",
          address: "",
          city: "",
          country: "",
          postal_code: "",
          comp_industry: "",
          comp_description: "",
          image: null,
        });
        setPreviewImage(null);

        // Close the dialog after 3 seconds
        setTimeout(() => {
          setOpenDialog(false);
        }, 3000);
      } else {
        const errorData = await response.json();
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorData.error || `Server error: ${response.status}`,
          life: 3000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message || "Failed to create recruiter",
        life: 3000,
      });
      console.error("Error creating recruiter:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Toast ref={toast} />

      <Grid container spacing={4} sx={{ minHeight: "80vh" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            padding: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {isLoading && <LinearProgress />}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  label="Username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  label="First Name"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  label="Last Name"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    label="Gender"
                  >
                    <MenuItem value="f">Female</MenuItem>
                    <MenuItem value="m">Male</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  label="Company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  label="Address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>City</InputLabel>
                  <Select
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    label="City"
                  >
                    {cities.map((city) => (
                      <MenuItem key={city} value={city}>
                        {city}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  label="Country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  label="Postal Code"
                  name="postal_code"
                  value={formData.postal_code}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  label="Industry"
                  name="comp_industry"
                  value={formData.comp_industry}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Company Description"
                  name="comp_description"
                  value={formData.comp_description}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                {previewImage && (
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      src={previewImage}
                      alt="Preview"
                      style={{ marginTop: 10, maxHeight: 100 }}
                    />
                    <IconButton
                      onClick={handleRemoveImage}
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: "red",
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              marginTop: "-15%",
            }}
          >
            <SvgCreatRecruiter style={{ width: "100%", height: "auto" }} />
          </Box>
        </Grid>
      </Grid>

      <Dialog open={openDialog}>
        <DialogContent
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CheckCircleIcon color="success" sx={{ fontSize: 100 }} />
          <Typography variant="h5" sx={{ mt: 2 }}>
            Recruiter Created Successfully!
          </Typography>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ open: false, message: "" })}
        message={snackbar.message}
      />
    </Container>
  );
};

export default CreateRecruiter;
