import React, { useEffect, useState, useRef } from "react";
import "./login.css";
import { FaGoogle, FaLinkedin } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Toast } from "primereact/toast";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { connect } from "react-redux";
import { setUserId } from "../../Redux/actions";
import { COLORS } from "../../colors/color";
import { GoogleLogin } from "react-google-login";
import styled from "styled-components";
import { Dropdown } from "primereact/dropdown";

// const StyledButton = styled(Button)({
//   borderRadius: "50px",
//   padding: "12px 24px",
//   textTransform: "none",
//   fontWeight: 600,
//   backgroundColor: COLORS.white,
//   color: COLORS.signIn,
//   border: `1px solid ${COLORS.signIn}`,
//   "&:hover": {
//     backgroundColor: COLORS.Button_OnHold,
//     color: COLORS.signIn,
//     border: `1px solid ${COLORS.signIn}`,
//   },
// });
const Login = (props) => {
  const [authMode, setAuthMode] = useState("signin");
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password2, setPassword2] = useState("");
  const [selectedGender, setSelectedGender] = useState("m");
  const gender = [{ name: "Male" }, { name: "Female" }];
  const passwordsMatch = password === password2;
  const toast = useRef(null);
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;
  const clientId =
    "1065255482933-3pgtuvdlckvr8s4uo1ecko09gm2blo9s.apps.googleusercontent.com";

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    if (window.location.href.includes("code=")) {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      fetchCallbackData(code);
    }
  }, []);

  const toggleAuthMode = () =>
    setAuthMode(authMode === "signin" ? "signup" : "signin");
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const [images, setImages] = useState([
    require("assets/img/ai7.gif"),
    require("assets/img/ai8.gif"),
    // require("assets/img/ai3.png"),
    require("assets/img/ai6.gif"),
    // Add more image paths as needed
  ]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [images.length]);
  const connexion = async () => {
    try {
      const response = await axios.post(`${apiUrl}authentication/login/`, {
        email: login,
        password: password,
      });
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);

      const userId = getUserIdFromAccessToken();
      if (userId) {
        props.setUserId(userId);
      }

      if (response.data.user.role === "r") {
        console.log("Redirecting to dashboard for recruiter");
        redirectToDashboard();
      } else if (response.data.user.role === "a") {
        console.log("Redirecting to admin for admin");
        redirectToAdminA();
      } else {
        console.log("Redirecting to home for other roles");
        redirectToHome();
      }

      setLogin("");
      setPassword("");
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Logged in",
        life: 3000,
      });
    } catch (err) {
      console.error("Login failed:", err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Login failed. Please check your credentials.",
        life: 3000,
      });
    }
  };

  const registration = async () => {
    try {
      console.log(name, email, password, genderValue);
      await axios.post(`${apiUrl}authentication/signup/`, {
        username: name,
        email: email,
        password: password,
        gender: 'm',
      });

      setName("");
      setPassword2("");
      setEmail("");
      setSelectedGender("m");
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "User created",
        life: 3000,
      });
      toggleAuthMode(); // Switch to signin mode after successful registration
    } catch (err) {
      console.error("Registration failed:", err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Registration failed. Please try again.",
        life: 3000,
      });
    }
  };

  const onGoogleSuccess = (res) => {
    console.log("Login Success: currentUser:", res.profileObj);
    fetchGoogleData(res);
  };

  const onGoogleFailure = (res) => {
    console.error("Login failed: res:", res);
  };

  const linkedinAuth = () => {
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86o1ech9y4in4m&redirect_uri=${window.location.origin}/login&state=1234&scope=openid%20email%20profile`;
  };

  const fetchGoogleData = async (res) => {
    try {
      const response = await axios.get(
        `${apiUrl}authentication/google/userinfo/`,
        { withCredentials: true, params: res.profileObj }
      );
      console.log("this is the res data  : ", response.data);
      console.log("userRole : ", response.data.user.role);
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
      const userId = getUserIdFromAccessToken();
      if (userId) {
        console.log("User ID:", userId);
        props.setUserId(userId); // Store userId in Redux
      } else {
        console.log("User ID not found or failed to decode token");
      }

      if (response.data.user.role === "r") {
        console.log("Redirecting to dashboard for recruiter");
        redirectToDashboard();
      } else if (response.data.user.role === "a") {
        console.log("Redirecting to admin for admin");
        redirectToAdminA();
      } else {
        console.log("Redirecting to home for other roles");
        redirectToHome();
      }
    } catch (error) {
      console.error("Error fetching data from callback:", error);
    }
  };

  const fetchCallbackData = async (code) => {
    console.log("Code:", code);
    try {
      const params = {
        grant_type: "authorization_code",
        code: code,
        redirect_uri: `${apiUrl}login`,
        client_id: "86o1ech9y4in4m",
        client_secret: "WPL_AP0.jW3gypWTWCnun6rz.MTIxMTM2NzA2NA==",
      };
      const response = await axios.get(
        `${apiUrl}authentication/linkedin-callback/`,
        { params: params }
      );
      console.log("params : ", params);
      const result = response.data;
      console.log(result);
      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
      const userId = getUserIdFromAccessToken();
      if (userId) {
        console.log("User ID:", userId);
        props.setUserId(userId); // Store userId in Redux
      } else {
        console.log("User ID not found or failed to decode token");
      }
      // redirectToDashboard();
      if (response.data.user.role === "r") {
        console.log("Redirecting to dashboard for recruiter");
        redirectToDashboard();
      } else if (response.data.user.role === "a") {
        console.log("Redirecting to admin for admin");
        redirectToAdminA();
      } else {
        console.log("Redirecting to home for other roles");
        redirectToHome();
      }
    } catch (error) {
      console.error("Error fetching data from callback:", error);
    }
  };

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  const redirectToAdminA = () => navigate("/AdminA/createRecruiter");
  const redirectToDashboard = () => navigate("/admin/dashboard");
  const redirectToHome = () => navigate("/home");
  const mapGenderToValue = (selectedOption) => {
    console.log("option : ", selectedOption);
    switch (selectedOption.name) {
      case "Male":
        return "m";
      case "Female":
        return "f";
      default:
        return null;
    }
  };
  const genderValue = mapGenderToValue(selectedGender);
  return (
    <div className="login-container">
      
      <Toast ref={toast} />
      <div className="login-card">
        <div className="login-form">
          <h2>{authMode === "signin" ? "Login" : "Sign Up"}</h2>
          {authMode === "signin" ? (
            <>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                fullWidth
                sx={{
                  // height: "100%",
                  marginTop: "3%",
                  color: COLORS.white,
                  border: "1px solid",
                  borderColor: COLORS.grey,
                  bgcolor: COLORS.signIn,
                  ":hover": {
                    bgcolor: COLORS.Button_OnHold,
                    borderColor: COLORS.signIn,
                    color: COLORS.signIn,
                  },
                }}
                onClick={connexion}
              >
                Login
              </Button>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                  marginBottom: "2%",
                }}
              >
                <GoogleLogin
                  clientId={clientId}
                  onSuccess={onGoogleSuccess}
                  onFailure={onGoogleFailure}
                  cookiePolicy={"single_host_origin"}
                  isSignedIn={false}
                  render={({ onClick }) => (
                    <Button
                      variant="outlined"
                      onClick={onClick}
                      startIcon={<FaGoogle />}
                      sx={{
                        borderRadius: "50px",
                        padding: "12px 24px",
                        textTransform: "none",
                        fontWeight: 600,
                        backgroundColor: COLORS.white,
                        color: COLORS.signIn,
                        border: `1px solid ${COLORS.signIn}`,
                        width: "48%",
                        "&:hover": {
                          backgroundColor: COLORS.Button_OnHold,
                          color: COLORS.signIn,
                          border: `1px solid ${COLORS.signIn}`,
                        },
                      }}
                    >
                      Google
                    </Button>
                  )}
                />
                <Button
                  variant="outlined"
                  onClick={linkedinAuth}
                  startIcon={<FaLinkedin />}
                  sx={{
                    borderRadius: "50px",
                    padding: "12px 24px",
                    textTransform: "none",
                    fontWeight: 600,
                    backgroundColor: COLORS.white,
                    color: COLORS.signIn,
                    border: `1px solid ${COLORS.signIn}`,
                    width: "48%",
                    "&:hover": {
                      backgroundColor: COLORS.Button_OnHold,
                      color: COLORS.signIn,
                      border: `1px solid ${COLORS.signIn}`,
                    },
                  }}
                >
                  LinkedIn
                </Button>
              </Box>
            </>
          ) : (
            <>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                label="Repeat Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                margin="normal"
                error={password2 !== '' && !passwordsMatch}
                helperText={password2 !== '' && !passwordsMatch ? "Passwords do not match" : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <Box sx={{marginTop:'3%'}}></Box> */}
              {/* <Dropdown
              
                value={selectedGender}
                onChange={(e) => setSelectedGender(e.value)}
                options={gender}
                optionLabel="name"
                placeholder="Select your Gender"
                checkmark={true}
                highlightOnSelect={false}
                className="full-width-dropdown"
              /> */}
              <Box sx={{marginTop:'5%'}}></Box>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  // height: "100%",
                  marginTop: "3%",
                  color: COLORS.white,
                  border: "1px solid",
                  borderColor: COLORS.grey,
                  bgcolor: COLORS.signIn,
                  ":hover": {
                    bgcolor: COLORS.Button_OnHold,
                    borderColor: COLORS.signIn,
                    color: COLORS.signIn,
                  },
                }}
                onClick={registration}
                disabled={!passwordsMatch}
              >
                Sign Up
              </Button>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                  marginBottom: "2%",
                }}
              >
                <GoogleLogin
                  clientId={clientId}
                  onSuccess={onGoogleSuccess}
                  onFailure={onGoogleFailure}
                  cookiePolicy={"single_host_origin"}
                  isSignedIn={false}
                  render={({ onClick }) => (
                    <Button
                      variant="outlined"
                      onClick={onClick}
                      startIcon={<FaGoogle />}
                      sx={{
                        borderRadius: "50px",
                        padding: "12px 24px",
                        textTransform: "none",
                        fontWeight: 600,
                        backgroundColor: COLORS.white,
                        color: COLORS.signIn,
                        border: `1px solid ${COLORS.signIn}`,
                        width: "48%",
                        "&:hover": {
                          backgroundColor: COLORS.Button_OnHold,
                          color: COLORS.signIn,
                          border: `1px solid ${COLORS.signIn}`,
                        },
                      }}
                    >
                      Google
                    </Button>
                  )}
                />
                <Button
                  variant="outlined"
                  onClick={linkedinAuth}
                  startIcon={<FaLinkedin />}
                  sx={{
                    borderRadius: "50px",
                    padding: "12px 24px",
                    textTransform: "none",
                    fontWeight: 600,
                    backgroundColor: COLORS.white,
                    color: COLORS.signIn,
                    border: `1px solid ${COLORS.signIn}`,
                    width: "48%",
                    "&:hover": {
                      backgroundColor: COLORS.Button_OnHold,
                      color: COLORS.signIn,
                      border: `1px solid ${COLORS.signIn}`,
                    },
                  }}
                >
                  LinkedIn
                </Button>
              </Box>
            </>
          )}
          <p>
            {authMode === "signin"
              ? "Don't have an account? "
              : "Already have an account? "}
            <span onClick={toggleAuthMode} className="auth-toggle">
              {authMode === "signin" ? "Sign Up" : "Sign In"}
            </span>
          </p>
        </div>
        <div className="login-info">
          <img
            src={images[currentImageIndex]}
            alt={`AI illustration ${currentImageIndex + 1}`}
            className="info-illustration"
          />
          <div className="image-dots">
            {images.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentImageIndex ? "active" : ""}`}
                onClick={() => setCurrentImageIndex(index)}
              ></span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
