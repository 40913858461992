import React, { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "perfect-scrollbar";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ButtonAppBar from "components/Navbars/navBar";
import SidebarAdmin from "../../components/Sidebar/Sidebaradmin";
import adminRoutes from "../../views/admin/adminRoutes";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footer/Footer.js";
import CreateRecruiter from "../../views/admin/createRecruiter";
import styled from "styled-components";

const MainContent = styled.div`
  // padding: 20px;
  overflow-y: auto;
  height: calc(100vh - 60px); // Adjust 60px to match your navbar height
  padding-top: 60px;
`;

const NonTransparentNavWrapper = styled.div`
  .navbar {
    background-color: #ffffff !important;
    backdrop-filter: none !important;
    opacity: 1 !important;
  }
`;

function AdminA(props) {
  const [backgroundColor, setBackgroundColor] = useState("black");
  const [activeColor, setActiveColor] = useState("info");
  const mainPanel = useRef();

  const navigate = useNavigate();

  const location = useLocation();
  let ps;
  useEffect(() => {
    checkUserRole();
  }, []);

  const checkUserRole = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      // No access token found, redirect to login
      navigate("/login");
      return;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      if (decodedToken.role !== "a") {
        // User is not an AdminA, redirect to login
        navigate("/login");
      }
    } catch (error) {
      console.error("Error decoding token:", error);
      navigate("/login");
    }
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  }, []);

  useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);

  const handleActiveClick = (color) => {
    setActiveColor(color);
  };

  const handleBgClick = (color) => {
    setBackgroundColor(color);
  };

  return (
    <div className="wrapper">
      <SidebarAdmin
        {...props}
        routes={adminRoutes}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel" ref={mainPanel}>
        <NonTransparentNavWrapper>
          <DemoNavbar {...props} />
        </NonTransparentNavWrapper>
        <MainContent>
          <Routes>
            {adminRoutes.map((prop, key) => (
              <Route
                path={prop.path}
                element={prop.component}
                key={key}
                exact
              />
            ))}
            <Route path="/createRecruiter" element={<CreateRecruiter />} />
          </Routes>
        </MainContent>
        <Footer fluid />
      </div>
    </div>
  );
}

export default AdminA;
