import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
} from "reactstrap";

import routes from "routes.jsx";
import { COLORS } from "colors/color";
import { Button } from "@mui/material";

function Header(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [color, setColor] = React.useState("transparent");
  const [recruiter, setRecruiter] = React.useState(true);
  const sidebarToggle = React.useRef();
  const location = useLocation();
  const navigate = useNavigate();
    const [userRole, setUserRole] = React.useState(null);

    const userid = getUserInfoFromAccessToken();
    function getUserInfoFromAccessToken() {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        console.error("No access token found in local storage");
        return { userId: null, role: null };
      }

      try {
        const decodedToken = parseJwt(accessToken);
        console.log("Access token : ", decodedToken);
        const userId = decodedToken.user_id;
        const role = decodedToken.role; // Assuming the role is stored in the token
        return { userId, role };
      } catch (error) {
        console.error("Failed to decode access token", error);
        return { userId: null, role: null };
      }
    }

    function parseJwt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      console.log("payload : ", jsonPayload);

      return JSON.parse(jsonPayload);
    }
    const toggle = () => {
      if (isOpen) {
        setColor("transparent");
      } else {
        setColor("dark");
      }
      setIsOpen(!isOpen);
    };
    const dropdownToggle = (e) => {
      setDropdownOpen(!dropdownOpen);
    };
    const getBrand = () => {
      let brandName = "Job Apply";
      routes.map((prop, key) => {
        if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
          brandName = prop.name;
        }
        return null;
      });
      return brandName;
    };
    const openSidebar = () => {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    };
    const updateColor = () => {
      if (window.innerWidth < 993 && isOpen) {
        setColor("dark");
      } else {
        setColor("transparent");
      }
    };
    React.useEffect(() => {
      const { userId, role } = getUserInfoFromAccessToken();
      setUserRole(role);
    }, []);
    React.useEffect(() => {
      window.addEventListener("resize", updateColor.bind(this));
    });
    React.useEffect(() => {
      if (
        window.innerWidth < 993 &&
        document.documentElement.className.indexOf("nav-open") !== -1
      ) {
        document.documentElement.classList.toggle("nav-open");
        sidebarToggle.current.classList.toggle("toggled");
      }
    }, [location]);

    return (
      <Navbar
        color="light"
        expand="lg"
        className={
          "navbar-absolute fixed-top " +
          (color === "transparent" ? "navbar-transparent " : "")
        }
        style={{ backgroundColor: "#f4f2ee" }}
      >
        <Container
          fluid
          className="d-flex justify-content-between align-items-center"
        >
          <div className="navbar-wrapper d-flex align-items-center">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={sidebarToggle}
                className="navbar-toggler"
                onClick={() => openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand>{getBrand()}</NavbarBrand>
          </div>
          <NavbarToggler onClick={toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar className="justify-content-end">
            <form className="d-flex align-items-center">
              <InputGroup className="no-border">
                <Input placeholder="Search..." />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i className="nc-icon nc-zoom-split" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </form>
            <Nav navbar className="d-flex align-items-center">
              <Dropdown
                nav
                isOpen={dropdownOpen}
                toggle={(e) => dropdownToggle(e)}
              >
                <DropdownToggle caret nav>
                  <i className="nc-icon nc-bell-55" />
                  <p>
                    <span className="d-lg-none d-md-block">Some Actions</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag="a">Action</DropdownItem>
                  <DropdownItem tag="a">Another Action</DropdownItem>
                  <DropdownItem tag="a">Something else here</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <NavItem>
                {/* <Link to="#" className="nav-link btn-rotate">
                <i className="nc-icon nc-settings-gear-65" />
                <p>
                  <span className="d-lg-none d-md-block">Account</span>
                </p>
              </Link> */}
                <Link to="/login" className="nav-link btn-rotate">
                  <i className="nc-icon nc-button-power" />
                  <p>
                    <span className="d-lg-none d-md-block">logOut</span>
                  </p>
                </Link>
              </NavItem>
            </Nav>
            {userRole !== "a" && (
              <Button
                variant="contained"
                type="submit"
                sx={{
                  height: "36px",
                  color: COLORS.white,
                  backgroundColor: "#333333",
                  border: "1px solid #333333",
                  borderRadius: "4px",
                  fontSize: "14px",
                  fontWeight: 500,
                  textTransform: "none",
                  padding: "0 16px",
                  "&:hover": {
                    backgroundColor: COLORS.Button_OnHold,
                    borderColor: "#555555",
                    color: COLORS.signIn,
                  },

                  transition: "all 0.3s ease",
                }}
                onClick={() => {
                  navigate("/home", { state: { recruiter } });
                }}
              >
                Navigate to Home
              </Button>
            )}
          </Collapse>
        </Container>
      </Navbar>
    );
}

export default Header;
