// import React, { useEffect, useState } from "react";
// import "./searchBar.css";
// import Home from "./Home";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


// const SearchBar = ({setSortedPosts }) => {
//   const [searchTerm, setSearchTerm] = useState("");
//   const [location, setLocation] = useState("");
//   // const [sortedposts, setSortedposts] = useState([]);

//   const handleSearch = (event) => {
//     event.preventDefault();
//     // console.log(`Searching for ${searchTerm}:`);
//     if (searchTerm.trim() === "") {
//       toast.error("Please enter a job title.");
//       return;
//     }
   
//     search();
   

//   };
//   const search = async () => {
//     const response = await fetch(`http://127.0.0.1:8000/posts/search_post_by_title/${searchTerm}/`);
//     const json = await response.json();
//     // console.log(json);
//     setSortedPosts(json)
//     return json;
//   };
  
//   // useEffect(() => {
//   //   console.log(sortedposts);
//   // }, [sortedposts]);



//   return (
//     <div>
//       <form
//         className="myForm"
//         onSubmit={handleSearch}
//         style={{
//           height: "60px",
//           // scrollPaddingTop: '0px'
//         }}
//       >
//         <div className="bar">
//           <div className="input-container">
//             <i className="fas fa-briefcase icon-job"></i>
//             <input
//               className="input1"
//               type="text"
//               placeholder="Job title, keywords, or company"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             />
//           </div>

//           {/* <span>|</span> */}
//           <div className="input-container">
//             <i className="fas fa-map-marker-alt icon"></i>
//             <input
//               className="input2"
//               type="text"
//               placeholder="City, state, zip code, or 'remote'"
//               value={location}
//               onChange={(e) => setLocation(e.target.value)}
//             />
//           </div>

//           <button  type="submit" className="findButton" >
//             Find Jobs
//           </button>
//         </div>
//       </form>
//       <ToastContainer />
//     </div>
//   );
// };

// export default SearchBar;
import React, { useState } from "react";
import "./searchBar.css";
import { COLORS } from "colors/color";
import { Button } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// =======
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// >>>>>>> d38f552fb8c65c9629d1ed5d94496992a304116b

const SearchBar = ({ props }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [location, setLocation] = useState("");
  
  const handleSearch = async (event) => {
    event.preventDefault();
    if (searchTerm.trim() === "" && location.trim() === "") {
      toast.error("Please enter either a job title or a city name.");
      return;
    }

    const apiUrl = process.env.REACT_APP_API_URL;

    try {
      let url = `${apiUrl}posts/search_recommendation/?`;
      if (searchTerm.trim()) {
        url += `title=${encodeURIComponent(searchTerm.trim())}`;
      }
      if (location.trim()) {
        url += `${url.includes('?') ? '&' : '?'}localisation=${encodeURIComponent(location.trim())}`;
      }
  
      console.log(url);

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data); // Check the response data in the console
  
      if (data.posts && data.posts.length === 0) {
        props([], data.message);
      } else {
        props(data.posts, '');
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  return (
    <div className="search-bar-container">
      <form className="myForm" onSubmit={handleSearch}>
        <div className="bar">
          <div className="input-container">
            <i className="fas fa-briefcase icon-job"></i>
            <input
              className="input1"
              type="text"
              placeholder="Job title, keywords, or company"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="input-container">
            <i className="fas fa-map-marker-alt icon"></i>
            <input
              className="input2"
              type="text"
              placeholder="City, state, zip code, or 'remote'"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
          <div className="button-container">
            <Button
              variant="contained"
              type="submit"
              sx={{
                height: "100%",
                color: COLORS.white,
                border: "1px solid",
                borderColor: COLORS.grey,
                bgcolor: COLORS.signIn,
                ":hover": {
                  bgcolor: COLORS.pulse_color,
                  borderColor: COLORS.signIn,
                  color: COLORS.white,
                },
              }}
            >
              Find Jobs
            </Button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default SearchBar;
