import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
  CardMedia,
  Card,
  CardContent,
} from "@mui/material";
import { Archive, Unarchive } from "@mui/icons-material";
import { motion } from "framer-motion";
import { CreateOutlined, DeleteOutline } from "@mui/icons-material";
import { connect } from "react-redux";
import { setUserId } from "../../Redux/actions";
import "./post.css";
import { COLORS } from "colors/color";
import { styled } from "@mui/system";
import MyArrayInput from "../MyArrayInput";
import { Checkbox, FormControlLabel } from "@mui/material";
const ArchiveToggle = styled(motion.div)({
  position: "absolute",
  top: 5,
  right: 0,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  borderRadius: "20px",
  transition: "background-color 0.3s",
});

const ArchiveIcon = styled(motion.div)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 50, // Increased width
  height: 50, // Increased height
  borderRadius: "50%",

  border: "1px solid #0001", // Thinner border
  color: COLORS.Button_OnHold,
  marginRight: 12,
  "& .MuiSvgIcon-root": {
    fontSize: 28, // Increased font size of the icon
  },
});


// const ArchiveLabel = styled(Typography)({
//   userSelect: "none",
//   fontWeight: 500,
// });
const StyledCard = styled(Card)(({ theme }) => ({
  position: "relative", // Add this line
  display: "flex",
  flexDirection: "column",
  height: "100%",
  borderRadius: "8px",
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const StyledCardMedia = styled(CardMedia)({
  height: 200,
  objectFit: "cover",
});

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: "10px",
  borderRadius: "8px",
  textTransform: "none",
  boxShadow: "none",
  "&:hover": {
    boxShadow: "0 4px 10px rgba(0,0,0,0.6)",
  },
}));

function CardPost({
  postid,
  imageSrc,
  title,
  description,
  requirement,
  deadline,
  company,
  onDeletePost,
  updatePost,
  onSelectPost,
  archived,
  onArchivePost,
}) {
  const [postTitle, setPostTitle] = useState(title);
  const [postDescription, setPostDescription] = useState(description);
  const [postRequirement, setPostRequirement] = useState(requirement);
  const [postDeadline, setPostDeadline] = useState(deadline);
  const [postToDeleteId, setPostToDeleteId] = useState(null);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);

  const theme = useTheme();
  const userId = getUserIdFromAccessToken();
  const [isArchived, setIsArchived] = useState(archived);
  const handleArchiveChange = async () => {
    const newArchivedStatus = !isArchived;
    setIsArchived(newArchivedStatus);
    const animationDuration = 500; // 500ms, matching the rotation duration

    await new Promise((resolve) => setTimeout(resolve, animationDuration));

    await onArchivePost(postid, newArchivedStatus);
  };

  // useEffect(() => {
  //   console.log("this is the req :: ", imageSrc);
  // }, []);

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      return decodedToken.user_id;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  async function handleUpdatePost() {
    setOpen4(false);
    const updatedPostData = {
      postid: postid,
      recruiter: userId,
      image: imageSrc,
      title: postTitle,
      description: postDescription,
      requirements: postRequirement,
      deadline: postDeadline,
      company: "pulse",
    };
    updatePost(updatedPostData);
  }

  return (
    <StyledCard>
      <StyledCardMedia component="img" image={imageSrc} alt={title} />
      <StyledCardContent>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{ color: COLORS.signIn, fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          <span style={{ color: COLORS.grey, fontWeight: "bold" }}>
            Deadline:{" "}
          </span>
          {deadline}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <StyledButton
            onClick={onSelectPost}
            variant="contained"
            type="submit"
            sx={{
              height: "100%",
              color: COLORS.signIn,
              border: "1px solid",
              borderColor: COLORS.grey,
              bgcolor: COLORS.white,
              ":hover": {
                bgcolor: COLORS.signIn,
                borderColor: COLORS.signIn,
                color: COLORS.white,
              },
            }}
          >
            More details
          </StyledButton>

          <Stack direction="row" spacing={2} alignItems="center">
            <ArchiveToggle onClick={handleArchiveChange}>
              <ArchiveIcon
                animate={{ rotate: isArchived ? 360 : 0 }}
                transition={{ duration: 0.5 }}
              >
                {isArchived ? (
                  <Unarchive fontSize="small" />
                ) : (
                  <Archive fontSize="small" />
                )}
              </ArchiveIcon>
            </ArchiveToggle>
            <IconButton
              onClick={() => setOpen4(true)}
              size="small"
              sx={{ color: "#9f9f9f" }}
            >
              <CreateOutlined />
            </IconButton>
            <IconButton
              onClick={() => {
                setPostToDeleteId(postid);
                setOpen5(true);
              }}
              size="small"
              sx={{ color: "#9f9f9f" }}
            >
              <DeleteOutline />
            </IconButton>
          </Stack>
        </Stack>
      </StyledCardContent>

      <Dialog
        open={open4}
        onClose={() => setOpen4(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Stack spacing={2.5} margin={1}>
            <Typography
              fontSize={25}
              fontFamily={"sans-serif"}
              color={COLORS.pulse_color}
            >
              Update Post
            </Typography>
            <TextField
              variant="outlined"
              label="Title"
              value={postTitle}
              onChange={(event) => setPostTitle(event.target.value)}
            />
            <TextField
              variant="outlined"
              label="Deadline"
              value={postDeadline}
              onChange={(event) => setPostDeadline(event.target.value)}
            />
            <TextField
              variant="outlined"
              label="Description"
              value={postDescription}
              onChange={(event) => setPostDescription(event.target.value)}
              multiline
              rows={6}
              fullWidth
            />
            <MyArrayInput
              value={postRequirement}
              onChange={setPostRequirement}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpen4(false)}
            sx={{
              color: COLORS.signIn,
              bgcolor: COLORS.white,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                color: COLORS.signIn,
              },
            }}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdatePost}
            sx={{
              color: COLORS.white,
              bgcolor: COLORS.signIn,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                color: COLORS.signIn,
              },
            }}
          >
            UPDATE
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={open5}
        onClose={() => setOpen5(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Typography
            fontSize={25}
            fontFamily={"sans-serif"}
            color={COLORS.pulse_color}
          >
            Are you sure you want to delete this Post?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpen5(false)}
            sx={{
              color: COLORS.signIn,
              bgcolor: COLORS.white,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                color: COLORS.signIn,
              },
            }}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onDeletePost(postToDeleteId);
              setOpen5(false);
            }}
            sx={{
              color: COLORS.white,
              bgcolor: COLORS.signIn,
              ":hover": {
                bgcolor: COLORS.Button_OnHold,
                color: COLORS.signIn,
              },
            }}
          >
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
    </StyledCard>
  );
}

CardPost.propTypes = {
  postid: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  requirement: PropTypes.arrayOf(PropTypes.string).isRequired,
  deadline: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  onDeletePost: PropTypes.func.isRequired,
  updatePost: PropTypes.func.isRequired,
  onSelectPost: PropTypes.func.isRequired,
  archived: PropTypes.bool.isRequired,
  onArchivePost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPost);
