import React, { useEffect, useState } from "react";
import SearchBar from "../client/SearchBar";
import MyCard from "../client/Card";
import "../client/home.css";
import MyCardDetails from "../client/CardDetails";
import DevOpsLoop from 'assets/img/DevOpsLoop.png'; // Adjust the path according to your project structure
import ButtonAppBar from "../../components/Navbars/navBar";
import LoginForDialog from "views/Auth/LoginForDialog";
import SignInForDialog from "views/Auth/SignInForDialog"; // Make sure the path is correct
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { connect } from "react-redux"; // Import connect from react-redux
import { setUserId } from "../../Redux/actions"; // Import your action
import Footer from "../client/Footer";
import App from "views/Chat/Chat";


function SavedPosts() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [allJobs, setAllJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isAuth, setIsAuth] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);
  const [saved, setSaved] = useState(true);
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [ChatComponent, setChatComponent] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);






  useEffect(() => {
    if (allJobs.length > 0 && !selectedJob) {
      setSelectedJob(allJobs[0]);
    }
  }, [allJobs, selectedJob]);



  const closeLoginPopUp = () => {
    setOpenLoginDialog(false);
  };

  const closeSignInPopUp = () => {
    setOpenSignInDialog(false);
  };
  function truncateDescription(description, maxLength) {
    const words = description.split(' ');
    if (words.length > maxLength) {
      return words.slice(0, maxLength).join(' ') + '...';
    } else {
      return description;
    }
  }

  const Load = async () => {
    const userId = getUserIdFromAccessToken();
    const response = await fetch(`${apiUrl}candidates/list_saved_posts/${userId}/`);

    if (!response.ok) {
      if (response.status === 404) {
        console.error("post_saved not found");
        // Handle the 404 case
        setAllJobs([]);

        return { error: "post_saved not found" };
      } else {
        throw new Error(`An error occurred: ${response.statusText}`);
      }
    }

    const json = await response.json();
    console.log(json);
    setAllJobs(json);
    setSaved(true) // for making the saved icon black
    setSelectedJob(allJobs[0])
    return json;
  };

  useEffect(() => {
    Load();
    console.log(allJobs);
  }, []);


  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  useEffect(() => {
    console.log('is Auth ?: ', isAuth);
  }, [isAuth]);

  const handleCardClick = (job) => {
    setSelectedJob(job);
  };

  const handleLoginClick = () => {
    setOpenLoginDialog(true);
  };

  const handleSignInClick = () => {
    setOpenSignInDialog(true);
  };

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  const senderId = getUserIdFromAccessToken();


  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }
  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector('footer');
      const rect = footer.getBoundingClientRect();
      const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
      setIsFooterVisible(isVisible);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (ChatComponent === false) {
      //console.log("ChatComponent", ChatComponent);
      const handleScroll = () => {
        if (
          window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 2 &&
          hasMore &&
          !isLoading
        ) {
          setPage((prevPage) => prevPage + 1);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [hasMore, isLoading]);
  useEffect(() => {
    if (ChatComponent == true) {
      const handleScroll = () => {
        //console.log("scrolling");
        const footer = document.querySelector("footer");
        const rect = footer.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        setIsFooterVisible(isVisible);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);




  return (
    <>
      {ChatComponent ? (
        <>
          <ButtonAppBar
            isAuth={isAuth}
            onLoginClick={handleLoginClick}
            onSignInClick={handleSignInClick}
            setChatComponent={setChatComponent}
          />
          <App candidate_id={senderId} />
        </>
      ) : (
        <>
      <ButtonAppBar 
        isAuth={isAuth} 
        onLoginClick={handleLoginClick} 
        onSignInClick={handleSignInClick} 
        setChatComponent={setChatComponent} />

      <SearchBar />

      <div className="container">
        <div className="row justify-content-center home">
          <div className="col-lg-5 col-md-6 col-12 myCard">
            <div className="mycard-scroll">
              {allJobs.map((job, index) => (
                <div key={index} onClick={() => handleCardClick(job)}>
                  <MyCard
                    title={job.post.title}
                    deadline={job.post.deadline}
                    description={job.post.description}
                    image={job.post.image}
                    post_id={job.post.id}
                    savedBlack={saved}
                    refresh={Load}
                    recruiter={job.post.recruiter}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-12 myCard card-gap">
            <div className="mycard-scroll fixed-component">
              {selectedJob ? (
                <MyCardDetails
                  title={selectedJob.post.title}
                  description={selectedJob.post.description}
                  requirements={<span style={{ color: 'black' }}>{selectedJob.post.requirements.join(', ')}</span>} // Apply red color to deadline
                  localisation={selectedJob.post.localisation}
                  mode={selectedJob.post.mode}
                  deadline={selectedJob.post.deadline}
                  image={selectedJob.post.image}
                  id={selectedJob.post.id}
                  isFooterVisible={isFooterVisible}

                />
              ) : (

                <h2 >No Saved Post !!!</h2>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Dialog
        open={openLoginDialog}
        onClose={closeLoginPopUp}
        // fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <LoginForDialog />
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSignInDialog}
        onClose={closeSignInPopUp}
        // fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <SignInForDialog />
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
      </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};
export default connect(mapStateToProps, mapDispatchToProps)(SavedPosts);


