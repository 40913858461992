import * as React from "react";
const SvgCreatRecruiter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="animated"
    viewBox="0 0 500 500"
    {...props}
  >
    <style>
      {`
          .animable { opacity: 0; animation: fadeIn 3s forwards; }
          .animated #freepik--Plant--inject-2 { animation: zoomOut 3s 0.3s forwards cubic-bezier(.36,-0.01,.5,1.38); }
          .animated #freepik--Notebook--inject-2 { animation: lightSpeedLeft 3s 0.6s forwards cubic-bezier(.36,-0.01,.5,1.38); }
          .animated #freepik--Signature--inject-2 { animation: slideDown 3s 0.9s forwards cubic-bezier(.36,-0.01,.5,1.38); }
          .animated #freepik--Texts--inject-2 { animation: lightSpeedRight 3s 1.2s forwards cubic-bezier(.36,-0.01,.5,1.38); }
          .animated #freepik--Photo--inject-2 { animation: fadeIn 3s 1.5s forwards cubic-bezier(.36,-0.01,.5,1.38); }
          .animated #freepik--Icons--inject-2 { animation: slideRight 3s 1.8s forwards cubic-bezier(.36,-0.01,.5,1.38); }
          .animated #freepik--photo--inject-2 { animation: lightSpeedLeft 3s 2.1s forwards cubic-bezier(.36,-0.01,.5,1.38); }
          .animated #freepik--Character--inject-2 { animation: lightSpeedLeft 3s 2.4s forwards cubic-bezier(.36,-0.01,.5,1.38); }
          .animated #freepik--Floor--inject-2 { animation: fadeIn 3s 2.7s forwards cubic-bezier(.36,-0.01,.5,1.38); }

          @keyframes zoomOut {
            0% { opacity: 0; transform: scale(1.5); }
            100% { opacity: 1; transform: scale(1); }
          }

          @keyframes lightSpeedLeft {
            from { transform: translateX(-50%); opacity: 0; }
            60% { transform: translateX(0); opacity: 1; }
            100% { transform: translateX(0); }
          }

          @keyframes lightSpeedRight {
            from { transform: translateX(50%); opacity: 0; }
            60% { transform: translateX(0); opacity: 1; }
            100% { transform: translateX(0); }
          }

          @keyframes slideDown {
            0% { opacity: 0; transform: translateY(-30px); }
            100% { opacity: 1; transform: translateY(0); }
          }

          @keyframes fadeIn {
            0% { opacity: 0; }
            100% { opacity: 1; }
          }

          @keyframes slideRight {
            0% { opacity: 0; transform: translateX(30px); }
            100% { opacity: 1; transform: translateX(0); }
          }

          svg#freepik_stories-personal-data:not(.animated) .animable { opacity: 0; }
          svg#freepik_stories-personal-data.animated #freepik--Plant--inject-2 {
            animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) zoomOut;
            animation-delay: 0.3s;
          }
          svg#freepik_stories-personal-data.animated #freepik--Notebook--inject-2 {
            animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) lightSpeedLeft;
            animation-delay: 0.6s;
          }
          svg#freepik_stories-personal-data.animated #freepik--Signature--inject-2 {
            animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideDown;
            animation-delay: 0.9s;
          }
          svg#freepik_stories-personal-data.animated #freepik--Texts--inject-2 {
            animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) lightSpeedRight;
            animation-delay: 1.2s;
          }
          svg#freepik_stories-personal-data.animated #freepik--Photo--inject-2 {
            animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) fadeIn;
            animation-delay: 1.5s;
          }
          svg#freepik_stories-personal-data.animated #freepik--Icons--inject-2 {
            animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideRight;
            animation-delay: 1.8s;
          }
          svg#freepik_stories-personal-data.animated #freepik--photo--inject-2 {
            animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) lightSpeedLeft;
            animation-delay: 2.1s;
          }
          svg#freepik_stories-personal-data.animated #freepik--Character--inject-2 {
            animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) lightSpeedLeft;
            animation-delay: 2.4s;
          }
          svg#freepik_stories-personal-data.animated #freepik--Floor--inject-2 {
            animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) fadeIn;
            animation-delay: 2.7s;
          }
        `}
    </style>
    <g
      className="animable"
      style={{
        transformOrigin: "193.223px 249.731px",
      }}
    >
      <path
        d="M255.48 163.75c-.19.15.51 1.55-3.06.42-8.8-2.79-23.93-8.93-26.83-18.91-4.14-14.24 14.45-6.33 14.86-13.2s-21.8-9.2-22.56-19.51 13.42-3.2 14.39-8-16.72-4.54-18.44-15.8 10-1.3 9.26-6-12.1-27.17-3.85-28.25 18.35 21.73 23 25.47 11.94-12.4 17-4.3-8.69 31.5-1.63 37.58c5.26 4.53 5.19-5.66 12.59-2.58 10.05 4.17.67 40.54-14.73 53.08Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "244.023px 109.535px",
        }}
      />
      <g
        className="animable"
        style={{
          opacity: 0.2,
          transformOrigin: "244.023px 109.535px",
        }}
      >
        <path
          d="M255.48 163.75c-.19.15.51 1.55-3.06.42-8.8-2.79-23.93-8.93-26.83-18.91-4.14-14.24 14.45-6.33 14.86-13.2s-21.8-9.2-22.56-19.51 13.42-3.2 14.39-8-16.72-4.54-18.44-15.8 10-1.3 9.26-6-12.1-27.17-3.85-28.25 18.35 21.73 23 25.47 11.94-12.4 17-4.3-8.69 31.5-1.63 37.58c5.26 4.53 5.19-5.66 12.59-2.58 10.05 4.17.67 40.54-14.73 53.08Z"
          className="animable"
          style={{
            fill: "#fff",
            transformOrigin: "244.023px 109.535px",
          }}
        />
      </g>
      <path
        d="M239.92 99.67S265.15 135 251 196.23"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "247.615px 147.95px",
        }}
      />
      <path
        d="M232.15 105c8.76 1.23 13.14 4.65 13.14 4.65"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "238.72px 107.325px",
        }}
      />
      <path
        d="M250.22 84.13s5.27 17.24-1.3 34.8"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "250.57px 101.53px",
        }}
      />
      <path
        d="M253.51 137.47s-4.77-9.49-15.71-9.53"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "245.655px 132.705px",
        }}
      />
      <path
        d="M255 149.77s7.06-7.55 9.59-20.89"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "259.795px 139.325px",
        }}
      />
      <path
        d="M349.1 56.48c-7.26-4.16-25.23 13.12-31 14.8s-6.26-11.06-14-5.47-3.92 32.43-12.77 35.38c-6.58 2.19-3.08-9.76-10.67-7.17-13.28 4.53-7.6 55.94-3.87 56.25 9.2.76 25.49-6.79 32.2-14.72s-11.16-3.93-8.93-10.45 23.66-.22 28.28-9.47-11.2-8.06-10.27-12.87 17.12-2.79 23-12.56-8.79-5-6.28-9.06 21.56-20.51 14.29-24.66"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "311.526px 103.079px",
        }}
      />
      <path
        d="M349.1 56.48c-7.26-4.16-25.23 13.12-31 14.8s-6.26-11.06-14-5.47-3.92 32.43-12.77 35.38c-6.58 2.19-3.08-9.76-10.67-7.17-13.28 4.53-7.6 55.94-3.87 56.25 9.2.76 25.49-6.79 32.2-14.72s-11.16-3.93-8.93-10.45 23.66-.22 28.28-9.47-11.2-8.06-10.27-12.87 17.12-2.79 23-12.56-8.79-5-6.28-9.06 21.56-20.51 14.29-24.66"
        className="animable"
        style={{
          opacity: 0.1,
          transformOrigin: "311.526px 103.079px",
        }}
      />
      <path
        d="M324.07 81.72s-42.5 29-59.4 104"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "294.37px 133.72px",
        }}
      />
      <path
        d="M338.46 93.35c-14.71-5.42-24.56-2.58-25.16-2.4h0"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "325.88px 91.6984px",
        }}
      />
      <path
        d="M304.75 100.23c.3-12.71 4.72-23.8 8.62-31.19"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "309.06px 84.635px",
        }}
      />
      <path
        d="M290.87 119.73s9.91-9 22.56-4.46"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "302.15px 116.86px",
        }}
      />
      <path
        d="M283.5 133.34s-4.65-11.67-1.41-28.14"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "282.221px 119.27px",
        }}
      />
      <path
        d="M118.33 360.93c-.2.15.42 1.58-3.08.26-8.64-3.27-23.4-10.24-25.75-20.36-3.35-14.45 14.78-5.53 15.57-12.37s-21.27-10.38-21.46-20.71 13.58-2.46 14.81-7.21-16.42-5.45-17.54-16.79 10.1-.75 9.57-5.48-10.64-27.79-2.29-28.41 17.13 22.7 21.6 26.7 12.61-11.73 17.17-3.36-10.41 31-3.7 37.43c5 4.81 5.49-5.37 12.72-1.89 9.81 4.72-1.56 40.51-17.62 52.19Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "110.199px 305.796px",
        }}
      />
      <g
        className="animable"
        style={{
          opacity: 0.1,
          transformOrigin: "110.199px 305.796px",
        }}
      >
        <path
          d="M118.33 360.93c-.2.15.42 1.58-3.08.26-8.64-3.27-23.4-10.24-25.75-20.36-3.35-14.45 14.78-5.53 15.57-12.37s-21.27-10.38-21.46-20.71 13.58-2.46 14.81-7.21-16.42-5.45-17.54-16.79 10.1-.75 9.57-5.48-10.64-27.79-2.29-28.41 17.13 22.7 21.6 26.7 12.61-11.73 17.17-3.36-10.41 31-3.7 37.43c5 4.81 5.49-5.37 12.72-1.89 9.81 4.72-1.56 40.51-17.62 52.19Z"
          className="animable"
          style={{
            transformOrigin: "110.199px 305.796px",
          }}
        />
      </g>
      <path
        d="M106.31 296.09s23.25 36.62 5.79 97"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "112.454px 344.59px",
        }}
      />
      <path
        d="M98.26 301c8.68 1.71 12.87 5.36 12.87 5.36"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "104.695px 303.68px",
        }}
      />
      <path
        d="M117.45 281.14s4.32 17.51-3.2 34.69"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "116.467px 298.485px",
        }}
      />
      <path
        d="M117.81 334.59s-4.25-9.74-15.16-10.38"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "110.23px 329.4px",
        }}
      />
      <path
        d="M118.58 347s7.46-7.16 10.72-20.34"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "123.94px 336.83px",
        }}
      />
      <path
        d="M122 415c-4.56 4.22-16.32 7-23.16 5.57s4.28-6.71.38-9.24S86.62 421 80.33 418s2.59-8.92.09-11.09-10.22 5.66-17.42 2.92 2.57-6.31-.44-7.41-19.94-1.92-17.81-7.13 18.81-3.53 22.56-5-1.28-8.46 5.13-8.7S88 397.11 93.94 395c4.4-1.57-2.43-6.45 2.67-8.23 8.92-3.12 27.26 26.47 25.39 28.23Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "83.3491px 401.268px",
        }}
      />
      <path
        d="M64.51 395.57s43.36 2 74.94 34.73"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "101.98px 412.935px",
        }}
      />
      <path
        d="M73.32 406.57a32.57 32.57 0 0 1 11.42-6.72h0"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "79.03px 403.21px",
        }}
      />
      <path
        d="M71.27 386.82s12.5 3.52 20.57 14.45"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "81.555px 394.045px",
        }}
      />
      <path
        d="M100.26 404a13.74 13.74 0 0 0-11.66 6.14"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "94.43px 407.069px",
        }}
      />
      <path
        d="M112.86 409.66a32.55 32.55 0 0 0-8.9-14.08"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "108.41px 402.62px",
        }}
      />
      <path
        d="M37.51 301.84c8.05-4.6 28 14.54 34.34 16.41s6.94-12.27 15.48-6.07 4.35 35.94 14.15 39.2c7.3 2.43 3.41-10.81 11.82-7.95 14.72 5 8.43 62 4.29 62.34-10.19.84-28.24-7.52-35.67-16.31s12.37-4.35 9.89-11.58-26.22-.25-31.34-10.49 12.42-8.93 11.38-14.27-19-3.09-25.47-13.91 9.75-5.57 7-10-23.89-22.73-15.84-27.33"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "79.1299px 353.478px",
        }}
      />
      <path
        d="M37.51 301.84c8.05-4.6 28 14.54 34.34 16.41s6.94-12.27 15.48-6.07 4.35 35.94 14.15 39.2c7.3 2.43 3.41-10.81 11.82-7.95 14.72 5 8.43 62 4.29 62.34-10.19.84-28.24-7.52-35.67-16.31s12.37-4.35 9.89-11.58-26.22-.25-31.34-10.49 12.42-8.93 11.38-14.27-19-3.09-25.47-13.91 9.75-5.57 7-10-23.89-22.73-15.84-27.33"
        className="animable"
        style={{
          fill: "#fff",
          opacity: 0.3,
          transformOrigin: "79.1299px 353.478px",
        }}
      />
      <path
        d="M65.24 329.82S112.34 362 131.06 445"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "98.15px 387.41px",
        }}
      />
      <path
        d="M49.31 342.69c16.3-6 27.21-2.85 27.87-2.65h0"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "63.245px 340.863px",
        }}
      />
      <path
        d="M86.66 350.32c-.34-14.08-5.23-26.38-9.55-34.56"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "81.885px 333.04px",
        }}
      />
      <path
        d="M102 371.94S91.06 362 77 367"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "89.5px 368.767px",
        }}
      />
      <path
        d="M110.2 387s5.16-12.93 1.56-31.18"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "111.618px 371.41px",
        }}
      />
    </g>
    <g
      className="animable"
      style={{
        transformOrigin: "218.255px 280.725px",
      }}
    >
      <path
        d="M120.48 130.33h219.76v323.08H120.48z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "230.36px 291.87px",
        }}
      />
      <path
        d="M331.34 449.35H108l10.82-128.2a644.43 644.43 0 0 0 2.16-66.79l-2.5-128.09h218.57l1 157.12q.14 19-.76 38Z"
        className="animable"
        style={{
          fill: "#bfbfbf",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "223.034px 287.81px",
        }}
      />
      <path
        d="M322 445H96.27l6.92-35.94c14.73-87.91 18.4-126.84 16.36-240.76l-2.32-46.38h214.15l2.52 43.8C339.32 356.35 322 445 322 445Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "215.61px 283.46px",
        }}
      />
      <path
        d="M129.91 138.22a5.83 5.83 0 0 1 0-11.66.87.87 0 1 1 0 1.74 4.09 4.09 0 1 0 4.09 4.09.87.87 0 1 1 1.74 0 5.84 5.84 0 0 1-5.83 5.83Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "129.91px 132.39px",
        }}
      />
      <path
        d="M129.77 135.81c-5.32 0-5.61-11.51-5.62-13.81v-.08c0-2.32.27-13.88 5.62-13.88s5.72 12.48 5.75 13.91l-4.63.09a28.57 28.57 0 0 0-1.15-7.93 28 28 0 0 0-1 7.81v.08a27.31 27.31 0 0 0 1 7.85c0-.17.11-.35.17-.56l.36-1.26 3.23.94a1.28 1.28 0 0 1 .87 1.58h0c-.92 3.45-2.48 5.26-4.6 5.26Z"
        className="animable"
        style={{
          fill: "#bfbfbf",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "129.835px 121.925px",
        }}
      />
      <path
        d="M134.87 133.26a.87.87 0 0 1-.87-.87 4.09 4.09 0 0 0-4.09-4.09.87.87 0 0 1 0-1.74 5.84 5.84 0 0 1 5.83 5.83.87.87 0 0 1-.87.87Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "132.39px 129.91px",
        }}
      />
      <path
        d="M157.38 138.22a5.83 5.83 0 0 1 0-11.66.87.87 0 0 1 0 1.74 4.09 4.09 0 1 0 4.09 4.09.87.87 0 1 1 1.74 0 5.84 5.84 0 0 1-5.83 5.83Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "157.38px 132.39px",
        }}
      />
      <path
        d="M157.24 135.81c-5.32 0-5.61-11.51-5.62-13.81v-.08c0-2.32.27-13.88 5.62-13.88S163 120.52 163 122l-4.63.09a28.57 28.57 0 0 0-1.15-7.93 28 28 0 0 0-1 7.81v.03a28.05 28.05 0 0 0 1 7.85c.06-.17.11-.35.17-.56l.36-1.26 3.25.97a1.28 1.28 0 0 1 .86 1.58h0c-.94 3.42-2.5 5.23-4.62 5.23Z"
        className="animable"
        style={{
          fill: "#bfbfbf",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "157.31px 121.925px",
        }}
      />
      <path
        d="M162.34 133.26a.87.87 0 0 1-.87-.87 4.09 4.09 0 0 0-4.09-4.09.87.87 0 0 1 0-1.74 5.84 5.84 0 0 1 5.83 5.83.87.87 0 0 1-.87.87Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "159.86px 129.91px",
        }}
      />
      <path
        d="M184.85 138.22a5.83 5.83 0 0 1 0-11.66.87.87 0 0 1 0 1.74 4.09 4.09 0 1 0 4.09 4.09.87.87 0 1 1 1.74 0 5.84 5.84 0 0 1-5.83 5.83Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "184.85px 132.39px",
        }}
      />
      <path
        d="M184.72 135.81c-5.33 0-5.62-11.51-5.62-13.81v-.08c0-2.32.27-13.88 5.62-13.88s5.72 12.48 5.74 13.91l-4.63.09a28.57 28.57 0 0 0-1.15-7.93 28 28 0 0 0-1 7.81v.08a28.05 28.05 0 0 0 1 7.85c.06-.17.11-.35.17-.56l.37-1.26 3.23.94a1.28 1.28 0 0 1 .86 1.58h0c-.92 3.45-2.48 5.26-4.59 5.26Z"
        className="animable"
        style={{
          fill: "#bfbfbf",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "184.78px 121.925px",
        }}
      />
      <path
        d="M189.81 133.26a.87.87 0 0 1-.87-.87 4.09 4.09 0 0 0-4.09-4.09.87.87 0 0 1 0-1.74 5.84 5.84 0 0 1 5.83 5.83.87.87 0 0 1-.87.87Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "187.33px 129.91px",
        }}
      />
      <path
        d="M212.33 138.22a5.83 5.83 0 0 1 0-11.66.87.87 0 1 1 0 1.74 4.09 4.09 0 1 0 4.08 4.09.87.87 0 1 1 1.74 0 5.83 5.83 0 0 1-5.82 5.83Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "212.325px 132.39px",
        }}
      />
      <path
        d="M212.19 135.81c-5.33 0-5.62-11.51-5.62-13.81v-.08c0-2.32.27-13.88 5.62-13.88s5.72 12.48 5.75 13.91l-4.64.09a28.2 28.2 0 0 0-1.15-7.93 28 28 0 0 0-1 7.81v.08a27.6 27.6 0 0 0 1 7.85c.05-.17.11-.35.16-.56l.37-1.26 3.32.97a1.28 1.28 0 0 1 .86 1.58h0c-1 3.42-2.55 5.23-4.67 5.23Z"
        className="animable"
        style={{
          fill: "#bfbfbf",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "212.255px 121.925px",
        }}
      />
      <path
        d="M217.28 133.26a.87.87 0 0 1-.87-.87 4.09 4.09 0 0 0-4.08-4.09.87.87 0 1 1 0-1.74 5.83 5.83 0 0 1 5.82 5.83.87.87 0 0 1-.87.87Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "214.805px 129.91px",
        }}
      />
      <path
        d="M239.63 138.22a5.83 5.83 0 1 1 0-11.66.87.87 0 0 1 0 1.74 4.09 4.09 0 1 0 4.09 4.09.87.87 0 1 1 1.73 0 5.83 5.83 0 0 1-5.82 5.83Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "239.628px 132.39px",
        }}
      />
      <path
        d="M239.49 135.81c-5.33 0-5.62-11.51-5.62-13.81v-.08c0-2.32.27-13.88 5.62-13.88s5.72 12.48 5.75 13.91l-4.64.09a28.2 28.2 0 0 0-1.15-7.93 28 28 0 0 0-.95 7.81v.08a27.6 27.6 0 0 0 1 7.85c0-.17.11-.35.16-.56L240 128l3.23.94a1.28 1.28 0 0 1 .86 1.58h0c-.92 3.48-2.48 5.29-4.6 5.29Z"
        className="animable"
        style={{
          fill: "#bfbfbf",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "239.555px 121.925px",
        }}
      />
      <path
        d="M244.58 133.26a.86.86 0 0 1-.86-.87 4.09 4.09 0 0 0-4.09-4.09.87.87 0 0 1 0-1.74 5.83 5.83 0 0 1 5.82 5.83.87.87 0 0 1-.87.87Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "242.105px 129.91px",
        }}
      />
      <path
        d="M267.1 138.22a5.83 5.83 0 0 1 0-11.66.87.87 0 0 1 0 1.74 4.09 4.09 0 1 0 4.09 4.09.87.87 0 1 1 1.74 0 5.84 5.84 0 0 1-5.83 5.83Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "267.1px 132.39px",
        }}
      />
      <path
        d="M267 135.81c-5.33 0-5.62-11.51-5.62-13.81v-.08c0-2.32.27-13.88 5.62-13.88s5.72 12.48 5.75 13.91l-4.64.09a27.83 27.83 0 0 0-1.15-7.93 28.45 28.45 0 0 0-.94 7.81v.08a27.31 27.31 0 0 0 1 7.85c.05-.17.11-.35.16-.56l.37-1.26 3.23.94a1.28 1.28 0 0 1 .86 1.58h0c-1 3.45-2.56 5.26-4.64 5.26Z"
        className="animable"
        style={{
          fill: "#bfbfbf",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "267.065px 121.925px",
        }}
      />
      <path
        d="M272.06 133.26a.87.87 0 0 1-.87-.87 4.09 4.09 0 0 0-4.09-4.09.87.87 0 0 1 0-1.74 5.84 5.84 0 0 1 5.83 5.83.87.87 0 0 1-.87.87Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "269.58px 129.91px",
        }}
      />
      <path
        d="M294.57 138.22a5.83 5.83 0 1 1 0-11.66.87.87 0 0 1 0 1.74 4.09 4.09 0 1 0 4.09 4.09.87.87 0 1 1 1.74 0 5.84 5.84 0 0 1-5.83 5.83Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "294.57px 132.39px",
        }}
      />
      <path
        d="M294.43 135.81c-5.32 0-5.61-11.51-5.62-13.81v-.08c0-2.32.27-13.88 5.62-13.88s5.72 12.48 5.75 13.91l-4.64.09a28.27 28.27 0 0 0-1.14-7.93 28 28 0 0 0-.95 7.81v.08a27.31 27.31 0 0 0 1 7.85c.05-.17.11-.35.16-.56L295 128l3.23.94a1.28 1.28 0 0 1 .87 1.58h0c-.99 3.48-2.55 5.29-4.67 5.29Z"
        className="animable"
        style={{
          fill: "#bfbfbf",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "294.495px 121.925px",
        }}
      />
      <path
        d="M299.53 133.26a.87.87 0 0 1-.87-.87 4.09 4.09 0 0 0-4.09-4.09.87.87 0 0 1 0-1.74 5.84 5.84 0 0 1 5.83 5.83.87.87 0 0 1-.87.87Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "297.05px 129.91px",
        }}
      />
      <path
        d="M322 138.22a5.83 5.83 0 0 1 0-11.66.87.87 0 0 1 0 1.74 4.09 4.09 0 1 0 4.09 4.09.87.87 0 1 1 1.74 0 5.84 5.84 0 0 1-5.83 5.83Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "322px 132.39px",
        }}
      />
      <path
        d="M321.9 135.81c-5.32 0-5.61-11.51-5.62-13.81v-.08c0-2.32.27-13.88 5.62-13.88s5.73 12.48 5.75 13.91L323 122a28.57 28.57 0 0 0-1.15-7.93 28 28 0 0 0-.95 7.81v.12a28.05 28.05 0 0 0 1 7.85c.06-.17.11-.35.17-.56l.36-1.26 3.23.94a1.28 1.28 0 0 1 .87 1.58h0c-.95 3.45-2.53 5.26-4.63 5.26Z"
        className="animable"
        style={{
          fill: "#bfbfbf",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "321.965px 121.925px",
        }}
      />
      <path
        d="M327 133.26a.87.87 0 0 1-.87-.87 4.09 4.09 0 0 0-4.13-4.09.87.87 0 0 1 0-1.74 5.84 5.84 0 0 1 5.83 5.83.87.87 0 0 1-.83.87Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "324.48px 129.91px",
        }}
      />
    </g>
    <g
      className="animable"
      style={{
        transformOrigin: "291.272px 404.482px",
      }}
    >
      <path
        d="M267.51 423.72h47v1h-47z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "291.01px 424.22px",
        }}
      />
      <path
        d="M291.48 427.34a14.39 14.39 0 0 1-2-.14c-15.6-2.21-16.1-17.21-15.59-23.55.62-7.81 4.72-18.11 14.22-21.43 9-3.16 17.22 7 19.41 12.89a31.87 31.87 0 0 1-.1 22.12c-2.21 5.77-9.33 10.11-15.94 10.11Zm-16.57-23.61c-.6 7.53.51 20.46 14.74 22.48 6.67 1 14.56-3.42 16.87-9.35a30.83 30.83 0 0 0 .09-21.4c-2.07-5.57-9.75-15.23-18.14-12.29-9.05 3.16-13 13.05-13.56 20.56Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "291.612px 404.482px",
        }}
      />
      <path
        d="M277.83 414.4a.51.51 0 0 1-.48-.34 29.68 29.68 0 0 1-1.47-9.69 21.89 21.89 0 0 1 4.52-13 .51.51 0 0 1 .7-.1.5.5 0 0 1 .1.7 20.91 20.91 0 0 0-4.32 12.41 28.55 28.55 0 0 0 1.42 9.36.5.5 0 0 1-.31.64.45.45 0 0 1-.16.02Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "278.588px 402.787px",
        }}
      />
      <path
        d="M287.68 424h-.12a13.66 13.66 0 0 1-9-7.23.49.49 0 0 1 .2-.67.5.5 0 0 1 .68.2 12.71 12.71 0 0 0 8.38 6.73.51.51 0 0 1 .37.61.5.5 0 0 1-.51.36Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "283.351px 420.02px",
        }}
      />
      <path
        d="M291.73 424.51a.5.5 0 0 1 0-1c7.85 0 12.81-4.5 14.35-13a.5.5 0 1 1 1 .18c-1.65 9.03-6.95 13.82-15.35 13.82Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "299.159px 417.299px",
        }}
      />
      <path
        d="M306.94 408.25a.5.5 0 0 1-.46-.54c.1-1.06.14-2.18.14-3.33a20.23 20.23 0 0 0-2.59-9.7.5.5 0 1 1 .87-.48 21.1 21.1 0 0 1 2.72 10.18c0 1.17-.05 2.32-.14 3.42a.5.5 0 0 1-.54.45Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "305.779px 401.071px",
        }}
      />
      <path
        d="M303.36 393.2a.49.49 0 0 1-.36-.2 21.19 21.19 0 0 0-6-6 .48.48 0 0 1-.16-.68.49.49 0 0 1 .69-.16 22.05 22.05 0 0 1 6.31 6.25.5.5 0 0 1-.14.69.47.47 0 0 1-.34.1Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "300.341px 389.641px",
        }}
      />
      <path
        d="M283.79 388.91a.46.46 0 0 1-.37-.17.48.48 0 0 1 0-.7c2.73-2.42 5.74-3.81 8.27-3.81a9.6 9.6 0 0 1 3.84.91.51.51 0 0 1 .25.66.5.5 0 0 1-.66.26 8.49 8.49 0 0 0-3.43-.83c-2.28 0-5.06 1.3-7.61 3.56a.5.5 0 0 1-.29.12Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "289.545px 386.57px",
        }}
      />
      <path
        d="M279.38 410.11a.5.5 0 0 1-.49-.4 27.6 27.6 0 0 1-.48-5.2c0-9.56 8.11-16.76 13.37-16.76a9.14 9.14 0 0 1 4.43 1.34.5.5 0 0 1 .19.68.52.52 0 0 1-.69.19 8.21 8.21 0 0 0-3.93-1.21c-4.86 0-12.37 6.77-12.37 15.76a26.42 26.42 0 0 0 .46 5 .48.48 0 0 1-.39.58Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "287.437px 398.93px",
        }}
      />
      <path
        d="M284.2 418.85a.51.51 0 0 1-.31-.11 13.34 13.34 0 0 1-4.14-6 .5.5 0 1 1 .93-.35 12.3 12.3 0 0 0 3.83 5.52.5.5 0 0 1 .08.71.49.49 0 0 1-.39.23Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "282.199px 415.431px",
        }}
      />
      <path
        d="M291.78 421.28a13.78 13.78 0 0 1-5.07-.91.51.51 0 0 1-.28-.65.5.5 0 0 1 .65-.28 12.81 12.81 0 0 0 4.7.84c8 0 12.37-5.6 12.37-15.77a.5.5 0 0 1 .5-.5.51.51 0 0 1 .5.5c0 10.81-4.75 16.77-13.37 16.77Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "295.774px 412.645px",
        }}
      />
      <path
        d="M304.48 402.76a.5.5 0 0 1-.49-.43 17.76 17.76 0 0 0-5.85-10.62.5.5 0 0 1 0-.71.5.5 0 0 1 .7 0 18.79 18.79 0 0 1 6.16 11.19.51.51 0 0 1-.43.57Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "301.498px 396.809px",
        }}
      />
      <path
        d="M291.83 418c-6.71 0-10.88-5.13-10.88-13.38a14.42 14.42 0 0 1 3.4-9.12.51.51 0 0 1 .71-.06.49.49 0 0 1 .06.7 13.36 13.36 0 0 0-3.17 8.48c0 7.64 3.78 12.38 9.88 12.38a9.86 9.86 0 0 0 4.95-1.19.5.5 0 0 1 .5.86 10.74 10.74 0 0 1-5.45 1.33Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "289.218px 406.663px",
        }}
      />
      <path
        d="M299.57 414.57a.5.5 0 0 1-.3-.11.5.5 0 0 1-.09-.7c1.65-2.12 2.53-5.27 2.53-9.1 0-7.07-6-12.39-9.88-12.39a7.41 7.41 0 0 0-4.08 1.48.5.5 0 0 1-.56-.82 8.33 8.33 0 0 1 4.64-1.66c4.28 0 10.88 5.75 10.88 13.39 0 4-.95 7.41-2.74 9.71a.51.51 0 0 1-.4.2Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "294.864px 402.92px",
        }}
      />
      <path
        d="M286.42 412.58a.46.46 0 0 1-.36-.16 11 11 0 0 1-2.58-7.62c0-5.39 4.62-10 8.4-10a5.66 5.66 0 0 1 2.07.42.5.5 0 0 1-.36.93 4.79 4.79 0 0 0-1.71-.35c-3.33 0-7.4 4.16-7.4 9a10.09 10.09 0 0 0 2.3 6.94.5.5 0 0 1 0 .71.48.48 0 0 1-.36.13Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "288.851px 403.69px",
        }}
      />
      <path
        d="M291.88 414.81a8.55 8.55 0 0 1-3.25-.61.5.5 0 0 1-.28-.65.49.49 0 0 1 .65-.27 7.65 7.65 0 0 0 2.88.53c4.63 0 7.39-3.37 7.39-9a9.74 9.74 0 0 0-3.62-7.42.5.5 0 1 1 .63-.77 10.74 10.74 0 0 1 4 8.19c-.01 6.26-3.15 10-8.4 10Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "294.297px 405.642px",
        }}
      />
      <path
        d="M291.92 411.57a5.63 5.63 0 0 1-5.7-4.75.5.5 0 1 1 1-.22 4.69 4.69 0 0 0 4.73 4c3 0 4.91-2.15 4.91-5.63a.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5c-.03 4.03-2.35 6.6-5.94 6.6Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "292.034px 408.021px",
        }}
      />
      <path
        d="M286.51 405.44a.5.5 0 0 1-.5-.5c0-3.74 3.18-6.63 5.91-6.63 2.13 0 4.39 1.7 5.39 4.06a.5.5 0 0 1-.93.39 5.35 5.35 0 0 0-4.46-3.45c-2.27 0-4.91 2.46-4.91 5.63a.5.5 0 0 1-.5.5Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "291.679px 401.875px",
        }}
      />
      <path
        d="M292 408.34a3.26 3.26 0 1 1 0-6.52.5.5 0 0 1 .5.5.5.5 0 0 1-.5.5 2.27 2.27 0 1 0 2.43 2.26 2.17 2.17 0 0 0-.77-1.64.5.5 0 0 1 .66-.76 3.22 3.22 0 0 1 1.11 2.4 3.35 3.35 0 0 1-3.43 3.26Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "292.085px 405.08px",
        }}
      />
      <path
        d="M272.58 416.22a.47.47 0 0 1-.17 0 .53.53 0 0 1-.33-.39 22.13 22.13 0 0 1 10-22 12.14 12.14 0 0 1 5.53-2c.15-.47.29-.94.42-1.42a21 21 0 0 0-18.53 22.49.5.5 0 1 1-1 .08 22 22 0 0 1 20.17-23.68.48.48 0 0 1 .42.17.49.49 0 0 1 .1.45c-.16.62-.33 1.24-.52 1.85a6.45 6.45 0 0 1 5.13 2.8 5.39 5.39 0 0 1 .82 2.35l.48-.58a.5.5 0 0 1 .84.53c-1 2.23-1.79 4.24-1.35 6.22a3.51 3.51 0 0 0 .65 1.33 19 19 0 0 1 6.75-4.48.49.49 0 0 1 .48.07.51.51 0 0 1 .19.31 51.75 51.75 0 0 1 11.66-6.4.5.5 0 1 1 .36.93 50.77 50.77 0 0 0-12.22 6.87 14.52 14.52 0 0 1-8.26 10l-3.4 3.46a.5.5 0 0 1-.58.1.53.53 0 0 1-.27-.53 19.39 19.39 0 0 1 4.6-9.61 4.56 4.56 0 0 1-.93-1.85 6.35 6.35 0 0 1-.15-1.56l-.3.47a20 20 0 0 1-5.87 5 .5.5 0 0 1-.65-.74l7-8.39a4.85 4.85 0 0 0-.68-3 5.57 5.57 0 0 0-4.6-2.34 52.7 52.7 0 0 1-15.46 23.34.52.52 0 0 1-.33.15Zm14.72-23.4a11.65 11.65 0 0 0-4.68 1.8 21.15 21.15 0 0 0-9.68 20.11 51.6 51.6 0 0 0 14.36-21.91Zm4.21 19.73-.21.73.8-.82-.4.09a.31.31 0 0 1-.19 0Zm5.29-5.85a49 49 0 0 0-3.92 4.49c.25-.09.5-.18.75-.29l4-4a3.84 3.84 0 0 1-.83-.2Zm-1.53-.83a18.35 18.35 0 0 0-2.77 4.19 47.9 47.9 0 0 1 3.33-3.8 5.58 5.58 0 0 1-.56-.39Zm3.23 1.5-1.1 1.12a11.63 11.63 0 0 0 1.1-1.12Zm-.85-1.52a2 2 0 0 0 1.28-.33.5.5 0 0 1 .66.1.46.46 0 0 1 .1.18 13.27 13.27 0 0 0 1.47-3.06q-1.83 1.47-3.51 3.11Zm-1.7-.72a3 3 0 0 0 .61.39 51.35 51.35 0 0 1 4.84-4.24 18.16 18.16 0 0 0-5.4 3.85Zm-2.86-4.8-3.3 4a12.32 12.32 0 0 0 2.56-2.64 8 8 0 0 0 .74-1.36Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "291.732px 402.762px",
        }}
      />
    </g>
    <g
      className="animable"
      style={{
        transformOrigin: "224.295px 289.135px",
      }}
    >
      <path
        d="M313.43 178.53h11.09v1h-11.09z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "318.975px 179.03px",
        }}
      />
      <path
        d="M301.89 178.53h6.68v1h-6.68z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "305.23px 179.03px",
        }}
      />
      <path
        d="M129.91 178.53h166.92v1H129.91z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "213.37px 179.03px",
        }}
      />
      <path
        d="M242.73 403.3h8.83v1h-8.83z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "247.145px 403.8px",
        }}
      />
      <path
        d="M204.56 403.3h31.72v1h-31.72z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "220.42px 403.8px",
        }}
      />
      <path
        d="M248.23 424.72c-12.25-.22-23.21-.22-35.52 0v-1c12.32-.22 23.28-.22 35.55 0Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "230.485px 424.137px",
        }}
      />
      <path
        d="M201.24 423.72h5.02v1h-5.02z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "203.75px 424.22px",
        }}
      />
      <path
        d="M252.12 240.58h6.2v1h-6.2z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "255.22px 241.08px",
        }}
      />
      <path
        d="M243.08 239.03h1v4.08h-1z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "243.58px 241.07px",
          transform: "rotate(-89.64deg)",
        }}
      />
      <path
        d="M211.33 241.58v-1c7.9-.05 15.81-.07 23.51-.05v1c-7.71-.02-15.61 0-23.51.05Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "223.085px 241.051px",
        }}
      />
      <path
        d="M244.67 261h13.66v1h-13.66z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "251.5px 261.5px",
        }}
      />
      <path
        d="M211.33 261h26.63v1h-26.63z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "224.645px 261.5px",
        }}
      />
      <path
        d="M240.51 282.48c-5.81 0-11.61 0-17.72-.07v-1c12.22.09 23.18.09 35.52 0v1c-6.16.04-11.98.07-17.8.07Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "240.55px 281.945px",
        }}
      />
      <path
        d="M211.33 281.41h4.8v1h-4.8z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "213.73px 281.91px",
        }}
      />
      <path
        d="M211.33 301.82h47v1h-47z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "234.83px 302.32px",
        }}
      />
      <path
        d="M240 323.43h-7.05v-1c8.25.07 16.79 0 25.36-.16v1c-6.14.1-12.31.16-18.31.16Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "245.63px 322.85px",
        }}
      />
      <path
        d="m226.2 323.32-4-.08v-1l4 .08Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "224.2px 322.78px",
        }}
      />
      <path
        d="M211.33 322.24h4.36v1h-4.36z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "213.51px 322.74px",
        }}
      />
      <path
        d="M239.5 343.39c-5.77 0-11.54-.11-17.64-.33v-1c12.17.44 23 .44 35.19 0v1c-6.05.22-11.78.33-17.55.33Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "239.455px 342.725px",
        }}
      />
      <path
        d="M210.11 342.06h5.25v1h-5.25z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "212.735px 342.56px",
        }}
      />
      <path
        d="M249.3 362.48h6.7v1h-6.7z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "252.65px 362.98px",
        }}
      />
      <path
        d="M226 363.49h-17v-1h34v1Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "226px 362.99px",
        }}
      />
      <path
        d="M218.57 383.89v-1c12.53-.39 23.4-.39 35.26 0v1c-11.9-.39-22.76-.39-35.26 0Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "236.2px 383.244px",
        }}
      />
      <path
        d="M206.78 382.89h5.25v1h-5.25z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "209.405px 383.39px",
        }}
      />
      <g
        className="animable"
        style={{
          transformOrigin: "184.37px 288.925px",
        }}
      >
        <path
          d="M144.08 157.67v2.08c0 2.55-1.23 3.94-3.74 3.94h-1.18v6h-2.51v-16h3.69c2.51.04 3.74 1.43 3.74 3.98Zm-4.92-1.67v5.4h1.18c.8 0 1.23-.36 1.23-1.5v-2.39c0-1.14-.43-1.51-1.23-1.51Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "140.365px 161.69px",
          }}
        />
        <path
          d="M147.87 160.46h3.44v2.28h-3.44v4.67h4.33v2.28h-6.84v-16h6.84V156h-4.33Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "148.78px 161.69px",
          }}
        />
        <path
          d="M159 169.69a5.11 5.11 0 0 1-.22-2v-2.51c0-1.48-.51-2-1.65-2h-.86v6.5h-2.51v-16h3.78c2.6 0 3.72 1.21 3.72 3.67v1.25c0 1.65-.52 2.72-1.64 3.24 1.25.53 1.66 1.73 1.66 3.4v2.46a4.7 4.7 0 0 0 .28 1.94ZM156.26 156v4.9h1c.93 0 1.5-.41 1.5-1.69v-1.57c0-1.14-.39-1.64-1.27-1.64Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "157.66px 161.685px",
          }}
        />
        <path
          d="M166.29 153.55c2.44 0 3.69 1.46 3.69 4v.5h-2.37v-.66c0-1.14-.46-1.57-1.25-1.57s-1.26.43-1.26 1.57c0 3.28 4.9 3.9 4.9 8.46 0 2.55-1.27 4-3.73 4s-3.74-1.46-3.74-4v-1h2.37V166c0 1.14.5 1.55 1.3 1.55s1.3-.41 1.3-1.55c0-3.28-4.9-3.9-4.9-8.46 0-2.54 1.25-3.99 3.69-3.99Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "166.265px 161.7px",
          }}
        />
        <path
          d="M171.24 157.56c0-2.55 1.34-4 3.8-4s3.81 1.46 3.81 4v8.3c0 2.55-1.34 4-3.81 4s-3.8-1.46-3.8-4Zm2.5 8.46c0 1.14.51 1.57 1.3 1.57s1.3-.43 1.3-1.57v-8.62c0-1.14-.5-1.57-1.3-1.57s-1.3.43-1.3 1.57Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "175.045px 161.71px",
          }}
        />
        <path
          d="M182.7 158.13v11.56h-2.25v-16h3.14l2.58 9.55v-9.55h2.23v16h-2.57Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "184.425px 161.69px",
          }}
        />
        <path
          d="M198.32 169.69h-2.53l-.43-2.9h-3.08l-.43 2.9h-2.31l2.56-16h3.67Zm-5.72-5.06h2.4l-1.21-8.07Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "193.93px 161.69px",
          }}
        />
        <path
          d="M199.48 153.73H202v13.68h4.13v2.28h-6.64Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "202.805px 161.71px",
          }}
        />
        <path
          d="M210.75 153.73h4c2.51 0 3.74 1.39 3.74 3.94v8.07c0 2.56-1.23 3.95-3.74 3.95h-4Zm2.5 2.28v11.4h1.42c.8 0 1.27-.41 1.27-1.55v-8.3c0-1.14-.47-1.55-1.27-1.55Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "214.62px 161.71px",
          }}
        />
        <path
          d="M228.14 169.69h-2.53l-.43-2.9h-3.08l-.43 2.9h-2.31l2.56-16h3.67Zm-5.72-5.06h2.42l-1.21-8.07Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "223.75px 161.69px",
          }}
        />
        <path
          d="M228.58 153.73h7.75V156h-2.63v13.68h-2.5V156h-2.62Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "232.455px 161.705px",
          }}
        />
        <path
          d="M244.67 169.69h-2.53l-.43-2.9h-3.08l-.43 2.9h-2.31l2.56-16h3.67Zm-5.67-5.06h2.42l-1.21-8.07Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "240.28px 161.69px",
          }}
        />
        <path
          d="M139.15 235.66v1H137v4h-1v-9.32h3.7v1H137v3.39Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "137.85px 236px",
          }}
        />
        <path
          d="M141.53 231.32v9.32h-1v-9.32Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "141.03px 235.98px",
          }}
        />
        <path
          d="M144.67 231.32c1.46 0 2.08.75 2.08 2.13v.76c0 1-.36 1.67-1.16 1.92.85.25 1.17.94 1.17 1.94v1.45a2.27 2.27 0 0 0 .19 1.12h-1.06a2.47 2.47 0 0 1-.17-1.13V238c0-1-.49-1.38-1.36-1.38h-.73v4h-1v-9.32Zm-.27 4.39c.83 0 1.32-.26 1.32-1.27v-.89c0-.84-.3-1.28-1.08-1.28h-1v3.44Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "144.79px 235.97px",
          }}
        />
        <path
          d="M151.87 233.56v.25h-1v-.3c0-.84-.32-1.33-1.06-1.33s-1.07.49-1.07 1.31c0 2.09 3.13 2.29 3.13 4.91 0 1.41-.68 2.33-2.1 2.33s-2.09-.92-2.09-2.33v-.53h1v.59c0 .83.35 1.33 1.09 1.33s1.09-.5 1.09-1.33c0-2.08-3.11-2.28-3.11-4.9 0-1.44.67-2.33 2.06-2.33s2.06.92 2.06 2.33Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "149.775px 235.98px",
          }}
        />
        <path
          d="M154.14 240.64v-8.37h-1.71v-1h4.47v1h-1.72v8.37Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "154.665px 235.955px",
          }}
        />
        <path
          d="M160.6 240.64h-.93v-9.32H161l2.16 6.74v-6.74h.92v9.32H163l-2.38-7.53Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "161.875px 235.98px",
          }}
        />
        <path
          d="m166.17 238.76-.36 1.88h-1l1.79-9.32h1.5l1.78 9.32h-1l-.36-1.88Zm.13-.89h2.08l-1-5.37Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "167.345px 235.98px",
          }}
        />
        <path
          d="M174.17 240.62h-1l-1.54-7.41v7.43h-.91v-9.32h1.45l1.53 7.44 1.48-7.44h1.45v9.32h-1v-7.49Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "173.675px 235.98px",
          }}
        />
        <path
          d="M181 235.45v.93h-2.28v3.31h2.8v.95h-3.84v-9.32h3.84v1h-2.8v3.18Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "179.6px 235.98px",
          }}
        />
        <path
          d="M202 234.28v1.29h-1v-1.29Zm0 5.07v1.29h-1v-1.29Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "201.5px 237.46px",
          }}
        />
        <path
          d="M136 261.49v-9.31h1v8.37h2.66v.94Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "137.83px 256.835px",
          }}
        />
        <path
          d="m141.5 259.62-.36 1.87h-.95l1.78-9.31h1.5l1.79 9.31h-1l-.36-1.87Zm.14-.89h2.07l-1.05-5.38Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "142.725px 256.835px",
          }}
        />
        <path
          d="M150.1 254.42v.25h-1v-.31c0-.84-.32-1.33-1.06-1.33s-1.06.49-1.06 1.32c0 2.09 3.12 2.29 3.12 4.91 0 1.41-.68 2.33-2.1 2.33s-2.09-.92-2.09-2.33v-.53h1v.58c0 .84.35 1.33 1.09 1.33s1.09-.49 1.09-1.33c0-2.07-3.11-2.27-3.11-4.89 0-1.44.67-2.33 2.06-2.33s2.06.91 2.06 2.33Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "148.005px 256.84px",
          }}
        />
        <path
          d="M152.37 261.49v-8.36h-1.71v-.95h4.47v.95h-1.72v8.36Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "152.895px 256.835px",
          }}
        />
        <path
          d="M158.83 261.49h-.93v-9.31h1.31l2.16 6.73v-6.73h.92v9.31h-1.08l-2.38-7.49Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "160.095px 256.835px",
          }}
        />
        <path
          d="m164.4 259.62-.36 1.87h-.95l1.78-9.31h1.5l1.78 9.31h-1l-.36-1.87Zm.14-.89h2.07l-1.05-5.38Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "165.62px 256.835px",
          }}
        />
        <path
          d="M172.4 261.48h-1l-1.54-7.41v7.42H169v-9.31h1.45l1.53 7.44 1.48-7.44h1.45v9.31h-1V254Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "171.955px 256.835px",
          }}
        />
        <path
          d="M179.24 256.31v.93H177v3.31h2.8v.94h-3.84v-9.31h3.84v.95H177v3.18Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "177.88px 256.835px",
          }}
        />
        <path
          d="M202.34 255.13v1.3h-1v-1.3Zm0 5.07v1.29h-1v-1.29Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "201.84px 258.31px",
          }}
        />
        <path
          d="M137 272.52v9.31h-1v-9.31Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "136.5px 277.175px",
          }}
        />
        <path
          d="M140.25 272.52c1.45 0 2.12.87 2.12 2.31v4.7c0 1.42-.67 2.3-2.12 2.3h-2.18v-9.31Zm0 8.37c.74 0 1.1-.47 1.1-1.32v-4.79c0-.85-.36-1.32-1.11-1.32h-1.12v7.43Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "140.22px 277.175px",
          }}
        />
        <path
          d="M146.32 281.83h-.93v-9.31h1.32l2.15 6.73v-6.73h.92v9.31h-1.08l-2.38-7.53Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "147.585px 277.175px",
          }}
        />
        <path
          d="M151.83 279.63c0 .84.33 1.35 1.09 1.35s1.08-.51 1.08-1.35v-7.11h1v7.06c0 1.41-.65 2.36-2.09 2.36s-2.08-.95-2.08-2.36v-7.06h1Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "152.915px 277.23px",
          }}
        />
        <path
          d="M159.43 281.82h-1l-1.55-7.41v7.42H156v-9.31h1.45L159 280l1.48-7.43h1.45v9.31h-1v-7.49Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "158.965px 277.2px",
          }}
        />
        <path
          d="M165 272.52c1.45 0 2 .69 2 2.06v.42c0 1-.28 1.59-1.09 1.85.91.25 1.25 1 1.25 2v.79a1.92 1.92 0 0 1-2.13 2.18H163v-9.31Zm-.25 4c.82 0 1.25-.27 1.25-1.24v-.54c0-.83-.28-1.23-1-1.23h-1v3Zm.3 4.42c.76 0 1.11-.4 1.11-1.27v-.82c0-1-.41-1.39-1.31-1.39H164v3.48Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "165.09px 277.172px",
          }}
        />
        <path
          d="M171.48 276.64v.93h-2.27v3.32H172v.94h-3.83v-9.31H172v.94h-2.79v3.18Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "170.085px 277.175px",
          }}
        />
        <path
          d="M175 272.52c1.47 0 2.08.74 2.08 2.13v.75c0 1-.36 1.67-1.16 1.92.85.25 1.17.94 1.17 1.94v1.45a2.39 2.39 0 0 0 .19 1.12h-1.05a2.43 2.43 0 0 1-.18-1.13v-1.46c0-1.06-.49-1.39-1.35-1.39h-.7v4h-1v-9.31Zm-.26 4.39c.82 0 1.31-.27 1.31-1.28v-.89c0-.84-.3-1.28-1.07-1.28h-1v3.45Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "175.14px 277.185px",
          }}
        />
        <path
          d="M201.88 275.47v1.29h-1v-1.29Zm0 5.07v1.29h-1v-1.29Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "201.38px 278.65px",
          }}
        />
        <path
          d="M140.08 293.94v.79h-1v-.85c0-.84-.34-1.34-1.1-1.34s-1.09.5-1.09 1.34v4.93c0 .83.35 1.34 1.09 1.34s1.1-.51 1.1-1.34v-1.87h-1V296h1.94v2.74c0 1.41-.67 2.34-2.1 2.34s-2.09-.93-2.09-2.34v-4.81c0-1.41.66-2.35 2.09-2.35s2.16.95 2.16 2.36Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "137.955px 296.33px",
          }}
        />
        <path
          d="M144.38 295.81v.93h-2.28v3.32h2.8v.94h-3.84v-9.31h3.84v.94h-2.8v3.18Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "142.98px 296.345px",
          }}
        />
        <path
          d="M146.77 301h-.93v-9.31h1.32l2.15 6.73v-6.73h.92V301h-1.08l-2.38-7.53Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "148.035px 296.345px",
          }}
        />
        <path
          d="M153.48 291.69c1.45 0 2.11.88 2.11 2.31v4.7c0 1.42-.66 2.3-2.11 2.3h-2.18v-9.31Zm0 8.37c.74 0 1.1-.47 1.1-1.32V294c0-.85-.36-1.32-1.12-1.32h-1.12v7.43Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "153.445px 296.345px",
          }}
        />
        <path
          d="M159.88 295.81v.93h-2.28v3.32h2.8v.94h-3.83v-9.31h3.83v.94h-2.8v3.18Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "158.485px 296.345px",
          }}
        />
        <path
          d="M163.43 291.69c1.46 0 2.08.74 2.08 2.13v.76c0 1-.36 1.66-1.16 1.91.85.25 1.17.95 1.17 1.94v1.45a2.25 2.25 0 0 0 .19 1.12h-1.06a2.55 2.55 0 0 1-.17-1.13v-1.46c0-1.05-.49-1.39-1.36-1.39h-.73v4h-1v-9.31Zm-.27 4.39c.83 0 1.32-.27 1.32-1.28v-.89c0-.84-.3-1.28-1.08-1.28h-1v3.45Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "163.55px 296.355px",
          }}
        />
        <path
          d="M202.66 294.64v1.29h-1v-1.29Zm0 5.07V301h-1v-1.29Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "202.16px 297.82px",
          }}
        />
        <path
          d="M138.15 312c1.45 0 2.12.88 2.12 2.31V319c0 1.42-.67 2.3-2.12 2.3H136V312Zm0 8.37c.74 0 1.1-.47 1.1-1.32v-4.79c0-.85-.36-1.32-1.12-1.32H137v7.43Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "138.135px 316.65px",
          }}
        />
        <path
          d="M142.1 320v1.29h-1V320Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "141.6px 320.645px",
          }}
        />
        <path
          d="M143 314.22c0-1.43.71-2.34 2.13-2.34s2.15.91 2.15 2.34V319a2.06 2.06 0 0 1-2.15 2.35c-1.42 0-2.13-.94-2.13-2.35Zm1 4.87c0 .85.36 1.34 1.1 1.34s1.11-.49 1.11-1.34v-4.93c0-.83-.35-1.34-1.11-1.34s-1.1.51-1.1 1.34Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "145.15px 316.617px",
          }}
        />
        <path
          d="M149.07 320v1.29h-1V320Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "148.57px 320.645px",
          }}
        />
        <path
          d="M152.23 312c1.45 0 2 .69 2 2.06v.44c0 1-.28 1.6-1.09 1.85.9.25 1.25 1 1.25 2v.78a1.91 1.91 0 0 1-2.13 2.18h-2.14V312Zm-.25 3.95c.82 0 1.25-.27 1.25-1.24v-.54c0-.83-.28-1.23-1-1.23h-1v3Zm.3 4.42c.76 0 1.11-.4 1.11-1.27v-.82c0-1-.42-1.38-1.31-1.38h-.9v3.47Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "152.266px 316.663px",
          }}
        />
        <path
          d="M201.72 314.92v1.29h-1v-1.29Zm0 5.07v1.29h-1V320Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "201.22px 318.1px",
          }}
        />
        <path
          d="M135.21 337.4v4.19h-1v-9.31h1v4.19h2.29v-4.19h1v9.31h-1v-4.19Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "136.355px 336.935px",
          }}
        />
        <path
          d="M142.91 336.4v.93h-2.27v3.32h2.79v.94h-3.83v-9.31h3.83v.94h-2.79v3.18Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "141.515px 336.935px",
          }}
        />
        <path
          d="M145.42 332.28v9.31h-1v-9.31Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "144.92px 336.935px",
          }}
        />
        <path
          d="M150.6 334.53v.8h-1v-.86c0-.83-.34-1.34-1.1-1.34s-1.09.51-1.09 1.34v4.93c0 .84.35 1.34 1.09 1.34s1.1-.5 1.1-1.34v-1.87h-1v-.93h1.94v2.74c0 1.41-.66 2.35-2.1 2.35s-2.09-.94-2.09-2.35v-4.81c0-1.41.66-2.34 2.09-2.34s2.16.93 2.16 2.34Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "148.475px 336.94px",
          }}
        />
        <path
          d="M152.62 337.4v4.19h-1v-9.31h1v4.19h2.29v-4.19h1v9.31h-1v-4.19Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "153.765px 336.935px",
          }}
        />
        <path
          d="M158.37 341.59v-8.37h-1.72v-.94h4.47v.94h-1.71v8.37Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "158.885px 336.935px",
          }}
        />
        <path
          d="M200 335.23v1.29h-1v-1.29Zm0 5.07v1.29h-1v-1.29Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "199.5px 338.41px",
          }}
        />
        <path
          d="M134.43 360.76H133l-1.27-9.31h1l1.09 7.93 1-7.92h1l1 7.93 1.06-7.94h.9l-1.26 9.31h-1.37l-.9-6.32Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "135.255px 356.105px",
          }}
        />
        <path
          d="M143 355.58v.93h-2.27v3.31h2.79v.94h-3.83v-9.31h3.83v.95h-2.79v3.18Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "141.605px 356.105px",
          }}
        />
        <path
          d="M145.54 351.45v9.31h-1v-9.31Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "145.04px 356.105px",
          }}
        />
        <path
          d="M150.71 353.7v.8h-1v-.85c0-.84-.34-1.35-1.09-1.35s-1.09.51-1.09 1.35v4.92c0 .84.34 1.34 1.09 1.34s1.09-.5 1.09-1.34v-1.86h-1v-.93h1.94v2.74c0 1.41-.66 2.34-2.1 2.34s-2.09-.93-2.09-2.34v-4.82c0-1.41.67-2.34 2.09-2.34s2.16.93 2.16 2.34Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "148.585px 356.11px",
          }}
        />
        <path
          d="M152.73 356.57v4.19h-1v-9.31h1v4.19H155v-4.19h1v9.31h-1v-4.19Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "153.865px 356.105px",
          }}
        />
        <path
          d="M158.48 360.76v-8.36h-1.71v-.95h4.47v.95h-1.72v8.36Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "159.005px 356.105px",
          }}
        />
        <path
          d="M198 354.4v1.3h-1v-1.3Zm0 5.07v1.29h-1v-1.29Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "197.5px 357.58px",
          }}
        />
        <path
          d="M132 372.84c1.45 0 2 .69 2 2.06v.44c0 1-.28 1.6-1.09 1.85.91.25 1.25 1 1.25 2v.81a1.91 1.91 0 0 1-2.13 2.18h-2.14v-9.31Zm-.25 4c.82 0 1.25-.26 1.25-1.24v-.6c0-.83-.28-1.22-1-1.22h-1v3Zm.3 4.42c.76 0 1.11-.4 1.11-1.26v-.83c0-1-.41-1.38-1.31-1.38H131v3.47Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "132.036px 377.518px",
          }}
        />
        <path
          d="M135.16 382.15v-9.31h1v8.37h2.66v.94Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "136.99px 377.495px",
          }}
        />
        <path
          d="M139.56 375.09c0-1.42.71-2.34 2.13-2.34s2.14.92 2.14 2.34v4.82c0 1.41-.7 2.34-2.14 2.34s-2.13-.93-2.13-2.34Zm1 4.87c0 .85.36 1.34 1.1 1.34s1.1-.49 1.1-1.34V375c0-.84-.34-1.35-1.1-1.35s-1.1.51-1.1 1.35Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "141.695px 377.5px",
          }}
        />
        <path
          d="M144.74 375.09c0-1.42.7-2.34 2.13-2.34s2.14.92 2.14 2.34v4.82a2 2 0 0 1-2.14 2.34c-1.43 0-2.13-.93-2.13-2.34Zm1 4.87c0 .85.36 1.34 1.11 1.34s1.1-.49 1.1-1.34V375c0-.84-.35-1.35-1.1-1.35s-1.11.51-1.11 1.35Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "146.89px 377.504px",
          }}
        />
        <path
          d="M152.17 372.84c1.45 0 2.12.88 2.12 2.32v4.69c0 1.43-.67 2.3-2.12 2.3H150v-9.31Zm0 8.37c.75 0 1.11-.47 1.11-1.32v-4.79c0-.85-.36-1.31-1.12-1.31H151v7.42Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "152.145px 377.495px",
          }}
        />
        <path
          d="M158.68 382.15v-8.36H157v-1h4.48v1h-1.72v8.36Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "159.24px 377.47px",
          }}
        />
        <path
          d="m163.8 378.81-2-6h1.08l1.45 4.58 1.47-4.58h1l-2 6v3.34h-1Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "164.3px 377.48px",
          }}
        />
        <path
          d="M169.59 372.84c1.46 0 2.09.87 2.09 2.28v1.11c0 1.48-.69 2.25-2.17 2.25h-.92v3.67h-1v-9.31Zm-.08 4.71c.77 0 1.15-.36 1.15-1.25v-1.24c0-.85-.31-1.27-1.07-1.27h-1v3.76Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "169.635px 377.495px",
          }}
        />
        <path
          d="M175.84 377v.94h-2.27v3.31h2.79v.94h-3.83v-9.31h3.83v1h-2.79V377Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "174.445px 377.535px",
          }}
        />
        <path
          d="M196.88 375.79v1.29h-1v-1.29Zm0 5.07v1.29h-1v-1.29Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "196.38px 378.97px",
          }}
        />
        <path
          d="M128.39 398.59v4.19h-1v-9.31h1v4.19h2.29v-4.19h1v9.31h-1v-4.19Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "129.535px 398.125px",
          }}
        />
        <path
          d="M132.71 395.72c0-1.43.7-2.34 2.13-2.34s2.14.91 2.14 2.34v4.81c0 1.41-.71 2.35-2.14 2.35s-2.13-.94-2.13-2.35Zm1 4.87c0 .85.36 1.34 1.11 1.34s1.1-.49 1.1-1.34v-4.93c0-.83-.35-1.34-1.1-1.34s-1.11.51-1.11 1.34Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "134.845px 398.13px",
          }}
        />
        <path
          d="M141.39 402.77h-1l-1.55-7.41v7.42H138v-9.31h1.45l1.53 7.44 1.48-7.44h1.45v9.31h-1v-7.49Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "140.955px 398.125px",
          }}
        />
        <path
          d="M148.23 397.59v.93H146v3.32h2.79v.94h-3.83v-9.31h3.83v.94H146v3.18Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "146.875px 398.125px",
          }}
        />
        <path
          d="m152.82 400.91-.36 1.87h-1l1.78-9.31h1.5l1.79 9.31h-1l-.36-1.87Zm.14-.9H155l-1-5.37Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "153.995px 398.125px",
          }}
        />
        <path
          d="M159.57 393.47c1.45 0 2.11.88 2.11 2.31v4.7c0 1.42-.66 2.3-2.11 2.3h-2.18v-9.31Zm0 8.37c.74 0 1.1-.47 1.1-1.32v-4.79c0-.85-.36-1.32-1.12-1.32h-1.12v7.43Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "159.535px 398.125px",
          }}
        />
        <path
          d="M164.73 393.47c1.47 0 2.08.74 2.08 2.13v.76c0 1-.36 1.66-1.16 1.91.85.25 1.17 1 1.17 1.94v1.45a2.25 2.25 0 0 0 .19 1.12h-1a2.38 2.38 0 0 1-.18-1.13v-1.46c0-1.05-.49-1.39-1.35-1.39h-.74v4h-1v-9.31Zm-.26 4.39c.82 0 1.31-.27 1.31-1.28v-.89c0-.84-.3-1.28-1.07-1.28h-1v3.45Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "164.875px 398.135px",
          }}
        />
        <path
          d="M171.21 397.59v.93h-2.27v3.32h2.79v.94h-3.83v-9.31h3.83v.94h-2.79v3.18Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "169.815px 398.125px",
          }}
        />
        <path
          d="M176.72 395.7v.3h-1v-.31c0-.84-.31-1.33-1.06-1.33s-1.06.49-1.06 1.32c0 2.09 3.12 2.29 3.12 4.91 0 1.41-.68 2.33-2.1 2.33s-2.09-.92-2.09-2.33V400h1v.59c0 .84.35 1.33 1.09 1.33s1.1-.49 1.1-1.33c0-2.08-3.12-2.27-3.12-4.9 0-1.43.67-2.32 2.06-2.32s2.06.92 2.06 2.33Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "174.625px 398.145px",
          }}
        />
        <path
          d="M181.67 395.7v.3h-1v-.31c0-.84-.31-1.33-1.06-1.33s-1.06.49-1.06 1.32c0 2.09 3.12 2.29 3.12 4.91 0 1.41-.68 2.33-2.1 2.33s-2.09-.92-2.09-2.33V400h1v.59c0 .84.35 1.33 1.09 1.33s1.09-.49 1.09-1.33c0-2.08-3.11-2.27-3.11-4.9 0-1.43.67-2.32 2.06-2.32s2.06.92 2.06 2.33Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "179.575px 398.145px",
          }}
        />
        <path
          d="M193.9 396.42v1.29h-1v-1.29Zm0 5.07v1.29h-1v-1.29Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "193.4px 399.6px",
          }}
        />
        <path
          d="M126.07 414.86c1.46 0 2.09.86 2.09 2.27v1.12c0 1.48-.7 2.25-2.17 2.25h-.92v3.67h-1v-9.31Zm-.08 4.71c.77 0 1.14-.36 1.14-1.25v-1.24c0-.85-.31-1.28-1.06-1.28h-1v3.77Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "126.115px 419.515px",
          }}
        />
        <path
          d="M130 420v4.19h-1v-9.31h1v4.19h2.29v-4.19h1v9.31h-1V420Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "131.145px 419.535px",
          }}
        />
        <path
          d="M134.32 417.11c0-1.43.7-2.35 2.12-2.35s2.15.92 2.15 2.35v4.81a2 2 0 0 1-2.15 2.34c-1.42 0-2.12-.93-2.12-2.34Zm1 4.87c0 .85.36 1.34 1.1 1.34s1.11-.49 1.11-1.34v-4.93c0-.84-.35-1.34-1.11-1.34s-1.1.5-1.1 1.34Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "136.47px 419.514px",
          }}
        />
        <path
          d="M140.49 424.17h-.93v-9.31h1.32l2.15 6.73v-6.73h.97v9.31h-1.08l-2.38-7.53Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "141.78px 419.515px",
          }}
        />
        <path
          d="M148.33 419v.93h-2.28v3.32h2.79v.94H145v-9.31h3.83v.94h-2.79V419Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "146.92px 419.535px",
          }}
        />
        <path
          d="M152.78 424.17h-.93v-9.31h1.32l2.15 6.73v-6.73h.92v9.31h-1.08l-2.38-7.53Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "154.045px 419.515px",
          }}
        />
        <path
          d="M158.29 422c0 .83.33 1.34 1.09 1.34s1.08-.51 1.08-1.34v-7.12h1v7.06c0 1.41-.65 2.36-2.09 2.36s-2.07-.95-2.07-2.36v-7.06h1Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "159.38px 419.59px",
          }}
        />
        <path
          d="M165.89 424.16h-1l-1.54-7.41v7.42h-.91v-9.31h1.45l1.53 7.44 1.48-7.44h1.45v9.31h-1v-7.49Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "165.395px 419.515px",
          }}
        />
        <path
          d="M171.5 414.86c1.45 0 2 .69 2 2.06v.44c0 1-.28 1.6-1.09 1.85.9.25 1.25 1 1.25 2v.79a1.91 1.91 0 0 1-2.13 2.18h-2.15v-9.31Zm-.25 3.95c.83 0 1.25-.27 1.25-1.24V417c0-.83-.28-1.23-1-1.23h-1v3Zm.31 4.42c.76 0 1.1-.4 1.1-1.27v-.82c0-1-.41-1.39-1.3-1.39h-.91v3.48Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "171.531px 419.528px",
          }}
        />
        <path
          d="M177.94 419v.93h-2.27v3.32h2.79v.94h-3.83v-9.31h3.83v.94h-2.79V419Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "176.545px 419.535px",
          }}
        />
        <path
          d="M181.5 414.86c1.46 0 2.07.74 2.07 2.13v.76c0 1-.36 1.66-1.16 1.91.86.25 1.17.95 1.17 1.94v1.45a2.36 2.36 0 0 0 .19 1.12h-1.05a2.41 2.41 0 0 1-.17-1.13v-1.46c0-1.05-.5-1.39-1.36-1.39h-.73v4h-1v-9.31Zm-.27 4.39c.82 0 1.32-.27 1.32-1.28v-.89c0-.84-.31-1.28-1.08-1.28h-1v3.45Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "181.615px 419.525px",
          }}
        />
        <path
          d="M191.85 417.81v1.29h-1v-1.29Zm0 5.07v1.29h-1v-1.29Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "191.35px 420.99px",
          }}
        />
      </g>
    </g>
    <g
      className="animable"
      style={{
        transformOrigin: "296.835px 219.77px",
      }}
    >
      <path
        d="M269.76 185.09h54.15v69.1h-54.15z"
        className="animable"
        style={{
          fill: "#bfbfbf",
          transformOrigin: "296.835px 219.64px",
        }}
      />
      <path
        d="M320.32 254.43h-47l1-5.68v-1.17a31.36 31.36 0 0 1 3-13.34 1.09 1.09 0 0 1 0-.12l11.79-3.2a8.83 8.83 0 0 1 .93-2.83 7.6 7.6 0 0 1 2.13-1v-2.15a20.59 20.59 0 0 1-2.09-4.57 1 1 0 0 1-.42-.09 2.48 2.48 0 0 1-.69-.47 8 8 0 0 1-1.4-1.44 3 3 0 0 1-.6-1.89 5.05 5.05 0 0 1 .39-1.91 2.61 2.61 0 0 1 1.05-.81 12.7 12.7 0 0 0 0-2.36c-1.46-13.63 17.9-17.34 18.14.49 0 .67.05 1.52.05 1.52a1.69 1.69 0 0 1 .93 1 17.45 17.45 0 0 1 .3 2.05 3.06 3.06 0 0 1-.6 1.89 8.35 8.35 0 0 1-1.41 1.44 2.48 2.48 0 0 1-.69.47.92.92 0 0 1-.54.07 19.85 19.85 0 0 1-2.5 5.19v2c1.41.41 2.74 1.18 2.82 2.53a5.56 5.56 0 0 0 .08.62c4.4 1.05 11.25 3 11.25 3l.09.47a31.15 31.15 0 0 1 3 13.38l1 6.93Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "296.825px 227.133px",
        }}
      />
    </g>
    <g
      className="animable"
      style={{
        transformOrigin: "387.41px 263.285px",
      }}
    >
      <path
        d="M369.96 171.14h-10.58"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "364.67px 171.14px",
        }}
      />
      <path
        d="M369.61 189.32h-10.23"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "364.495px 189.32px",
        }}
      />
      <path
        d="M369.25 207.5h-9.87"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "364.315px 207.5px",
        }}
      />
      <path
        d="M368.9 225.68h-9.52"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "364.14px 225.68px",
        }}
      />
      <path
        d="M368.55 243.86h-9.17"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "363.965px 243.86px",
        }}
      />
      <path
        d="M368.19 262.04h-8.81"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "363.785px 262.04px",
        }}
      />
      <path
        d="M367.84 280.22h-8.46"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "363.61px 280.22px",
        }}
      />
      <path
        d="M367.49 298.4h-8.11"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "363.435px 298.4px",
        }}
      />
      <path
        d="M367.13 316.58h-7.75"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "363.255px 316.58px",
        }}
      />
      <path
        d="M366.78 334.76h-7.4"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "363.08px 334.76px",
        }}
      />
      <path
        d="M366.43 352.94h-7.05"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "362.905px 352.94px",
        }}
      />
      <path
        d="M366.07 371.12h-6.69"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "362.725px 371.12px",
        }}
      />
      <path
        d="M365.72 389.3h-6.34"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "362.55px 389.3px",
        }}
      />
      <path
        d="M365.37 407.48h-5.99"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "362.375px 407.48px",
        }}
      />
      <path
        d="M365.01 425.66h-5.63"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "362.195px 425.66px",
        }}
      />
      <path
        d="M364.66 443.84h-5.28"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "362.02px 443.84px",
        }}
      />
      <path
        d="M364.31 462.02h-4.93"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "361.845px 462.02px",
        }}
      />
      <path
        d="M359.26 171.14v290.88"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "359.26px 316.58px",
        }}
      />
      <path
        d="M426.75 146.58c-2.39 3-5.88 6-9.19 9.08l-2.26 2h-.05l-2.37-2a75.9 75.9 0 0 1-8.51-8.21"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "415.56px 152.12px",
        }}
      />
      <path
        d="m427.31 144.16-4 .17-1.14 2.32-2.72-7.35-1.72 7.38L416 143l-2.15 6.67-3.62-14.35-2.08 9.64-4.42.2c-2.89-3.76-4-7.54-.61-11.08 4.19-4.45 9.45-.72 12.14 1.74 2.6-2.51 8.34-7.65 13.19-1.51 2.47 3.04 1.24 6.57-1.14 9.85Z"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "415.415px 140.541px",
        }}
      />
      <path
        d="M352.52 100.34a9.09 9.09 0 0 0-9.09 9.08c0 5 9.09 18.25 9.09 18.25s9.08-13.22 9.08-18.25a9.09 9.09 0 0 0-9.08-9.08Zm0 13.56a4.14 4.14 0 1 1 4.13-4.14 4.14 4.14 0 0 1-4.13 4.14Z"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "352.515px 114.005px",
        }}
      />
      <path
        d="M422.3 115.2a9.1 9.1 0 0 0 9.09-9.09c0-5-9.09-18.25-9.09-18.25s-9.09 13.23-9.09 18.25a9.09 9.09 0 0 0 9.09 9.09Z"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "422.3px 101.53px",
        }}
      />
      <path
        d="M422.3 98.91v6.55"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "422.3px 102.185px",
        }}
      />
      <path
        d="M419.02 102.19h6.55"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "422.295px 102.19px",
        }}
      />
      <path
        d="M419.02 108.73h6.55"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "422.295px 108.73px",
        }}
      />
      <path
        d="M383 148.91c0 2.94-2 5.52-6.2 6.31a16.23 16.23 0 0 1-4.39.16 16.38 16.38 0 0 1-5.26-1.35 2 2 0 0 1-1-2.66h0a2 2 0 0 1 2.71-1 12.92 12.92 0 0 0 3.52 1 10.28 10.28 0 0 0 1.65.13 6.94 6.94 0 0 0 2.74-.45 1.85 1.85 0 0 0 0-3.55 24.1 24.1 0 0 0-4.39-1.25c-3.2-.79-6.55-2-6.55-6.1 0-3.08 2.14-5.72 6.55-6.4a14.47 14.47 0 0 1 2.35-.18 17.46 17.46 0 0 1 2 .13 14.67 14.67 0 0 1 3.44.83 2 2 0 0 1 1.15 2.65h0a2 2 0 0 1-2.64 1.1 12 12 0 0 0-1.95-.57 9.83 9.83 0 0 0-2.07-.21 7.31 7.31 0 0 0-2.32.32 2.18 2.18 0 0 0-1.69 2.09c0 .91.67 1.45 1.69 1.86a42.1 42.1 0 0 0 4.39 1.15c3.17.83 6.27 2.08 6.27 5.99Z"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "374.415px 144.515px",
        }}
      />
      <path
        d="M372.42 131.58v-2.56h4.39v2.51"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "374.615px 130.3px",
        }}
      />
      <path
        d="M376.81 157.43v2.38h-4.39v-2.21"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "374.615px 158.62px",
        }}
      />
      <path
        d="m383.91 64.55-14 14h5.25V91.7h6.05v-8.62h5.29v8.62h6.05V78.5h5.26Z"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "383.86px 78.125px",
        }}
      />
    </g>
    <g
      className="animable"
      style={{
        transformOrigin: "346.74px 300.88px",
      }}
    >
      <path
        d="M301.4 240.48h90.68v120.75H301.4z"
        className="animable"
        style={{
          fill: "#bfbfbf",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "346.74px 300.855px",
        }}
      />
      <path
        d="M316 360.89h-14.11v0l1.89-10.9v-2.29a59.91 59.91 0 0 1 5.75-25.59v-.08l6.47 38.82Z"
        className="animable"
        style={{
          fill: "#e39292",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "308.945px 341.46px",
        }}
      />
      <path
        d="M392 360.89h-14.15v0l5-30.21 1.45-8.76a59.75 59.75 0 0 1 5.78 25.67l1.93 13.3Z"
        className="animable"
        style={{
          fill: "#e39292",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "384.93px 341.405px",
        }}
      />
      <path
        d="M384.14 321s-.57 4.19-1.28 9.66c-1.05 8-2.4 18.74-2.75 23.2-.11 1.44-.28 3.92-.48 7h-64.52c-2.08-15.65-5.2-36.32-5.56-38.74v-.23l22.62-6.14 3.08-.84a8.77 8.77 0 0 1 2.81-1.14 22.7 22.7 0 0 1 3.88-.57h.62a64.06 64.06 0 0 1 9.19.27c2 .18 3.83.42 5.28.63h.07c1.24.19 3.19.62 5.5 1.17 8.4 1.98 21.54 5.73 21.54 5.73Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "346.845px 337.001px",
        }}
      />
      <g
        className="animable"
        style={{
          clipPath: "url(#a)",
          transformOrigin: "347.17px 324.355px",
        }}
      >
        <path
          d="m331.22 332.28 9.53-5.31c.93-.52 2.13-1 3-.37s.69 1.91 1.24 2.78a3 3 0 0 0 3.35.93c1.21-.32 2.27-3 3.45-3.42 3.88-1.27 7.93 3.11 11.33 5.38q-.81-7.92-1.61-15.84c0 .27-22-.12-24 .32-6.51 1.47-5.51 10.14-6.29 15.53Z"
          className="animable"
          style={{
            opacity: 0.2,
            transformOrigin: "347.17px 324.355px",
          }}
        />
      </g>
      <path
        d="M384.14 321s-.57 4.19-1.28 9.66c-1.05 8-2.4 18.74-2.75 23.2-.11 1.44-.28 3.92-.48 7v0h-64.52a.06.06 0 0 0 0 0c-2.08-15.65-5.2-36.32-5.56-38.74v-.23l22.62-6.14 3.08-.84a8.77 8.77 0 0 1 2.81-1.14 22.7 22.7 0 0 1 3.88-.57h.62a64.06 64.06 0 0 1 9.19.27c2 .18 3.83.42 5.28.63h.07c1.24.19 3.19.62 5.5 1.17 8.4 1.98 21.54 5.73 21.54 5.73Z"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "346.845px 337.001px",
        }}
      />
      <path
        d="M340.3 307.64s-5.33 1.6-6.39 2.66-1.86 6.7-2.94 9.59-4.52 8-3.45 8.25 17.4-5.11 17.4-5.11l3.1 12.62L350 323s15.57 6.19 16.09 5.38S362.66 318 362.4 314s-6.13-5.33-9.59-5.58-12.51-.78-12.51-.78Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "346.727px 321.645px",
        }}
      />
      <path
        d="M338.01 301.19v15.57l9.62 4.89.09 12.98-.09-12.98 9.35-5.11v-16.26l-18.97.91z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "347.495px 317.455px",
        }}
      />
      <path
        d="M329.12 285.1A6.62 6.62 0 0 0 328 288a5.71 5.71 0 0 0 1.14 3.63 15.39 15.39 0 0 0 2.7 2.77 5.14 5.14 0 0 0 1.32.92 1.55 1.55 0 0 0 2.26-1.61A6.44 6.44 0 0 0 335 292c-.8-2.38-.55-4.93-1.23-7.23-.77-2.64-3.53-1.5-4.65.33Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "331.718px 289.359px",
        }}
      />
      <path
        d="M366.89 285.1A6.52 6.52 0 0 1 368 288a5.66 5.66 0 0 1-1.15 3.63 15.67 15.67 0 0 1-2.69 2.77 5 5 0 0 1-1.33.92 1.75 1.75 0 0 1-1.56-.06 1.78 1.78 0 0 1-.7-1.55A6.1 6.1 0 0 1 361 292c.79-2.38.54-4.93 1.22-7.23.78-2.64 3.55-1.5 4.67.33Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "364.284px 289.345px",
        }}
      />
      <path
        d="M338 304.26s2.93 11.11 8.81 11.35 10.19-10.2 10.19-10.2-6.08-4.41-19-1.15Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "347.5px 309.337px",
        }}
      />
      <path
        d="M364 282.3c0 12.29-7.23 29.23-16.14 29.23s-16.15-16.94-16.15-29.23 7.23-22.24 16.15-22.24S364 270 364 282.3Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "347.855px 285.795px",
        }}
      />
      <path
        d="M342 284.38c.5 0 .9 1 .9 2.31s-.4 2.32-.9 2.32-.91-1-.91-2.32.37-2.31.91-2.31Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "341.995px 286.695px",
        }}
      />
      <path
        d="M353.24 284.38c.5 0 .9 1 .9 2.31s-.4 2.32-.9 2.32-.91-1-.91-2.32.41-2.31.91-2.31Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "353.235px 286.695px",
        }}
      />
      <path
        d="m349.51 283.22-.59 1.19s.4 6.24.57 7.59-.82 2.87-2.29 2.07l-1.48-.8"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "347.615px 288.756px",
        }}
      />
      <path
        d="M343.37 299.3c0 1.23 9 1.23 9 0"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "347.87px 299.761px",
        }}
      />
      <path
        d="m332.49 290.59 1.89.37a29.19 29.19 0 0 1-1.3-6.14c-.17-1.77 2.37-4.42 2.37-5.66 0-6.73 3.66-6.54 4.41-5.55.1-1.23 5.86-7.17 10.16 1.06 1.1 2.1 12.8 4 12.76 5.86a62.67 62.67 0 0 1-1 9.94c.95.18 1.79-.81 1.91-1.78s.36-4.71 1.17-5.25a1.88 1.88 0 0 0 2 1 9.56 9.56 0 0 1-.88-3.72c.06-1.29.73-.44 2-.8.65-.19 6.12-3.88 6.8-5.85-2.48.31-4.82-1.39-6.24-3.45s-2.19-4.48-3.39-6.67c-3-5.46-9-9.34-15.21-9.1s-12.17 5-13.11 11.16a3.55 3.55 0 0 0-3.66.6 10.58 10.58 0 0 0-2.36 3.09 20.46 20.46 0 0 0-2.81 6.45c-.43 2.31 1.92 5.57 3.71 7.1.29-.54.13 7.34.78 7.34Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "351.364px 272.9px",
        }}
      />
      <path
        d="M333.35 269.38a7.69 7.69 0 0 0-2.73 3.72"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "331.985px 271.24px",
        }}
      />
      <path
        d="M333.53 271.61a6.4 6.4 0 0 0-1.15 1.58"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "332.955px 272.4px",
        }}
      />
      <path
        d="M334.64 272.89a3.64 3.64 0 0 0-.91 1.52"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "334.185px 273.65px",
        }}
      />
      <path
        d="M347.34 267.34a3.31 3.31 0 0 1 2.77 2.4"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "348.725px 268.54px",
        }}
      />
      <path
        d="M347.48 264.72a6.29 6.29 0 0 1 4.75 4.51"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "349.855px 266.975px",
        }}
      />
      <path
        d="M352.36 264.83a4.89 4.89 0 0 1 1.58 3.43"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "353.15px 266.545px",
        }}
      />
      <path
        d="M355.13 265.38a3.37 3.37 0 0 1 .75 2.24"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "355.506px 266.5px",
        }}
      />
      <path
        d="M357.16 265.92a1.78 1.78 0 0 1 .43 1.38"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "357.381px 266.61px",
        }}
      />
      <path
        d="M359.3 266.13c.07.25.14.49.22.74"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "359.41px 266.5px",
        }}
      />
      <path
        d="M337.19 282.85c.63-.53 1.05-1.27 1.72-1.75a3.8 3.8 0 0 1 3.09-.43 11 11 0 0 1 2.92 1.34"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "341.055px 281.684px",
        }}
      />
      <path
        d="M351.13 282a11 11 0 0 1 2.92-1.34 3.8 3.8 0 0 1 3.09.43c.66.48 1.09 1.22 1.72 1.75"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "354.995px 281.674px",
        }}
      />
      <path
        d="M341.58 310.92a15.44 15.44 0 0 0 3.9 9.39"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "343.53px 315.615px",
        }}
      />
      <path
        d="M341.6 273.46a9.91 9.91 0 0 1 1.49-2.53 3.12 3.12 0 0 1 1.05-.9 1.29 1.29 0 0 1 1.33.09"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "343.535px 271.673px",
        }}
      />
      <path
        d="M343.43 273.86a5.71 5.71 0 0 1 4.58-3.05"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "345.72px 272.335px",
        }}
      />
      <path
        d="M345.44 274c.62-.83 5.47-3.52 6.58.63"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "348.73px 273.503px",
        }}
      />
      <path
        d="M338.07 274.87a5.39 5.39 0 0 0-4.45 1.91"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "335.845px 275.82px",
        }}
      />
      <path
        d="M336.68 276.66a5.44 5.44 0 0 0-3.85 3.62"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "334.755px 278.47px",
        }}
      />
      <path
        d="M304 349.86a10.37 10.37 0 0 0 1.83 6.22"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "304.912px 352.97px",
        }}
      />
      <path
        d="M314 341.09a129.49 129.49 0 0 1 1.14 19.68"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "314.581px 350.93px",
        }}
      />
      <path
        d="M384.3 336.43q-2.13 8.85-4.27 17.71"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "382.165px 345.285px",
        }}
      />
      <path
        d="M388 350.67a32.38 32.38 0 0 1-5.86 10.61"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeMiterlimit: 10,
          transformOrigin: "385.07px 355.975px",
        }}
      />
      <path
        d="M308.57 337.43a41.25 41.25 0 0 0 5.39 16.4"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeMiterlimit: 10,
          transformOrigin: "311.265px 345.63px",
        }}
      />
      <path
        d="m347.72 334.63-.38 16.3h3.79l.25-27.69"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeMiterlimit: 10,
          transformOrigin: "349.36px 337.085px",
        }}
      />
      <circle
        cx={349.27}
        cy={347.89}
        r={0.92}
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeMiterlimit: 10,
          transformOrigin: "349.27px 347.89px",
        }}
      />
      <path
        d="M350.38 336.29a.92.92 0 1 1-.92-.93.92.92 0 0 1 .92.93Z"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeMiterlimit: 10,
          transformOrigin: "349.46px 336.28px",
        }}
      />
    </g>
    <g
      className="animable"
      style={{
        transformOrigin: "414.44px 317.185px",
      }}
    >
      <path
        d="M381.7 317.87c-.09.32 2.46 11.13 2.46 11.13s1.69.72 1.95-1.59c.07-.62-.25-3.06-.21-4.18.11-3.26.91-6.07-2.07-6.42 0 0-1.75-.25-2.13 1.06Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "383.909px 322.949px",
        }}
      />
      <path
        d="M382.5 321.53c-.54-.06.74 2.07.81 6.66a8.36 8.36 0 0 0 1.08 3.7s1.33.59 1.29-1.73c0-1.45-.6-5.26-.67-5.85-.5-3.95-.46-3.41-.39-3a8.24 8.24 0 0 1-2.12.22Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "384.024px 326.492px",
        }}
      />
      <path
        d="M380.37 322.6a90.28 90.28 0 0 0 1.6 9.7c.53 2.74 3 1.33 2.86-1.42a40.43 40.43 0 0 1-.61-5.1 10.93 10.93 0 0 0-.72-4.45s-2.75-.05-3.13 1.27Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "382.603px 327.555px",
        }}
      />
      <path
        d="M390.23 274.78c-.64 12.8-1.94 28.33-2.06 29.86l-.25 4.81a14.82 14.82 0 0 1 .78 4.56 7.66 7.66 0 0 1-.64 3.22 15.36 15.36 0 0 1-1.53 2.29c-.62 3.55-.85 4.46-3.24 4.29-.12 0-.15 3.7-.15 3.7a10.55 10.55 0 0 1 .25 4.35 1.68 1.68 0 0 1-1.6 1.28s-.78-.7-1.55-4.63c-.1-.47-.51-3.76-.53-4.28a32.41 32.41 0 0 1 .45-6.25 5.06 5.06 0 0 1 .08-.52s1.38-7.36 1.51-7.46l-1-23.57a178.4 178.4 0 0 1 .75-18.49c.3-3.12-.62-6.84-.63-13 0-5.46 1.71-15.51 2-19.39.07-.94 3.15-.88 3.23-1.05l5.29 1.77s-.48 25.2-1.16 38.51Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "385.545px 283.82px",
        }}
      />
      <path
        d="M384.22 315.33c-.81 2.54-.67 4.48-1.32 6.8-.14.5.19 1.5.65 1.59"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "383.544px 319.525px",
        }}
      />
      <path
        d="m383.08 315.33.33 2.36"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "383.245px 316.51px",
        }}
      />
      <path
        d="M448.07 319c.09.31-2.45 11.13-2.45 11.13s-1.69.71-1.95-1.6c-.07-.62.24-3.05.21-4.18-.12-3.25-.91-6.06 2.06-6.41 0-.02 1.75-.27 2.13 1.06Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "445.866px 324.071px",
        }}
      />
      <path
        d="M447.27 322.64c.55-.06-.74 2.06-.8 6.66a8.29 8.29 0 0 1-1.08 3.69s-1.34.6-1.3-1.72c0-1.45.6-5.27.68-5.85.5-3.95.45-3.41.38-3a8.62 8.62 0 0 0 2.12.22Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "445.748px 327.599px",
        }}
      />
      <path
        d="M449.4 323.7a88.55 88.55 0 0 1-1.59 9.71c-.53 2.73-3.06 1.33-2.86-1.42a42.62 42.62 0 0 0 .61-5.11 10.85 10.85 0 0 1 .71-4.45s2.73-.04 3.13 1.27Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "447.169px 328.658px",
        }}
      />
      <path
        d="M439.54 275.89c.65 12.79 1.94 28.33 2.07 29.86l.24 4.81a15 15 0 0 0-.77 4.56 7.77 7.77 0 0 0 .63 3.21 17.55 17.55 0 0 0 1.53 2.3c.63 3.54.85 4.46 3.24 4.29a35.1 35.1 0 0 1 .15 3.7 10.73 10.73 0 0 0-.25 4.35 1.68 1.68 0 0 0 1.6 1.27s.79-.69 1.56-4.63c.09-.46.5-3.75.52-4.27a31 31 0 0 0-.45-6.26 4.79 4.79 0 0 0-.07-.51s-1.38-7.36-1.52-7.46l1-23.57a181.1 181.1 0 0 0-.75-18.49c-.31-3.12.61-6.85.62-13 0-5.46-1.7-15.51-2-19.4-.07-.93-3.15-.88-3.22-1l-5.29 1.76s.49 25.17 1.16 38.48Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "444.227px 284.945px",
        }}
      />
      <path
        d="M445.55 316.43c.82 2.54.67 4.48 1.32 6.8.15.51-.19 1.5-.65 1.59"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "446.228px 320.625px",
        }}
      />
      <path
        d="M446.69 316.43c-.1.79-.21 1.58-.32 2.37"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "446.53px 317.615px",
        }}
      />
      <path
        d="M401 447.6a21.49 21.49 0 0 1-6.54 8.53 10.65 10.65 0 0 0-3.18 3.78 2.39 2.39 0 0 0 2.22 3.31h16.22a2.37 2.37 0 0 0 2.28-1.52c.52-1.41.85-3.87-.36-7.73l-1.58-8.13Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: ".75px",
          transformOrigin: "401.782px 454.53px",
        }}
      />
      <path
        d="M412 461.7c.52-1.41.85-3.87-.36-7.73l-.51-2.61-1.13 1.52s-1.16-1.86-5.29-1.86-5.78 1.86-5.78 1.86l-.45-.89a23.6 23.6 0 0 1-4 4.14 10.65 10.65 0 0 0-3.18 3.78 2.39 2.39 0 0 0 2.22 3.31h16.22a2.37 2.37 0 0 0 2.26-1.52Z"
        className="animable"
        style={{
          fill: "#263238",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: ".75px",
          transformOrigin: "401.792px 457.12px",
        }}
      />
      <path
        d="M391.14 460.61a2.41 2.41 0 0 0 2.4 2.61h16.22a2.37 2.37 0 0 0 2.24-1.52 6.54 6.54 0 0 0 .3-1.09Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: ".75px",
          transformOrigin: "401.716px 461.915px",
        }}
      />
      <path
        d="M416.43 302.56 415 383l-1.18 64.71h-16.28l-8.65-120 2.11-30.05a29.88 29.88 0 0 1 24.23 4c.77.54 1.2.9 1.2.9Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "402.66px 372.183px",
        }}
      />
      <path
        d="M428.88 447.6a21.49 21.49 0 0 0 6.54 8.53 10.65 10.65 0 0 1 3.18 3.78 2.39 2.39 0 0 1-2.22 3.31h-16.22a2.38 2.38 0 0 1-2.24-1.52c-.51-1.41-.84-3.87.37-7.73l1.58-8.13Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: ".75px",
          transformOrigin: "428.122px 454.53px",
        }}
      />
      <path
        d="M417.92 461.7c-.51-1.41-.84-3.87.37-7.73l.51-2.61 1.09 1.52s1.16-1.86 5.29-1.86 5.77 1.86 5.77 1.86l.46-.89a23.6 23.6 0 0 0 4 4.14 10.65 10.65 0 0 1 3.18 3.78 2.39 2.39 0 0 1-2.22 3.31h-16.21a2.38 2.38 0 0 1-2.24-1.52Z"
        className="animable"
        style={{
          fill: "#263238",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: ".75px",
          transformOrigin: "428.117px 457.12px",
        }}
      />
      <path
        d="M438.78 460.61a2.41 2.41 0 0 1-2.4 2.61h-16.22a2.38 2.38 0 0 1-2.24-1.52 7.74 7.74 0 0 1-.29-1.09Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: ".75px",
          transformOrigin: "428.209px 461.915px",
        }}
      />
      <path
        d="m441 327.74-8.66 120h-16.19L415 383l1.47-80.48s-.43-.36-1.23-.9a35.69 35.69 0 0 1 24-1.9Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "428px 373.107px",
        }}
      />
      <path
        d="M390.28 253.56h-11.63l1.47-8.47v-1.75c0-6.88-.23-14.31 5.31-19.89.18 1.18 3.34 10.02 4.85 30.11Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "384.465px 238.505px",
        }}
      />
      <g
        className="animable"
        style={{
          clipPath: "url(#b)",
          transformOrigin: "383.06px 244.435px",
        }}
      >
        <path
          d="m387.57 248.82-3.63-1.89c1.15 2.11 2.29 4.23 3.43 6.34l-8.82.55q.79-4.68 1.6-9.37l1.72 3c-1.53-2.66-3.22-10.29-.36-12.4 2.87 3.32 4.33 9.58 6.06 13.77Z"
          className="animable"
          style={{
            opacity: 0.2,
            transformOrigin: "383.06px 244.435px",
          }}
        />
      </g>
      <path
        d="M390.28 253.56h-11.63l1.47-8.47v-1.75c0-6.88-.23-14.31 5.31-19.89.18 1.18 3.34 10.02 4.85 30.11Z"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "384.465px 238.505px",
        }}
      />
      <path
        d="m438.4 252.78 3.89-23.47 1.13-6.82c6 6.55 5.32 16.5 5.32 19.95l1.59 10.34Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "444.365px 237.635px",
        }}
      />
      <g
        className="animable"
        style={{
          clipPath: "url(#c)",
          transformOrigin: "442.813px 243.54px",
        }}
      >
        <path
          d="M441.7 238.66c.65-1.45 1.29-2.91 1.93-4.36l-1.86 7.53a3.2 3.2 0 0 0 0 2.26 2.14 2.14 0 0 0 2.48.79 6.22 6.22 0 0 0 2.32-1.56 7.89 7.89 0 0 0-2.07 8.56 3.27 3.27 0 0 1-5.28-1.13 6.28 6.28 0 0 1 0-2.86c.67-4.21 1.13-8.57 2.05-12.72.14.76.73 2.83.43 3.49Z"
          className="animable"
          style={{
            opacity: 0.2,
            transformOrigin: "442.813px 243.54px",
          }}
        />
      </g>
      <path
        d="M438.4 252.78v0l3.89-23.47 1.13-6.82c6 6.55 5.32 16.5 5.32 19.95l1.59 10.34Z"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "444.365px 237.635px",
        }}
      />
      <path
        d="M440.07 242.74c-2.52 23.4-.79 56.4-.79 56.4-19.35 8.76-48.82-.4-48.83-.41a354.43 354.43 0 0 0-.17-45.17c-1.51-20.09-4.67-28.93-4.85-30.14v-.17l17.57-4.77 2.38-.66a7 7 0 0 1 2.19-.88 17.35 17.35 0 0 1 3-.45h.49a50.21 50.21 0 0 1 7.15.21c1.54.14 3 .32 4.1.49h.05c1 .14 2.48.48 4.27.9 6.56 1.58 16.79 4.48 16.79 4.48s-2.98 16.72-3.35 20.17Z"
        className="animable"
        style={{
          fill: "#e39292",
          transformOrigin: "414.425px 259.689px",
        }}
      />
      <g
        className="animable"
        style={{
          clipPath: "url(#d)",
          transformOrigin: "410.848px 275.389px",
        }}
      >
        <path
          d="m390.5 248.87 11.76 17.69-4.14-11a67.77 67.77 0 0 0 30.77 29.67 25.28 25.28 0 0 1-20.58-5.8 25.91 25.91 0 0 0 19.59 11.21 41.76 41.76 0 0 1-25.14 2.39 47.75 47.75 0 0 0 22 8.26 119.52 119.52 0 0 1-22.38.18c-4.7-.41-10.18-1.67-12-6a13.9 13.9 0 0 1-.7-5.75l.76-39.86c-.13.58-.27 1.16-.4 1.73"
          className="animable"
          style={{
            opacity: 0.2,
            transformOrigin: "409.258px 275.389px",
          }}
        />
        <path
          d="M432.07 274c-7.88-2.24-16.63-7.26-23.18-11.13 2.61 2.25 6.52 6.94 9.83 8.38 3.79 1.63 9.5 2.08 13.35 2.75Z"
          className="animable"
          style={{
            opacity: 0.2,
            transformOrigin: "420.48px 268.435px",
          }}
        />
      </g>
      <path
        d="M440.07 242.74c-2.52 23.4-.79 56.4-.79 56.4-19.35 8.76-48.82-.4-48.83-.41a354.43 354.43 0 0 0-.17-45.17c-1.51-20.09-4.67-28.93-4.85-30.14v-.17l17.57-4.77 2.38-.66a7 7 0 0 1 2.19-.88 17.35 17.35 0 0 1 3-.45h.49a50.21 50.21 0 0 1 7.15.21c1.54.14 3 .32 4.1.49h.05c1 .14 2.48.48 4.27.9 6.56 1.58 16.79 4.48 16.79 4.48s-2.98 16.72-3.35 20.17Z"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "414.425px 259.689px",
        }}
      />
      <path
        d="M429.33 219a17.49 17.49 0 0 1-28.22.32l-.24-.32 6.68-2.11v-9.77l14.75-.71V217l.34-.07Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "415.1px 216.364px",
        }}
      />
      <path
        d="M400.64 194.66a5.19 5.19 0 0 0-.85 2.27 4.42 4.42 0 0 0 .89 2.81 12.27 12.27 0 0 0 2.1 2.16 4 4 0 0 0 1 .71 1.2 1.2 0 0 0 1.75-1.25 4.52 4.52 0 0 0-.32-1.34c-.62-1.84-.42-3.83-.95-5.61-.58-2.05-2.74-1.17-3.62.25Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "402.667px 197.976px",
        }}
      />
      <path
        d="M430 194.66a5.07 5.07 0 0 1 .85 2.27 4.36 4.36 0 0 1-.89 2.81 12.27 12.27 0 0 1-2.1 2.16 3.86 3.86 0 0 1-1 .71 1.32 1.32 0 0 1-1.21-.05 1.38 1.38 0 0 1-.54-1.2 4.85 4.85 0 0 1 .32-1.34c.62-1.84.43-3.83 1-5.61.57-2.05 2.7-1.17 3.57.25Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "427.98px 197.967px",
        }}
      />
      <path
        d="M407.55 209.55s2.28 8.63 6.85 8.82 7.9-7.93 7.9-7.93-4.72-3.44-14.75-.89Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "414.925px 213.491px",
        }}
      />
      <path
        d="M427.77 192.49c0 9.55-5.62 22.71-12.55 22.71s-12.55-13.2-12.55-22.71 5.62-17.29 12.55-17.29 12.55 7.74 12.55 17.29Z"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "415.22px 195.2px",
        }}
      />
      <path
        d="M410.62 194.1c.39 0 .7.81.7 1.8s-.31 1.8-.7 1.8-.7-.81-.7-1.8.31-1.8.7-1.8Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "410.62px 195.9px",
        }}
      />
      <path
        d="M419.39 194.1c.38 0 .7.81.7 1.8s-.32 1.8-.7 1.8-.71-.81-.71-1.8.32-1.8.71-1.8Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "419.385px 195.9px",
        }}
      />
      <path
        d="m416.49 193.2-.46.92s.31 4.86.45 5.91-.64 2.22-1.79 1.61l-1.14-.63"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "415.023px 197.504px",
        }}
      />
      <path
        d="M411.72 205.7c0 1 7 1 7 0"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "415.22px 206.075px",
        }}
      />
      <path
        d="m403.26 198.93 1.47.28a22.62 22.62 0 0 1-1-4.77c-.13-1.37 1.84-3.43 1.84-4.39 0-5.24 2.85-5.09 3.43-4.32.08-.95 4.56-5.57 7.9.83.85 1.63 9.95 3.07 9.92 4.55a50 50 0 0 1-.76 7.72c.74.15 1.39-.63 1.48-1.38s.28-3.66.91-4.08a1.46 1.46 0 0 0 1.58.77 7.19 7.19 0 0 1-.68-2.89c0-1 .56-.34 1.52-.62.5-.15 4.76-3 5.28-4.55-1.92.24-3.74-1.08-4.84-2.68a52.65 52.65 0 0 1-2.64-5.19 13.37 13.37 0 0 0-11.82-7.06c-4.83.18-9.46 3.89-10.19 8.67a2.73 2.73 0 0 0-2.84.47 8 8 0 0 0-1.84 2.39 15.81 15.81 0 0 0-2.18 5c-.33 1.81 1.49 4.34 2.88 5.53.18-.4.08 5.72.58 5.72Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "417.955px 185.18px",
        }}
      />
      <path
        d="M406.91 192.91c.49-.41.83-1 1.34-1.35a3 3 0 0 1 2.41-.34 8.73 8.73 0 0 1 2.26 1"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "409.915px 192.008px",
        }}
      />
      <path
        d="M417.75 192.26a8.73 8.73 0 0 1 2.26-1 2.94 2.94 0 0 1 2.41.34c.52.37.85.94 1.34 1.35"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "420.755px 192.045px",
        }}
      />
      <path
        d="M410.33 214.72a11.9 11.9 0 0 0 3 7.3"
        className="animable"
        style={{
          fill: "#fff",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "411.83px 218.37px",
        }}
      />
      <path
        d="M410.34 185.62a7.8 7.8 0 0 1 1.16-2 2.4 2.4 0 0 1 .82-.7 1 1 0 0 1 1 .07"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "411.83px 184.219px",
        }}
      />
      <path
        d="M411.77 185.93a4.42 4.42 0 0 1 3.56-2.37"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "413.55px 184.745px",
        }}
      />
      <path
        d="M413.33 186c.48-.65 4.25-2.74 5.11.49"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "415.885px 185.612px",
        }}
      />
      <path
        d="M407.6 186.71a4.18 4.18 0 0 0-3.46 1.49"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "405.87px 187.451px",
        }}
      />
      <path
        d="M406.52 188.1a4.23 4.23 0 0 0-3 2.81"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "405.02px 189.505px",
        }}
      />
      <path
        d="M406.12 326.63a73.59 73.59 0 0 0 9.27 3.52l10.61-3.66"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "416.06px 328.32px",
        }}
      />
      <path
        d="M389.88 316c1.77-.23 3-1.85 3.9-3.39a39.21 39.21 0 0 0 4-9.46"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "393.83px 309.575px",
        }}
      />
      <path
        d="M439.53 316c-1.77-.23-3-1.85-3.9-3.39a39.64 39.64 0 0 1-4-9.46"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "435.58px 309.575px",
        }}
      />
      <path
        d="M414.34 303.16v1.44s16.05.72 23.21-3.27"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "425.945px 302.99px",
        }}
      />
      <path
        d="M392.33 300.68c5.63 3.06 15.61 3.6 22 3.92"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "403.33px 302.64px",
        }}
      />
      <path
        d="m415.24 304.63.2 10.06v.83l.12 5.56a2.52 2.52 0 0 0 2.95-2.1c.37-2.06.11-11.19 0-14.34"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "416.971px 312.876px",
        }}
      />
      <path
        d="M414.8 391.61c-.15-5.9.64-76.92.64-76.92"
        className="animable"
        style={{
          fill: "none",
          stroke: "#5c5c5c",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "415.111px 353.15px",
        }}
      />
      <path
        d="M397.63 286.52a32.06 32.06 0 0 0 30.66 4.08"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "412.96px 289.651px",
        }}
      />
      <path
        d="M399.48 255.59a90.16 90.16 0 0 0 8.64 14.09C413.23 276.32 420 282 428 284.4"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "413.74px 269.995px",
        }}
      />
      <path
        d="M397.88 252.4c.27.56.3.61.57 1.16"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "398.165px 252.98px",
        }}
      />
      <path
        d="M395.69 247.89c.41.85.82 1.71 1.24 2.56"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "396.31px 249.17px",
        }}
      />
      <path
        d="M408.89 262.85a32.15 32.15 0 0 0 22.5 11.36"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "420.14px 268.53px",
        }}
      />
      <path
        d="M380.19 237.72c.1 6 1.37 8.67 2.55 10.13"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "381.465px 242.785px",
        }}
      />
      <path
        d="M389.79 238.46c.14 6.62 1.22 13.94 1 24.39"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "390.305px 250.655px",
        }}
      />
      <path
        d="M385.4 235a31.89 31.89 0 0 0 4.19 12.75"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "387.495px 241.375px",
        }}
      />
      <path
        d="M444.71 232.06c-1.1 4.59-2.39 9.64-4.79 12.12"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "442.315px 238.12px",
        }}
      />
      <path
        d="M443.57 247.74c-.69.58-1.37 1.11-2 1.59"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "442.57px 248.535px",
        }}
      />
      <path
        d="M448.77 240.41a14.62 14.62 0 0 1-3.67 5.92"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "446.935px 243.37px",
        }}
      />
    </g>
    <g
      className="animable"
      style={{
        transformOrigin: "258.345px 463.17px",
      }}
    >
      <path
        d="M466.23 463.17H50.46"
        className="animable"
        style={{
          fill: "none",
          stroke: "#263238",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          transformOrigin: "258.345px 463.17px",
        }}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          d="M384.14 321s-.57 4.19-1.28 9.66c-1.05 8-2.4 18.74-2.75 23.2-.11 1.44-.28 3.92-.48 7v0h-64.52a.06.06 0 0 0 0 0c-2.08-15.65-5.2-36.32-5.56-38.74v-.23l22.62-6.14 3.08-.84a8.77 8.77 0 0 1 2.81-1.14 22.7 22.7 0 0 1 3.88-.57h.62a64.06 64.06 0 0 1 9.19.27c2 .18 3.83.42 5.28.63h.07c1.24.19 3.19.62 5.5 1.17 8.4 1.98 21.54 5.73 21.54 5.73Z"
          style={{
            fill: "#e39292",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </clipPath>
      <clipPath id="b">
        <path
          d="M390.28 253.56h-11.63l1.47-8.47v-1.75c0-6.88-.23-14.31 5.31-19.89.18 1.18 3.34 10.02 4.85 30.11Z"
          style={{
            fill: "#e39292",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </clipPath>
      <clipPath id="c">
        <path
          d="M438.4 252.78v0l3.89-23.47 1.13-6.82c6 6.55 5.32 16.5 5.32 19.95l1.59 10.34Z"
          style={{
            fill: "#e39292",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </clipPath>
      <clipPath id="d">
        <path
          d="M440.07 242.74c-2.52 23.4-.79 56.4-.79 56.4-19.35 8.76-48.82-.4-48.83-.41a354.43 354.43 0 0 0-.17-45.17c-1.51-20.09-4.67-28.93-4.85-30.14v-.17l17.57-4.77 2.38-.66a7 7 0 0 1 2.19-.88 17.35 17.35 0 0 1 3-.45h.49a50.21 50.21 0 0 1 7.15.21c1.54.14 3 .32 4.1.49h.05c1 .14 2.48.48 4.27.9 6.56 1.58 16.79 4.48 16.79 4.48s-2.98 16.72-3.35 20.17Z"
          style={{
            fill: "#e39292",
            stroke: "#263238",
            strokeLinecap: "round",
            strokeLinejoin: "round",
          }}
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCreatRecruiter;
