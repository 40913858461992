import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ImageUploadButton from "../ImageUploadButton";
import MyArrayInput from "../MyArrayInput";
import InputMask from "react-input-mask";
import CardPost from "./CardPost";
import PostDetails from "./PostDetails";
import { COLORS } from "colors/color";
import { connect } from "react-redux";
import { setUserId } from "../../Redux/actions";
import { Switch, FormControlLabel } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { Chips } from "primereact/chips";
import {
  Row,
  Col,
  Container,
} from "reactstrap";
const apiUrl = process.env.REACT_APP_API_URL;
const apiUrlsoket = process.env.REACT_APP_API_URL_SOKET;
const PostPage = () => {
  const [postImage, setPostImage] = useState("");
  const [postTitle, setPostTitle] = useState("");
  const [postDescription, setPostDescription] = useState("");
  const [postRequirement, setPostRequirement] = useState([]);
  const [postDeadline, setPostDeadline] = useState("");
  const [posts, setPosts] = useState([]);
  const [filter, setFilter] = useState("");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const theme = useTheme();
  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = getUserIdFromAccessToken();
  const [errors, setErrors] = useState({});
  const [location, setLocation] = useState("");
  const [numPeople, setNumPeople] = useState("");
  const [salary, setSalary] = useState("");
  const [workingMode, setWorkingMode] = useState("");
  const [showAllPosts, setShowAllPosts] = useState(false);
  const client_id = "86dpwd87p8nanb";
  const secret_id = process.env.LINKEDIN_CLIENT_SECRET;
  //   useEffect(() => {
  //     console.log("test1");
  //     const searchParams = new URLSearchParams(window.location.search);
  //     const code = searchParams.get('code');
  //     console.log("test2");
  //     if (code) {
  //       console.log("The URL contains the code:", code);
  //       fetchCallbackData(code);
  //     } else {
  //       console.log("Code not found in the URL.");
  //     }
  //   }, []);

  //   const fetchCallbackData = async (code) => {
  //   console.log("Code:", code);
  //   try {
  //     const response = await axios.post(
  //       `${apiUrl}authentication/linkedin-callback/`,
  //       {
  //         grant_type: "authorization_code",
  //         code: code,
  //         redirect_uri: `${window.location.origin}/admin/post/PostDetails`,
  //         client_id: "86o1ech9y4in4m",
  //         client_secret: "WPL_AP0.jW3gypWTWCnun6rz.MTIxMTM2NzA2NA==",
  //       }
  //     );
  //     console.log("LinkedIn token response:", response.data);
  //     // Handle the response, store the access token, etc.
  //   } catch (error) {
  //     console.error("Error fetching data from callback:", error);
  //   }
  // };
  const validateFirstDialog = () => {
    let tempErrors = {};
    tempErrors.postTitle = postTitle ? "" : "Post title is required";
    tempErrors.postDescription = postDescription
      ? ""
      : "Post description is required";
    tempErrors.postRequirement =
      postRequirement.length > 0 ? "" : "At least one requirement is required";
    tempErrors.postDeadline = postDeadline ? "" : "Deadline is required";
    tempErrors.postImage = postImage ? "" : "Image is required";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const validateSecondDialog = () => {
    let tempErrors = {};
    tempErrors.location = location ? "" : "Location is required";
    tempErrors.workingMode = workingMode ? "" : "Working mode is required";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };
  const handleNext = () => {
    if (validateFirstDialog()) {
      setOpen(false);
      setOpen2(true);
    }
  };

  const handleSave = () => {
    if (validateSecondDialog()) {
      save();
    }
  };
  useEffect(() => {
    console.log("clientid is : ", client_id);
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    console.log("test2");
    if (code) {
      console.log("The URL contains the code:", code);
      fetchCallbackData(code);
    } else {
      console.log("Code not found in the URL.");
    }
  }, []);

  const fetchCallbackData = async (code) => {
    console.log("Code:", code);
    try {
      const response = await axios.get(
        `${apiUrl}authentication/linkedin_callback_post/`,
        {
          params: { code: code },
        }
      );
      console.log("LinkedIn token response:", response.data);
      localStorage.setItem(
        "accessToken_LinkedIn",
        response.data.linkedIn_access_token
      );
      localStorage.setItem(
        "linkedin_person_id",
        response.data.linkedin_person_id
      );
      handleCreatePost(
        response.data.linkedIn_access_token,
        response.data.linkedin_person_id
      );
      // Handle the response, store the access token, etc.
    } catch (error) {
      console.error("Error fetching data from callback:", error);
    }
  };

  useEffect(() => {
    console.log("test1");
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    console.log("test2");
    if (code) {
      console.log("The URL contains the code:", code);
      fetchCallbackData(code);
    } else {
      console.log("Code not found in the URL.");
    }
  }, []);

  // const fetchCallbackData = async (code) => {

  //   console.log("Code:", code);
  //   try {
  //     const response = await axios.get(
  //       `${apiUrl}authentication/linkedin_callback_post/`,
  //       {
  //         params: { code: code }
  //       }
  //     );
  //     console.log("LinkedIn token response:", response.data);
  //     localStorage.setItem("accessToken_LinkedIn", response.data.linkedIn_access_token);
  //     // Handle the response, store the access token, etc.
  //   } catch (error) {
  //     console.error("Error fetching data from callback:", error);
  //   }
  // };

  useEffect(() => {
    Load();
    console.log("user id is :", userId);
  }, []);
  useEffect(() => {
    Load();
  }, [showAllPosts]);

  const handleCreatePost = async (token, personnalId) => {
    console.log("token,personnalId", token, personnalId);
    const formData = JSON.parse(localStorage.getItem("postFormData"));
    if (!formData) {
      console.error("Form data not found in localStorage");
    }
    const {
      postImage,
      postTitle,
      postDescription,
      postRequirement,
      postDeadline,
      location,
    } = formData;

    if (
      !postTitle ||
      !postDeadline ||
      !location ||
      !postDescription ||
      !postRequirement
    ) {
      console.error("Missing required fields");
    }
    console.log("Sending data:", {
      token,
      personnalId,
      postTitle,
      postDeadline,
      location,
      postImage,
      postDescription,
      postRequirement,
    });
    try {
      const response = await axios.post(
        `${apiUrl}authentication/linkedin/post-create/`,
        {
          token: token,
          personnalId: personnalId,
          subject: postTitle,
          // access_token: linkedInAccessToken,
          lien: `${apiUrl}`,
          postDeadline: postDeadline,
          location: "location",
          postImage: postImage,
          postDescription: postDescription,
          requirement: postRequirement,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      resetForm();
      localStorage.removeItem("linkedin_person_id");
      localStorage.removeItem("accessToken_LinkedIn");
      localStorage.removeItem("postFormData");

      console.log(response.data);
    } catch (error) {
      if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error request data:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
      }
      console.error("Error config:", error.config);
      // Handle error (e.g., show error message to the user)
    }
  };

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  async function Load() {
    try {
      const endpoint = showAllPosts
        ? `${apiUrl}posts/list/${userId}/all/`
        : `${apiUrl}posts/list/${userId}/`;
      const response = await fetch(endpoint, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      if (response.status === 401) {
        // Handle unauthorized access
        return;
      }
      const json = await response.json();
      setPosts(json);
      console.log(json);
    } catch (error) {
      console.error("Error loading data:", error);
    }
  }

  async function save() {
    setOpen2(false);
    const formData = {
      postImage,
      postTitle,
      postDescription,
      postRequirement,
      postDeadline,
      location,
      numPeople,
      workingMode,
      salary,
    };

    if (
      isChecked &&
      !localStorage.getItem("linkedin_person_id") &&
      !localStorage.getItem("accessToken_LinkedIn")
    ) {
      localStorage.setItem("postFormData", JSON.stringify(formData));
      try {
        console.log("window.location.origin", window.location.origin);
        // window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${window.location.origin}/admin/post/PostDetails/&scope=openid%20email%20profile%20w_member_social`;
        let fileURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&redirect_uri=${window.location.origin}/admin/post/PostDetails/&scope=openid%20email%20profile%20w_member_social`;
        window.open(fileURL, "_blank"); 
      } catch (error) {
        console.error("Error redirecting to LinkedIn authentication:", error);
      }
    } else if (
      isChecked &&
      localStorage.getItem("linkedin_person_id") &&
      localStorage.getItem("accessToken_LinkedIn")
    ) {
      handleCreatePost(
        localStorage.getItem("linkedin_person_id"),
        localStorage.getItem("accessToken_LinkedIn")
      );
    }
    try {
      await axios.post(`${apiUrl}posts/create/`, {
        image: postImage,
        title: postTitle,
        description: postDescription,
        requirements: postRequirement,
        deadline: postDeadline,
        recruiter: userId,
      });
      alert("Post Registration Successful");
      // if(!localStorage.getItem('linkedin_person_id')){

      // }
      Load();
    } catch (err) {
      alert("Post Registration Failed");
    }
  }

  const resetForm = () => {
    setPostTitle("");
    setPostDescription("");
    setPostRequirement([]);
    setPostDeadline("");
    setPostImage("");
  };

  const handleImageUpload = (postImage) => {
    setPostImage(postImage);
  };

  const deletePostById = async (postId) => {
    try {
      await axios.delete(`${apiUrl}posts/${postId}/delete/`);
      console.log(`Post with ID ${postId} has been deleted.`);
      Load();
    } catch (error) {
      console.error(`Error deleting post with ID ${postId}: ${error.message}`);
    }
  };

  const updatePost = async (updatedData) => {
    try {
      const response = await axios.put(
        `${apiUrl}posts/${updatedData.postid}/update/`,
        updatedData
      );
      if (response.status === 200) {
        console.log(`Post with ID has been updated.`);
        Load();
      } else {
        console.error(`Error updating post with ID : ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error updating post: ${error.message}`);
    }
  };

  const handleSelectPost = (post) => {
    setSelectedPost(post);
  };

  const handleBackToList = () => {
    setSelectedPost(null);
  };

  const filteredCards = posts.filter((post) => {
    const job = post.title.toLowerCase();
    const filterLower = filter.toLowerCase();
    return job.startsWith(filterLower);
  });
  const cities = [
    "Casablanca",
    "Rabat",
    "Marrakesh",
    "Fes",
    "Tangier",
    "Agadir",
    "Meknes",
    "Oujda",
    "Kenitra",
    "Tetouan",
    "Safi",
    "El Jadida",
    "Nador",
    "Khouribga",
    "Beni Mellal",
    "Mohammedia",
    "Laayoune",
    "Khemisset",
    "Settat",
    "Sidi Kacem",
    "Taza",
    "Larache",
    "Berkane",
    "Errachidia",
    "Taroudant",
    "Guelmim",
    "Ouarzazate",
    "Essaouira",
    "Dakhla",
    "Azrou",
    "Oued Zem",
    "Fqih Ben Salah",
  ];
  const onArchivePost = async (postId, archivedStatus) => {
    try {
      // If trying to de-archive, check the deadline first
      const post = posts.find((p) => p.id === postId);
      if (post && new Date(post.deadline) < new Date()) {
        toast.warning(
          "You need to change the deadline to de-archive this post."
        );
        return;
      }

      const response = await fetch(
        `${apiUrl}posts/${postId}/archive/${userId}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          body: JSON.stringify({ archived: archivedStatus }),
        }
      );
      if (response.ok) {
        console.log(
          `Post with ID ${postId} has been ${
            archivedStatus ? "archived" : "unarchived"
          }.`
        );
        await Load();
        toast.success(
          `Post has been ${
            archivedStatus ? "archived" : "de-archived"
          } successfully.`
        );
      } else {
        console.error(
          `Error updating archive status for post with ID ${postId}: ${response.statusText}`
        );
        toast.error("Failed to update archive status.");
      }
    } catch (error) {
      console.error(`Error updating archive status: ${error.message}`);
      toast.error("An error occurred while updating archive status.");
    }
  };
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const [value, setValue] = useState([]);
  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />

      <div className="d-flex flex-column content">
        {selectedPost ? (
          <PostDetails
            post={selectedPost}
            onBack={handleBackToList}
            apiUrl={apiUrl}
          />
        ) : (
          <>
            <div
              className=""
              style={{
                // marginLeft: "20px",
                // marginRight: "5px",
                // marginTop: "70px",
                transform: "scale(0.9)", // This scales down the entire dashboard to 80% of its original size
                // transformOrigin: "top ", // This ensures the scaling starts from the top left corner
              }}
            >
              <Stack direction="row" padding={2}>
                <Box flexGrow={3} borderRadius={8}></Box>
                <TextField
                  style={{ marginRight: "30px" }}
                  variant="standard"
                  type="text"
                  placeholder="Filter by Job post"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={showAllPosts}
                      onChange={(e) => setShowAllPosts(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Show Archived Posts"
                  style={{ marginRight: "30px" }}
                />
                <Button
                  onClick={() => setOpen(true)}
                  variant="contained"
                  type="submit"
                  sx={{
                    height: "100%",
                    color: COLORS.white,
                    border: "1px solid",
                    borderColor: COLORS.grey,
                    bgcolor: COLORS.signIn,
                    ":hover": {
                      bgcolor: COLORS.white,
                      borderColor: COLORS.signIn,
                      color: COLORS.signIn,
                    },
                  }}
                >
                  Add new Post
                </Button>
              </Stack>
            </div>
            <Container fluid className="px-4">
      <Row className="g-4">
        {filteredCards.map((post) => (
          <Col key={post.id} xs={12} md={6} lg={4}>
            <CardPost
              postid={post.id}
              imageSrc={post.image}
              title={post.title}
              description={post.description}
              requirement={post.requirements}
              deadline={post.deadline}
              company={post.company}
              onDeletePost={deletePostById}
              updatePost={updatePost}
              handleImageUpload={handleImageUpload}
              onSelectPost={() => handleSelectPost(post)}
              archived={post.archived}
              onArchivePost={onArchivePost}
            />
          </Col>
        ))}
      </Row>
    </Container>
          </>
        )}

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <Stack spacing={2.5} margin={2}>
              <Typography
                fontSize={25}
                fontFamily={"sans-serif"}
                color={theme.palette.mode === "dark" ? "#009688" : "#9cd6d1"}
              >
                Add post
              </Typography>
              <TextField
                variant="outlined"
                label="Post Title"
                value={postTitle}
                onChange={(event) => setPostTitle(event.target.value)}
                error={!!errors.postTitle}
                helperText={errors.postTitle}
                required
              />
              <TextField
                variant="outlined"
                label="Post Description"
                value={postDescription}
                onChange={(event) => setPostDescription(event.target.value)}
                multiline
                rows={6}
                fullWidth
                error={!!errors.postDescription}
                helperText={errors.postDescription}
                required
              />
              <MyArrayInput
                value={postRequirement}
                onChange={setPostRequirement}
                error={!!errors.postRequirement}
                helperText={errors.postRequirement}
                required
              />
              <InputMask
                mask="9999-99-99"
                value={postDeadline}
                onChange={(event) => setPostDeadline(event.target.value)}
                maskChar=" "
              >
                {(inputProps) => (
                  <TextField
                    {...inputProps}
                    variant="outlined"
                    label="Deadline"
                    fullWidth
                    error={!!errors.postDeadline}
                    helperText={errors.postDeadline}
                    required
                  />
                )}
              </InputMask>
              <ImageUploadButton
                onImageUpload={handleImageUpload}
                error={!!errors.postImage}
                helperText={errors.postImage}
                required
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              type="submit"
              sx={{
                height: "100%",
                color: COLORS.signIn,
                border: "1px solid",
                borderColor: COLORS.grey,
                bgcolor: COLORS.Button_OnHold,
                ":hover": {
                  bgcolor: COLORS.white,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
              onClick={handleNext}
            >
              NEXT
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open2}
          onClose={() => setOpen2(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <Stack spacing={2.5} margin={2}>
              <Typography
                fontSize={25}
                fontFamily={"sans-serif"}
                color={theme.palette.mode === "dark" ? "#009688" : "#9cd6d1"}
              >
                Add post
              </Typography>

              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Working Mode
                </Typography>
                <Stack direction="row" spacing={1}>
                  {["Remote", "Onsite", "Hybrid"].map((mode) => (
                    <Chip
                      key={mode}
                      label={mode}
                      onClick={() => setWorkingMode(mode)}
                      color={workingMode === mode ? "primary" : "default"}
                      variant={workingMode === mode ? "filled" : "outlined"}
                    />
                  ))}
                </Stack>
                {errors.workingMode && (
                  <FormHelperText error>{errors.workingMode}</FormHelperText>
                )}
              </Box>

              <Autocomplete
                options={cities}
                value={location}
                onChange={(event, newValue) => setLocation(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%", marginTop: "3%", marginBottom: "3%" }}
                    variant="outlined"
                    label="Location"
                    error={!!errors.location}
                    helperText={errors.location}
                    required
                  />
                )}
              />
              <FormControl fullWidth sx={{ width: "100%", marginTop: "2%" }}>
                <InputLabel id="demo-simple-select-label">
                  Number of people to hire in this job
                </InputLabel>
                <Select
                  labelId="number"
                  id="number"
                  value={numPeople}
                  onChange={(event) => setNumPeople(event.target.value)}
                  label="Number of people to hire in this job"
                >
                  {[...Array(10).keys()].map((num) => (
                    <MenuItem key={num} value={num + 1}>
                      {num + 1}
                    </MenuItem>
                  ))}
                  <MenuItem value={10}>10+</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{}}>
                <InputLabel htmlFor="outlined-adornment-amount">
                  Salary
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  value={salary}
                  onChange={(event) => setSalary(event.target.value)}
                  startAdornment={
                    <InputAdornment position="start">MAD</InputAdornment>
                  }
                  label="Salary"
                />
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Make a post on LinkedIn"
                  />
                </FormControl>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              type="submit"
              sx={{
                height: "100%",
                color: COLORS.signIn,
                border: "1px solid",
                borderColor: COLORS.grey,
                bgcolor: COLORS.Button_OnHold,
                ":hover": {
                  bgcolor: COLORS.white,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
              onClick={() => {
                setOpen(true);
                setOpen2(false);
              }}
            >
              PREVIOUS
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                height: "100%",
                color: COLORS.signIn,
                border: "1px solid",
                borderColor: COLORS.grey,
                bgcolor: COLORS.Button_OnHold,
                ":hover": {
                  bgcolor: COLORS.white,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
              onClick={handleSave}
            >
              ADD POST
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

// const mapStateToProps = (state) => ({
//   userId: state.userId,
// });

// const mapDispatchToProps = {
//   setUserId,
// };

export default PostPage;
