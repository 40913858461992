import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Col, Card, CardHeader, CardBody, CardFooter } from "reactstrap";
import styled from 'styled-components';
import { Typography } from "@mui/material";

const StyledCard = styled(Card)`
  margin-bottom: 30px;
`;

const CardHeaderWrapper = styled(CardHeader)`
  padding: 15px;
`;

const CardBodyWrapper = styled(CardBody)`
  padding: 15px;
  height: 250px; // Increased from 266px to 400px

`;

const CardFooterWrapper = styled(CardFooter)`
  padding: 15px;
`;

const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const LegendIcon = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
`;

const InternshipStatistics = ({ userId, apiUrl }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}ai/internships_with_interns/${userId}/`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        const totalInterns = Object.values(data).reduce((sum, count) => sum + parseInt(count), 0);
        const formattedData = Object.entries(data).map(([internshipName, internCount]) => {
          const count = parseInt(internCount);
          return {
            name: internshipName,
            value: count,
            percentage: ((count / totalInterns) * 100).toFixed(2)
          };
        });
        setChartData(formattedData);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userId, apiUrl]);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const colors = ['#007bff', '#ffc107', '#dc3545', '#6c757d', '#28a745', '#17a2b8'];

  const data = {
    labels: chartData.map(item => item.name),
    datasets: [
      {
        data: chartData.map(item => item.value),
        backgroundColor: colors.slice(0, chartData.length),
        hoverBackgroundColor: colors.slice(0, chartData.length),
      },
    ],
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
      <StyledCard>
        <CardHeaderWrapper>
          <Typography variant="h5" fontWeight="bold">Internship Statistics</Typography>
        </CardHeaderWrapper>
        <CardBodyWrapper style={{ height: "266px" }}>
          <Pie data={data} options={chartOptions} />
        </CardBodyWrapper>
        <CardFooterWrapper>
          <Legend>
            {chartData.map((item, index) => (
              <LegendItem key={item.name}>
                <LegendIcon style={{ backgroundColor: colors[index] }} />
                {item.name} ({item.percentage}%)
              </LegendItem>
            ))}
          </Legend>
          <hr />
        </CardFooterWrapper>
      </StyledCard>
  );
};

export default InternshipStatistics;