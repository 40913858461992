import React, { useState, useEffect } from "react";
import axios from "axios";
import RecruiterCard from "./RecruiterCard";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { COLORS } from "colors/color";
import { List } from "bootstrap-icons-react";
import RecruiterDetails from "./RecruiterDetails";
const apiUrl = process.env.REACT_APP_API_URL;
const apiUrlsoket = process.env.REACT_APP_API_URL_SOKET;

function ListRecruiter() {
  const [recruiters, setRecruiters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openCreate, setOpenCreate] = useState(false);
  const [filter, setFilter] = useState("");
  const [selectedRecruiterId, setSelectedRecruiterId] = useState(null);
  const filteredRecruiters = recruiters.filter((recruiter) =>
    `${recruiter.first_name} ${recruiter.last_name}`
      .toLowerCase()
      .includes(filter.toLowerCase())
  );
  const [newRecruiter, setNewRecruiter] = useState({
    name: "",
    email: "",
    company: "",
    specialization: "",
  });

  const adminId = getUserIdFromAccessToken();

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      console.log("Access token : ", decodedToken);
      const senderId = decodedToken.user_id;
      return senderId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }

  useEffect(() => {
    fetchRecruiters();
  }, []);

  const fetchRecruiters = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}recruiters/admin/${adminId}/recruiters/`
      );
      setRecruiters(response.data);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch recruiters");
      setLoading(false);
    }
  };

  const handleDeleteRecruiter = async (recruiterId) => {
    try {
      await axios.delete(
        `${apiUrl}recruiters/admin/${adminId}/recruiters/${recruiterId}/delete/`
      );
      fetchRecruiters();
    } catch (error) {
      console.error("Failed to delete recruiter", error);
    }
  };

  const handleUpdateRecruiter = async (updatedRecruiterData) => {
    try {
      await axios.put(
        `${apiUrl}recruiters/admin/${adminId}/recruiters/${updatedRecruiterData.recruiterId}/update/`,
        updatedRecruiterData
      );
      fetchRecruiters();
    } catch (error) {
      console.error("Failed to update recruiter", error);
    }
  };

  const handleSelectRecruiter = (recruiterId) => {
    console.log(`Selected recruiter: ${recruiterId}`);
    setSelectedRecruiterId(recruiterId);
  };

  const handleCreateRecruiter = async () => {
    try {
      await axios.post(
        `${apiUrl}recruiters/admin/${adminId}/recruiters/create/`,
        newRecruiter
      );
      setOpenCreate(false);
      setNewRecruiter({ name: "", email: "", company: "", specialization: "" });
      fetchRecruiters();
    } catch (error) {
      console.error("Failed to create recruiter", error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  const handleBackToList = () => {
    setSelectedRecruiterId(null);
  };
  return (
    <>
      <Stack direction="row" padding={2}>
        <Box flexGrow={3} borderRadius={8}></Box>
        <TextField
          style={{
            marginRight: "1.56%",
            padding: "0.26%",
          }}
          variant="standard"
          type="text"
          placeholder="Filter by recruiter's name"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </Stack>
      {selectedRecruiterId ? (
        <RecruiterDetails
          recruiterId={selectedRecruiterId}
          onBack={handleBackToList}
        />
      ) : (
        <div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
              gap: "20px",
              padding: "20px",
            }}
          >
            {filteredRecruiters.map((recruiter) => (
              <RecruiterCard
                key={recruiter.id}
                recruiterId={recruiter.id}
                imageSrc={recruiter.image || "default-image-url"}
                firstName={recruiter.first_name}
                lastName={recruiter.last_name}
                email={recruiter.email}
                company={recruiter.company}
                specialization={recruiter.comp_industry}
                onDeleteRecruiter={handleDeleteRecruiter}
                updateRecruiter={handleUpdateRecruiter}
                onSelectRecruiter={handleSelectRecruiter}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default ListRecruiter;
