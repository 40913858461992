import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Instagram, Twitter } from "@mui/icons-material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box } from "@mui/material";
import { Row } from "reactstrap";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 2, // Reduced padding
        position: 'relative' // Ensure the box is positioned relative to the container
      }}
    >
      <Container maxWidth="lg" sx={{ position: 'relative', minHeight: '150px' }}>
        <Grid container spacing={0} sx={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1200px', paddingLeft: '20px', paddingRight: '20px' }}>
          <Grid item xs={12} sm={4} sx={{ paddingRight: '10px' }}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Nous sommes PULSE.digital, Innovateurs déterminés nous tissons des liens de confiance en unissant talents et technologies pour un avenir numérique audacieux.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ padding: '0 10px' }}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
            Maroc, Marrakech, Bureau 7 centre d'affaire Guélizium, Avenue Mohamed V
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Email: maroc@pulse.digital
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Phone: +212 524 33 52 63
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ paddingLeft: '10px' }}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Follow Us
            </Typography>
            <Link href="https://www.linkedin.com/company/pulse-group-sa/posts/?feedView=all" color="inherit">
              <LinkedInIcon />
            </Link>
            <Link
              href="https://www.instagram.com/pulse_digital/"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
            >
              <Instagram />
            </Link>
            <Link href="https://www.twitter.com/" color="inherit">
              <Twitter />
            </Link>
          </Grid>
        </Grid>
        <Row style={{ position: 'absolute',  right: '10px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <div className="credits">
            <div className="copyright">
              &copy; {1900 + new Date().getYear()}, made with{" "}
              <i className="fa fa-heart heart" /> by{" "}
              <Link href="https://pulse.digital/" color="inherit">
                Pulse.digital
              </Link>
            </div>
          </div>
        </Row>
      </Container>
    </Box>
  );
};

export default Footer;
