import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="freepik_stories-online-review"
    className="animated"
    viewBox="0 0 500 500"
    {...props}
  >
    <g
      id="freepik--Floor--inject-15--inject-26"
      className="animable"
      style={{
        transformOrigin: "249.05px 363.55px",
      }}
    >
      <style>
        {`
        svg#freepik_stories-online-review:not(.animated) .animable {opacity: 0;}
        svg#freepik_stories-online-review.animated #freepik--Floor--inject-15--inject-26 {animation: 3s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideLeft;animation-delay: 0s;}
        svg#freepik_stories-online-review.animated #freepik--Shadow--inject-15--inject-26 {animation: 3s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) zoomIn;animation-delay: 0s;}
        svg#freepik_stories-online-review.animated #freepik--Plants--inject-15--inject-26 {animation: 3s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) zoomIn;animation-delay: 0s;}
        svg#freepik_stories-online-review.animated #freepik--Board--inject-15--inject-26 {animation: 3s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) fadeIn;animation-delay: 0s;}
        svg#freepik_stories-online-review.animated #freepik--characters-boxes--inject-15--inject-26 {animation: 3s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) lightSpeedRight;animation-delay: 0s;}
        svg#freepik_stories-online-review.animated #freepik--character--inject-15--inject-26 {animation: 3s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) zoomIn;animation-delay: 0s;}

        @keyframes slideLeft {
          0% {
            opacity: 0;
            transform: translateX(-30px);
          }
          100% {
            opacity: 1;
            transform: translateX(0);
          }
        }

        @keyframes zoomIn {
          0% {
            opacity: 0;
            transform: scale(0.5);
          }
          100% {
            opacity: 1;
            transform: scale(1);
          }
        }

        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        @keyframes lightSpeedRight {
          from {
            transform: translate3d(50%, 0, 0) skewX(-20deg);
            opacity: 0;
          }
          60% {
            transform: skewX(10deg);
            opacity: 1;
          }
          80% {
            transform: skewX(-2deg);
          }
          to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }
        }
      `}
      </style>
      <ellipse
        id="freepik--floor--inject-15--inject-26"
        cx={249.05}
        cy={363.55}
        className="animable"
        rx={221.3}
        ry={114.92}
        style={{
          fill: "#f5f5f5",
          transformOrigin: "249.05px 363.55px",
        }}
      />
    </g>
    <g
      id="freepik--Shadow--inject-15--inject-26"
      className="animable"
      style={{
        transformOrigin: "403.41px 390.32px",
      }}
    >
      <ellipse
        id="freepik--shadow--inject-15--inject-26"
        cx={403.41}
        cy={390.32}
        className="animable"
        rx={49.59}
        ry={30.13}
        style={{
          fill: "#e0e0e0",
          transformOrigin: "403.41px 390.32px",
        }}
      />
    </g>
    <g
      id="freepik--Plants--inject-15--inject-26"
      className="animable"
      style={{
        transformOrigin: "66.6786px 364.475px",
      }}
    >
      <g
        id="freepik--plants--inject-15--inject-26"
        className="animable"
        style={{
          transformOrigin: "66.6786px 364.475px",
        }}
      >
        <path
          id="elpag31iherso"
          d="M87.32 364.06a59 59 0 0 0-4-17C79.78 338.3 73 329.48 65.1 327.81s-11.19 3.62-9.53 8.82c1.43 4.47 7.27 8.18 11.7 12.09s12.72 13.33 15 22.45l4.43.48Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "71.2354px 349.573px",
          }}
        />
        <g id="el8hu24h0yxnm">
          <g
            id="el1co4n6df9ev"
            className="animable"
            style={{
              opacity: 0.15,
              transformOrigin: "71.2354px 349.573px",
            }}
          >
            <path
              id="elp6kr7s2byw"
              d="M87.32 364.06a59 59 0 0 0-4-17C79.78 338.3 73 329.48 65.1 327.81s-11.19 3.62-9.53 8.82c1.43 4.47 7.27 8.18 11.7 12.09s12.72 13.33 15 22.45l4.43.48Z"
              className="animable"
              style={{
                transformOrigin: "71.2354px 349.573px",
              }}
            />
          </g>
        </g>
        <path
          id="el6tne81hzoyh"
          d="M84.3 368.17a.5.5 0 0 1-.5-.48C83 344.78 60.57 332 60.34 331.84a.49.49 0 1 1 .48-.86c.23.13 23.12 13.22 24 36.68a.49.49 0 0 1-.48.51Z"
          className="animable"
          style={{
            fill: "#fff",
            transformOrigin: "72.454px 349.544px",
          }}
        />
        <path
          id="elokibchhczl"
          d="M84.55 399.49s-4.58 2.89-11.74 1.66a11 11 0 0 1-6.16-3c-1.85-1.91-2.14-3.82-2.66-6.37a7.66 7.66 0 0 0-1.08-3c-1.53-2.11-4.59-2.07-7.14-2.62a10.6 10.6 0 0 1-8-6.93c-1.05-3.56.65-7.89 4.08-9.28l-4.2-2.69a18.37 18.37 0 0 1-3.65-2.73 6.2 6.2 0 0 1-1.81-4 5.94 5.94 0 0 1 3.7-5.15 12.59 12.59 0 0 1 6.59-.67 29.3 29.3 0 0 1 6.58 1.55 4.7 4.7 0 0 0 1.84.41 4.86 4.86 0 0 0 2.25-1c4.81-3.18 12.66-2.94 15.24 3.06.38.88.61 1.82 1 2.69 1.5 3.23 5.71 2.26 8.43 3.77A6.87 6.87 0 0 1 91 370c.54 3.2-.35 6.84-.94 10s-1.34 6.08-2.2 9.08c-.7 2.46-1.47 4.91-2.29 7.34-.14.58-.52 2.75-1.02 3.07Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "66.6786px 377.55px",
          }}
        />
        <path
          id="el2g2yv4j0dj4"
          d="M87.43 392.1a44.12 44.12 0 0 0-11.5-17.32.49.49 0 0 0-.26-.25 62.669 62.669 0 0 0-4.67-3.95A23.73 23.73 0 0 1 72.59 358a.49.49 0 0 0-.24-.66.48.48 0 0 0-.65.24 24.52 24.52 0 0 0-1.82 12.13 75.71 75.71 0 0 0-21.67-10.86.5.5 0 0 0-.26 1A73.4 73.4 0 0 1 73.67 374c-7.07-1.23-15.52 2.18-15.91 2.34a.5.5 0 0 0-.27.65.49.49 0 0 0 .46.3h.18c.1 0 10-4.06 17-1.88a43.24 43.24 0 0 1 11.33 17 .51.51 0 0 0 .47.33.45.45 0 0 0 .16 0 .49.49 0 0 0 .34-.64Z"
          className="animable"
          style={{
            fill: "#fff",
            transformOrigin: "67.511px 375.019px",
          }}
        />
      </g>
    </g>
    <g
      id="freepik--Board--inject-15--inject-26"
      className="animable"
      style={{
        transformOrigin: "199.795px 215.19px",
      }}
    >
      <path
        id="elhu0vm6feznw"
        d="m98.49 388.04 209.05-120.69V46.05L98.49 166.75v221.29z"
        className="animable"
        style={{
          fill: "#de4747",
          transformOrigin: "203.015px 217.045px",
        }}
      />
      <path
        id="elyj1jiewu8rc"
        d="m88.84 161.18 9.65 5.57 209.05-120.7 9.64-16.71L88.84 161.18z"
        className="animable"
        style={{
          fill: "#37474f",
          transformOrigin: "203.01px 98.045px",
        }}
      />
      <path
        id="elg7n84hcx57"
        d="M317.18 272.92V29.34l-9.64 16.71v221.3l9.64 5.57z"
        className="animable"
        style={{
          fill: "#37474f",
          transformOrigin: "312.36px 151.13px",
        }}
      />
      <path
        id="elgt7uups4ql6"
        d="m88.84 161.18 9.65 5.57v221.29l-9.65 16.71V161.18z"
        className="animable"
        style={{
          fill: "#37474f",
          transformOrigin: "93.665px 282.965px",
        }}
      />
      <path
        id="el2omcnh3gb92"
        d="m98.49 388.04 209.05-120.69 9.64 5.57L88.84 404.75l9.65-16.71z"
        className="animable"
        style={{
          fill: "#37474f",
          transformOrigin: "203.01px 336.05px",
        }}
      />
      <g id="elmxif12edbm">
        <g
          id="elz4scxod8sk"
          className="animable"
          style={{
            opacity: 0.5,
            transformOrigin: "203.01px 98.045px",
          }}
        >
          <path
            id="el873lv49jihk"
            d="m88.84 161.18 9.65 5.57 209.05-120.7 9.64-16.71L88.84 161.18z"
            className="animable"
            style={{
              fill: "#455a64",
              transformOrigin: "203.01px 98.045px",
            }}
          />
        </g>
      </g>
      <g id="elc70v3gqie2q">
        <g
          id="el8adbozyv7tc"
          className="animable"
          style={{
            opacity: 0.5,
            transformOrigin: "203.01px 336.05px",
          }}
        >
          <path
            id="el5ckyd06f6zg"
            d="m98.49 388.04 209.05-120.69 9.64 5.57L88.84 404.75l9.65-16.71z"
            className="animable"
            style={{
              fill: "#455a64",
              transformOrigin: "203.01px 336.05px",
            }}
          />
        </g>
      </g>
      <path
        id="elw6ebdmsc27j"
        d="m317.18 29.34-6.43-3.71L82.41 157.46l6.43 3.72L317.18 29.34z"
        className="animable"
        style={{
          fill: "#455a64",
          transformOrigin: "199.795px 93.405px",
        }}
      />
      <path
        id="eldbi5860ncte"
        d="m82.41 157.46 6.43 3.72v243.57l-6.43-3.71V157.46z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "85.625px 281.105px",
        }}
      />
      <g id="elblmx1w7bf2w">
        <g
          id="elq6uyp7491wb"
          className="animable"
          style={{
            opacity: 0.1,
            mixBlendMode: "multiply",
            transformOrigin: "203.015px 217.045px",
          }}
        >
          <path
            id="elr9qtekgr3g"
            d="m307.54 46.05-3.22 1.86v217.58L98.49 384.32v3.72l209.05-120.69V46.05z"
            className="animable"
            style={{
              transformOrigin: "203.015px 217.045px",
            }}
          />
        </g>
      </g>
    </g>
    <g
      id="freepik--characters-boxes--inject-15--inject-26"
      className="animable"
      style={{
        transformOrigin: "217.543px 222.901px",
      }}
    >
      <path
        id="elpxj809mumai"
        d="m296.3 174-.06-2.93-3.48-2a1.09 1.09 0 0 0-1.11.11l-48.49 28a3.49 3.49 0 0 0-1.57 2.72v61.66a1.11 1.11 0 0 0 .45 1l3.49 2v-3.47l46.11-26.57a3.46 3.46 0 0 0 1.57-2.72V174Z"
        className="animable"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "268.943px 216.763px",
        }}
      />
      <path
        id="elww6rh23507"
        d="m296.24 171-3.48-2a1.09 1.09 0 0 0-1.11.11l-48.49 28a3.12 3.12 0 0 0-1 1l4.94 2.89 46.12-26.05Z"
        className="animable"
        style={{
          fill: "#fafafa",
          transformOrigin: "269.2px 184.948px",
        }}
      />
      <path
        id="elf8mcdrzak4b"
        d="m246.64 199.13 48.48-28c.87-.5 1.57-.1 1.57.91v61.71a3.46 3.46 0 0 1-1.57 2.72l-48.48 27.94c-.87.5-1.57.1-1.57-.9v-61.66a3.46 3.46 0 0 1 1.57-2.72Z"
        className="animable"
        style={{
          fill: "#f0f0f0",
          transformOrigin: "270.88px 217.77px",
        }}
      />
      <g
        id="elae95m8pwoeg"
        className="animable"
        style={{
          clipPath: "url(#freepik--clip-path--inject-15--inject-26)",
          transformOrigin: "286.075px 248.491px",
        }}
      >
        <g
          id="freepik--character-boy--inject-15--inject-26"
          className="animable"
          style={{
            transformOrigin: "290.675px 237.883px",
          }}
        >
          <path
            id="el91hgneynth"
            d="M280.52 225.21s3.81-1.18 7 3.83c2.16 3.4 9.7 16.33 9.7 16.33s14.67-7.78 15.49-8.8.78-3.08.9-4.19a12.16 12.16 0 0 0-.57-4.13c-.17-1.17 1.51-1.23 2.34.39.6 1.18.65 2.53 1.17 2.55a14.57 14.57 0 0 0 2.06-1.75c.91-1 2.55-2.66 3.8-2.43a1.08 1.08 0 0 1 .83.89 5.75 5.75 0 0 0 .46 2 3.24 3.24 0 0 1 .49.8 7 7 0 0 1 0 1.29c0 .61.65.87.73 1.47.06.43-.16.93-.15 1.39 0 .29.09.55.09.85a2.82 2.82 0 0 1-.75 1.63 17.47 17.47 0 0 1-4.36 3.65c-1.34.87-3.1 1.65-4.61 2.47S296.31 257.51 294 256s-13.84-18.38-13.84-18.38c-2.64-7.47.36-12.41.36-12.41Z"
            className="animable"
            style={{
              fill: "#ffa8a7",
              transformOrigin: "302.002px 240.604px",
            }}
          />
          <path
            id="elp1qq1riwnz"
            d="M273.82 225.32s4.2-1.7 6.93-1.53 4.74 1 6.74 4.17c2.67 4.28 7.08 11.69 7.08 11.69a21.32 21.32 0 0 1-8.58 9.18l-8.13-12.28Z"
            className="animable"
            style={{
              fill: "#455a64",
              transformOrigin: "284.195px 236.304px",
            }}
          />
          <g
            className="animable"
            style={{
              transformOrigin: "271.598px 237.883px",
            }}
          >
            <path
              id="el4hplyp2r5ig"
              d="M260.5 232.74a9 9 0 0 0-3 4.37 21.2 21.2 0 0 0-.91 6.39v13.95l-.17 26.4c8.85 3 20.1.36 29.54-10.14 0 0-.52-33.76-.52-39.31v-.76c-.34-7.83-7.1-11.45-13.75-7.7l-5.59 3.16-5.61 3.64Z"
              className="animable"
              style={{
                fill: "#de4747",
                transformOrigin: "271.19px 254.73px",
              }}
            />
            <g id="elnc6nxfpgftf">
              <path
                id="el9x2j3es9aek"
                d="M260.5 232.74a9 9 0 0 0-3 4.37 21.2 21.2 0 0 0-.91 6.39v13.95l-.17 26.4c8.85 3 20.1.36 29.54-10.14 0 0-.52-33.76-.52-39.31v-.76c-.34-7.83-7.1-11.45-13.75-7.7l-5.59 3.16-5.61 3.64Z"
                className="animable"
                style={{
                  opacity: 0.2,
                  transformOrigin: "271.19px 254.73px",
                }}
              />
            </g>
            <path
              id="el15gv5kqnuyk"
              d="M279.68 201.26c-1-1-1.62-2.55-.43-3.7a1.91 1.91 0 0 1 1-.58c.48-.07.91 0 1.39-.12s1.11-.22 1.65-.38a10.92 10.92 0 0 0 3-1.4.33.33 0 0 1 .25-.08c.1 0 .15.15.17.26a5.92 5.92 0 0 1-2.63 5.85 5 5 0 0 1-2.23.88 2.83 2.83 0 0 1-2.17-.73Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "282.688px 198.5px",
              }}
            />
            <path
              id="elp8cbknfkjxn"
              d="M261.69 213.45s1.37 7.09 1.92 8.17a2.1 2.1 0 0 0 1.69 1.12l-.09-5.22Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "263.495px 218.095px",
              }}
            />
            <path
              id="eluqjljkqxu2"
              d="M263.41 202.3a3.85 3.85 0 0 0-2.63 1.78c-1.14 1.64-.5 5.65.91 9.37l2.58-.35Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "262.232px 207.875px",
              }}
            />
            <path
              id="eld7ube4rvgp"
              d="M265.05 212.9c-.52.49-1.26-.46-1.9-1.06s-2.73-1.1-3.77 1.71.92 5.76 2.56 5.92c2.84.26 3.27-1.95 3.27-1.95l.09 12.11c4.76 5 13.1-.91 9.35-3.14v-3.59a16.45 16.45 0 0 0 3.83-.8c2.08-.93 3.39-3.18 4-6 1-4.46.92-7.11.58-15.15-.23-5.52-10.08-7.23-15-2.35s-3.01 14.3-3.01 14.3Z"
              className="animable"
              style={{
                fill: "#b17a7b",
                transformOrigin: "271.19px 213.657px",
              }}
            />
            <path
              id="el00rxftv1c1re"
              d="M265.05 213.9c-.26.08-1.25-1.56-1.9-2.06-.89-.7.26-9.54.26-9.54-1-2.27.87-5.71 2.87-7.11a11.65 11.65 0 0 1 4.73-1.74c2-.36 3.95-.58 5.95-.79a20.68 20.68 0 0 0 3.22-.57 26.67 26.67 0 0 0 2.95-1.18 1.17 1.17 0 0 1 .89-.11c1.19.54.41 3.38.14 4.31a10.49 10.49 0 0 1-2.35 4.24 6.42 6.42 0 0 1-1.24 1.09 8.17 8.17 0 0 1-1.77.83 23.17 23.17 0 0 1-9 1.31 4 4 0 0 0-1.7.13c-1.24.5-1.18 2.65-1.37 3.75-.42 2.71-.8 7.19-1.68 7.44Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "273.737px 202.33px",
              }}
            />
            <path
              id="el9cigbbvj69n"
              d="m263.42 203-3.14-1.11a2.08 2.08 0 0 1 2.37-1.54c.87.27 1.21 1.46.77 2.65Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "261.946px 201.66px",
              }}
            />
            <path
              id="eljw6c0lg04nl"
              d="M274.64 222.9s-4.65.16-6.28-.4a4 4 0 0 1-2.25-2 6.82 6.82 0 0 0 1.28 2.69c1.19 1.44 7.25 1.11 7.25 1.11Z"
              className="animable"
              style={{
                fill: "#9a585a",
                transformOrigin: "270.375px 222.416px",
              }}
            />
            <path
              id="elcqcvwti3wsj"
              d="M274.05 210.47a1.43 1.43 0 0 1-.93 1.37c-.51.13-.93-.26-.93-.88a1.43 1.43 0 0 1 .93-1.36c.52-.14.93.25.93.87Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "273.12px 210.718px",
              }}
            />
            <path
              id="elpnb3paab1ng"
              d="m272.71 207.33-2 1.8a1.76 1.76 0 0 1 .47-1.95.92.92 0 0 1 1.53.15Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "271.65px 207.984px",
              }}
            />
            <path
              id="el209oo4b0468"
              d="m274.22 216.54 2.13.35a1.46 1.46 0 0 1-1.45 1.2c-.58-.09-.9-.79-.68-1.55Z"
              className="animable"
              style={{
                fill: "#9a585a",
                transformOrigin: "275.25px 217.315px",
              }}
            />
            <path
              id="elne1anxmzomo"
              d="m282.31 206-1.82-1.13c.37-.7 1.08-1 1.58-.7a1.4 1.4 0 0 1 .24 1.83Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "281.518px 205.027px",
              }}
            />
            <path
              id="elyk8eek8dbie"
              d="M281.81 208.08a1.43 1.43 0 0 1-.93 1.37c-.51.14-.93-.25-.93-.87a1.45 1.45 0 0 1 .93-1.37c.52-.13.93.26.93.87Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "280.88px 208.332px",
              }}
            />
            <path
              id="eluiiruk9avsf"
              d="m277.2 208.46.11 6.5 3.35-1.75-3.46-4.75z"
              className="animable"
              style={{
                fill: "#9a585a",
                transformOrigin: "278.93px 211.71px",
              }}
            />
          </g>
        </g>
        <path
          id="elwalwrw3g18l"
          d="M247.33 272.65c2-26.89 5.94-36.53 12.49-39.36 2.27 1.69 2.81 9.59.45 15.59 0 0-5.09 17.93-5.38 24a98.11 98.11 0 0 0 1 17 2.44 2.44 0 0 0 1 1.69c1.44.93 1.34 2.28 2.37 3.57.8 1 1.55 1.17 1.39 1.55a1.77 1.77 0 0 1-2 1.07 5.53 5.53 0 0 1-1.76-1 14.43 14.43 0 0 0 1.85 4c1.86 2.84 1.77 4.76-2.6 5.41-2.74.4-4.73-1.3-6.11-8.16-.4-2-1.33-5.54-1.72-8.1a79.26 79.26 0 0 1-.98-17.26Z"
          className="animable"
          style={{
            fill: "#b17a7b",
            transformOrigin: "254.511px 269.757px",
          }}
        />
        <path
          id="elr9f5d8v4o"
          d="M261.69 232s-4.78 1.67-7.19 5.58-4.7 10.19-6.32 21.35c0 0 3 4.13 10.9.7 0 0 3.42-14.24 3.73-19.87.33-6.17-1.12-7.76-1.12-7.76Z"
          className="animable"
          style={{
            fill: "#455a64",
            transformOrigin: "255.519px 246.496px",
          }}
        />
      </g>
      <path
        id="el71jwucy277v"
        d="m296.3 247.71-.06-2.93-3.48-2a1.09 1.09 0 0 0-1.11.11l-48.49 28a3.49 3.49 0 0 0-1.57 2.72v14.44a1.1 1.1 0 0 0 .45 1l3.49 2v-3.45l46.12-26.6a3.46 3.46 0 0 0 1.57-2.72v-10.6Z"
        className="animable"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "268.942px 266.863px",
        }}
      />
      <path
        id="elxnn5ik5mb2q"
        d="m296.24 244.78-3.48-2a1.09 1.09 0 0 0-1.11.11l-48.49 28a3.12 3.12 0 0 0-1 1l4.93 2.85 46.12-26.05Z"
        className="animable"
        style={{
          fill: "#fafafa",
          transformOrigin: "269.2px 258.708px",
        }}
      />
      <path
        id="el97x83qd3np"
        d="m246.64 272.88 48.48-28c.87-.5 1.57-.09 1.57.91v14.49a3.45 3.45 0 0 1-1.57 2.72l-48.48 28c-.87.5-1.57.09-1.57-.91v-14.48a3.47 3.47 0 0 1 1.57-2.73Z"
        className="animable"
        style={{
          fill: "#f0f0f0",
          transformOrigin: "270.88px 267.94px",
        }}
      />
      <g
        id="freepik--stars--inject-15--inject-26"
        className="animable"
        style={{
          transformOrigin: "270.909px 268.18px",
        }}
      >
        <path
          id="eletnh7kujd1"
          d="m251.25 274.23.89 1.87a.21.21 0 0 0 .28.12l2-.76c.31-.12.43.32.21.75l-1.43 2.68a.88.88 0 0 0-.11.51l.34 2.42c.05.39-.28.9-.56.86l-1.76-.22a.46.46 0 0 0-.36.21l-1.75 2.25c-.28.36-.61.23-.56-.21l.34-2.82a.4.4 0 0 0-.11-.38l-1.43-1c-.22-.16-.1-.74.22-1l2-1.52a.94.94 0 0 0 .29-.44l.88-2.89c.08-.49.48-.73.62-.43Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "250.935px 279.613px",
          }}
        />
        <path
          id="elugo1f1yj21h"
          d="m261.24 268.51.88 1.88a.21.21 0 0 0 .29.11l2-.76c.31-.12.44.32.21.75l-1.43 2.68a.89.89 0 0 0-.11.51l.34 2.43c.05.38-.27.89-.55.85l-1.77-.21a.41.41 0 0 0-.35.2l-1.75 2.26c-.28.36-.6.23-.55-.22l.34-2.81a.41.41 0 0 0-.11-.39l-1.43-1c-.23-.17-.1-.75.21-1l2-1.52a1 1 0 0 0 .29-.45l.88-2.89c.06-.48.47-.71.61-.42Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "260.935px 273.9px",
          }}
        />
        <path
          id="eltjv9rjj91sp"
          d="m271.22 262.8.88 1.87a.21.21 0 0 0 .29.11l2-.75c.32-.12.44.32.22.74l-1.43 2.68a.91.91 0 0 0-.11.52l.34 2.42c.05.39-.28.89-.56.86l-1.76-.22a.44.44 0 0 0-.36.21l-1.76 2.25c-.28.36-.61.23-.56-.22l.34-2.81a.42.42 0 0 0-.11-.39l-1.43-1c-.22-.16-.1-.75.22-1l2-1.52a1 1 0 0 0 .29-.44l.88-2.9c.08-.48.48-.71.62-.41Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "270.91px 268.182px",
          }}
        />
        <path
          id="el1piv4x14inv"
          d="m281.21 257.08.88 1.87a.22.22 0 0 0 .29.12l2-.76c.31-.12.44.32.21.75l-1.43 2.68a.88.88 0 0 0-.11.51l.34 2.42c.05.39-.27.9-.55.86l-1.77-.22a.45.45 0 0 0-.35.21l-1.77 2.25c-.28.36-.61.23-.55-.21l.33-2.82a.38.38 0 0 0-.11-.38l-1.42-1c-.23-.17-.1-.75.21-1l2-1.52a.88.88 0 0 0 .29-.44l.88-2.89c.08-.51.49-.73.63-.43Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "280.895px 262.461px",
          }}
        />
        <path
          id="elgnwi85y4vxr"
          d="m291.19 251.36.88 1.88a.21.21 0 0 0 .29.11l2-.76c.32-.12.44.32.21.75l-1.45 2.66a.89.89 0 0 0-.11.51l.33 2.43c.06.38-.27.89-.55.86l-1.79-.2a.41.41 0 0 0-.36.2l-1.76 2.26c-.28.36-.61.23-.56-.22l.34-2.81a.41.41 0 0 0-.11-.39l-1.43-1c-.22-.17-.1-.75.21-1l2-1.52a.94.94 0 0 0 .28-.45l.89-2.89c.15-.48.55-.71.69-.42Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "290.848px 256.75px",
          }}
        />
      </g>
      <path
        id="ely94gllh49r"
        d="m296.3 68.74-.06-2.93-3.48-2a1.09 1.09 0 0 0-1.11.11l-48.49 28a3.49 3.49 0 0 0-1.57 2.72v61.66a1.11 1.11 0 0 0 .45 1l3.49 2v-3.47l46.11-26.57a3.46 3.46 0 0 0 1.57-2.72v-57.8Z"
        className="animable"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "268.943px 111.503px",
        }}
      />
      <path
        id="elo8odg69444"
        d="m296.24 65.81-3.48-2a1.09 1.09 0 0 0-1.11.11l-48.49 28a3.12 3.12 0 0 0-1 1l4.93 2.85 46.12-26Z"
        className="animable"
        style={{
          fill: "#fafafa",
          transformOrigin: "269.2px 79.7385px",
        }}
      />
      <path
        id="elr7ydjuy50ea"
        d="m246.64 93.91 48.48-28c.87-.5 1.57-.1 1.57.91v61.71a3.46 3.46 0 0 1-1.57 2.72l-48.48 27.95c-.87.5-1.57.1-1.57-.91V96.63a3.46 3.46 0 0 1 1.57-2.72Z"
        className="animable"
        style={{
          fill: "#f0f0f0",
          transformOrigin: "270.88px 112.555px",
        }}
      />
      <g
        id="elxvgfu4xukd7"
        className="animable"
        style={{
          clipPath: "url(#freepik--clip-path-2--inject-15--inject-26)",
          transformOrigin: "276.171px 144.654px",
        }}
      >
        <g
          id="freepik--Character--inject-15--inject-26"
          className="animable"
          style={{
            transformOrigin: "281.645px 135.708px",
          }}
        >
          <path
            id="elbhs8ikotvvr"
            d="M284.1 157.54c-5.44-23.86-11.66-28.07-5.85-33 6-2.28 9.06 1 10.1 8 .51 3.42 1.62 17.72 3 23.07 1.22 4.7 2.86 10.52 4.69 13.88a2 2 0 0 0 1.5 1c1.7.25 2.2 1.2 3.59 1.91 1.08.55 1.88.41 1.83.79a2.15 2.15 0 0 1-1.71 1.7 5.93 5.93 0 0 1-2-.14 11.29 11.29 0 0 0 3 2.72c2.66 1.72 3.1 3.41-1.13 5.66-2.64 1.41-5.12.72-8.4-4.65a42.79 42.79 0 0 1-3.31-6.22c-2.34-5.84-4.41-10.88-5.31-14.72Z"
            className="animable"
            style={{
              fill: "#b16668",
              transformOrigin: "290.096px 153.793px",
            }}
          />
          <path
            id="el1mxpa5sty0y"
            d="M278.25 124.5s2.79-1.31 4.83-.87 4.56 2.11 5.45 8.49c0 0-.54 2-3.4 4Z"
            className="animable"
            style={{
              fill: "#455a64",
              transformOrigin: "283.39px 129.83px",
            }}
          />
          <path
            id="el3zzfpyg2msi"
            d="M268.69 127.38c-5.92 2.05-6.33 5.46-7.81 9.47a13.63 13.63 0 0 0-.28 8.87l4.12 12.66a85.49 85.49 0 0 0-4.17 11.62c10.34 3.9 23.26-3.07 26.37-8.76.13-1.07-.67-8.92-1.16-14.06 1.82-5.76 2.3-9.23 1.53-12.51-1.43-6.1-7-10.92-9.41-10Z"
            className="animable"
            style={{
              fill: "#de4747",
              transformOrigin: "273.817px 147.845px",
            }}
          />
          <g id="ela86eumv05z8">
            <path
              id="elqqhl5czi6p"
              d="M268.69 127.38c-5.92 2.05-6.33 5.46-7.81 9.47a13.63 13.63 0 0 0-.28 8.87l4.12 12.66a85.49 85.49 0 0 0-4.17 11.62c10.34 3.9 23.26-3.07 26.37-8.76.13-1.07-.67-8.92-1.16-14.06 1.82-5.76 2.3-9.23 1.53-12.51-1.43-6.1-7-10.92-9.41-10Z"
              className="animable"
              style={{
                opacity: 0.1,
                transformOrigin: "273.817px 147.845px",
              }}
            />
          </g>
          <path
            id="el9q96layjmk9"
            d="m275.72 119.23-4.36 1.19c-6.08 1.65-11-2.68-11.05-9.69 0-8.4 5.85-16.82 13.13-18.8 7.27-2 13.2 3.21 13.23 11.61.03 7-4.88 14.03-10.95 15.69Z"
            className="animable"
            style={{
              fill: "#263238",
              transformOrigin: "273.49px 106.137px",
            }}
          />
          <path
            id="el2v1rneczqga"
            d="M270 89.27c1.88 2.69-.11 5.41-2.88 8.33s-5.27 4.92-7.15 2.23-.85-6.55 1.92-9.46 6.25-3.8 8.11-1.1Z"
            className="animable"
            style={{
              fill: "#263238",
              transformOrigin: "264.876px 94.3737px",
            }}
          />
          <path
            id="elbrlhdvrhfd"
            d="m263.24 101-2.79 3.13a2.28 2.28 0 0 1 .3-2.87 1.63 1.63 0 0 1 2.49-.26Z"
            className="animable"
            style={{
              fill: "#263238",
              transformOrigin: "261.658px 102.334px",
            }}
          />
          <path
            id="ellc9ajpfqj9d"
            d="M281.58 96.52c1.4.57 3.13 4.14 3 12.66-.12 7.21-2.13 9.54-3.14 10.32s-3 1-4.87 1.16l.11 4.34s3.2 2.17 3 4.19-4.43 4.08-6.89 2.82a20.74 20.74 0 0 1-4.44-3.23.37.37 0 0 1-.1-.27v-10.87s-1.12 1.55-3 .16a4.08 4.08 0 0 1-.89-5.43c1.21-1.76 3.47-2 4.34-.18 0 0 3-.7 7-4.36a18.22 18.22 0 0 0 5.88-11.31Z"
            className="animable"
            style={{
              fill: "#b16668",
              transformOrigin: "274.142px 114.458px",
            }}
          />
          <path
            id="ellor14ar11gi"
            d="M277 109.18a1.23 1.23 0 0 1-.74 1.18c-.43.14-.8-.16-.82-.67a1.2 1.2 0 0 1 .73-1.18c.39-.15.75.15.83.67Z"
            className="animable"
            style={{
              fill: "#263238",
              transformOrigin: "276.219px 109.433px",
            }}
          />
          <path
            id="eleovpcxj6ev5"
            d="m277.85 115.29 1.82.18a1.25 1.25 0 0 1-1.18 1.07c-.49-.05-.78-.6-.64-1.25Z"
            className="animable"
            style={{
              fill: "#9a4a4d",
              transformOrigin: "278.742px 115.915px",
            }}
          />
          <path
            id="elrbi08wzeys"
            d="m284 104.75-1.59-.84c.29-.6.87-.9 1.31-.67a1.15 1.15 0 0 1 .28 1.51Z"
            className="animable"
            style={{
              fill: "#263238",
              transformOrigin: "283.289px 103.956px",
            }}
          />
          <path
            id="elgnhm9gm5k1b"
            d="M283.8 107a1.21 1.21 0 0 1-.73 1.18c-.44.14-.81-.16-.83-.67a1.23 1.23 0 0 1 .74-1.18c.43-.2.8.1.82.67Z"
            className="animable"
            style={{
              fill: "#263238",
              transformOrigin: "283.02px 107.241px",
            }}
          />
          <path
            id="el8883pmuypa3"
            d="m280.15 106.96-.03 5.89 2.48-1.56-2.45-4.33z"
            className="animable"
            style={{
              fill: "#9a4a4d",
              transformOrigin: "281.36px 109.905px",
            }}
          />
          <path
            id="el3owr58xoeeb"
            d="M287.07 105.62c-.21-1.41-1.6-2-3.08-1.57h-.14a4.29 4.29 0 0 0-2.51 2.27 1.9 1.9 0 0 0-1.48 0 3.79 3.79 0 0 0-1.29.84 2.4 2.4 0 0 0-2.65-.59h-.14a4.2 4.2 0 0 0-2.77 3.13l-4.86 1.6a2.46 2.46 0 0 1 .33.37l4.5-1.49a2.1 2.1 0 0 0 2.95 2l.43-.14a3.87 3.87 0 0 0 2.64-3.92 2.19 2.19 0 0 0-.2-.61 2.87 2.87 0 0 1 1.11-.75 1.39 1.39 0 0 1 1.24 0 3.12 3.12 0 0 0-.11.67 2.11 2.11 0 0 0 3 2.13l.43-.14a3.85 3.85 0 0 0 2.6-3.8Zm-8.54 3.48a3.42 3.42 0 0 1-2.28 2.6l-.26.09a1.82 1.82 0 0 1-2.48-1.08 3.16 3.16 0 0 1 2.15-3.5l.43-.14a1.78 1.78 0 0 1 2.44 2.03Zm8.07-2.6a3.41 3.41 0 0 1-2.28 2.6l-.26.09a1.8 1.8 0 0 1-2.47-1.08 3.15 3.15 0 0 1 2.14-3.5l.43-.14a1.77 1.77 0 0 1 2.44 2.03Z"
            className="animable"
            style={{
              fill: "#de4747",
              transformOrigin: "277.612px 108.136px",
            }}
          />
          <path
            id="elyhz7eg242jm"
            d="M276.57 120.66c-2 .25-6.12 0-6.77-1.7a3.58 3.58 0 0 0 1.45 2c1.24.88 5.36 1.14 5.36 1.14Z"
            className="animable"
            style={{
              fill: "#9a4a4d",
              transformOrigin: "273.205px 120.53px",
            }}
          />
          <g id="elw6n0tpun7h">
            <path
              id="el3bg56rl5egp"
              d="M271.33 168.38c-3.39 0-5.55-3.41-4.65-7.55 0-.16.07-.29.1-.4.67-2 2.1-2.17 2.1-2.17-1.34-.66-2-2.25-2.29-4l-1.59-8.04a12.16 12.16 0 0 1 .31-4.27l-4.15-4.44-.77.82a13.46 13.46 0 0 0 .17 7.39l4.12 12.66a78.63 78.63 0 0 0-4.13 11.62c7.62 2.87 16.64-.16 22.1-4.23a28.25 28.25 0 0 1-11.32 2.61Z"
              className="animable"
              style={{
                opacity: 0.1,
                transformOrigin: "271.302px 154.32px",
              }}
            />
          </g>
        </g>
        <path
          id="elfrv6jiwlgp"
          d="M250 166.66c4.6-22.93 7.5-32.82 12.54-36.31a16.56 16.56 0 0 1 3.41-1.69c.69.26.76 1.78.9 2.75a24.21 24.21 0 0 1-2.62 13.13s-6.71 18.14-7.78 24.24c-.94 5.33-2 12-1.94 16.38a2.74 2.74 0 0 0 .74 1.93c1.23 1.21 1.23 2.44 2 3.93.62 1.16 1.3 1.47 1.11 1.83a1.59 1.59 0 0 1-2 .71 5.59 5.59 0 0 1-1.52-1.29 16.16 16.16 0 0 0 1.21 4.38c1.37 3.22 1 5.14-3.15 5-2.63-.09-4.28-2.18-4.67-9.36a64.22 64.22 0 0 1-.07-8.05c.54-7.08.94-13.24 1.84-17.58Z"
          className="animable"
          style={{
            fill: "#b16668",
            transformOrigin: "257.5px 165.159px",
          }}
        />
        <path
          id="elxmx2xiy23vr"
          d="M266.32 128.43a7.81 7.81 0 0 0-6.68 3.69 41.83 41.83 0 0 0-3.52 8.37c.81 1.69 5.3 6.11 7.48 6.66a32.49 32.49 0 0 0 1.92-4.49c1.32-3.73 3.38-10.11.8-14.23Z"
          className="animable"
          style={{
            fill: "#455a64",
            transformOrigin: "261.837px 137.79px",
          }}
        />
      </g>
      <path
        id="elxtpt23su75d"
        d="m296.3 142-.06-2.93-3.48-2a1.11 1.11 0 0 0-1.11.11l-48.49 28a3.49 3.49 0 0 0-1.57 2.73v14.44a1.1 1.1 0 0 0 .45 1l3.49 2v-3.46l46.11-26.58a3.45 3.45 0 0 0 1.57-2.72V142Z"
        className="animable"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "268.942px 161.16px",
        }}
      />
      <path
        id="ela03zniw7697"
        d="m296.24 139.08-3.48-2a1.11 1.11 0 0 0-1.11.11l-48.49 28a3.05 3.05 0 0 0-1 1l4.94 2.81 46.12-26Z"
        className="animable"
        style={{
          fill: "#fafafa",
          transformOrigin: "269.2px 152.99px",
        }}
      />
      <path
        id="elya3yzjw3pir"
        d="m246.64 167.18 48.48-28c.87-.5 1.57-.09 1.57.91v14.49a3.47 3.47 0 0 1-1.57 2.73l-48.48 27.94c-.87.5-1.57.09-1.57-.91v-14.43a3.47 3.47 0 0 1 1.57-2.73Z"
        className="animable"
        style={{
          fill: "#f0f0f0",
          transformOrigin: "270.88px 162.215px",
        }}
      />
      <g
        className="animable"
        style={{
          transformOrigin: "270.909px 162.484px",
        }}
      >
        <path
          id="elns2wkwu4ljj"
          d="m251.25 168.53.89 1.88a.21.21 0 0 0 .28.11l2-.76c.31-.12.43.32.21.75l-1.43 2.68a.89.89 0 0 0-.11.51l.34 2.43c.05.38-.28.89-.56.85l-1.76-.22a.46.46 0 0 0-.36.21l-1.75 2.26c-.28.35-.61.23-.56-.22l.34-2.82a.4.4 0 0 0-.11-.38l-1.43-1c-.22-.17-.1-.75.22-1l2-1.53a.94.94 0 0 0 .29-.44l.88-2.89c.08-.48.48-.72.62-.42Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "250.935px 173.916px",
          }}
        />
        <path
          id="elqni0iclfazf"
          d="m261.24 162.82.88 1.87a.21.21 0 0 0 .29.11l2-.75c.31-.12.44.32.21.74l-1.43 2.68a.89.89 0 0 0-.11.51l.34 2.43c.05.38-.27.89-.55.86l-1.77-.22a.41.41 0 0 0-.35.2l-1.75 2.26c-.28.36-.6.23-.55-.22l.34-2.81a.42.42 0 0 0-.11-.39l-1.43-1c-.23-.16-.1-.75.21-1l2-1.52a.91.91 0 0 0 .29-.45l.88-2.89c.06-.48.47-.71.61-.41Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "260.935px 168.202px",
          }}
        />
        <path
          id="elr347is4ukki"
          d="m271.22 157.1.88 1.87a.22.22 0 0 0 .29.12l2-.76c.32-.12.44.32.22.75l-1.43 2.67a.93.93 0 0 0-.11.52l.34 2.42c.05.39-.28.89-.56.86l-1.76-.22a.44.44 0 0 0-.36.21l-1.76 2.25c-.28.36-.61.23-.56-.21l.34-2.82a.42.42 0 0 0-.11-.39l-1.43-1c-.22-.16-.1-.75.22-1l2-1.52a.94.94 0 0 0 .29-.44l.88-2.9c.08-.51.48-.71.62-.41Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "270.91px 162.479px",
          }}
        />
        <path
          id="elqr6s2lr4ku"
          d="m281.21 151.38.88 1.88a.21.21 0 0 0 .29.11l2-.76c.31-.12.44.32.21.75l-1.46 2.64a.89.89 0 0 0-.11.51l.34 2.43c.05.38-.27.89-.55.85l-1.77-.21a.41.41 0 0 0-.35.2l-1.77 2.26c-.28.35-.61.23-.55-.22l.33-2.81a.39.39 0 0 0-.11-.39l-1.42-1c-.23-.17-.1-.75.21-1l2-1.53a.88.88 0 0 0 .29-.44l.88-2.89c.11-.44.52-.67.66-.38Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "280.88px 156.747px",
          }}
        />
        <path
          id="el83bvwg3gomf"
          d="m291.19 145.67.88 1.87a.21.21 0 0 0 .29.11l2-.75c.32-.12.44.32.21.74l-1.42 2.68a.91.91 0 0 0-.11.52l.33 2.42c.06.39-.27.89-.55.86l-1.82-.22a.44.44 0 0 0-.36.2l-1.76 2.26c-.28.36-.61.23-.56-.22l.34-2.81a.42.42 0 0 0-.11-.39l-1.43-1c-.22-.16-.1-.75.21-1l2-1.52a.89.89 0 0 0 .28-.45l.89-2.89c.15-.48.55-.71.69-.41Z"
          className="animable"
          style={{
            fill: "#e0e0e0",
            transformOrigin: "290.848px 151.052px",
          }}
        />
      </g>
      <path
        id="elp38oxoj7b1a"
        d="m230.61 211.87-.06-2.93-3.48-2a1.09 1.09 0 0 0-1.11.11l-48.48 28a3.46 3.46 0 0 0-1.57 2.72v61.66a1.13 1.13 0 0 0 .44 1l3.48 2V299L226 272.41a3.48 3.48 0 0 0 1.58-2.72v-57.82Z"
        className="animable"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "203.258px 254.633px",
        }}
      />
      <path
        id="elvkt9lg1mx1l"
        d="m230.55 208.94-3.48-2a1.09 1.09 0 0 0-1.11.11l-48.48 28a3.12 3.12 0 0 0-1 1l4.92 2.85 46.13-26.05Z"
        className="animable"
        style={{
          fill: "#fafafa",
          transformOrigin: "203.515px 222.868px",
        }}
      />
      <path
        id="el2ghz7qik222"
        d="m181 237 48.49-28c.87-.5 1.57-.1 1.57.91v61.71a3.46 3.46 0 0 1-1.57 2.72L181 302.33c-.87.5-1.58.1-1.58-.91v-61.66A3.49 3.49 0 0 1 181 237Z"
        className="animable"
        style={{
          fill: "#f0f0f0",
          transformOrigin: "205.24px 255.665px",
        }}
      />
      <g
        id="elmqwy3e61ku"
        className="animable"
        style={{
          clipPath: "url(#freepik--clip-path-3--inject-15--inject-26)",
          transformOrigin: "210.297px 287.612px",
        }}
      >
        <path
          id="elg8qkoigzf65"
          d="M219.53 298.91c-5.84-25.63-12.87-30.28-6.63-35.62 7.27-1.11 10.08 1.17 11.2 8.69.55 3.67 1.74 19 3.23 24.78 1.31 5.05 3.08 11.3 5 14.91a2.14 2.14 0 0 0 1.67 1.13c1.83.26 2.36 1.29 3.85 2 1.17.6 2 .45 2 .86a2.27 2.27 0 0 1-1.84 1.81 6.23 6.23 0 0 1-2.18-.14 12 12 0 0 0 3.18 2.92c2.86 1.85 3.34 3.66-1.2 6.08-2.85 1.51-5.51.77-9-5a47.07 47.07 0 0 1-3.55-6.68c-2.54-6.2-4.79-11.65-5.73-15.74Z"
          className="animable"
          style={{
            fill: "#ffa8a7",
            transformOrigin: "225.827px 295.017px",
          }}
        />
        <path
          id="elxw7kudqgmp"
          d="M212.9 263.29s3.76-1.13 6.53 0 5 2.72 5.73 13.57l-5.63 3.24Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "219.03px 271.444px",
          }}
        />
        <path
          id="el7naijkhq5ds"
          d="M188.66 266.31a9.23 9.23 0 0 0 9.23-9.23v-12.79a9.24 9.24 0 0 0-9.23 9.23Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "193.275px 255.3px",
          }}
        />
        <g id="elh51xwwp2cmn">
          <path
            id="eldgtuoimv4tf"
            d="M188.66 266.31a9.23 9.23 0 0 0 9.23-9.23v-12.79a9.24 9.24 0 0 0-9.23 9.23Z"
            className="animable"
            style={{
              opacity: 0.4,
              transformOrigin: "193.275px 255.3px",
            }}
          />
        </g>
        <path
          id="el65sww7340ih"
          d="M211.08 259.43a11.81 11.81 0 0 1-15.22-11.31c0-8.4 5.9-16.79 13.18-18.74l1-.26c7.28-2 13.17 3.28 13.17 11.68v2.34a17 17 0 0 1-12.11 16.29Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "209.535px 244.315px",
          }}
        />
        <g id="el9jz4t9zmdeo">
          <path
            id="elhpyfed0wb5q"
            d="M211.08 259.43a11.81 11.81 0 0 1-15.22-11.31c0-8.4 5.9-16.79 13.18-18.74l1-.26c7.28-2 13.17 3.28 13.17 11.68v2.34a17 17 0 0 1-12.11 16.29Z"
            className="animable"
            style={{
              opacity: 0.3,
              transformOrigin: "209.535px 244.315px",
            }}
          />
        </g>
        <path
          id="els3yd51j9an"
          d="M200.39 232.86c-.71-.71-1.92-.44-2.7.59l1.44 1.43a1.81 1.81 0 0 0-1 .19 3 3 0 0 0-1.48 3l3.75-1.91a1.6 1.6 0 0 0-.08-.26 2.46 2.46 0 0 0 .07-3.04Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "198.753px 235.267px",
          }}
        />
        <g id="eltwtha2jmogk">
          <path
            id="elovc9ervwo3"
            d="M200.39 232.86c-.71-.71-1.92-.44-2.7.59l1.44 1.43a1.81 1.81 0 0 0-1 .19 3 3 0 0 0-1.48 3l3.75-1.91a1.6 1.6 0 0 0-.08-.26 2.46 2.46 0 0 0 .07-3.04Z"
            className="animable"
            style={{
              opacity: 0.3,
              transformOrigin: "198.753px 235.267px",
            }}
          />
        </g>
        <path
          id="elzjl6zxv0e5"
          d="M204.15 265.92s-8 1.89-8.93 2.64c5.77 6.2 1.05 19.27 1.05 19.27l3.21 11.17a64.64 64.64 0 0 0-6.59 15.06c-1.91 6.34-3.13 11.11-4.61 30.2 7.58 6 32.83-.56 39.25-12 0 0-2-12.52-4.52-24.15-1.87-8.49-3.71-13.08-3.26-16.14 3.39-12.93 3.65-16.15 3.05-18.67-1.69-7.1-8.35-10.42-10-10Z"
          className="animable"
          style={{
            fill: "#37474f",
            transformOrigin: "207.905px 304.896px",
          }}
        />
        <path
          id="el5mymviz7kwv"
          d="M218.05 233.79c1.44.6 3.23 4.31 3 13.15-.15 7.49-2.24 9.89-3.29 10.7s-3.07 1-5 1.18v4.47s2.72 2.92 2.55 5-3.93 3.59-6.48 2.27-4.69-4.65-4.69-4.65v-10.29s-1.16 1.61-3.12.15a4.23 4.23 0 0 1-.91-5.63c1.26-1.82 3.6-2.05 4.5-.17a13.35 13.35 0 0 1 5.62-6.73c4.16-2.63 6.39-5.01 7.82-9.45Z"
          className="animable"
          style={{
            fill: "#ffa8a7",
            transformOrigin: "210.245px 252.423px",
          }}
        />
        <path
          id="eleg2gqks1gdk"
          d="M213.9 247a1.27 1.27 0 0 1-.77 1.22c-.45.14-.83-.17-.85-.7a1.26 1.26 0 0 1 .77-1.22c.44-.15.83.17.85.7Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "213.09px 247.258px",
          }}
        />
        <path
          id="elotacqqwhdqj"
          d="m214.12 253.25 1.88.19a1.33 1.33 0 0 1-1.23 1.11c-.52-.05-.77-.63-.65-1.3Z"
          className="animable"
          style={{
            fill: "#f28f8f",
            transformOrigin: "215.045px 253.9px",
          }}
        />
        <path
          id="elcgbg134tmr7"
          d="m220.55 242.34-1.64-.88c.3-.62.91-.92 1.36-.68a1.18 1.18 0 0 1 .28 1.56Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "219.82px 241.519px",
          }}
        />
        <path
          id="elwnv1sin315p"
          d="m211.54 245.45 1.64-1.76c-.3-.46-.91-.44-1.36.05a1.48 1.48 0 0 0-.28 1.71Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "212.282px 244.404px",
          }}
        />
        <path
          id="el0e2hi5id0ki"
          d="M220.17 244.64a1.26 1.26 0 0 1-.77 1.22c-.44.14-.83-.17-.85-.7a1.26 1.26 0 0 1 .77-1.22c.45-.14.83.17.85.7Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "219.36px 244.9px",
          }}
        />
        <path
          id="el8k6m8y4hfcl"
          d="m216.34 245.13.13 5.6 2.57-1.61-2.7-3.99z"
          className="animable"
          style={{
            fill: "#f28f8f",
            transformOrigin: "217.69px 247.93px",
          }}
        />
        <path
          id="elsrqbhs48cv"
          d="M212.77 258.82c-2.08.24-6.34 0-7-1.8a3.68 3.68 0 0 0 1.5 2.08c1.27.92 5.51 1.21 5.51 1.21Z"
          className="animable"
          style={{
            fill: "#f28f8f",
            transformOrigin: "209.275px 258.665px",
          }}
        />
        <path
          id="elrq99i5zbvb"
          d="M181.46 308.61c4.94-24.64 8.35-36.27 13.76-40.05 2.71.28 3.53 3.88 3.81 5.94a23.35 23.35 0 0 1-2.76 13.33s-6.73 16.5-7.88 23.05c-1 5.73-2.15 12.9-2.09 17.6a2.94 2.94 0 0 0 .8 2.07c1.32 1.3 1.33 2.63 2.18 4.22.67 1.25 1.4 1.59 1.19 2a1.7 1.7 0 0 1-2.16.76 5.64 5.64 0 0 1-1.64-1.38 17.67 17.67 0 0 0 1.3 4.71c1.48 3.45 1.12 5.51-3.39 5.35-2.82-.11-4.59-2.35-5-10.06a69.84 69.84 0 0 1-.08-8.65c.59-7.62 1.03-14.26 1.96-18.89Z"
          className="animable"
          style={{
            fill: "#ffa8a7",
            transformOrigin: "189.265px 307.389px",
          }}
        />
        <path
          id="elt05mmngpdca"
          d="M196.12 268.18c-2.21.77-4.36 2.95-6.41 7.69s-4.48 14.57-4.48 14.57 4.34 4.58 9.13 3.21c0 0 3.72-7.74 4.66-11.79s1.67-11.34-2.9-13.68Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "192.476px 281.043px",
          }}
        />
      </g>
      <path
        id="elcrrobjwzw1"
        d="m230.61 285.12-.06-2.93-3.48-2a1.09 1.09 0 0 0-1.11.11l-48.48 28a3.46 3.46 0 0 0-1.57 2.72v14.44a1.13 1.13 0 0 0 .44 1l3.48 2V325L226 298.44a3.48 3.48 0 0 0 1.58-2.72v-10.6Z"
        className="animable"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "203.258px 304.273px",
        }}
      />
      <path
        id="ellwsp7w8y9r"
        d="m230.55 282.19-3.48-2a1.09 1.09 0 0 0-1.11.11l-48.48 28a3.12 3.12 0 0 0-1 1l4.92 2.85 46.13-26Z"
        className="animable"
        style={{
          fill: "#fafafa",
          transformOrigin: "203.515px 296.118px",
        }}
      />
      <path
        id="el2q05f40uoxr"
        d="m181 310.29 48.49-28c.87-.5 1.57-.1 1.57.91v14.5a3.46 3.46 0 0 1-1.57 2.72L181 328.36c-.87.51-1.58.1-1.58-.9V313a3.49 3.49 0 0 1 1.58-2.71Z"
        className="animable"
        style={{
          fill: "#f0f0f0",
          transformOrigin: "205.24px 305.327px",
        }}
      />
      <g
        className="animable"
        style={{
          transformOrigin: "205.225px 305.59px",
        }}
      >
        <path
          id="eld97oxabfvcg"
          d="m185.57 311.64.88 1.87a.22.22 0 0 0 .29.12l2-.76c.31-.12.44.32.21.75l-1.42 2.67a.85.85 0 0 0-.11.52l.33 2.42c.06.39-.27.9-.55.86l-1.77-.22a.45.45 0 0 0-.35.21l-1.76 2.25c-.29.36-.61.23-.56-.21l.34-2.82a.42.42 0 0 0-.11-.39l-1.43-1c-.23-.16-.1-.75.21-1l2-1.52a.82.82 0 0 0 .29-.44l.88-2.89c.06-.48.49-.72.63-.42Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "185.255px 317.023px",
          }}
        />
        <path
          id="elpxcxwqz2an"
          d="m195.55 305.92.89 1.88a.2.2 0 0 0 .28.11l2-.76c.31-.12.43.32.21.75l-1.43 2.68a.89.89 0 0 0-.11.51l.34 2.43c0 .38-.28.89-.56.85l-1.76-.21a.42.42 0 0 0-.36.2l-1.76 2.26c-.28.35-.61.23-.55-.22l.33-2.81a.41.41 0 0 0-.11-.39l-1.42-1c-.23-.17-.11-.75.21-1l2-1.52a1 1 0 0 0 .29-.45l.88-2.89c.08-.48.49-.71.63-.42Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "195.232px 311.308px",
          }}
        />
        <path
          id="el95zao7gwyci"
          d="m205.54 300.21.88 1.87a.21.21 0 0 0 .29.11l2-.75c.31-.12.44.32.21.74l-1.43 2.68a.91.91 0 0 0-.11.52l.34 2.42c.06.39-.27.89-.55.86l-1.77-.22a.43.43 0 0 0-.35.2l-1.77 2.26c-.28.36-.6.23-.55-.22l.34-2.81a.42.42 0 0 0-.11-.39l-1.43-1c-.23-.16-.1-.75.21-1l2-1.52a.84.84 0 0 0 .29-.45l.88-2.89c.09-.48.49-.71.63-.41Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "205.225px 305.592px",
          }}
        />
        <path
          id="elglzqm53x7y"
          d="m215.52 294.49.88 1.87a.22.22 0 0 0 .29.12l2-.76c.32-.12.44.32.22.75l-1.43 2.68a.88.88 0 0 0-.11.51l.34 2.42c0 .39-.28.9-.56.86l-1.76-.22a.46.46 0 0 0-.36.21l-1.76 2.25c-.28.36-.61.23-.56-.21l.34-2.82a.4.4 0 0 0-.11-.38l-1.43-1c-.22-.16-.1-.75.22-1l2-1.52a.94.94 0 0 0 .29-.44l.88-2.89c.1-.49.48-.73.62-.43Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "215.21px 299.873px",
          }}
        />
        <path
          id="el4x0lkf6632l"
          d="m215.36 302.72 1.76.22c.28 0 .61-.47.56-.86l-.34-2.42a.88.88 0 0 1 .11-.51l1.43-2.68c.22-.43.1-.87-.22-.75l-2 .76a.22.22 0 0 1-.29-.12l-.88-1.87c-.07-.15-.2-.16-.34-.09v8.37a.31.31 0 0 1 .21-.05Z"
          className="animable"
          style={{
            fill: "#e0e0e0",
            transformOrigin: "217.071px 298.649px",
          }}
        />
        <path
          id="elhoq2jsspub"
          d="m225.51 288.77.88 1.88a.21.21 0 0 0 .29.11l2-.76c.31-.12.44.32.21.75l-1.43 2.68a.89.89 0 0 0-.11.51l.34 2.43c.05.38-.27.89-.55.85l-1.77-.21a.41.41 0 0 0-.35.2l-1.77 2.26c-.28.35-.61.23-.55-.22l.34-2.81a.41.41 0 0 0-.11-.39l-1.43-1c-.23-.17-.1-.75.21-1l2-1.52a1 1 0 0 0 .29-.45l.88-2.89c.12-.48.49-.71.63-.42Z"
          className="animable"
          style={{
            fill: "#e0e0e0",
            transformOrigin: "225.195px 294.158px",
          }}
        />
      </g>
      <path
        id="els5pqhs1627b"
        d="m230.61 106.64-.06-2.93-3.48-2a1.11 1.11 0 0 0-1.11.11l-48.48 28a3.47 3.47 0 0 0-1.57 2.73v61.66a1.11 1.11 0 0 0 .44 1l3.48 2v-3.46L226 167.18a3.46 3.46 0 0 0 1.58-2.72v-57.82Z"
        className="animable"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "203.257px 149.41px",
        }}
      />
      <path
        id="el8pt4t5zqn3u"
        d="m230.55 103.71-3.48-2a1.11 1.11 0 0 0-1.11.11l-48.48 28a3.05 3.05 0 0 0-1 1l4.92 2.84 46.13-26Z"
        className="animable"
        style={{
          fill: "#fafafa",
          transformOrigin: "203.515px 117.635px",
        }}
      />
      <path
        id="el9how03jkk5"
        d="m181 131.81 48.49-28c.87-.5 1.57-.09 1.57.91v61.71a3.47 3.47 0 0 1-1.57 2.73L181 197.11c-.87.5-1.58.09-1.58-.91v-61.66a3.51 3.51 0 0 1 1.58-2.73Z"
        className="animable"
        style={{
          fill: "#f0f0f0",
          transformOrigin: "205.24px 150.46px",
        }}
      />
      <g
        id="el55gfk2cjkpb"
        className="animable"
        style={{
          clipPath: "url(#freepik--clip-path-4--inject-15--inject-26)",
          transformOrigin: "220.383px 181.001px",
        }}
      >
        <g
          className="animable"
          style={{
            transformOrigin: "224.98px 170.367px",
          }}
        >
          <path
            id="elx5ugzlb315"
            d="M214.83 157.89s3.81-1.18 7 3.83c2.15 3.4 9.69 16.33 9.69 16.33s14.67-7.78 15.5-8.8.77-3.08.9-4.19a12.36 12.36 0 0 0-.57-4.12c-.17-1.17 1.5-1.23 2.33.38.61 1.19.66 2.54 1.18 2.55a14.57 14.57 0 0 0 2.06-1.75c.91-1 2.55-2.66 3.79-2.43a1.08 1.08 0 0 1 .83.89 6 6 0 0 0 .47 2 3.18 3.18 0 0 1 .49.79 7 7 0 0 1 0 1.29c0 .61.64.87.73 1.47a11.17 11.17 0 0 1-.16 1.39c0 .3.1.56.1.85a2.83 2.83 0 0 1-.76 1.63 17.22 17.22 0 0 1-4.35 3.65c-1.34.87-3.1 1.65-4.62 2.47s-18.8 14.09-21.1 12.52-13.84-18.38-13.84-18.38c-2.66-7.43.33-12.37.33-12.37Z"
            className="animable"
            style={{
              fill: "#ffa8a7",
              transformOrigin: "236.314px 173.269px",
            }}
          />
          <path
            id="elmxrhiylmzjp"
            d="M208.14 158s4.2-1.7 6.92-1.53 4.75 1 6.74 4.17c2.67 4.29 7.09 11.69 7.09 11.69a21.4 21.4 0 0 1-8.58 9.18l-8.14-12.27Z"
            className="animable"
            style={{
              fill: "#de4747",
              transformOrigin: "218.515px 168.984px",
            }}
          />
          <g id="elm8mu2sunujk">
            <g
              id="elh2oludnw1or"
              className="animable"
              style={{
                opacity: 0.25,
                transformOrigin: "218.515px 168.984px",
              }}
            >
              <path
                id="el28yol3p7647"
                d="M208.14 158s4.2-1.7 6.92-1.53 4.75 1 6.74 4.17c2.67 4.29 7.09 11.69 7.09 11.69a21.4 21.4 0 0 1-8.58 9.18l-8.14-12.27Z"
                className="animable"
                style={{
                  transformOrigin: "218.515px 168.984px",
                }}
              />
            </g>
          </g>
          <g
            className="animable"
            style={{
              transformOrigin: "205.505px 170.367px",
            }}
          >
            <path
              id="elbvfq5fqrzr"
              d="M194.81 165.42a9.16 9.16 0 0 0-3 4.37 21.59 21.59 0 0 0-.9 6.4v13.95l-.18 26.39c8.85 3 20.11.36 29.55-10.13 0 0-.53-33.77-.53-39.32v-.75c-.34-7.83-7.09-11.46-13.75-7.7l-5.58 3.15-5.62 3.64Z"
              className="animable"
              style={{
                fill: "#455a64",
                transformOrigin: "205.505px 187.413px",
              }}
            />
            <path
              id="elxp6looafyxo"
              d="M200.87 136.72c0 5.95 4.18 9.65 9.33 8.27s9.33-7.32 9.33-13.27-4.44-6-9.6-4.58-9.06 3.63-9.06 9.58Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "210.2px 135.826px",
              }}
            />
            <path
              id="elct0onrpjgeb"
              d="M196 146.13s1.38 7.09 1.93 8.17a2.1 2.1 0 0 0 1.69 1.12l-.1-5.22Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "197.81px 150.775px",
              }}
            />
            <path
              id="eli7i7m37lgye"
              d="M197.73 135a3.84 3.84 0 0 0-2.64 1.77c-1.13 1.64-.5 5.66.91 9.37l2.59-.35Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "196.549px 140.57px",
              }}
            />
            <path
              id="elngeakr5ksen"
              d="M199.37 145.58c-.52.5-1.26-.46-1.9-1.06s-2.73-1.1-3.78 1.72.92 5.75 2.57 5.91c2.84.27 3.26-2 3.26-2l.1 12.11c4.76 5 13.09-.9 9.35-3.14v-3.59a16.31 16.31 0 0 0 3.82-.8c2.09-.93 3.4-3.18 4-6 1-4.46 1.4-7.94.54-15.95-.94-8.77-10-6.43-14.94-1.54s-3.02 14.34-3.02 14.34Z"
              className="animable"
              style={{
                fill: "#ffbda7",
                transformOrigin: "205.598px 145.55px",
              }}
            />
            <path
              id="elqzf62mu3gs"
              d="M199.37 146.59c-.26.07-1.26-1.56-1.9-2.07-.9-.7.26-9.53.26-9.53-1-2.28.87-5.72 2.87-7.12a11.58 11.58 0 0 1 4.73-1.73c1.43-.27 2.86-.47 4.28-.6a38.84 38.84 0 0 0 5.19-.62 11.59 11.59 0 0 0 4.1-1.76c.42-.29.84 0 .71.55a8 8 0 0 1-.49 1.36 12.92 12.92 0 0 1-1.38 2.43 20.33 20.33 0 0 1-2 2.22c-3.84 3.64-8.69 4.63-13 5.1-1 .1-1.17 1.24-1.69 4.32-.43 2.71-.8 7.19-1.68 7.45Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "208.381px 134.819px",
              }}
            />
            <path
              id="el5jp5jy0ed7p"
              d="m197.73 135.65-3.14-1.11c.45-1.15 1.51-1.85 2.38-1.54s1.21 1.49.76 2.65Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "196.259px 134.288px",
              }}
            />
            <path
              id="eliktkuvu5pwj"
              d="M209 155.58s-4.66.16-6.29-.4a4 4 0 0 1-2.25-2 6.93 6.93 0 0 0 1.28 2.69c1.15 1.47 7.26 1.13 7.26 1.13Z"
              className="animable"
              style={{
                fill: "#f29077",
                transformOrigin: "204.73px 155.107px",
              }}
            />
            <path
              id="eloodbkcfi0lm"
              d="M207.84 142a1.45 1.45 0 0 1-.93 1.37c-.52.14-.94-.25-.94-.87a1.44 1.44 0 0 1 .94-1.37c.51-.13.93.22.93.87Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "206.905px 142.251px",
              }}
            />
            <path
              id="eljmtzx9jg0m"
              d="m206.34 137.9-2 1.81a1.77 1.77 0 0 1 .47-2 .92.92 0 0 1 1.53.19Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "205.274px 138.547px",
              }}
            />
            <path
              id="el5ynrsb7xd09"
              d="m208.54 149.22 2.13.35a1.46 1.46 0 0 1-1.45 1.2c-.59-.09-.89-.77-.68-1.55Z"
              className="animable"
              style={{
                fill: "#f29077",
                transformOrigin: "209.572px 149.995px",
              }}
            />
            <path
              id="el2uxesm3vu35"
              d="m216.4 136.16-1.82-1.16c.37-.7 1.08-1 1.58-.71a1.42 1.42 0 0 1 .24 1.87Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "215.613px 135.17px",
              }}
            />
            <path
              id="el40c1ls4wm9f"
              d="M215.6 139.58a1.42 1.42 0 0 1-.93 1.36c-.52.14-.94-.25-.94-.87a1.42 1.42 0 0 1 .94-1.36c.51-.14.93.29.93.87Z"
              className="animable"
              style={{
                fill: "#263238",
                transformOrigin: "214.665px 139.826px",
              }}
            />
            <path
              id="elqw9n360xf4g"
              d="m210.99 139.95.11 6.5 3.34-1.74-3.45-4.76z"
              className="animable"
              style={{
                fill: "#f29077",
                transformOrigin: "212.715px 143.2px",
              }}
            />
          </g>
        </g>
        <path
          id="el6ouy6ipezpt"
          d="M181.65 205.33c2-26.89 5.94-36.53 12.49-39.36 2.26 1.69 2.81 9.59.44 15.6 0 0-5.08 17.92-5.37 24a97.31 97.31 0 0 0 1 17 2.41 2.41 0 0 0 1 1.68c1.43.93 1.34 2.29 2.36 3.57.81 1 1.55 1.18 1.4 1.55a1.79 1.79 0 0 1-2 1.07 5.23 5.23 0 0 1-1.76-.95 14.43 14.43 0 0 0 1.85 4c1.86 2.85 1.77 4.77-2.6 5.41-2.74.41-4.74-1.29-6.12-8.16-.39-2-1.33-5.54-1.72-8.1a79.11 79.11 0 0 1-.97-17.31Z"
          className="animable"
          style={{
            fill: "#ffbda7",
            transformOrigin: "188.826px 202.464px",
          }}
        />
        <path
          id="elmwo8hjrwn1s"
          d="M196 164.65s-4.77 1.67-7.18 5.58-4.71 10.2-6.32 21.36c0 0 3 4.12 10.89.69 0 0 3.43-14.24 3.74-19.87.33-6.14-1.13-7.76-1.13-7.76Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "189.839px 179.147px",
          }}
        />
        <g id="elp8hgemxzc">
          <path
            id="el8bnwm7ivhpm"
            d="M196 164.65s-4.77 1.67-7.18 5.58-4.71 10.2-6.32 21.36c0 0 3 4.12 10.89.69 0 0 3.43-14.24 3.74-19.87.33-6.14-1.13-7.76-1.13-7.76Z"
            className="animable"
            style={{
              opacity: 0.15,
              transformOrigin: "189.839px 179.147px",
            }}
          />
        </g>
      </g>
      <path
        id="eltkc4iveehhj"
        d="m230.61 179.89-.06-2.93-3.48-2a1.11 1.11 0 0 0-1.11.11l-48.48 28a3.47 3.47 0 0 0-1.57 2.73v14.44a1.11 1.11 0 0 0 .44 1l3.48 2v-3.46L226 193.22a3.49 3.49 0 0 0 1.58-2.73v-10.6Z"
        className="animable"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "203.257px 199.05px",
        }}
      />
      <path
        id="elprwcwinelb"
        d="m230.55 177-3.48-2a1.11 1.11 0 0 0-1.11.11l-48.48 28a3.05 3.05 0 0 0-1 1l4.92 2.84 46.13-26Z"
        className="animable"
        style={{
          fill: "#fafafa",
          transformOrigin: "203.515px 190.925px",
        }}
      />
      <path
        id="el5kphfkoxofa"
        d="m181 205.06 48.49-28c.87-.5 1.57-.09 1.57.91v14.49a3.47 3.47 0 0 1-1.57 2.73L181 223.14c-.87.5-1.58.09-1.58-.91v-14.44a3.51 3.51 0 0 1 1.58-2.73Z"
        className="animable"
        style={{
          fill: "#f0f0f0",
          transformOrigin: "205.24px 200.1px",
        }}
      />
      <g
        className="animable"
        style={{
          transformOrigin: "205.225px 200.364px",
        }}
      >
        <path
          id="elj9m0hpgq09t"
          d="m185.57 206.41.88 1.88a.22.22 0 0 0 .29.11l2-.76c.31-.12.44.32.21.75l-1.42 2.68a.82.82 0 0 0-.11.51l.33 2.43c.06.38-.27.89-.55.85l-1.77-.21a.41.41 0 0 0-.35.2l-1.76 2.26c-.29.35-.61.23-.56-.22l.34-2.81a.41.41 0 0 0-.11-.39l-1.43-1c-.23-.17-.1-.75.21-1l2-1.52a.88.88 0 0 0 .29-.45l.88-2.89c.06-.48.49-.71.63-.42Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "185.255px 211.798px",
          }}
        />
        <path
          id="elp672mwmsct"
          d="m195.55 200.7.89 1.87a.2.2 0 0 0 .28.11l2-.75c.31-.12.43.32.21.74l-1.43 2.68a.91.91 0 0 0-.11.52l.34 2.42c0 .39-.28.89-.56.86l-1.76-.22a.45.45 0 0 0-.36.2l-1.76 2.26c-.28.36-.61.23-.55-.22l.33-2.81a.42.42 0 0 0-.11-.39l-1.42-1c-.23-.16-.11-.75.21-1l2-1.52a1 1 0 0 0 .25-.45l.88-2.89c.12-.48.53-.71.67-.41Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "195.232px 206.082px",
          }}
        />
        <path
          id="el1t2mk85l2yz"
          d="m205.54 195 .88 1.87a.22.22 0 0 0 .29.12l2-.76c.31-.12.44.32.21.75l-1.43 2.68a.88.88 0 0 0-.11.51l.34 2.42c.06.39-.27.9-.55.86l-1.77-.22a.45.45 0 0 0-.35.21l-1.77 2.25c-.28.36-.6.23-.55-.21l.34-2.82a.4.4 0 0 0-.11-.38l-1.43-1c-.23-.16-.1-.75.21-1l2-1.52a.82.82 0 0 0 .29-.44l.88-2.89c.09-.51.49-.75.63-.43Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "205.225px 200.377px",
          }}
        />
        <path
          id="eljw4j7yp6irr"
          d="m215.52 189.26.88 1.88a.21.21 0 0 0 .29.11l2-.76c.32-.12.44.32.22.75l-1.43 2.68a.89.89 0 0 0-.11.51l.34 2.43c0 .38-.28.89-.56.85l-1.76-.21a.42.42 0 0 0-.36.2l-1.79 2.3c-.28.35-.61.23-.56-.22l.34-2.81a.41.41 0 0 0-.11-.39l-1.43-1c-.22-.17-.1-.75.22-1l2-1.52a1 1 0 0 0 .29-.45l.88-2.89c.13-.52.51-.72.65-.46Z"
          className="animable"
          style={{
            fill: "#e0e0e0",
            transformOrigin: "215.195px 194.675px",
          }}
        />
        <path
          id="elwipbj3ts2s"
          d="m225.51 183.55.88 1.87a.21.21 0 0 0 .29.11l2-.75c.31-.12.44.32.21.74l-1.43 2.68a.91.91 0 0 0-.11.52l.34 2.42c.05.39-.27.89-.55.86l-1.77-.22a.43.43 0 0 0-.35.2l-1.77 2.26c-.28.36-.61.23-.55-.22l.34-2.81a.42.42 0 0 0-.11-.39l-1.43-1c-.23-.16-.1-.75.21-1l2-1.52a.91.91 0 0 0 .29-.45l.88-2.89c.12-.48.49-.71.63-.41Z"
          className="animable"
          style={{
            fill: "#e0e0e0",
            transformOrigin: "225.195px 188.932px",
          }}
        />
      </g>
      <path
        id="el2qvr17nv9go"
        d="m164.87 249.82-.06-2.92-3.48-2a1.11 1.11 0 0 0-1.11.11l-48.48 28a3.48 3.48 0 0 0-1.58 2.72v61.67a1.11 1.11 0 0 0 .45 1l3.48 2v-3.47l46.11-26.57a3.46 3.46 0 0 0 1.57-2.72v-57.82Z"
        className="animable"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "137.513px 292.6px",
        }}
      />
      <path
        id="elqbh2m1qdwjs"
        d="m164.81 246.9-3.48-2a1.11 1.11 0 0 0-1.11.11l-48.48 28a3.14 3.14 0 0 0-1 1l4.93 2.85 46.12-26.05Z"
        className="animable"
        style={{
          fill: "#fafafa",
          transformOrigin: "137.775px 260.83px",
        }}
      />
      <path
        id="ela15ri7365kg"
        d="m115.21 275 48.48-28c.87-.5 1.58-.1 1.58.9v61.72a3.48 3.48 0 0 1-1.58 2.72l-48.48 27.94c-.87.5-1.57.1-1.57-.91v-61.65a3.46 3.46 0 0 1 1.57-2.72Z"
        className="animable"
        style={{
          fill: "#f0f0f0",
          transformOrigin: "139.455px 293.64px",
        }}
      />
      <g
        id="el9pbpd2vh9dc"
        className="animable"
        style={{
          clipPath: "url(#freepik--clip-path-5--inject-15--inject-26)",
          transformOrigin: "154.624px 323.684px",
        }}
      >
        <path
          id="elwka3c27rom"
          d="M149.09 301.07s3.81-1.17 7 3.83c2.16 3.41 9.7 16.33 9.7 16.33s14.67-7.78 15.49-8.79.78-3.09.9-4.2a11.89 11.89 0 0 0-.57-4.12c-.17-1.17 1.51-1.23 2.34.39.61 1.18.65 2.53 1.18 2.55a15.36 15.36 0 0 0 2-1.76c.91-1 2.56-2.66 3.8-2.43a1.11 1.11 0 0 1 .83.9 5.53 5.53 0 0 0 .47 2 3.36 3.36 0 0 1 .49.8 7.83 7.83 0 0 1 0 1.28c.05.62.65.88.73 1.48a9.82 9.82 0 0 1-.15 1.39c0 .29.09.55.09.85a2.84 2.84 0 0 1-.75 1.63 17.43 17.43 0 0 1-4.36 3.64c-1.33.88-3.1 1.65-4.61 2.48s-18.8 14.08-21.1 12.51-13.84-18.37-13.84-18.37c-2.63-7.46.36-12.39.36-12.39Z"
          className="animable"
          style={{
            fill: "#ffa8a7",
            transformOrigin: "170.549px 316.455px",
          }}
        />
        <path
          id="ellu35hp7e81"
          d="M142.4 301.19s4.2-1.71 6.92-1.53 4.74.95 6.74 4.16c2.67 4.29 7.08 11.7 7.08 11.7a21.32 21.32 0 0 1-8.58 9.18l-8.13-12.28Z"
          className="animable"
          style={{
            fill: "#455a64",
            transformOrigin: "152.77px 312.173px",
          }}
        />
        <path
          id="elzzohfnly4mi"
          d="M129.07 308.61a8.94 8.94 0 0 0-3 4.36 21.31 21.31 0 0 0-.9 6.4v13.95l-.17 26.39c8.85 3 20.1.36 29.54-10.13 0 0-.52-33.77-.52-39.31v-.76c-.34-7.83-7.1-11.46-13.75-7.7l-5.59 3.19-5.61 3.64Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "139.77px 330.593px",
          }}
        />
        <g id="elnv3q4wjber">
          <path
            id="elwwvwww8tbt"
            d="M129.07 308.61a8.94 8.94 0 0 0-3 4.36 21.31 21.31 0 0 0-.9 6.4v13.95l-.17 26.39c8.85 3 20.1.36 29.54-10.13 0 0-.52-33.77-.52-39.31v-.76c-.34-7.83-7.1-11.46-13.75-7.7l-5.59 3.19-5.61 3.64Z"
            className="animable"
            style={{
              fill: "#fff",
              opacity: 0.6,
              transformOrigin: "139.77px 330.593px",
            }}
          />
        </g>
        <path
          id="elbkksbqs941g"
          d="M135.13 279.9c0 6 4.18 9.66 9.33 8.28s9.33-7.33 9.33-13.28-4.45-5.95-9.6-4.57-9.06 3.67-9.06 9.57Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "144.46px 279.025px",
          }}
        />
        <path
          id="el458ix980r29"
          d="M130.26 289.32s1.37 7.09 1.92 8.17a2.14 2.14 0 0 0 1.69 1.12l-.09-5.22Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "132.065px 293.965px",
          }}
        />
        <path
          id="elhzujeznirng"
          d="M132 278.17a3.85 3.85 0 0 0-2.63 1.78c-1.13 1.64-.5 5.65.91 9.37l2.59-.35Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "130.829px 283.745px",
          }}
        />
        <path
          id="elunhvyj4ake8"
          d="M133.62 288.76c-.52.5-1.26-.45-1.9-1.06s-2.73-1.09-3.77 1.72.92 5.76 2.57 5.91c2.83.27 3.26-1.94 3.26-1.94l.09 12.1c4.76 5.05 13.1-.9 9.35-3.13v-3.6A16.25 16.25 0 0 0 147 298c2.08-.94 3.4-3.19 4-6 1-4.46 1.4-7.95.54-15.95-1-8.78-10-6.43-15-1.55s-2.92 14.26-2.92 14.26Z"
          className="animable"
          style={{
            fill: "#ffbda7",
            transformOrigin: "139.835px 288.808px",
          }}
        />
        <path
          id="el0q1uenup22v"
          d="M133.62 289.77c-.26.08-1.25-1.56-1.9-2.07-.33-.26-.3-1-.32-1.39a37.47 37.47 0 0 1 .29-5.6 11.35 11.35 0 0 0 .1-3.39 7.86 7.86 0 0 1 .15-3.67 6.48 6.48 0 0 1 2.56-3.65 6.6 6.6 0 0 1 5.44-.77 2.69 2.69 0 0 1 1.31-2.18 4.11 4.11 0 0 1 2.55-.54A2.41 2.41 0 0 1 146 268a4.45 4.45 0 0 1 3.34-2.67 4.35 4.35 0 0 1 3.72 1.16c.82.91 1 2.62.93 3.79a6.39 6.39 0 0 1-.22 1.62 4.81 4.81 0 0 1-2.21 2.78 5.45 5.45 0 0 1-3.52.65c.26 0-.49 1.9-.59 2.06a3.35 3.35 0 0 1-1.73 1.46 3.89 3.89 0 0 1-4.05-1.05 2.05 2.05 0 0 1-2 1.47 16.94 16.94 0 0 1-2.64-.3c-1.69-.16-1.53 2.23-1.72 3.4-.43 2.63-.81 7.15-1.69 7.4Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "142.697px 277.523px",
          }}
        />
        <path
          id="elrfkwll3qef8"
          d="m132 278.83-3.14-1.1a2.1 2.1 0 0 1 2.38-1.55 2 2 0 0 1 .76 2.65Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "130.544px 277.491px",
          }}
        />
        <path
          id="el04z10vrhp1gc"
          d="M143.21 298.76s-4.65.17-6.28-.4a4 4 0 0 1-2.25-2 7 7 0 0 0 1.28 2.69c1.19 1.43 7.25 1.1 7.25 1.1Z"
          className="animable"
          style={{
            fill: "#f29077",
            transformOrigin: "138.945px 298.271px",
          }}
        />
        <path
          id="elfn3wg2okd2d"
          d="M142.1 285.58a1.42 1.42 0 0 1-.94 1.36c-.51.14-.93-.25-.93-.87a1.42 1.42 0 0 1 .93-1.36c.52-.14.94.29.94.87Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "141.165px 285.826px",
          }}
        />
        <path
          id="el7ctrdjkeybx"
          d="m140.6 281.52-2 1.81a1.75 1.75 0 0 1 .47-2 .92.92 0 0 1 1.53.19Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "139.532px 282.167px",
          }}
        />
        <path
          id="elmj61i4dt27f"
          d="m142.79 292.4 2.13.36a1.46 1.46 0 0 1-1.44 1.2c-.59-.1-.9-.79-.69-1.56Z"
          className="animable"
          style={{
            fill: "#f29077",
            transformOrigin: "143.822px 293.18px",
          }}
        />
        <path
          id="elj0980fbh7a"
          d="m150.65 279.77-1.82-1.13c.38-.7 1.08-1 1.59-.7a1.39 1.39 0 0 1 .23 1.83Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "149.862px 278.797px",
          }}
        />
        <path
          id="elyk7xnrxclu"
          d="M150.26 283.08a1.44 1.44 0 0 1-.94 1.37c-.51.13-.93-.26-.93-.88a1.43 1.43 0 0 1 .93-1.36c.52-.14.94.25.94.87Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "149.325px 283.328px",
          }}
        />
        <path
          id="eldmlnonjbm2n"
          d="m145.24 283.13.11 6.51 3.35-1.75-3.46-4.76z"
          className="animable"
          style={{
            fill: "#f29077",
            transformOrigin: "146.97px 286.385px",
          }}
        />
        <path
          id="el363uw9xf0np"
          d="M115.91 348.51c2-26.88 5.94-36.52 12.49-39.35 2.26 1.68 2.8 9.59.44 15.59 0 0-5.09 17.93-5.38 24a98.24 98.24 0 0 0 1 17 2.4 2.4 0 0 0 1 1.68c1.44.93 1.35 2.29 2.37 3.57.8 1 1.55 1.18 1.4 1.56a1.79 1.79 0 0 1-2 1.07 5.53 5.53 0 0 1-1.76-1 14.8 14.8 0 0 0 1.85 4c1.87 2.84 1.78 4.76-2.6 5.41-2.74.4-4.73-1.3-6.11-8.16-.4-2-1.33-5.54-1.72-8.11a78.49 78.49 0 0 1-.98-17.26Z"
          className="animable"
          style={{
            fill: "#ffbda7",
            transformOrigin: "123.084px 345.627px",
          }}
        />
        <path
          id="elbwoau0ey147"
          d="M130.26 307.84s-4.77 1.67-7.18 5.57-4.71 10.2-6.32 21.36c0 0 3 4.13 10.89.7 0 0 3.43-14.25 3.73-19.87.34-6.14-1.12-7.76-1.12-7.76Z"
          className="animable"
          style={{
            fill: "#455a64",
            transformOrigin: "124.096px 322.336px",
          }}
        />
        <path
          id="eljizpjg7gd5"
          d="M138 286.51a4.48 4.48 0 0 1 3.06-3.76l.15-.05c1.6-.51 3.11.18 3.34 1.71a4.2 4.2 0 0 1-2.86 4.26l-.48.15a2.29 2.29 0 0 1-3.21-2.31Zm2.92-3.2a3.43 3.43 0 0 0-2.33 3.8 2 2 0 0 0 2.69 1.18l.28-.09a3.72 3.72 0 0 0 2.48-2.83 1.93 1.93 0 0 0-2.65-2.21Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "141.274px 285.776px",
          }}
        />
        <path
          id="elcevx4qta4a8"
          d="M146.8 283.69a4.47 4.47 0 0 1 3.06-3.76l.15-.05c1.6-.51 3.11.18 3.34 1.71a4.2 4.2 0 0 1-2.86 4.26l-.49.15a2.29 2.29 0 0 1-3.2-2.31Zm2.92-3.2a3.43 3.43 0 0 0-2.33 3.8 2 2 0 0 0 2.69 1.17l.28-.09a3.69 3.69 0 0 0 2.48-2.82 1.93 1.93 0 0 0-2.65-2.21Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "150.075px 282.953px",
          }}
        />
        <path
          id="el2hk7o0gfdba"
          d="M144.26 283.86a3.06 3.06 0 0 1 1.31-.92 1.42 1.42 0 0 1 1.43.06l.39-.44a1.79 1.79 0 0 0-1.88-.13 3.74 3.74 0 0 0-1.61 1.13c-.13.19.25.44.36.3Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "145.632px 283.062px",
          }}
        />
        <path
          id="elxfrhwjm53ac"
          d="m132.44 288.47 5.87-1.88v-.51l-6.17 2a2.2 2.2 0 0 1 .3.39Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "135.225px 287.275px",
          }}
        />
      </g>
      <path
        id="el1qvq3pcv5sd"
        d="m164.87 323.57-.06-2.92-3.48-2a1.12 1.12 0 0 0-1.11.12l-48.48 28a3.48 3.48 0 0 0-1.58 2.72v14.44a1.11 1.11 0 0 0 .45 1l3.48 2v-3.47l46.11-26.57a3.46 3.46 0 0 0 1.57-2.72v-10.6Z"
        className="animable"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "137.513px 342.743px",
        }}
      />
      <path
        id="el5j02n4fq12q"
        d="m164.81 320.65-3.48-2a1.12 1.12 0 0 0-1.11.12l-48.48 28a3.14 3.14 0 0 0-1 1l4.93 2.85 46.12-26.05Z"
        className="animable"
        style={{
          fill: "#fafafa",
          transformOrigin: "137.775px 334.588px",
        }}
      />
      <path
        id="elokdgma129jp"
        d="m115.21 348.75 48.48-28c.87-.5 1.58-.1 1.58.91v14.49a3.48 3.48 0 0 1-1.58 2.72l-48.48 27.94c-.87.5-1.57.1-1.57-.91v-14.43a3.46 3.46 0 0 1 1.57-2.72Z"
        className="animable"
        style={{
          fill: "#f0f0f0",
          transformOrigin: "139.455px 343.78px",
        }}
      />
      <g
        className="animable"
        style={{
          transformOrigin: "139.478px 344.048px",
        }}
      >
        <path
          id="elitpt8wzkzt"
          d="m119.83 350.1.88 1.87a.21.21 0 0 0 .29.11l2-.75c.31-.12.44.32.21.74l-1.43 2.68a.91.91 0 0 0-.11.52l.34 2.42c0 .39-.27.89-.55.86l-1.77-.22a.42.42 0 0 0-.35.21l-1.77 2.25c-.28.36-.61.23-.55-.22l.33-2.81a.4.4 0 0 0-.11-.39l-1.42-1c-.23-.16-.1-.75.21-1l2-1.52a.92.92 0 0 0 .29-.44l.88-2.9c.08-.51.49-.71.63-.41Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "119.515px 355.479px",
          }}
        />
        <path
          id="el7bufalnkqmm"
          d="m129.81 344.38.88 1.87a.22.22 0 0 0 .29.12l2-.76c.32-.12.44.32.21.75l-1.45 2.64a.89.89 0 0 0-.11.51L132 352c.06.38-.27.89-.55.85l-1.76-.22a.45.45 0 0 0-.36.21l-1.76 2.25c-.28.36-.61.23-.56-.21l.34-2.82a.4.4 0 0 0-.11-.38l-1.43-1c-.23-.17-.1-.75.21-1l2-1.52a.86.86 0 0 0 .28-.44l.89-2.89c.08-.51.48-.75.62-.45Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "129.501px 349.773px",
          }}
        />
        <path
          id="elbuovooum3s"
          d="m139.79 338.66.89 1.88a.2.2 0 0 0 .28.11l2-.76c.31-.12.43.33.21.75l-1.43 2.68a.89.89 0 0 0-.11.51l.34 2.43c.05.38-.28.89-.56.86l-1.76-.22c-.11 0-.24.06-.36.2l-1.76 2.26c-.28.36-.61.23-.55-.22l.33-2.81a.41.41 0 0 0-.11-.39l-1.42-1c-.23-.16-.11-.75.21-1l2-1.52a1 1 0 0 0 .29-.45l.88-2.89c.09-.48.49-.71.63-.42Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "139.472px 344.05px",
          }}
        />
        <path
          id="elub2cs99166c"
          d="m149.78 333 .88 1.87a.21.21 0 0 0 .29.11l2-.75c.31-.12.44.32.21.74l-1.42 2.68a.84.84 0 0 0-.11.52l.33 2.42c.06.39-.27.89-.55.86l-1.77-.22a.42.42 0 0 0-.35.21l-1.77 2.25c-.28.36-.6.23-.55-.22l.34-2.81a.42.42 0 0 0-.11-.39l-1.43-1c-.23-.16-.1-.75.21-1l2-1.52a.86.86 0 0 0 .29-.44l.88-2.9c.08-.53.49-.76.63-.41Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "149.465px 338.365px",
          }}
        />
        <path
          id="el6yp5e4r37a6"
          d="m159.76 327.23.89 1.88a.21.21 0 0 0 .28.11l2-.76c.31-.12.43.32.21.75l-1.43 2.68a.89.89 0 0 0-.11.51l.34 2.43c.05.38-.28.89-.56.85l-1.76-.22a.46.46 0 0 0-.36.21l-1.76 2.25c-.28.36-.61.23-.56-.21l.34-2.82a.4.4 0 0 0-.11-.38l-1.43-1c-.22-.17-.1-.75.22-1l2-1.52a.94.94 0 0 0 .29-.44l.88-2.89c.09-.49.49-.73.63-.43Z"
          className="animable"
          style={{
            fill: "#e0e0e0",
            transformOrigin: "159.44px 332.613px",
          }}
        />
      </g>
      <path
        id="el4eb3ljortja"
        d="m164.87 144.6-.06-2.92-3.48-2a1.11 1.11 0 0 0-1.11.11l-48.48 28a3.48 3.48 0 0 0-1.58 2.72v61.66a1.11 1.11 0 0 0 .45 1l3.48 2v-3.46l46.11-26.57a3.46 3.46 0 0 0 1.57-2.72V144.6Z"
        className="animable"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "137.513px 187.375px",
        }}
      />
      <path
        id="elii28xre63ke"
        d="m164.81 141.68-3.48-2a1.11 1.11 0 0 0-1.11.11l-48.48 28a3 3 0 0 0-1 1l4.93 2.85 46.12-26Z"
        className="animable"
        style={{
          fill: "#fafafa",
          transformOrigin: "137.775px 155.61px",
        }}
      />
      <path
        id="eljalhbu593n9"
        d="m115.21 169.78 48.48-28c.87-.51 1.58-.1 1.58.9v61.72a3.48 3.48 0 0 1-1.58 2.72l-48.48 27.94c-.87.5-1.57.09-1.57-.91V172.5a3.46 3.46 0 0 1 1.57-2.72Z"
        className="animable"
        style={{
          fill: "#f0f0f0",
          transformOrigin: "139.455px 188.417px",
        }}
      />
      <g
        id="elt20bj8ceh1"
        className="animable"
        style={{
          clipPath: "url(#freepik--clip-path-6--inject-15--inject-26)",
          transformOrigin: "144.855px 222.469px",
        }}
      >
        <path
          id="elzsrdrggtd"
          d="m155.24 206.33-26.36 7.31v-27c0-8.4 5.85-16.82 13.13-18.8 7.28-2 13.2 3.21 13.23 11.6.03 6.97 0 26.89 0 26.89Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "142.067px 190.526px",
          }}
        />
        <path
          id="elyhlgtwn81p"
          d="m131.12 177.52-3.41-2.44a2.29 2.29 0 0 1 2.89 0 1.63 1.63 0 0 1 .52 2.44Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "129.587px 176.043px",
          }}
        />
        <path
          id="elqmo7ntdfmzq"
          d="m131.37 176.87-1.35-4a2.31 2.31 0 0 1 2.36 1.68 1.63 1.63 0 0 1-1.01 2.32Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "131.291px 174.868px",
          }}
        />
        <path
          id="elop3k02avby"
          d="M152.81 233.41c-5.44-23.86-11.67-28.07-5.85-33 6-2.28 9.06 1 10.1 8 .51 3.42 1.62 17.72 3 23.07 1.22 4.7 2.86 10.51 4.68 13.87a2 2 0 0 0 1.5 1.05c1.71.25 2.21 1.2 3.59 1.91 1.09.55 1.89.41 1.84.79a2.13 2.13 0 0 1-1.72 1.69 6.08 6.08 0 0 1-2-.13 11.34 11.34 0 0 0 3 2.72c2.67 1.72 3.1 3.4-1.12 5.65-2.65 1.41-5.13.72-8.41-4.64a43.34 43.34 0 0 1-3.3-6.22c-2.35-5.88-4.44-10.92-5.31-14.76Z"
          className="animable"
          style={{
            fill: "#995153",
            transformOrigin: "158.802px 229.678px",
          }}
        />
        <path
          id="eluzgz2ssudup"
          d="M137.26 203.25c-5.91 2.05-6.33 5.46-7.81 9.46a13.73 13.73 0 0 0-.28 8.88l4.12 12.66a85.49 85.49 0 0 0-4.17 11.59c10.35 3.89 23.26-3.07 26.37-8.77.13-1.06-.67-8.91-1.16-14 1.82-5.76 2.3-9.24 1.53-12.52-1.43-6.09-7-10.91-9.41-10Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "142.389px 223.704px",
          }}
        />
        <g id="elzm8yt7cerv">
          <path
            id="ele6agg4vm844"
            d="M139.91 244.24c-3.4 0-5.56-3.41-4.66-7.54a2.49 2.49 0 0 1 .11-.4c.66-2 2.09-2.17 2.09-2.17-1.34-.67-2-2.25-2.29-4l-1.55-8.06a12 12 0 0 1 .32-4.27l-4.16-4.43-.77.82a13.56 13.56 0 0 0 .17 7.39l4.12 12.66a78.63 78.63 0 0 0-4.17 11.59c7.63 2.87 16.64-.16 22.1-4.23a28 28 0 0 1-11.31 2.64Z"
            className="animable"
            style={{
              opacity: 0.1,
              transformOrigin: "139.894px 230.165px",
            }}
          />
        </g>
        <path
          id="el18dvifqdmuy"
          d="M150.29 172.39c1.39.57 3.13 4.14 3 12.65-.12 7.22-2.13 9.54-3.14 10.33s-3 1-4.86 1.16l.11 4.34s3.2 2.16 3 4.18-4.44 4.08-6.9 2.83a21 21 0 0 1-4.43-3.23.35.35 0 0 1-.1-.27V193.5s-1.11 1.56-3 .17a4.07 4.07 0 0 1-.89-5.43c1.21-1.76 3.46-2 4.33-.19 0 0 3-.69 7.06-4.35a18.3 18.3 0 0 0 5.82-11.31Z"
          className="animable"
          style={{
            fill: "#995153",
            transformOrigin: "142.856px 190.325px",
          }}
        />
        <path
          id="elovl5yku8hzl"
          d="M146.2 185.14a1.23 1.23 0 0 1-.74 1.18c-.43.14-.8-.16-.83-.67a1.23 1.23 0 0 1 .74-1.18c.43-.15.8.15.83.67Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "145.415px 185.393px",
          }}
        />
        <path
          id="el3pdb2ryqjmv"
          d="m146.43 191.16 1.82.18a1.27 1.27 0 0 1-1.18 1.07c-.51-.05-.79-.61-.64-1.25Z"
          className="animable"
          style={{
            fill: "#733b3c",
            transformOrigin: "147.32px 191.785px",
          }}
        />
        <path
          id="el7hrd8oj15a4"
          d="m152.6 180.61-1.59-.83c.29-.6.88-.9 1.32-.67a1.14 1.14 0 0 1 .27 1.5Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "151.89px 179.821px",
          }}
        />
        <path
          id="eldegrbkggmg"
          d="M152.24 182.83a1.2 1.2 0 0 1-.73 1.18c-.44.15-.81-.15-.83-.67a1.23 1.23 0 0 1 .74-1.18c.43-.16.8.16.82.67Z"
          className="animable"
          style={{
            fill: "#263238",
            transformOrigin: "151.461px 183.084px",
          }}
        />
        <path
          id="elb2egs80jefe"
          d="m148.72 182.83-.03 5.89 2.48-1.56-2.45-4.33z"
          className="animable"
          style={{
            fill: "#733b3c",
            transformOrigin: "149.93px 185.775px",
          }}
        />
        <path
          id="elrzigh7d074c"
          d="M145.15 196.53c-2 .25-6.13 0-6.78-1.7a3.56 3.56 0 0 0 1.46 2c1.23.88 5.35 1.15 5.35 1.15Z"
          className="animable"
          style={{
            fill: "#733b3c",
            transformOrigin: "141.775px 196.405px",
          }}
        />
        <path
          id="elucw26kzweog"
          d="M118.67 242.53c4.6-22.93 7.5-32.82 12.54-36.31a16.2 16.2 0 0 1 3.42-1.69c.69.26.76 1.77.89 2.75a24.12 24.12 0 0 1-2.62 13.13s-6.71 18.14-7.78 24.23c-.93 5.34-2 12-1.94 16.39a2.8 2.8 0 0 0 .74 1.93c1.24 1.21 1.24 2.44 2 3.93.63 1.16 1.31 1.47 1.11 1.82a1.57 1.57 0 0 1-2 .71 5.47 5.47 0 0 1-1.53-1.28 16.62 16.62 0 0 0 1.21 4.38c1.38 3.22 1 5.13-3.15 5-2.62-.1-4.27-2.18-4.67-9.36a66.18 66.18 0 0 1-.07-8.05c.58-7.11.99-13.27 1.85-17.58Z"
          className="animable"
          style={{
            fill: "#995153",
            transformOrigin: "126.168px 241.028px",
          }}
        />
      </g>
      <path
        id="els4fi2ecm0m"
        d="m164.87 217.88-.06-2.93-3.48-2a1.09 1.09 0 0 0-1.11.11l-48.48 28a3.48 3.48 0 0 0-1.58 2.72v14.44a1.11 1.11 0 0 0 .45 1l3.48 2v-3.47l46.11-26.57a3.46 3.46 0 0 0 1.57-2.72v-10.6Z"
        className="animable"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "137.513px 237.033px",
        }}
      />
      <path
        id="el93c3xu58bim"
        d="m164.81 215-3.48-2a1.09 1.09 0 0 0-1.11.11l-48.48 28a3.14 3.14 0 0 0-1 1l4.93 2.85 46.12-26Z"
        className="animable"
        style={{
          fill: "#fafafa",
          transformOrigin: "137.775px 228.928px",
        }}
      />
      <path
        id="elo3rcgoltyx"
        d="m115.21 243.05 48.48-28c.87-.5 1.58-.1 1.58.91v14.49a3.48 3.48 0 0 1-1.58 2.72l-48.48 27.94c-.87.51-1.57.1-1.57-.9v-14.44a3.46 3.46 0 0 1 1.57-2.72Z"
        className="animable"
        style={{
          fill: "#f0f0f0",
          transformOrigin: "139.455px 238.082px",
        }}
      />
      <g
        className="animable"
        style={{
          transformOrigin: "139.473px 238.355px",
        }}
      >
        <path
          id="el8txl3j35qi3"
          d="m119.83 244.4.88 1.87a.22.22 0 0 0 .29.12l2-.76c.31-.12.44.32.21.75l-1.43 2.67a.93.93 0 0 0-.11.52L122 252c0 .39-.27.89-.55.86l-1.77-.22a.45.45 0 0 0-.35.21l-1.77 2.25c-.28.36-.61.23-.55-.21l.33-2.82a.4.4 0 0 0-.11-.39l-1.42-1c-.23-.16-.1-.75.21-1l2-1.52a.88.88 0 0 0 .29-.44l.88-2.9c.09-.48.5-.72.64-.42Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "119.51px 249.788px",
          }}
        />
        <path
          id="elw1wn6v6jdqe"
          d="m129.81 238.68.88 1.88a.22.22 0 0 0 .29.11l2-.76c.32-.12.44.32.21.75l-1.42 2.68a.89.89 0 0 0-.11.51l.33 2.43c.06.38-.27.89-.55.85l-1.76-.21a.41.41 0 0 0-.36.2l-1.76 2.26c-.28.35-.61.23-.56-.22l.34-2.81a.41.41 0 0 0-.11-.39l-1.43-1c-.23-.17-.1-.75.21-1l2-1.52a.94.94 0 0 0 .28-.45l.89-2.89c.09-.48.49-.71.63-.42Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "129.496px 244.068px",
          }}
        />
        <path
          id="el7xmiop5h5s5"
          d="m139.79 233 .89 1.87a.2.2 0 0 0 .28.11l2-.75c.31-.12.43.32.21.74l-1.43 2.68a.91.91 0 0 0-.11.52l.34 2.42c.05.39-.28.89-.56.86l-1.76-.22a.46.46 0 0 0-.36.2l-1.76 2.26c-.28.36-.61.23-.55-.22l.33-2.81a.42.42 0 0 0-.11-.39l-1.42-1c-.23-.16-.11-.75.21-1l2-1.52a1 1 0 0 0 .29-.45l.88-2.89c.09-.51.49-.74.63-.41Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "139.472px 238.372px",
          }}
        />
        <path
          id="elq3nz7ob3cu"
          d="m149.78 227.25.88 1.87a.22.22 0 0 0 .29.12l2-.76c.31-.12.44.32.21.75l-1.42 2.67a.85.85 0 0 0-.11.52l.33 2.42c.06.39-.27.9-.55.86l-1.77-.22a.45.45 0 0 0-.35.21l-1.77 2.25c-.28.36-.6.23-.55-.21l.34-2.82a.42.42 0 0 0-.11-.39l-1.43-1c-.23-.16-.1-.75.21-1l2-1.52a.82.82 0 0 0 .29-.44l.88-2.89c.08-.48.49-.67.63-.42Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "149.465px 232.644px",
          }}
        />
        <path
          id="el09z2vog6ht7i"
          d="m159.76 221.53.89 1.88a.2.2 0 0 0 .28.11l2-.76c.31-.12.43.32.21.75l-1.43 2.68a.89.89 0 0 0-.11.51l.34 2.43c.05.38-.28.89-.56.85l-1.76-.21a.42.42 0 0 0-.36.2l-1.76 2.26c-.28.35-.61.23-.56-.22l.34-2.81a.41.41 0 0 0-.11-.39l-1.43-1c-.22-.17-.1-.75.22-1l2-1.52a1 1 0 0 0 .29-.45l.88-2.89c.09-.48.49-.71.63-.42Z"
          className="animable"
          style={{
            fill: "#de4747",
            transformOrigin: "159.44px 226.918px",
          }}
        />
      </g>
    </g>
    <g
      id="freepik--character--inject-15--inject-26"
      className="animable"
      style={{
        transformOrigin: "385.539px 283.036px",
      }}
    >
      <path
        id="elt4izk050ere"
        d="M432.74 378.26c.66.27.79 1.53.84 4 0 1.89.36 5.2-1.87 5.8s-5.46.2-6.84-1.61c-1.72-2.26-2.87-4.25-5.34-7-2.14-2.4-4.46-4.65-5.27-8-.73-3 0-4.28 2-5.1 2.82-1.13 7.13 2.86 9.31 3.53 1.89.61 6.52 8.12 7.17 8.38Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "423.792px 377.235px",
        }}
      />
      <path
        id="elt0rg5qrboe"
        d="m432.79 376-.07 3.07a7.52 7.52 0 0 1-7.75 0c-.13-.3-.71-4.4-.71-4.4Z"
        className="animable"
        style={{
          fill: "#ffa8a7",
          transformOrigin: "428.525px 377.408px",
        }}
      />
      <path
        id="elfaiqzx80t6"
        d="M407.51 395.38a51.26 51.26 0 0 1 1.24 5c.33 1.87.81 5 .12 6.36s-3.23 2.22-7.15 2.1c-2.55-.07-6.55 1.56-9.3 3s-8.68 2-11.63 1.57c-3.49-.48-7.06-2.59-7.59-4.05s.25-2.95 8.56-6.27c.08 0 11.53-3.47 15.61-7.78Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "391.147px 404.431px",
        }}
      />
      <path
        id="elqx4i0qrb3aj"
        d="M407.58 392.06s-.06 2.28-.08 3.58-1.6 3.2-5.57 3c-2.79-.16-4.72-1-4.56-3.26l.08-3.31Z"
        className="animable"
        style={{
          fill: "#ffa8a7",
          transformOrigin: "402.47px 395.357px",
        }}
      />
      <path
        id="elubshi7jj419"
        d="M435.7 335.3a82.65 82.65 0 0 0-3.26-16.06s1.41-13.75 2.23-26.81c.88-14.09.69-31.85 1-35.36 0 0-45.33-10.24-46.51 6.05s.5 57.89.88 65.06 1.47 13.55 2.69 25.2c1.2 11.49 4.69 38.66 4.69 38.66a10.74 10.74 0 0 0 10.13 0s3.94-32.55 3.79-40.82a70.44 70.44 0 0 0-2.73-18.12s1.17-13.44 2.2-25.12 2-17.28 2.25-22.37l1-.46s.54 20.2.92 27.38 1.36 14.08 3.13 27.44c1.54 11.59 6.35 36.14 6.35 36.14 5.14 2.93 8.31-.18 8.31-.18s4.11-30.58 2.93-40.63Z"
        className="animable"
        style={{
          fill: "#37474f",
          transformOrigin: "412.33px 323.601px",
        }}
      />
      <path
        id="el8dmuru8f1l"
        d="M413.09 285.63s11.17-5.19 16.81-10c0 0-5.3 7.12-12.11 10.17 0 0-3 25-2.94 24.53l-.85-24.72Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "421.495px 292.983px",
        }}
      />
      <path
        id="el7rucmjclzof"
        d="m405.35 192.63 2.35 14.89 13.42-.68-1.56-17.42-14.21 3.21z"
        className="animable"
        style={{
          fill: "#ffa8a7",
          transformOrigin: "413.235px 198.47px",
        }}
      />
      <path
        id="elz4dykzkup1m"
        d="M423.9 198.35s7.35-2.61 11.34 4.07.68 18.39.68 18.39Z"
        className="animable"
        style={{
          fill: "#de4747",
          transformOrigin: "430.554px 209.329px",
        }}
      />
      <path
        id="eld8ln6a6nfvf"
        d="M435.3 206.08c-1.24-4.07-6.26-8.57-11.4-7.73-1.19.19-2.35.39-3.49.59-.61 2.51-7 4.45-13.61 2.9q-3 .78-5.44 1.6c-4.73 1.57-9.09 5.52-9.82 20.93 0 0-2.38 35.91-2.57 42.86 11.67 4.92 23.91 5 30.71 3.73 14.15-2.76 16-8.57 16-8.57s.77-25.84.6-37c-.17-9.5-.14-16.52-.98-19.31Z"
        className="animable"
        style={{
          fill: "#e6e6e6",
          transformOrigin: "412.637px 234.949px",
        }}
      />
      <path
        id="el5qfp9jzlcao"
        d="m362.87 181.54-27.95 14.59 13.89 37.97 26.08-14.61-12.02-37.95z"
        className="animable"
        style={{
          fill: "#de4747",
          transformOrigin: "354.905px 207.82px",
        }}
      />
      <path
        id="elw070pny5yrb"
        d="m361.59 185.26-23.45 12.24 12.29 32.65 21.88-12.26-10.72-32.63z"
        className="animable"
        style={{
          fill: "#f5f5f5",
          transformOrigin: "355.225px 207.705px",
        }}
      />
      <g id="el3nvxy38x4z7">
        <path
          id="elnaku7i4tp9n"
          d="m356.64 188.78-.45-1.16c-.26-.64-2.21-.26-4.36.86l-4.13 2.13c-2.16 1.11-3.7 2.54-3.45 3.18l.45 1.16Z"
          className="animable"
          style={{
            opacity: 0.15,
            transformOrigin: "350.432px 191.13px",
          }}
        />
      </g>
      <path
        id="eldazlok6wpto"
        d="M356.19 184.14c-.16-.4-1-.34-1.9.14l-1.62.88s-1-2.51-4.71-.78-2.87 4.65-2.87 4.65l-1.72.94c-.95.53-1.62 1.28-1.51 1.69l.21.74 14.4-7.52Z"
        className="animable"
        style={{
          fill: "#37474f",
          transformOrigin: "349.159px 188.086px",
        }}
      />
      <path
        id="elk60a6oly1ni"
        d="M354.63 185.82c-1.34.66-1.86 1-1.48 2s.85 1.94.85 1.94l-6.37 3.33s-.53-1.44-.76-2-.7-.82-1.71-.29l-.38-.4 10.05-5.12Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "349.805px 189.185px",
        }}
      />
      <path
        id="elrb9ma9f57ta"
        d="m356.91 188.85-.45-1.17c-.25-.64-2.2-.25-4.36.86l-4.1 2.13c-2.16 1.11-3.7 2.54-3.45 3.18L345 195Z"
        className="animable"
        style={{
          fill: "#37474f",
          transformOrigin: "350.717px 191.186px",
        }}
      />
      <path
        id="elg8dpus3x6sq"
        d="M390.83 235.25s6.92-8.39 8.72-14.55a36.75 36.75 0 0 1-8.92 17.59Z"
        className="animable"
        style={{
          fill: "#e0e0e0",
          transformOrigin: "395.09px 229.495px",
        }}
      />
      <path
        id="el5unuumwuoyc"
        d="M400.58 203.76c-5.35 1.6-8.61 1.89-14.52 11S366.78 240.87 365.5 243c-1.78 3-2.12 7.6-.58 10.37 1.44 2.58 4.89 1.06 10.75-4.08 5.67-5 17.64-18.66 17.64-18.66a97.1 97.1 0 0 0 7.27-26.87Z"
        className="animable"
        style={{
          fill: "#ffa8a7",
          transformOrigin: "382.261px 229.182px",
        }}
      />
      <path
        id="elii9iv8ichli"
        d="M400.58 203.76a41.18 41.18 0 0 0-7.74 2.45 13.93 13.93 0 0 0-6.58 4.54c-4.8 6.42-11.84 19.4-11.84 19.4s1.61 7.64 11.95 10.6c0 0 10-12 11.35-15 2.33-5.16 4.53-13.96 2.86-21.99Z"
        className="animable"
        style={{
          fill: "#de4747",
          transformOrigin: "387.793px 222.255px",
        }}
      />
      <path
        id="elsvgoozh13nr"
        d="M366.72 254.67c-5-.6-15.63-15.56-18.91-20.68a36.63 36.63 0 0 0-10-10.56c-5.24-3.86-4.47-9.81-2.19-15.25.79-1.89 3.47-.66 3.47-.66l.95 2.58 1.64 4.48c1.11 1 1.74 1.36 1.81 1.06.14-.58-.81-3-.79-5.5 0-3 2.14-1 2.77 0a37.27 37.27 0 0 1 2.23 5c1.06 2.7.62 5.44 1.91 7.28s18.51 18.35 18.51 18.35Z"
        className="animable"
        style={{
          fill: "#ffa8a7",
          transformOrigin: "350.995px 230.898px",
        }}
      />
      <path
        id="elk4upw1mvgu"
        d="M395.41 174.35s-3.51 5.34-3.11 5.87 3 1 3 1Z"
        className="animable"
        style={{
          fill: "#f28f8f",
          transformOrigin: "393.839px 177.785px",
        }}
      />
      <path
        id="elyk2hmuo729f"
        d="M397.06 177.79a4.2 4.2 0 1 1 4.19-4.2 4.2 4.2 0 0 1-4.19 4.2Zm0-7.46a3.26 3.26 0 1 0 3.26 3.26 3.26 3.26 0 0 0-3.26-3.26Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "397.05px 173.59px",
        }}
      />
      <path
        id="ellbpwdrv8v3q"
        d="M405.19 159.58c-7.13 1.51-9.18 4.64-9.85 15.95-.7 11.82.5 15.53 2.06 17.13 1 1.07 6.87 1.27 9.81.45 3.68-1 12-4 15.88-9.9 4.58-7 5.79-16.48.88-20.37-6.9-5.47-15.89-3.84-18.78-3.26Z"
        className="animable"
        style={{
          fill: "#ffa8a7",
          transformOrigin: "411.134px 176.256px",
        }}
      />
      <path
        id="el5chmi2929xs"
        d="m393.26 172.72 12.66 1.63-.51.9-12.15-1.58v-.95z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "399.59px 173.985px",
        }}
      />
      <path
        id="el6c9cn6c441d"
        d="m394.55 163.28.22.21c1.37 1.3 3.12 2.12 4.55 3.36a10.59 10.59 0 0 1 3.24 5.14c.47 1.64.65 3.58 2.43 4.34 0 0 1.13-4.37 4.81-3.82s4.21 5.28 1.87 8.15-4.51 1.77-4.51 1.77.11 6.25 2.88 9.78c0 0 3.15 1 8.94-.21 3.56-.76 8.6-5.21 10.71-9.92 4.48-10 4.79-18.94-1.57-21.63-.69-4.59-4.31-6.65-8.43-7.6-6.16-1.41-11.93 2.25-18 .88-1.27-.28-2.52-.74-3.82-.9a.83.83 0 0 0-.44 0c-.31.16-.29.61-.2.94a8 8 0 0 0 1.8 3.29 18.84 18.84 0 0 1-6.91-.87 8.91 8.91 0 0 0 2.43 7.09Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "412.528px 172.559px",
        }}
      />
      <path
        id="el48vao4a1hbo"
        d="m428.23 162 4.26-2.51a2.47 2.47 0 1 0-4.26 2.51Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "430.189px 160.136px",
        }}
      />
      <path
        id="elmr509uny4qr"
        d="m404.92 178.83.13-5a2.48 2.48 0 1 0-.13 5Z"
        className="animable"
        style={{
          fill: "#263238",
          transformOrigin: "403.767px 176.33px",
        }}
      />
    </g>
    <defs>
      <filter id="active" height="200%">
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={2}
          result="DILATED"
        />
        <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter id="hover" height="200%">
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius={2}
          result="DILATED"
        />
        <feFlood floodColor="red" floodOpacity={0.5} result="PINK" />
        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
        <feMerge>
          <feMergeNode in="OUTLINE" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
        <feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0" />
      </filter>
    </defs>
    <defs>
      <clipPath id="freepik--clip-path--inject-15--inject-26">
        <path
          d="m246.64 199.13 48.48-28c.87-.5 1.57-.1 1.57.91v61.71a3.46 3.46 0 0 1-1.57 2.72l-48.48 27.94c-.87.5-1.57.1-1.57-.9v-61.66a3.46 3.46 0 0 1 1.57-2.72Z"
          style={{
            fill: "none",
          }}
        />
      </clipPath>
      <clipPath id="freepik--clip-path-2--inject-15--inject-26">
        <path
          d="m246.64 93.91 48.48-28c.87-.5 1.57-.1 1.57.91v61.71a3.46 3.46 0 0 1-1.57 2.72l-48.48 27.95c-.87.5-1.57.1-1.57-.91V96.63a3.46 3.46 0 0 1 1.57-2.72Z"
          style={{
            fill: "none",
          }}
        />
      </clipPath>
      <clipPath id="freepik--clip-path-3--inject-15--inject-26">
        <path
          d="m181 237 48.49-28c.87-.5 1.57-.1 1.57.91v61.71a3.46 3.46 0 0 1-1.57 2.72L181 302.33c-.87.5-1.58.1-1.58-.91v-61.66A3.49 3.49 0 0 1 181 237Z"
          style={{
            fill: "none",
          }}
        />
      </clipPath>
      <clipPath id="freepik--clip-path-4--inject-15--inject-26">
        <path
          d="m181 131.81 48.49-28c.87-.5 1.57-.09 1.57.91v61.71a3.47 3.47 0 0 1-1.57 2.73L181 197.11c-.87.5-1.58.09-1.58-.91v-61.66a3.49 3.49 0 0 1 1.58-2.73Z"
          style={{
            fill: "none",
          }}
        />
      </clipPath>
      <clipPath id="freepik--clip-path-5--inject-15--inject-26">
        <path
          d="m115.21 275 48.48-28c.87-.51 1.58-.1 1.58.9v61.72a3.48 3.48 0 0 1-1.58 2.72l-48.48 27.94c-.87.5-1.57.1-1.57-.91v-61.65a3.46 3.46 0 0 1 1.57-2.72Z"
          style={{
            fill: "none",
          }}
        />
      </clipPath>
      <clipPath id="freepik--clip-path-6--inject-15--inject-26">
        <path
          d="m115.21 169.78 48.48-28c.87-.51 1.58-.1 1.58.9v61.72a3.48 3.48 0 0 1-1.58 2.72l-48.48 27.94c-.87.5-1.57.09-1.57-.91V172.5a3.46 3.46 0 0 1 1.57-2.72Z"
          style={{
            fill: "none",
          }}
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
