import React, { useEffect, useRef, useState } from "react";
import SearchBar from "../client/SearchBar";
import MyCard from "../client/Card";
import "../client/home.css";
import MyCardDetails from "../client/CardDetails";
import DevOpsLoop from 'assets/img/DevOpsLoop.png'; // Adjust the path according to your project structure
import ButtonAppBar from "../../components/Navbars/navBar";
import LoginForDialog from "views/Auth/LoginForDialog";
import SignInForDialog from "views/Auth/SignInForDialog"; // Make sure the path is correct
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { connect } from "react-redux"; // Import connect from react-redux
import { setUserId } from "../../Redux/actions"; // Import your action
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import App from "views/Chat/Chat";


function PostsApplied() {
  const apiUrl = process.env.REACT_APP_API_URL;

  
  const [isAuth, setIsAuth] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);
  const [applicationList, setApplicationList] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const dt = useRef(null);
  const [ChatComponent, setChatComponent] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isFooterVisible, setIsFooterVisible] = useState(false);


  const closeLoginPopUp = () => {
    setOpenLoginDialog(false);
  };

  const closeSignInPopUp = () => {
    setOpenSignInDialog(false);
  };
 

  const Load = async () => {
    const userId = getUserIdFromAccessToken();
    try {
      const response = await fetch(`${apiUrl}candidates/list_applied_posts/${userId}/`);
      
      if (!response.ok) {
        if (response.status === 404) {
          console.error("Candidate not found");
          // Handle the 404 case
          setApplicationList([]);
          return { error: "Candidate not found" };
        } else {
          throw new Error(`An error occurred: ${response.statusText}`);
        }
      }
  
      const json = await response.json();
      console.log(json);
      setApplicationList(json);
      return json;
    } catch (error) {
      console.error("Error loading data", error);
      // Handle the error appropriately here
      return { error: error.message };
    }
  };
  

  useEffect(() => {
    Load();
    console.log(applicationList);
  }, []);

  const header = (
    // <div className="table-header">
    //   Applied Posts
    //   {/* <span className="p-input-icon-left">
    //     <i className="pi pi-search" />
    //     <input
    //       type="search"
    //       onChange={(e) => setGlobalFilter(e.target.value)}
    //       placeholder="Search..."
    //     />
    //   </span> */}
    // </div>
    <></>
  );


  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  useEffect(() => {
    console.log('is Auth ?: ', isAuth);
  }, [isAuth]);

  

  const handleLoginClick = () => {
    setOpenLoginDialog(true);
  };

  const handleSignInClick = () => {
    setOpenSignInDialog(true);
  };

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }
  const senderId = getUserIdFromAccessToken();


  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
}
useEffect(() => {
  const handleScroll = () => {
    const footer = document.querySelector('footer');
    const rect = footer.getBoundingClientRect();
    const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
    setIsFooterVisible(isVisible);
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);
useEffect(() => {
  if (ChatComponent === false) {
    //console.log("ChatComponent", ChatComponent);
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 2 &&
        hasMore &&
        !isLoading
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }
}, [hasMore, isLoading]);
useEffect(() => {
  if (ChatComponent == true) {
    const handleScroll = () => {
      //console.log("scrolling");
      const footer = document.querySelector("footer");
      const rect = footer.getBoundingClientRect();
      const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
      setIsFooterVisible(isVisible);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }
}, []);

  return (
    <>
     {ChatComponent ? (
        <>
          <ButtonAppBar
            isAuth={isAuth}
            onLoginClick={handleLoginClick}
            onSignInClick={handleSignInClick}
            setChatComponent={setChatComponent}
          />
          <App candidate_id={senderId} />
        </>
      ) : (
        <>
      <ButtonAppBar 
        isAuth={isAuth} 
        onLoginClick={handleLoginClick} 
        onSignInClick={handleSignInClick} 
        setChatComponent={setChatComponent} />

      <SearchBar />
      <div > 
        <DataTable className="my-custom-table" style={{ margin: '0 16px' }}
        ref={dt}
        value={applicationList}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} posts"
        globalFilter={globalFilter}
        header={header}
        dataKey="id"
        selectionMode="single"
      >
        <Column header="Application" body={(rowData, { rowIndex }) => rowIndex + 1} style={{ minWidth: '3rem' }} />
        <Column field="post.title" header="Title" sortable style={{ minWidth: '16rem' }} />
        {/* <Column field="post.description" header="Description" sortable style={{ minWidth: '30rem' }} /> */}
        {/* <Column field="post.requirements" header="Requirements" sortable style={{ minWidth: '20rem' }} /> */}
        <Column field="post.deadline" header="Deadline" sortable style={{ minWidth: '10rem' }} />
        <Column field="post.localisation" header="location" sortable style={{ minWidth: '10rem' }} />
        <Column field="post.mode" header="mode" sortable style={{ minWidth: '10rem' }} />
        <Column field="step" header="Status" sortable style={{ minWidth: '10rem' }} />
        </DataTable>
      </div>
      
     
      <Dialog
        open={openLoginDialog}
        onClose={closeLoginPopUp}
        // fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <LoginForDialog />
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSignInDialog}
        onClose={closeSignInPopUp}
        // fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <SignInForDialog />
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
      </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
    userId: state.userId,
  });
  
  const mapDispatchToProps = {
    setUserId,
  };
  export default connect(mapStateToProps, mapDispatchToProps)(PostsApplied);
  
  
