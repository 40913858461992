import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Chip,
  CircularProgress,
} from "@mui/material";
import { LocationOn, CalendarToday, BookmarkBorder } from "@mui/icons-material";
import { COLORS } from "colors/color";
import { styled } from "@mui/material/styles";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import "./home.css";

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 250,
  position: "relative",
  overflow: "hidden",
  borderRadius: theme.shape.borderRadius,
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    transition: "background-color 0.3s ease-in-out",
  },
  "&:hover::before": {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  "& img": {
    transition: "transform 0.3s ease-in-out",
  },
  "&:hover img": {
    transform: "scale(1.05)",
  },
}));

const ImageOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)",
  color: theme.palette.common.white,
  transition: "opacity 0.3s ease-in-out",
  opacity: 0.8,
  "&:hover": {
    opacity: 1,
  },
}));

function ViewJob() {
  const location = useLocation();
  const job = location.state.job;
  const [isLoading, setIsLoading] = useState(true);
  const [showFull, setShowFull] = useState(false);

  const toggleDescription = () => {
    setShowFull(!showFull);
  };

  useEffect(() => {
    console.log("job : ", job);
    if (job) {
      setIsLoading(false);
    }
  }, [job]);

  const RequirementChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0.5),
    backgroundColor: COLORS.Button_OnHold,
  }));

  if (isLoading || !job) {
    return <CircularProgress />;
  }

  return (
    <Card sx={{ maxWidth: 600, mx: "auto", my: 2, boxShadow: 3 }}>
      <CardContent sx={{ p: 2 }}>
        <StyledCardMedia
          image={job.image || "https://via.placeholder.com/600x250?text=Job+Image"}
          title={job.title}
        >
          <ImageOverlay>
            <Typography variant="h6" component="div" gutterBottom>
              {job.title}
            </Typography>
            <Typography variant="body2">
              {job.company} · {job.rating} ★
            </Typography>
          </ImageOverlay>
        </StyledCardMedia>

        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            {job.localisation}
          </Typography>
        </Box>

        <Divider sx={{ my: 4 }} />

        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Job details
          </Typography>
          <Typography
            variant="caption"
            color="text.secondary"
            display="block"
            gutterBottom
          >
            Here's how the job details align with your profile
          </Typography>
          <Box display="flex" alignItems="center" mt={2}>
            <CalendarToday fontSize="small" sx={{ mr: 1, color: "text.secondary" }} />
            <Typography variant="body1" sx={{ mr: 3, color: "text.secondary" }}>
              Job type
            </Typography>
            <Chip label="Permanent" size="small" variant="outlined" sx={{ mr: 1 }} />
            <Chip label={job.mode} size="small" color="success" />
          </Box>
        </Box>

        <Divider sx={{ my: 4 }} />

        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Location
          </Typography>
          <Box display="flex" alignItems="center">
            <LocationOn fontSize="small" sx={{ mr: 1, color: "text.secondary" }} />
            <Typography variant="body1">{job.localisation}</Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 4 }} />

        <Box sx={{ position: "relative" }}>
          <Typography
            variant="body2"
            gutterBottom
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: showFull ? "unset" : 5,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              margin:'5%',
              mb: 1,
              "&::after": !showFull
                ? {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "80%",
                    height: "20px",
                    background: "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
                    backdropFilter: "blur(20px)",
                  }
                : {},
            }}
          >
            {job.description}
          </Typography>
          <Button
            endIcon={showFull ? <ExpandLess /> : <ExpandMore />}
            sx={{ textTransform: "none", p: 0, color: "primary.main" }}
            onClick={toggleDescription}
          >
            {showFull ? "Show less" : "Show more"}
          </Button>
        </Box>

        <Box mt={2} display="flex" flexDirection="column" alignItems="center">
          <Box width="100%" mb={2} display="flex" justifyContent="center" marginTop="9%">
            <Button
              variant="contained"
              sx={{
                height: "100%",
                width: "70%",
                color: COLORS.white,
                border: "1px solid",
                borderColor: COLORS.grey,
                bgcolor: COLORS.signIn,
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
            >
              Apply now
            </Button>
          </Box>

          <Box width="100%" mb={2} display="flex" justifyContent="center">
            <Button
              variant="outlined"
              startIcon={<BookmarkBorder />}
              sx={{
                width: "70%",
                height: "100%",
                color: COLORS.signIn,
                border: "1px solid",
                borderColor: COLORS.grey,
                bgcolor: COLORS.white,
                ":hover": {
                  bgcolor: COLORS.Button_OnHold,
                  borderColor: COLORS.signIn,
                  color: COLORS.signIn,
                },
              }}
            >
              Save this job
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ViewJob;
