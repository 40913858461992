import { create } from "zustand";

const apiUrl = process.env.REACT_APP_API_URL;

const useSignalingStore = create((set, get) => ({
  signalingServer: null,
  signalingServer2: null,
  setSignalingServer: (server) => set({ signalingServer: server }),
  setSignalingServer2: (server) => set({ signalingServer2: server }),

  messages: [],
  addMessage: (message) =>
    set((state) => ({ messages: [...state.messages, message] })),

  notifications: [],
  unreadNotifications: [],
  setNotifications: (notifications) =>
    set({ notifications: Array.isArray(notifications) ? notifications : [] }),

  setUnreadNotifications: (unreadNotifications) =>
    set({
      unreadNotifications: Array.isArray(unreadNotifications)
        ? unreadNotifications
        : [],
    }),
  addNotification: (notification) =>
    set((state) => {
      console.log("Current state:", state);
      const currentNotifications = Array.isArray(state.notifications)
        ? state.notifications
        : [];
      const currentUnreadNotifications = Array.isArray(
        state.unreadNotifications
      )
        ? state.unreadNotifications
        : [];
      console.log("Adding notification:", notification);
      return {
        notifications: [notification, ...currentNotifications],
        unreadNotifications: [notification, ...currentUnreadNotifications],
      };
    }),
  markNotificationAsRead: async (notificationId) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        console.error("No access token found");
        return;
      }

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await fetch(
        `${apiUrl}messaging/api/mark-notification-read/${notificationId}/`,
        {
          method: "POST",
          headers: headers,
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      set((state) => ({
        notifications: state.notifications.map((notif) =>
          notif.id === notificationId ? { ...notif, read: true } : notif
        ),
        unreadNotifications: state.unreadNotifications.filter(
          (notif) => notif.id !== notificationId
        ),
      }));
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  },

  fetchUnreadNotifications: async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        console.error("No access token found");
        return;
      }

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await fetch(
        `${apiUrl}messaging/api/unread-notifications/`,
        {
          method: "GET",
          headers: headers,
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      set((state) => {
        const newNotifications = Array.isArray(data) ? data : [];
        const currentNotifications = Array.isArray(state.notifications)
          ? state.notifications
          : [];
        return {
          unreadNotifications: newNotifications,
          notifications: [
            ...newNotifications,
            ...currentNotifications.filter((n) => n.read),
          ],
        };
      });
    } catch (error) {
      console.error("Error fetching unread notifications:", error);
      set({ unreadNotifications: [], notifications: [] });
    }
  },

  markNotificationsAsRead: async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        console.error("No access token found");
        return;
      }

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await fetch(
        `${apiUrl}messaging/api/mark-notifications-read/`,
        {
          method: "POST",
          headers: headers,
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const updatedNotifications = await response.json();
      set({
        notifications: Array.isArray(updatedNotifications)
          ? updatedNotifications
          : [],
        unreadNotifications: [],
      });
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  },

  messageNotificationCounts: {},

  updateMessageNotificationCount: (senderId) =>
    set((state) => ({
      messageNotificationCounts: {
        ...state.messageNotificationCounts,
        [senderId]: (state.messageNotificationCounts[senderId] || 0) + 1,
      },
    })),

  resetMessageNotificationCount: (senderId) =>
    set((state) => ({
      messageNotificationCounts: {
        ...state.messageNotificationCounts,
        [senderId]: 0,
      },
    })),

  getTotalMessageNotifications: () => {
    const state = get();
    return Object.values(state.messageNotificationCounts).reduce(
      (a, b) => a + b,
      0
    );
  },

  handleMessageNotification: (data) => {
    if (data.type === "notif_from_messages") {
      console.log("Received a message notif", data.notif);
      get().updateMessageNotificationCount(data.sender_id);
    }
  },

  notificationCounts: {},

  // Add a method to update notificationCounts
  updateNotificationCount: (userId) =>
    set((state) => ({
      notificationCounts: {
        ...state.notificationCounts,
        [userId]: (state.notificationCounts[userId] || 0) + 1,
      },
    })),

  // Add a method to reset notification count for a user
  resetNotificationCount: (userId) =>
    set((state) => ({
      notificationCounts: {
        ...state.notificationCounts,
        [userId]: 0,
      },
    })),

  // Add a method to get total notifications
  getTotalNotifications: () => {
    const state = get();
    return Object.values(state.notificationCounts).reduce((a, b) => a + b, 0);
  },

  // New state for messagenotification
  messagenotification: [],

  // Method to add a new message notification
  addMessageNotification: (notification) =>
    set((state) => ({
      messagenotification: [...state.messagenotification, notification],
    })),

  // Method to clear all message notifications
  clearMessageNotifications: () => set({ messagenotification: [] }),

  // Method to remove a specific message notification
  removeMessageNotification: (notificationId) =>
    set((state) => ({
      messagenotification: state.messagenotification.filter(
        (n) => n.id !== notificationId
      ),
    })),
  setInitialNotificationCounts: (counts) =>
    set((state) => ({
      notificationCounts: {
        ...state.notificationCounts,
        ...counts,
      },
    })),
}));

export default useSignalingStore;
