import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Stack,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Container,
  // useTheme,
  Grid,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import CreateIcon from "@mui/icons-material/Create";
// import RemoveIcon from "@mui/icons-material/Remove";
// import { styled } from "@mui/system";
import { connect } from "react-redux"; // Import connect from react-redux
import { setUserId } from "../../Redux/actions"; // Import your action
import {
  // AttachEmailTwoTone,
  AttachFile,
  // CenterFocusStrong,
  // ShowChart,
} from "@mui/icons-material";
// import { GridLoadIcon } from "@mui/x-data-grid";
import { Radio } from "@mui/material";
import { Visibility, Delete } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { COLORS } from "colors/color";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// const FormContainer = styled(Box)({
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   padding: "20px",
//   gap: "20px",
// });

const ApplicationForm = ({
  setHiddforum,
  post_id,
  post_title,
  // setUserId,
  postApplied,
  setIsClicked,
  hasApplied,
  setHasApplied,
  // addPostApplied
}) => {
  const [academicDegree, setAcademicDegree] = useState("");
  const [lastPostOccupied, setLastPostOccupied] = useState("");
  const [linkedinProfile, setLinkedinProfile] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [familyStatus, setFamilyStatus] = useState("");

  const { register, handleSubmit, reset } = useForm();
  const userId = getUserIdFromAccessToken();
  const [resumeUploaded, setResumeUploaded] = useState(false);
  const [coverLetterUploaded, setCoverLetterUploaded] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({
    resumes: null,
  });
  const [uploadedFilesList, setUploadedFilesList] = useState({
    resumes: null,
  });
  const [fieldOfStudy, setFieldOfStudy] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedResume, setUploadedResume] = useState();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleFileSelect = (fileKey, file) => {
    setSelectedFile(fileKey);
    setResumeUploaded(true); //for color
    setUploadedResume(file);
  };
  useEffect(() => {
    console.log("Updated selected resume:", uploadedResume);
    console.log("type of selected resume:", typeof uploadedResume);
  }, [uploadedResume]);

  const handleResumeChange = (event) => {
    if (event.target.files.length > 0) {
      setResumeUploaded(true);
      setUploadedFiles((prev) => ({ ...prev, resume: event.target.files[0] }));
      setUploadedResume(uploadedFiles);
    }
  };

  const handleCoverLetterChange = (event) => {
    if (event.target.files.length > 0) {
      setCoverLetterUploaded(true);
      // setUploadedFiles(prev => ({...prev, coverLetter: event.target.files[0]}));
    }
  };

  useEffect(() => {
    if (resumeUploaded) {
      console.log("Resume uploaded");
      setUploadedFiles(resumeUploaded)
    }
  }, [resumeUploaded]);

  useEffect(() => {
    if (coverLetterUploaded) {
      //console.log("Cover letter uploaded");
    }
  }, [coverLetterUploaded]);

  // const theme = useTheme();

  const handleAcademicDegreeChange = (event) => {
    setAcademicDegree(event.target.value);
  };

  const handleLastPostOccupiedChange = (event) => {
    setLastPostOccupied(event.target.value);
  };

  const handleLinkedinProfileChange = (event) => {
    setLinkedinProfile(event.target.value);
  };

  const handleDateOfBirthChange = (newValue) => {
    setDateOfBirth(newValue);
  };

  const handleFamilyStatusChange = (event) => {
    setFamilyStatus(event.target.value);
  };

  const handleFieldOfStudyChange = (event) => {
    setFieldOfStudy(event.target.value);
  };

  const submitApplication = async (applicationFormData) => {
    const applicationResponse = await axios.post(
      `${apiUrl}candidates/application/create/`,
      applicationFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("Application submitted:", applicationResponse.data);
    return applicationResponse;
  };
  
  const updateCandidateInfo = async (candidateFormData) => {
    const candidateResponse = await axios.patch(
      `${apiUrl}candidates/${userId}/update/`,
      candidateFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("User updated:", candidateResponse.data);
    return candidateResponse;
  };
  
  const onSubmit = async (data) => {
    try {
      console.log('data:',data)
      const applicationFormData = new FormData();
      const candidateFormData = new FormData();
      
      console.log('test1');
      // Application data
      applicationFormData.append("post", post_id);
      applicationFormData.append("candidate", userId);
      applicationFormData.append("status", "p");
      
      console.log('test2');
      // Candidate data
      candidateFormData.append("academic_degree", academicDegree);
      candidateFormData.append("last_post_occupied", lastPostOccupied);
      candidateFormData.append("linkedin_profile", linkedinProfile);
      candidateFormData.append("field_of_study", fieldOfStudy);
      candidateFormData.append(
        "date_of_birth",
        dateOfBirth ? dateOfBirth.format("YYYY-MM-DD") : ""
      );
      candidateFormData.append("family_status", familyStatus);
      
      console.log('test3');
      // Handle resume
      if (uploadedResume) {
        console.log('test4');
        if (typeof uploadedResume === "string") {
          console.log('test5');
          // If uploadedResume is a path of an existing resumee
          const relativePath = uploadedResume.split("media/").pop();
          applicationFormData.append("resume_path", relativePath);
        } else if (uploadedResume instanceof File) {
          // If uploadedResume is a File object (new upload)
          applicationFormData.append("resume", uploadedResume);

        } else {
          throw new Error("Invalid resume format");
        }
      } else if (data.resume && data.resume[0]) {
        applicationFormData.append("resume", data.resume[0]);
        const resumePath = `resumes/${data.resume[0].name}`;
        applicationFormData.append("resume_path", resumePath);


      } else {
        throw new Error("No resume selected");
      }
      
      console.log('test6');
      // Handle cover letter
      if (data.cover_letter && data.cover_letter[0]) {
        applicationFormData.append("cover_letter", data.cover_letter[0]);
      }
      
      console.log('test7');
      // addPostApplied()
      postApplied();
      setIsClicked(true);
      console.log('applicationFormData', applicationFormData);
  
      // Submit application
      await submitApplication(applicationFormData);
  
      // Update candidate information
      await updateCandidateInfo(candidateFormData);
  
      setHiddforum(true);
      setHasApplied(true);
      reset();
    } catch (error) {
      console.error("Error submitting application:", error.response?.data || error.message);
      // Handle error (e.g., show error message to user)
    }
  };
  const Load = async () => {
    const userId = getUserIdFromAccessToken();
    const response = await fetch(
      `${apiUrl}authentication/files-for-candidate/${userId}/`
    );

    if (!response.ok) {
      if (response.status === 404) {
        console.error("resumes not found");
        // Handle the 404 case

        return { error: "post_saved not found" };
      } else {
        throw new Error(`An error occurred: ${response.statusText}`);
      }
    }

    const json = await response.json();
    //console.log(json);
    setUploadedFiles(json);
    setUploadedFilesList(json);
    return json;
  };
  useEffect(() => {
    Load();
    //console.log(uploadedFiles);
    //console.log("is already applied to a post?", hasApplied);
  }, []);

  function getUserIdFromAccessToken() {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("No access token found in local storage");
      return null;
    }

    try {
      const decodedToken = parseJwt(accessToken);
      const userId = decodedToken.user_id; // Adjust according to your JWT payload structure
      return userId;
    } catch (error) {
      console.error("Failed to decode access token", error);
      return null;
    }
  }

  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    //console.log("payload : ", jsonPayload);

    return JSON.parse(jsonPayload);
  }
  const UploadedFilesList = ({
    files,
    onDelete,
    selectedFile,
    onFileSelect,
    onShowFile,
  }) => (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        List of files attached to your submissions.
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Select</TableCell>
            <TableCell>Resume</TableCell>
            <TableCell>Date</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(files).map(
            ([key, file], index) =>
              file && (
                <TableRow key={key}>
                  <TableCell>
                    <Radio
                      checked={selectedFile === key}
                      onChange={() => onFileSelect(key, file)}
                      value={key}
                    />
                  </TableCell>
                  <TableCell>
                    {file.resume
                      ? `Resume ${index + 1}`
                      : `Resume ${index + 1}`}
                  </TableCell>
                  <TableCell>
                    {file.date
                      ? new Date(file.date).toLocaleDateString()
                      : new Date().toLocaleDateString()}
                  </TableCell>
                  <TableCell align="right">
                    <div className="flex justify-end">
                      <Tooltip title="View File">
                        <IconButton
                          onClick={() => onShowFile(key, file.resume || file)}
                          color="primary"
                          className="mr-2"
                        >
                          <Visibility fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete File">
                        <IconButton onClick={() => onDelete(key)} color="error">
                          <Delete fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </Box>
  );
  const handleFileDelete = (fileType) => {
    setUploadedFiles((prev) => ({ ...prev, [fileType]: null }));
    if (fileType === "resume") {
      setResumeUploaded(false);
    } else {
      setCoverLetterUploaded(false);
    }
  };

  const handleShowFile = (fileType, file) => {
    //console.log("File:,first method", file);
    let fileURL;

    if (typeof file === "string") {
      fileURL = `${apiUrl}media/${file}`;
    } else if (file instanceof File) {
      fileURL = URL.createObjectURL(file);
    } else {
      console.error("Invalid file type", file);
      return;
    }

    window.open(fileURL, "_blank");
  };

  return (
    <Container maxWidth="sm" component="form" onSubmit={handleSubmit(onSubmit)}>
    <Box
      sx={{
        mt: 4,
        bgcolor: "#fff",
        borderRadius: "16px",
        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
        padding: "32px",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          color: "#000",
          textAlign: "center",
          fontWeight: 800,
          marginBottom: "32px",
          textTransform: "uppercase",
          letterSpacing: "2px",
          fontSize: { xs: "1.5rem", sm: "1.75rem", md: "2rem" },
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      >
        {post_title}
      </Typography>
        <div className="row" style={{ marginTop: "3%" }}>
          {!hasApplied ? (
            <>
              <Box sx={{ width: "100%", marginBottom: "16px" }}>
                <Stack spacing={1} margin={1}>
                  <Typography
                    fontSize={25}
                    fontFamily={"sans-serif"}
                    color={COLORS.signIn}
                  >
                    Complete the user Information
                  </Typography>
                </Stack>

                <FormControl
                  fullWidth
                  sx={{ width: "100%", marginTop: "2%" }}
                  required="true"
                >
                  <InputLabel id="academic-degree-label">
                    Academic Degree
                  </InputLabel>
                  <Select
                    labelId="academic-degree-label"
                    id="academic-degree"
                    value={academicDegree}
                    onChange={handleAcademicDegreeChange}
                    label="Academic Degree"
                  >
                    <MenuItem value="baccalaureat">Baccalauréat</MenuItem>
                    <MenuItem value="deug">
                      DEUG (Diplôme d'Etudes Universitaires Générales)
                    </MenuItem>
                    <MenuItem value="licence">Licence</MenuItem>
                    <MenuItem value="master">Master</MenuItem>
                    <MenuItem value="doctorat">Doctorat</MenuItem>
                    <MenuItem value="ingenieur">Diplôme d'Ingénieur</MenuItem>
                    <MenuItem value="dut">
                      DUT (Diplôme Universitaire de Technologie)
                    </MenuItem>
                    <MenuItem value="bts">
                      BTS (Brevet de Technicien Supérieur)
                    </MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ width: "100%", marginBottom: "16px" }}>
                <FormControl
                  fullWidth
                  sx={{ width: "100%", marginTop: "2%" }}
                  required
                >
                  <InputLabel id="field-of-study-label">
                    Field of Study
                  </InputLabel>
                  <Select
                    labelId="field-of-study-label"
                    id="field-of-study"
                    value={fieldOfStudy}
                    onChange={handleFieldOfStudyChange}
                    label="Field of Study"
                  >
                    <MenuItem value="computer-science">
                      Computer Science
                    </MenuItem>
                    <MenuItem value="business-administration">
                      Business Administration
                    </MenuItem>
                    <MenuItem value="mechanical-engineering">
                      Mechanical Engineering
                    </MenuItem>
                    <MenuItem value="electrical-engineering">
                      Electrical Engineering
                    </MenuItem>
                    <MenuItem value="civil-engineering">
                      Civil Engineering
                    </MenuItem>
                    <MenuItem value="medicine">Medicine</MenuItem>
                    <MenuItem value="law">Law</MenuItem>
                    <MenuItem value="psychology">Psychology</MenuItem>
                    <MenuItem value="economics">Economics</MenuItem>
                    <MenuItem value="education">Education</MenuItem>
                    <MenuItem value="chemistry">Chemistry</MenuItem>
                    <MenuItem value="biology">Biology</MenuItem>
                    <MenuItem value="physics">Physics</MenuItem>
                    <MenuItem value="art-design">Art and Design</MenuItem>
                    <MenuItem value="literature">Literature</MenuItem>
                    <MenuItem value="history">History</MenuItem>
                    <MenuItem value="sociology">Sociology</MenuItem>
                    <MenuItem value="political-science">
                      Political Science
                    </MenuItem>
                    <MenuItem value="philosophy">Philosophy</MenuItem>
                    <MenuItem value="mathematics">Mathematics</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ width: "100%", marginBottom: "16px" }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Last post occupied"
                  id="Last post occupied"
                  required="true"
                  value={lastPostOccupied}
                  onChange={handleLastPostOccupiedChange}
                />
              </Box>
              <Box sx={{ width: "100%", marginBottom: "16px" }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="LinkedIn profile"
                  id="LinkedIn profile"
                  value={linkedinProfile}
                  onChange={handleLinkedinProfileChange}
                />
              </Box>

              <Box sx={{ display: "flex", gap: "16px", marginBottom: "5%" }}>
                <Box sx={{ flex: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      required="true"
                      sx={{ width: "100%" }}
                      label="Date of birth"
                      value={dateOfBirth}
                      onChange={handleDateOfBirthChange}
                      format="MM-DD-YYYY"
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Family status"
                    id="Family status"
                    value={familyStatus}
                    onChange={handleFamilyStatusChange}
                  />
                </Box>
              </Box>
            </>
          ) : (
            ""
          )}
        </div>
        <label
          htmlFor="resume-upload"
          style={{ display: "block", cursor: "pointer" }}
        >
          <Card
            sx={{
              mb: 3,
              borderRadius: "12px",
              boxShadow: "none",
              transition: "all 0.3s ease",
              "&:hover": {
                transform: "translateY(-4px)",
                boxShadow: "0 6px 12px rgba(0,0,0,0.1)",
              },
              bgcolor: resumeUploaded ? "rgba(0,0,0,0.05)" : "#fff",
              border: "2px dashed",
              borderColor: resumeUploaded ? "#000" : "#E1E1E1",
            }}
          >
            <CardContent sx={{ padding: "24px" }}>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <CloudUploadIcon sx={{ color: "#000", fontSize: "48px" }} />
                </Grid>
                <Grid item xs>
                  <Typography
                    variant="h6"
                    sx={{ color: "#000", fontWeight: 600 }}
                  >
                    Upload your Resume - CV
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#707070", mt: 1 }}>
                    Accepted formats: PDF, DOCX, RTF, TXT
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </label>
        <input
          id="resume-upload"
          type="file"
          style={{ display: "none" }}
          {...register("resume", {
            onChange: handleResumeChange,
            onChange: handleFileSelect,
          })}
        />

        <label
          htmlFor="cover-letter-upload"
          style={{ display: "block", cursor: "pointer" }}
        >
          <Card
            sx={{
              mb: 3,
              borderRadius: "12px",
              boxShadow: "none",
              transition: "all 0.3s ease",
              "&:hover": {
                transform: "translateY(-4px)",
                boxShadow: "0 6px 12px rgba(0,0,0,0.1)",
              },
              bgcolor: coverLetterUploaded ? "rgba(0,0,0,0.05)" : "#fff",
              border: "2px dashed",
              borderColor: coverLetterUploaded ? "#000" : "#E1E1E1",
            }}
          >
            <CardContent sx={{ padding: "24px" }}>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <AttachFile sx={{ color: "#000", fontSize: "48px" }} />
                </Grid>
                <Grid item xs>
                  <Typography
                    variant="h6"
                    sx={{ color: "#000", fontWeight: 600 }}
                  >
                    Upload your Cover Letter
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#707070", mt: 1 }}>
                    We'll guide you through the process
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </label>
        <input
          id="cover-letter-upload"
          type="file"
          style={{ display: "none" }}
          {...register("cover_letter", {
            required: true,
            onChange: handleCoverLetterChange,
          })}
        />

        <UploadedFilesList
          files={uploadedFilesList}
          onDelete={handleFileDelete}
          selectedFile={selectedFile}
          onFileSelect={(key, file) =>
            handleFileSelect(key, file.resume || file)
          }
          onShowFile={handleShowFile}
        />

        <Button
          variant="contained"
          fullWidth
          size="large"
          type="submit"
          sx={{
            mt: 4,
            bgcolor: "#000",
            color: "#fff",
            fontWeight: 600,
            fontSize: "1rem",
            padding: "12px",
            borderRadius: "8px",
            textTransform: "none",
            transition: "all 0.3s ease",
            "&:hover": {
              bgcolor: "rgba(0,0,0,0.8)",
            },
            "&:disabled": {
              bgcolor: "#E1E1E1",
              color: "#707070",
            },
          }}
        >
          Submit my application
        </Button>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  userId: state.userId,
});

const mapDispatchToProps = {
  setUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationForm);